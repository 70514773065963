import { Pipe, PipeTransform } from '@angular/core';
import { Locales } from '@shared/modules/localization/locales';
import { SportTypes } from '@core/models/sport';
import { LocalizationService } from '@shared/modules/localization/localization.service';

export function translateSportName(sportId: number, locale: Locales): string {
  if (locale === Locales.ru) {
    switch (sportId) {
      case SportTypes.basketball:
        return 'Баскетбол';
      case SportTypes.volleyball:
        return 'Волейбол';
      case SportTypes.classic_basketball:
        return 'Баскетбол';
      case SportTypes.streetball:
        return 'Баскетбол 3x3';
      case SportTypes.basketball_1x1:
        return 'Баскетбол 1x1';
      case SportTypes.classic_volleyball:
        return 'Волейбол';
      case SportTypes.beach_volleyball:
        return 'Пляжный волейбол';
      case SportTypes.hockey:
        return 'Хоккей';
      case SportTypes.ice_hockey:
        return 'Хоккей';
      case SportTypes.hockey_with_ball:
        return 'Хоккей с мячом';
      case SportTypes.football:
        return 'Футбол';
      case SportTypes.classic_football:
        return 'Футбол';
      case SportTypes.mini_football:
        return 'Мини-футбол';
      case SportTypes.handball:
        return 'Гандбол';
      case SportTypes.handball_classic:
        return 'Гандбол';
      case SportTypes.rugby:
        return 'Рэгби';
      case SportTypes.rugby15:
        return 'Рэгби 15';
      case SportTypes.rugby7:
        return 'Рэгби 7';
      case SportTypes.waterpolo:
        return 'Водное поло';
      case SportTypes.classic_waterpolo:
        return 'Водное поло';
      case SportTypes.mini_waterpolo:
        return 'Мини водное поло';
      case SportTypes.media_basket:
        return 'Медиа баскет';
      case SportTypes.moneyball_basket:
        return 'Moneyball баскет';
      case SportTypes.wrestball:
        return 'Рестбол';
      case SportTypes.wrestball_classic:
        return 'Рестбол';
      case SportTypes.winline_basket:
        return 'Winline баскет';
      case SportTypes.dota:
        return 'Dota';
      case SportTypes.dota_classic:
        return 'Dota';
      case SportTypes.cs:
        return 'Counter-Strike';
      case SportTypes.cs_classic:
        return 'Counter-Strike';
    }
  }
  if (locale === Locales.en) {
    switch (sportId) {
      case SportTypes.basketball:
        return 'Basketball';
      case SportTypes.volleyball:
        return 'Volleyball';
      case SportTypes.classic_basketball:
        return 'Basketball';
      case SportTypes.streetball:
        return 'Basketball 3x3';
      case SportTypes.basketball_1x1:
        return 'Basketball 1x1';
      case SportTypes.classic_volleyball:
        return 'Volleyball';
      case SportTypes.beach_volleyball:
        return 'Beach volleyball';
      case SportTypes.hockey:
        return 'Hockey';
      case SportTypes.ice_hockey:
        return 'Hockey';
      case SportTypes.hockey_with_ball:
        return 'Ball hockey';
      case SportTypes.football:
        return 'Football';
      case SportTypes.classic_football:
        return 'Football';
      case SportTypes.mini_football:
        return 'Mini-football';
      case SportTypes.handball:
        return 'Handball';
      case SportTypes.handball_classic:
        return 'Handball';
      case SportTypes.rugby:
        return 'Rugby';
      case SportTypes.rugby15:
        return 'Rugby 15';
      case SportTypes.rugby7:
        return 'Rugby 7';
      case SportTypes.waterpolo:
        return 'Waterpolo';
      case SportTypes.classic_waterpolo:
        return 'Waterpolo';
      case SportTypes.mini_waterpolo:
        return 'Waterpolo mini';
      case SportTypes.media_basket:
        return 'Media basket';
      case SportTypes.moneyball_basket:
        return 'Moneyball basket';
      case SportTypes.wrestball:
        return 'Wrestball';
      case SportTypes.wrestball_classic:
        return 'Wrestball';
      case SportTypes.winline_basket:
        return 'Winline basket';
      case SportTypes.dota:
        return 'Dota';
      case SportTypes.dota_classic:
        return 'Dota';
      case SportTypes.cs:
        return 'Counter-Strike';
      case SportTypes.cs_classic:
        return 'Counter-Strike';
    }
  }
}

@Pipe({
  name: 'mtgSportName',
})
export class SportNamePipe implements PipeTransform {
  constructor(
    private localizationService: LocalizationService,
  ) {
  }

  transform(sportId: number): any {
    return translateSportName(sportId, this.localizationService.getLocale());
  }
}
