import { BaseModel, ModelInstance, ToBackHook, ToFrontHook } from '@core/models/util';
import { File } from './file';


@ModelInstance({
  mappingFields: {
    id: 'id',
    file: 'file',
  },
  relation: {
    file: File,
  }
})
export class LeagueDocument extends BaseModel {
  id: number;
  file: File;

  @ToFrontHook
  static toFront(data: any): any {
    return undefined;
  }

  @ToBackHook
  static toBack(data: any): any {}
}
