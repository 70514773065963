import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, listField, MomentDateTimeField } from '@core/models/util';
import { File } from './file';
import { Tournament } from '@core/models/tournament';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    id: 'id',
    title: 'title',
    picture: 'picture',
    link: 'link',
    sort: 'sort',
  },
  relation: {
    picture: File
  }
})
export class LeaguePartner extends BaseModel {
  id: number;
  title: string;
  picture: File;
  link: string;
  sort: number;

  @ToFrontHook
  static toFront(data: any): any {
    return undefined;
  }

  @ToBackHook
  static toBack(data: any): any {}
}
