import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    game: 'матч',
    bullitsSeries: 'Буллиты серии',
  },
  [Locales.en]: {
    game: 'game',
    bullitsSeries: 'Shootout series',
  }
};
