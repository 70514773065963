import { Directive, HostBinding, HostListener, Input, OnInit, Optional } from '@angular/core';
import { ButtonGroupComponent } from '../button-group/button-group.component';

@Directive({
  selector: '[mtgButton]'
})
export class ButtonDirective implements OnInit {
  @Input()
  size: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  @Input('mtgButton')
  color: 'primary' | 'default' | 'secondary' | 'link' = 'default';

  @Input('mtgButtonType')
  type: 'button' | 'link' | 'circle' = 'button';

  @Input()
  circle: boolean;

  @Input()
  value: any;

  @Input()
  loading: boolean;

  @Input()
  active: boolean;

  @HostBinding('class.button')
  get defaultClass() {
    return true;
  }

  @HostBinding('class.button--color-primary')
  get primaryColor() {
    return this.color === 'primary';
  }

  @HostBinding('class.button--color-secondary')
  get secondaryColor() {
    return this.color === 'secondary';
  }

  @HostBinding('class.button--color-default')
  get defaultColor() {
    return this.color === 'default';
  }

  @HostBinding('class.button--link')
  get linkStyle() {
    return this.color === 'link';
  }

  @HostBinding('class.button--size-xs')
  get xsSize() {
    return this.size === 'xs';
  }

  @HostBinding('class.button--size-sm')
  get smSize() {
    return this.size === 'sm';
  }

  @HostBinding('class.button--size-md')
  get mdSize() {
    return this.size === 'md';
  }

  @HostBinding('class.button--size-lg')
  get lgSize() {
    return this.size === 'lg';
  }

  @HostBinding('class.button--type-button')
  get isButton() {
    return this.type === 'button';
  }

  @HostBinding('class.button--type-link')
  get isLink() {
    return this.type === 'link';
  }

  @HostBinding('class.button--type-circle')
  get isCircle() {
    return this.type === 'circle';
  }

  @HostBinding('class.loading')
  get loadingClass() {
    return this.loading;
  }

  @HostBinding('class.active')
  get activeClass() {
    return this.active;
  }

  @HostListener('click')
  onClick() {
    if (this.buttonGroup) {
      this.buttonGroup.onChange(this.value);
    }
  }

  constructor(
    @Optional() private buttonGroup: ButtonGroupComponent
  ) {}

  ngOnInit(): void {
    if (this.buttonGroup) {
      this.buttonGroup.addButton(this);
    }
  }
}
