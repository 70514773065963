import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from '@core/models/util';

export enum NotificationAllowTypes {
  all = 1,
  important,
  none
}

@ModelInstance({
  mappingFields: {
    user_id: 'userId',
    popup: 'popup',
    sound: 'sound',
    browser: 'browser',
    email: 'email'
  },
  relation: {
    sound: enumField(NotificationAllowTypes),
    browser: enumField(NotificationAllowTypes),
  }
})
export class NotificationSettings extends BaseModel {
  userId: number;
  popup: boolean;
  sound: NotificationAllowTypes;
  browser: NotificationAllowTypes;
  email: boolean;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
