import { enumField, ModelInstance, ToFrontHook, ToBackHook, BaseModel, DateTimeField, penaltyTypeField } from './util';
import { GameUser } from './game-user';
import { GameLogBase } from './game-log-base';

export enum HockeyGameLogTypes {
  enter_game = 1,
  exit_game,
  shot_miss,
  shot_on_goal,
  shot_blocked,
  goal,
  shootout_attempt,
  shootout_goal,
  assist,
  block_shot ,
  minor_penalty ,
  major_penalty ,
  misconduct_penalty ,
  game_misconduct_penalty ,
  match_penalty ,
  penalty_shot ,
  face_off_lose ,
  face_off_win ,
  save ,
  timeout,
  penalty_start,
  penalty_end,
  after_game_shootout_attempt,
  after_game_shootout_goal,
  after_game_shootout_save,
  shootout_save,
}

export enum HockeyAdvantageTypes {
  power_play  = 1,
  shorthanded,
  even_strength
}

export interface PenaltyType {
  id: number;
  code: string;
  title: string;
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    advantage: 'advantage',
    advantage_num: 'advantageNum',
    is_goalie: 'isGoalie',
    penalty_type: 'penaltyType',
    penalized_game_user_id: 'penalizedGameUserId',
    mutual_penalty: 'mutualPenalty',
  },
  relation: {
    datetime: DateTimeField,
    logType: enumField(HockeyGameLogTypes),
    advantage: enumField(HockeyAdvantageTypes),
    penaltyType: penaltyTypeField()
  }
})
export class HockeyGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: HockeyGameLogTypes;
  datetime: Date;
  time: number;
  period: number;
  active = true;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  advantage: HockeyAdvantageTypes;
  advantageNum: number;
  isGoalie: boolean;
  penaltyType: PenaltyType;
  _penalizedGameUserId: number;
  mutualPenalty: boolean;

  get penalizedGameUserId(): number {
    if (this._penalizedGameUserId) {
      return this._penalizedGameUserId;
    }
    return this.gameUserId;
  }

  set penalizedGameUserId(value: number) {
    this._penalizedGameUserId = value;
  }

  compare(model: HockeyGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      return this.datetime.getTime() < model.datetime.getTime() ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  isScoreType(): boolean {
    return [HockeyGameLogTypes.goal, HockeyGameLogTypes.shootout_goal, HockeyGameLogTypes.after_game_shootout_goal].indexOf(this.logType) > -1;
  }

  isPenaltyType(): boolean {
    return [
      HockeyGameLogTypes.minor_penalty, HockeyGameLogTypes.major_penalty,
      HockeyGameLogTypes.misconduct_penalty, HockeyGameLogTypes.game_misconduct_penalty,
      HockeyGameLogTypes.penalty_shot, HockeyGameLogTypes.match_penalty
    ].indexOf(this.logType) > -1;
  }

  isAfter(log: HockeyGameLog): boolean {
    if (this.time === log.time && this.period === log.period) {
      return this.id > log.id;
    }
    if (this.period === log.period) {
      return this.time > log.time;
    }
    return this.period > log.period;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
