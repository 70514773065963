import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    notificationLoadError: 'Ошибка загрузки уведомлений',
    error: 'Произошла ошибка',
    markRead: 'Отметить прочитанным',
    important: 'Важные',
    info: 'Информационные',
    all: 'Все',
    allMarkRead: 'Отметить все как прочитанные',
    settings: 'Настройки уведомлений',
    defaultText: 'Пока не происходит ничего интересного',
  },
  [Locales.en]: {
    notificationLoadError: 'Error loading notifications',
    error: 'An error has occurred',
    markRead: 'Mark as read',
    important: 'Important',
    info: 'Information',
    all: 'All',
    allMarkRead: 'Mark everything as read',
    settings: 'Notification settings',
    defaultText: 'Nothing interesting is happening yet',
  }
};
