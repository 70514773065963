import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { League } from '@core/models/league';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Tournament, TournamentStatuses } from '@core/models/tournament';
import { LeagueUser, LeagueUserPermissions } from '@core/models/league-user';
import { LeagueCourt } from '@core/models/league-court';
import { Game, GameStatuses } from '@core/models/game';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { TournamentSeason } from '@core/models/tournament-season';
import { LeagueToken, LeagueTokenTypes } from '@core/models/league-token';
import { IVKLogin } from '@shared/modules/authorization/models/social-auth';

export interface LeagueGamesFilters {
  courtId?: number;
  tournamentId?: number;
  tournamentTour?: number;
  tournamentSeasonId?: number;
  query?: string;
  status?: GameStatuses;
}

export interface LeagueTournamentsFilters {
  query?: string;
  status?: TournamentStatuses;
}


@Injectable()
export class OrgLeagueService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  checkDomain(domain: string, leagueId?: number): Observable<boolean> {
    const params = new HttpParams().set('domain', domain).set('league_id', leagueId ? leagueId.toString() : '');
    return this.httpClient.get(`/org/api/v1/check_league_domain/`, {params})
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  createLeague(league: League): Observable<League> {
    return this.httpClient.post('/org/api/v1/league/', League.toBack(league))
      .pipe(map(data => League.toFront(data)));
  }

  getLeagues(): Observable<League[]> {
    return this.httpClient.get('/org/api/v1/league/').pipe(
      map(data => League.toFront(data))
    );
  }

  getLeague(id: number): Observable<League> {
    return this.httpClient.get(`/org/api/v1/league/${id}/`).pipe(
      map(data => League.toFront(data))
    );
  }

  updateLeague(leagueId: number, data: any): Observable<League> {
    return this.httpClient.patch(`/org/api/v1/league/${leagueId}/`, League.toBack(data)).pipe(
      map(league => League.toFront(league))
    );
  }

  deleteLeague(leagueId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league/${leagueId}/`);
  }

  getLeagueUsers(leagueId: number): Observable<LeagueUser[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/users/`).pipe(
      map(data => LeagueUser.toFront(data))
    );
  }

  getLeagueUser(leagueUserId: number): Observable<LeagueUser> {
    return this.httpClient.get(`/org/api/v1/league_user/${leagueUserId}/`).pipe(
      map(data => LeagueUser.toFront(data))
    );
  }

  addLeagueUser(leagueId: number, leagueUser: LeagueUser): Observable<LeagueUser> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/users/`, LeagueUser.toBack(leagueUser)).pipe(
      map(data => LeagueUser.toFront(data))
    );
  }

  updateLeagueUser(leagueUserId: number, permissions: string[]): Observable<LeagueUser> {
    return this.httpClient.patch(`/org/api/v1/league_user/${leagueUserId}/`, {permissions}).pipe(
      map(data => LeagueUser.toFront(data))
    );
  }

  deleteLeagueUser(leagueUserId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_user/${leagueUserId}`);
  }

  getTournaments(leagueId: number): Observable<Tournament[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/tournaments/`).pipe(
      map(data => Tournament.toFront(data))
    );
  }

  getTournamentsPaginated(
    leagueId: number,
    page: number,
    size: number,
    filters?: LeagueTournamentsFilters
  ): Observable<PaginatedResponse<Tournament[]>> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (filters && filters.status) {
      params = params.set('status', TournamentStatuses[filters.status]);
    }
    if (filters && filters.query) {
      params = params.set('query', filters.query);
    }
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/tournaments/`, { params, observe: 'response' }).pipe(
      map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: Tournament.toFront(response.body)
      }))
    );
  }

  checkTournamentAlias(leagueId: number, alias: string, tournamentId?: number): Observable<boolean> {
    const params = new HttpParams().set('alias', alias).set('tournament_id', tournamentId ? tournamentId.toString() : '');
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/check_tournament_alias/`, {params}).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  createTournament(leagueId: number, tournament: Tournament): Observable<Tournament> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/tournaments/`, Tournament.toBack(tournament)).pipe(
      map(data => Tournament.toFront(data))
    );
  }

  deleteTournament(tournamentId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament/${tournamentId}/`);
  }

  getCourts(leagueId: number): Observable<LeagueCourt[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/courts/`).pipe(
      map(data => LeagueCourt.toFront(data))
    );
  }

  createCourt(leagueId: number, court: object|LeagueCourt): Observable<LeagueCourt> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/courts/`, LeagueCourt.toBack(court)).pipe(
      map(data => LeagueCourt.toFront(data))
    );
  }

  updateCourt(courtId: number, data: object|LeagueCourt): Observable<LeagueCourt> {
    return this.httpClient.patch(`/org/api/v1/league_court/${courtId}/`, LeagueCourt.toBack(data)).pipe(
      map(response => LeagueCourt.toFront(response))
    );
  }

  deleteCourt(courtId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_court/${courtId}/`);
  }

  getGames(leagueId: number): Observable<Game[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/games/`).pipe(
      map(data => Game.toFront(data))
    );
  }

  async getLeagueGames(leagueId: number, page: number, size: number, filters: LeagueGamesFilters): Promise<PaginatedResponse<Game[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    if (filters.courtId) {
      params = params.set('court_id', filters.courtId.toString());
    }
    if (filters.tournamentId) {
      params = params.set('tournament_id', filters.tournamentId.toString());
    }
    if (filters.tournamentTour) {
      params = params.set('tournament_tour', filters.tournamentTour.toString());
    }
    if (filters.tournamentSeasonId) {
      params = params.set('tournament_season_id', filters.tournamentSeasonId.toString());
    }
    if (filters.status) {
      params = params.set('status', GameStatuses[filters.status]);
    }
    if (filters.query) {
      params = params.set('query', filters.query);
    }
    return this.httpClient.get(
      `/org/api/v1/league/${leagueId}/games/`,
      {params, observe: 'response'}
    )
      .pipe(map(result => ({
        total: +result.headers.get('X-Page-Count'),
        data: Game.toFront(result.body)
      }))).toPromise();
  }

  myPermission(leagueId: number): Observable<LeagueUserPermissions[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/permission/`).pipe(
      map(data => (data['permissions'] || []).map(item => LeagueUserPermissions[item]))
    );
  }

  getLeagueGraphicSettings(leagueId: number): Observable<any> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/graphic_settings/`);
  }

  updateLeagueGraphicSettings(leagueId: number, data: any): Observable<any> {
    return this.httpClient.patch(`/org/api/v1/league/${leagueId}/graphic_settings/`, data);
  }

  getSeasons(leagueId: number): Observable<TournamentSeason[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/seasons/`).pipe(
      map(response => TournamentSeason.toFront(response))
    );
  }

  getTokens(leagueId: number, tokenType?: LeagueTokenTypes): Observable<LeagueToken[]> {
    let params = new HttpParams();
    if (tokenType) {
      params = params.set('token_type', LeagueTokenTypes[tokenType]);
    }
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/tokens/`, {params}).pipe(
      map(response => LeagueToken.toFront(response)),
    );
  }

  refreshVkToken(leagueId: number, data: IVKLogin): Observable<LeagueToken> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/tokens/vk_token/`, {
      code: data.code,
      redirect_uri: data.redirectUri,
    }).pipe(
      map(response => LeagueToken.toFront(response)),
    );
  }

  createSeason(leagueId: number, data: any): Observable<TournamentSeason> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/seasons/`, TournamentSeason.toBack(data)).pipe(
      map(response => TournamentSeason.toFront(response)),
    );
  }

  exportTeams(tournamentIds: number[]): Observable<Blob> {
    let params = new HttpParams();
    if (tournamentIds) {
      params = params.set('tournament_ids', tournamentIds.join(','));
    }
    return this.httpClient.get(`/org/api/v1/league_team/export_to_file/`, {params, responseType: 'blob'});
  }
}
