<div class="dialog">
  <div class="dialog-container" #container cdk-scrollable [ngClass]="{fullscreen: isFullScreen}">
    <a href="javascript:" class="btn back-btn" *ngIf="isBackButtonVisible" (click)="close()">
      <span class="icon-chevron-left"></span>
    </a>
    <a href="javascript:;" class="btn close-btn" *ngIf="isCloseButtonVisible" (click)="close()">
      <span class="icon-close"></span>
    </a>
    <div class="backdrop" (click)="backdropClicked($event)"></div>
    <div class="dialog-content" #content>
      <ng-container #host></ng-container>
    </div>
  </div>
</div>
