import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Game } from '@core/models/game';
import { map } from 'rxjs/operators';
import { RugbyGameConfig } from '@core/models/rugby-game-config';
import { RugbyGameLog } from '@core/models/rugby-game-log';
import { OrgGameService } from '@core/services/org-game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { RugbyGameTeamStatistic } from '@core/models/rugby-game-team-statistic';
import { RugbyGameStatistic } from '@core/models/rugby-game-statistic';

@Injectable()
export class OrgRugbyGameService extends OrgGameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(id: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/rugby_game/${id}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/rugby_game/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/rugby_game/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(data))
      );
  }

  getGameLogs(gameId: number): Observable<RugbyGameLog[]> {
    return this.httpClient
      .get(`${this.apiUrl}/rugby_game/${gameId}/logs/`)
      .pipe(
        map(data => RugbyGameLog.toFront(data))
      );
  }

  saveGameLog(gameId: number, gameLog: RugbyGameLog): Observable<RugbyGameLog> {
    return this.httpClient
      .post(`${this.apiUrl}/rugby_game/${gameId}/logs/`, RugbyGameLog.toBack(gameLog))
      .pipe(
        map(data => RugbyGameLog.toFront(data))
      );
  }

  updateGameLog(gameLogId: number, data: any): Observable<RugbyGameLog> {
    return this.httpClient
      .patch(`${this.apiUrl}/rugby_game_log/${gameLogId}/`, RugbyGameLog.toBack(data))
      .pipe(
        map(result => RugbyGameLog.toFront(result))
      );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient
      .delete(`${this.apiUrl}/rugby_game_log/${gameLogId}/`);
  }

  getGameConfig(gameId: number): Observable<RugbyGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/rugby_game/${gameId}/config/`).pipe(
      map(result => RugbyGameConfig.toFront(result))
    );
  }

  updateGameConfig(gameId: number, data: any): Observable<RugbyGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/rugby_game/${gameId}/config/`, RugbyGameConfig.toBack(data)).pipe(
      map(result => RugbyGameConfig.toFront(result))
    );
  }

  closeGame(game: Game, result_type?: any): Observable<Game> {
    return this.httpClient.post(`${this.apiUrl}/rugby_game/${game.id}/close/`, {result_type: result_type}).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<RugbyGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/rugby_game/${gameId}/team_statistic/`).pipe(
      map(result => RugbyGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<RugbyGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/rugby_game/${gameId}/user_statistic/`).pipe(
      map(result => RugbyGameStatistic.toFront(result))
    );
  }
}
