import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { City } from '@core/models/city';
import { map } from 'rxjs/operators';
import { Sport } from '@core/models/sport';

@Injectable()
export class ReferenceService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getCities(): Observable<City[]> {
    return this.httpClient
      .get('/api/v1/reference/cities/')
      .pipe(
        map(data => City.toFront(data))
      );
  }

  getSports(): Observable<Sport[]> {
    return this.httpClient
        .get('/api/v1/sport/')
        .pipe(
            map(data => Sport.toFront(data)),
        );
  }
}
