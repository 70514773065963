import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    yes: 'Да',
    no: 'Нет',
    download: 'Загрузить',
  },
  [Locales.en]: {
    yes: 'Yes',
    no: 'No',
    download: 'Download',
  }
};
