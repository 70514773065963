import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MenuComponent } from './menu.component';

@Directive({
  selector: '[mtgMenuTriggerFor]'
})
export class MenuTriggerDirective implements OnInit, OnDestroy {
  private _overlayRef: OverlayRef;
  private _isVisible: boolean;

  @Input('mtgMenuTriggerFor')
  menu: MenuComponent;
  @Input('mtgMenuPosition')
  position: 'left' | 'right' = 'right';
  @Input('mtgMenuActiveClass')
  activeClass: string;
  subscriptions: Function[] = [];

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.renderer.listen('document', 'click', $event => {
        if (!this.elementRef.nativeElement.contains($event.target) && this._isVisible) {
          this.hideMenu();
          this.cdr.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    if (!this._isVisible) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    if (this._overlayRef) {
      return;
    }

    this._isVisible = true;
    this._overlayRef = this._overlay.create({
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
    });
    const templatePortal = new TemplatePortal(this.menu.templateRef, this._viewContainerRef);
    this._overlayRef.attach(templatePortal);
    if (this.activeClass) {
      this.elementRef.nativeElement.classList.add(this.activeClass);
    }
  }

  hideMenu() {
    if (!this._overlayRef) {
      return;
    }
    this._isVisible = false;
    this._overlayRef.dispose();
    delete this._overlayRef;
    if (this.activeClass) {
      this.elementRef.nativeElement.classList.remove(this.activeClass);
    }
  }

  getPositionStrategy() {
    if (this.position === 'left') {
      return this._overlay.position()
          .flexibleConnectedTo(this.elementRef.nativeElement)
          .withFlexibleDimensions(false)
          .withPush(false)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top'
            },
            {
              originX: 'end',
              originY: 'top',
              overlayX: 'end',
              overlayY: 'bottom'
            },
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top'
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom'
            }
          ]);
    } else {
      return this._overlay.position()
          .flexibleConnectedTo(this.elementRef.nativeElement)
          .withFlexibleDimensions(false)
          .withPush(false)
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top'
            },
            {
              originX: 'end',
              originY: 'top',
              overlayX: 'end',
              overlayY: 'bottom'
            },
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top'
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom'
            }
          ]);
    }

  }
}
