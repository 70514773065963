import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { TypeaheadComponent } from '@ui-kit/form/components/typeahead/typeahead.component';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: 'mtg-input[mtgTypeahead]'
})
export class TypeaheadDirective implements OnInit, OnDestroy {
  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal;
  private _positionStrategy: PositionStrategy;
  private _subscriptions: Function[] = [];

  @Input('mtgTypeahead')
  typeahead: TypeaheadComponent;

  @HostListener('focus')
  onFocus() {
    this.attachOverlay();
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this.renderer.listen('document', 'click', $event => {
        if (!this._overlayRef) {
          return;
        }
        if (!this.elementRef.nativeElement.contains($event.target)) {
          this._overlayRef.dispose();
          this.cdr.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    for (const unsubscribe of this._subscriptions) {
      unsubscribe();
    }
  }

  private attachOverlay() {
    this._portal = new TemplatePortal(this.typeahead.template, this._viewContainerRef);
    this._overlayRef = this._overlay.create(this._getOverlayConfig());
    this._overlayRef.attach(this._portal);
  }

  private _getOverlayConfig() {
    return new OverlayConfig({
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      width: this._getHostWidth(),
    });
  }

  private getPositionStrategy() {
    return this._overlay.position()
      .flexibleConnectedTo(this.elementRef.nativeElement)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        }
      ]);
  }

  /** Returns the width of the input element, so the panel width can match it. */
  private _getHostWidth(): number {
    return this.elementRef.nativeElement.getBoundingClientRect().width;
  }
}
