import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    email: 'email',
    first_name: 'firstName',
    last_name: 'lastName',
  },
})
export class TeamInviteExternal extends BaseModel {
  id: number;
  teamId: number;
  email: string;
  firstName: string;
  lastName: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
