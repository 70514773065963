import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateMask } from '../input/input-mask';
import * as moment from 'moment';

const noop = () => {};

@Component({
  selector: 'mtg-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, DoCheck {
  private _onChangeFunc: any = noop;
  private _onTouchFunc: any = noop;
  private _control: AbstractControl;

  dateMask = dateMask({});
  @Input()
  label: string;
  @Input()
  required: boolean;
  @Input()
  value: any;
  @Input()
  disabled: boolean;
  @Input()
  filterDate: any;
  @Input()
  transparent: boolean;
  @Input()
  formControlName: string;
  @Input()
  formControl: AbstractControl;
  @Input()
  fluid = true;
  formattedValue = '';
  private dirty = false;

  @HostBinding('class.fluid')
  get fluidClass() {
    return this.fluid;
  }

  constructor(
    @Optional() private _parentFormGroup: FormGroupDirective,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this._parentFormGroup) {
      this._control = this._parentFormGroup.control.get(this.formControlName);
    } else if (this.formControl) {
      this._control = this.formControl;
    }
  }

  ngDoCheck (): void {
    if (this._control && this.dirty !== this._control.dirty) {
      this.dirty = this._control.dirty;
      this.cdr.markForCheck();
    }
  }

  writeValue(value: any): void {
    const re = /(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.[12]\d{3}/;
    if (moment.isMoment(value)) {
      this.value = value;
    } else if (re.test(value)) {
      this.value = moment(value, 'DD.MM.YYYY');
    }
    if (moment.isMoment(this.value)) {
      this.formattedValue = this.value.format('DD.MM.YYYY');
    }
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchFunc = fn;
  }

  dateChanged(event: any): void {
    this.value = event.value;
    this.formattedValue = this.value.format('DD.MM.YYYY');
    this._onChangeFunc(this.value);
    this._onTouchFunc();
  }

  inputChanged(value: any): void {
    const re = /(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.[12]\d{3}/;
    if (re.test(value)) {
      this.value = moment(value, 'DD.MM.YYYY');
    } else {
      this.value = null;
    }
    this.formattedValue = value;
    this._onChangeFunc(this.value);
    this._onTouchFunc();
  }

  get errors() {
    if (this._control && this._control.dirty && this._control.errors) {
      return this._control.errors;
    }
    return null;
  }
}
