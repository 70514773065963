import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { distinctUntilChanged, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { FileService } from '@core/services/file.service';
import { TeamService } from '@core/services/team.service';
import { Team } from '@core/models/team';
import { Sport, SportTypes } from '@core/models/sport';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import * as RoutingMap from '@app/routing-map';
import { Observable } from 'rxjs/internal/Observable';
import { ConfirmDialogComponent } from '@shared/modules/shared/components/confirm-dialog/confirm-dialog.component';
import { LoaderService } from '@core/services/loader.service';
import { handleError } from '@shared/util/errors';
import { TeamProfileService } from '@core/services/team-profile.service';
import { ProfileService } from '@core/services/profile.service';
import { ToastService } from '@ui-kit/toast/toast.service';
import { DialogConfig } from '@ui-kit/dialog/dialog-config';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { ReferenceService } from '@core/services/reference.service';
import { City, CountryLocalization } from '@core/models/city';
import { markFormGroupTouched } from '@shared/util/form';
import { TeamPermission, TeamPermissionTypes } from '@core/models/team-permission';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import teamFormLocalization from '@app/shared/components/team-form/team-form.localization';

@UntilDestroy
@Component({
  selector: 'mtg-team-form',
  templateUrl: './team-form.component.html',
  styleUrls: ['./team-form.component.scss']
})
export class TeamFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  settingsForm: FormGroup;
  isSucceed: boolean;
  team: Team;
  isChanged = false;
  cities: City[];
  filterCities: City[];
  sports: Sport[];
  permissionTypes = TeamPermissionTypes;
  permission: TeamPermission;
  sportTypes = SportTypes;
  countries: string[];
  countriesLocalization = CountryLocalization[this.localizationService.getLocale()];
  localization = teamFormLocalization[this.localizationService.getLocale()];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private fileService: FileService,
    private teamProfileService: TeamProfileService,
    private profileService: ProfileService,
    private teamService: TeamService,
    private dialogService: DialogService,
    private dialogRef: DialogRef<TeamFormComponent>,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private dialogConfig: DialogConfig,
    private referenceService: ReferenceService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit() {
    this.dialogRef.addBeforeClose(this.canClose());
    this.loaderService.show();
    forkJoin([
      this.referenceService.getCities(),
      this.referenceService.getSports()
    ])
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(([cities, sports]) => {
        this.cities = cities;
        this.sports = sports;
        const countries = [];
        cities.forEach(city => {
          countries.push(city.country);
        });
        this.countries = [...new Set(countries)];
      }, error => {
        handleError(error);
        this.toastService.danger(this.localization.error);
      });
    if (this.dialogConfig.data['id']) {
      this.loaderService.show();
      this.teamProfileService.permission$
      .pipe(take(1))
      .subscribe(permission => {
        this.permission = permission;
      });
      this.teamService.getById(this.dialogConfig.data['id']).subscribe(team => {
        this.loaderService.hide();
        this.team = team;
        this.createForm(team);
      }, error => {
        handleError(error);
        this.loaderService.hide();
        this.toastService.danger(this.localization.error);
      });
    } else {
      this.createForm();
    }
  }

  ngOnDestroy(): void {}

  canClose(): Observable<boolean> {
    return Observable.create(observer => {
      if (!this.isChanged || this.isSucceed) {
        observer.next(true);
        observer.complete();
        return;
      }
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          message: this.localization.closeAlert,
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          observer.next(res);
        } else {
          observer.error(res);
        }
        observer.complete();
      });
    });
  }

  createForm(team?: Team) {
    this.form = this.fb.group({
      id: new FormControl(team ? team.id : null),
      logo: new FormControl(null),
      name: new FormControl(team ? team.name : '', [Validators.required]),
      city: new FormControl(team ? team.city : null, [Validators.required]),
      country: new FormControl(team ? team.city.country : null, [Validators.required]),
      sport: new FormControl(team ? team.sport : null, [Validators.required]),
    });
    if (team) {
      this.form.get('sport').disable();
    }
    if (this.permission) {
      this.settingsForm = this.fb.group({
        statistic: new FormControl(this.permission.statistic === this.permissionTypes.for_users),
        games: new FormControl(this.permission.games === this.permissionTypes.for_users),
        polls: new FormControl(this.permission.polls === this.permissionTypes.for_users),
        events: new FormControl(this.permission.events === this.permissionTypes.for_users)
      });
    } else {
      this.settingsForm = this.fb.group({
        statistic: new FormControl(true),
        games: new FormControl(true),
        polls: new FormControl(false),
        events: new FormControl(false)
      });
    }
    if (team) {
      this.filterCities = this.cities.filter(item => item.country === team.city.country);
    }
    this.form.get('country').valueChanges.subscribe(value => {
      this.filterCities = this.cities.filter(item => item.country === value);
    });
    this.form.valueChanges.pipe(
      takeUntil(componentDestroyed(this)),
      distinctUntilChanged()
    ).subscribe(() => {
      this.isChanged = true;
    });
  }

  get inviteUserRoute() {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id, RoutingMap.ROUTE_TEAM_INVITE_USER];
  }

  get teamProfileRoute() {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id];
  }

  resizeImage($event) {
    if (!$event.target.files || !$event.target.files.length) {
      return;
    }
    this.form.get('logo').setValue($event.target.files[0]);

    // const dialogRef = this.dialogService.open(ImageSaveComponent, {
    //   data: {
    //     file: $event.target.files[0],
    //     options: {
    //       viewport: {
    //         width: 150,
    //         height: 150,
    //         type: 'rectangle'
    //       },
    //       boundary: {
    //         width: 300,
    //         height: 300
    //       },
    //     }
    //   }
    // });
    // const filename = $event.target.files[0].name;
    // dialogRef.afterClosed().subscribe(result => {
    //   $event.target.value = null;
    //   if (result) {
    //     this.form.get('logo').setValue({
    //       blob: result,
    //       filename: filename
    //     });
    //   }
    // });
  }

  save() {
    if (!this.form.valid) {
      markFormGroupTouched(this.form);
      return;
    }

    this.loaderService.show();
    const formData = this.form.value;
    (formData['logo']
      ? this.fileService.upload(formData['logo'], formData['logo'].name, 600, 600)
      : of(null)
    )
      .pipe(
        switchMap(file => {
          if (file) {
            formData['logo'] = file;
          } else {
            delete formData['logo'];
          }
          if (formData['id']) {
            return this.teamProfileService.updateTeam(formData['id'], formData);
          } else {
            return this.profileService.createTeam(formData);
          }
        })
      )
      .subscribe(team => {
        this.team = team;
        this.savePermission(team);
        this.isSucceed = true;
        this.loaderService.hide();
        if (formData['id']) {
          this.toastService.info(this.localization.teamUpdated);
          this.router.navigate(this.teamProfileRoute);
        }
      }, error => {
        handleError(error);
        this.loaderService.hide();
      });
  }

  savePermission(team: Team) {
    if (!this.settingsForm.valid) {
      markFormGroupTouched(this.settingsForm);
    }
    const formDataSettings = this.settingsForm.value;
    if (formDataSettings) {
      formDataSettings['statistic'] = formDataSettings['statistic'] ? this.permissionTypes.for_users : this.permissionTypes.for_team;
      formDataSettings['games'] = formDataSettings['games'] ? this.permissionTypes.for_users : this.permissionTypes.for_team;
      formDataSettings['events'] = formDataSettings['events'] ? this.permissionTypes.for_users : this.permissionTypes.for_team;
      formDataSettings['polls'] = formDataSettings['polls'] ? this.permissionTypes.for_users : this.permissionTypes.for_team;
      this.teamProfileService
          .updatePermission(team.id, formDataSettings)
          .subscribe(() => {
            /*this.toastService.success('Настройки приватности обновлены');*/
          }, error => {
            handleError(error);
            this.toastService.danger(this.localization.error);
          });
    }
  }

  deleteTeam() {
    const dialog = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        message: `${this.localization.deleteTeamPrompt} ${this.team.name}?`
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.teamService.delete(this.team).subscribe(() => {
          this.router.navigateByUrl(RoutingMap.ROUTE_PROFILE_ABSOLUTE);
        }, error => {
          handleError(error);
          this.toastService.danger(this.localization.error);
        });
      }
    });
  }
}
