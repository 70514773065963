import { Component } from '@angular/core';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import { Locales } from '@shared/modules/localization/locales';

@Component({
  selector: 'mtg-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent {
  locale = this.localizationService.getLocale();
  locales = Locales;

  constructor(
    private localizationService: LocalizationService,
  ) {
  }
}
