import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ToastComponent } from './toast.component';
import { ToastData } from './toast-config';
import { ToastRef } from './toast-ref';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _toasts: ToastRef[] = [];

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
  ) { }

  success(text: string): ToastRef {
    return this.show({type: 'success', text});
  }

  danger(text: string): ToastRef {
    return this.show({type: 'danger', text});
  }

  info(text: string): ToastRef {
    return this.show({type: 'info', text});
  }

  show(data: ToastData) {
    const positionStrategy = this.getPositionStrategy(this.lastToast);
    const overlayRef = this.overlay.create({ positionStrategy, width: '100%' });

    const toastRef = new ToastRef(overlayRef);
    this._toasts.push(toastRef);

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);
    overlayRef.detachments().subscribe(() => {
      setTimeout(() => {
        this._toasts.splice(this._toasts.indexOf(toastRef), 1);
        let previousToast = null;
        for (const toast of this._toasts) {
          toast.updatePosition(this.getPositionStrategy(previousToast));
          previousToast = toast;
        }
      }, 300);
    });

    return toastRef;
  }

  getPositionStrategy(lastToast: ToastRef) {
    if (lastToast) {
      return this.overlay.position()
        .global()
        .top(this.getPosition(lastToast))
        .right('0px');
    } else {
      return this.overlay.position()
        .global()
        .bottom('40px')
        .right('0px');
    }
  }

  getPosition(lastToast: ToastRef) {
    const lastToastIsVisible = lastToast && lastToast.isVisible();
    const position = lastToastIsVisible
      ? lastToast.getPosition().top - lastToast.getPosition().height
      : '0';

    return position + 'px';
  }

  get lastToast() {
    return this._toasts.length && this._toasts[this._toasts.length - 1];
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
