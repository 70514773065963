import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mtg-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements ControlValueAccessor {
  private _onChangeFunc: any;
  private _onTouchedFunc: any;
  currentValue: any;

  @Input() value: any;
  @Input() label = '';
  @Input() disabled: boolean;
  @Input() formControlName: string;

  @ViewChild('input', { read: ElementRef, static: true })
  inputElement: ElementRef;

  constructor() {}

  writeValue(value: any): void {
    this.currentValue = value;
    this.inputElement.nativeElement.checked = this.currentValue === this.value;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change() {
    if (this._onChangeFunc) {
      this._onChangeFunc(this.value);
    }
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
  }
}
