import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from './util';
import { WrestballStatisticTypes, GameTimeTypes, OvertimeTypes, TimerTypes } from './tournament';

@ModelInstance({
  mappingFields: {
    periods_count: 'periodsCount',
    period_time: 'periodTime',
    game_up_to_score: 'gameUpToScore',
    overtime_type: 'overtimeType',
    overtime_time: 'overtimeTime',
    overtime_score: 'overtimeScore',
    max_game_players: 'maxGamePlayers',
    timeout_count: 'timeoutCount',
    overtime_timeout_count: 'overtimeTimeoutCount',
    timeout_time: 'timeoutTime',
    statistic_type: 'statisticType',
    shot_clock_enabled: 'shotClockEnabled',
    game_time_type: 'gameTimeType',
    timer_type: 'timerType',
    wrestler_scrums_count: 'wrestlerScrumsCount',
    wrestlers_count: 'wrestlersCount',
  },
  relation: {
    overtimeType: enumField(OvertimeTypes),
    statisticType: enumField(WrestballStatisticTypes),
    gameTimeType: enumField(GameTimeTypes),
    timerType: enumField(TimerTypes),
  }
})
export class WrestballGameConfig extends BaseModel {
  periodsCount: number;
  periodTime: number;
  gameUpToScore: number;
  overtimeType: OvertimeTypes;
  overtimeTime: number;
  overtimeScore: number;
  maxGamePlayers: number;
  timeoutCount: number;
  overtimeTimeoutCount: number;
  timeoutTime: number;
  statisticType: WrestballStatisticTypes;
  shotClockEnabled: boolean;
  gameTimeType: GameTimeTypes;
  wrestlerScrumsCount: number;
  timerType: TimerTypes;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

