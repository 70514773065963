import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { LeaguePlayerField } from '@core/models/league-player-field';


@Injectable()
export class OrgLeaguePlayerFieldService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getPlayerFieldList(leagueId: number, page: number, size: number): Observable<PaginatedResponse<LeaguePlayerField[]>> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/league_player_fields/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: LeaguePlayerField.toFront(response.body)
      })));
  }

  getPlayerFieldById(playerFieldId: number): Observable<LeaguePlayerField> {
    return this.httpClient.get(`/org/api/v1/league_player_fields/${playerFieldId}/`)
      .pipe(map(result => LeaguePlayerField.toFront(result)));
  }

  createPlayerField(leagueId: number, data: any): Observable<LeaguePlayerField> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/league_player_fields/`, LeaguePlayerField.toBack(data))
      .pipe(map(result => LeaguePlayerField.toFront(result)));
  }

  updatePlayerField(playerFieldId: number, data: any): Observable<LeaguePlayerField> {
    return this.httpClient.patch(`/org/api/v1/league_player_fields/${playerFieldId}/`, LeaguePlayerField.toBack(data))
      .pipe(map(result => LeaguePlayerField.toFront(result)));
  }

  deletePlayerField(playerFieldId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_player_fields/${playerFieldId}/`);
  }
}
