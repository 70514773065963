import { ComponentRef, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '@ui-kit/spinner/spinner.component';

@Injectable()
export class LoaderService {
  private overlayRef: OverlayRef;
  private componentRef: ComponentRef<SpinnerComponent>;

  constructor(
    private _overlay: Overlay
  ) {}

  show() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    const positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();
    this.overlayRef = this._overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this._overlay.scrollStrategies.block()
    });
    const componentPortal = new ComponentPortal(SpinnerComponent);
    this.componentRef = this.overlayRef.attach(componentPortal);
  }

  hide() {
    this.overlayRef.dispose();
  }

  setProgress(progress: number): void {
    if (!this.componentRef) {
      return;
    }
    this.componentRef.instance.setProgress(progress);
  }
}
