import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    myTeams: 'Мои команды',
    createTeam: 'Создать команду',
    myProfile: 'Мой профиль',
    edit: 'Редактировать',
    settings: 'Настройки',
    logout: 'Выйти',
  },
  [Locales.en]: {
    myTeams: 'My teams',
    createTeam: 'Create team',
    myProfile: 'My profile',
    edit: 'Edit',
    settings: 'Settings',
    logout: 'Logout',
  }
};
