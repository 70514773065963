import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TabsComponent } from './tabs.component';
import { TabLabelDirective } from './tab-label.directive';
import { TabContentDirective } from './tab-content.directive';

@Component({
  selector: 'mtg-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit, OnDestroy {
  @Input()
  heading: string;

  @Input()
  badge: string|number;

  @Input()
  route: any;

  @ContentChild(TabLabelDirective, { read: TemplateRef })
  label: TemplateRef<TabLabelDirective>;

  @ContentChild(TabContentDirective, { read: TemplateRef })
  content: TemplateRef<TabContentDirective>;

  constructor(private tabsComponent: TabsComponent) {}

  ngOnInit(): void {
    this.tabsComponent.addTab(this);
  }

  ngOnDestroy(): void {
    this.tabsComponent.removeTab(this);
  }

  isActive() {
    return this.tabsComponent.isActive(this);
  }
}
