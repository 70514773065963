import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { LeaguePartner } from '@core/models/league-partner';

@Injectable()
export class OrgLeaguePartnerService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getPartnerList(leagueId: number, page: number, size: number): Observable<PaginatedResponse<LeaguePartner[]>> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/partners/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: LeaguePartner.toFront(response.body)
      })));
  }

  getPartnerById(partnerId: number): Observable<LeaguePartner> {
    return this.httpClient.get(`/org/api/v1/league_partners/${partnerId}/`)
      .pipe(map(result => LeaguePartner.toFront(result)));
  }

  createPartner(leagueId: number, data: any): Observable<LeaguePartner> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/partners/`, LeaguePartner.toBack(data))
      .pipe(map(result => LeaguePartner.toFront(result)));
  }

  updatePartner(partnerId: number, data: any): Observable<LeaguePartner> {
    return this.httpClient.patch(`/org/api/v1/league_partners/${partnerId}/`, LeaguePartner.toBack(data))
      .pipe(map(result => LeaguePartner.toFront(result)));
  }

  deletePartner(partnerId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_partners/${partnerId}/`);
  }
}
