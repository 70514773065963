import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
  ],
  declarations: [
    DialogComponent,
  ],
  providers: [
    DialogService,
  ],
  entryComponents: [
    DialogComponent,
  ],
})
export class DialogModule {}
