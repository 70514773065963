import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from '@ui-kit/accordion/accordion.component';
import { AccordionHeaderComponent } from '@ui-kit/accordion/accordion-header.component';
import { AccordionContentComponent } from '@ui-kit/accordion/accordion-content.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionContentComponent,
  ],
  exports: [
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionContentComponent,
  ]
})
export class AccordionModule {}
