import { ChangeDetectorRef, Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'img[mtgImageReader]'
})
export class ImageReaderDirective {
  @HostBinding('src')
  src: string | ArrayBuffer;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  @Input('mtgImageReader')
  set data(data: any) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.src = e.target['result'];
      this.cdr.markForCheck();
    };
    reader.readAsDataURL(data);
  }
}
