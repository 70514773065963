import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';


export enum WrestballWorkHand {
  left = 1,
  right
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    work_hand: 'workHand'
  },
  relation: {
    workHand: enumField(WrestballWorkHand)
  }
})
export class WrestballProfile extends BaseModel {
  id: number;
  userId: number;
  workHand: WrestballWorkHand;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
