<div class="game-dialog">
  <div class="header">
    <div class="team" [ngClass]="{winner: team.id === teamWinnerId}">
      <div class="logo">
        <img [src]="team.logo.path" *ngIf="team.logo.path">
        <div [class]="team.getEmptyLogoClass()" *ngIf="!team.logo.path"></div>
        <span class="trophy" *ngIf="team.id === teamWinnerId"></span>
      </div>
      <div class="score">{{ teamScore }}</div>
      <div class="name">{{ team.name }}</div>
      <div class="city">{{ team.city | mtgCityName }}</div>
    </div>
    <div class="team" [ngClass]="{winner: competitorTeam.id === teamWinnerId}">
      <div class="logo">
        <img [src]="competitorTeam.logo.path" *ngIf="competitorTeam.logo.path">
        <div [class]="competitorTeam.getEmptyLogoClass()" *ngIf="!competitorTeam.logo.path"></div>
        <span class="trophy" *ngIf="competitorTeam.id === teamWinnerId"></span>
      </div>
      <div class="score">{{ competitorTeamScore }}</div>
      <div class="name">{{ competitorTeam.name }}</div>
      <div class="city">{{ competitorTeam.city | mtgCityName }}</div>
    </div>
  </div>

  <a (click)="openGamePage(game)" class="game" *ngFor="let game of games; let i = index;">
    <div class="score" [ngClass]="{highlight: game.teamWinnerId === team.id}">
      {{ game.team.id === team.id ? game.teamScore : game.competitorTeamScore }}
    </div>
    <div class="title">
      {{ i + 1 }} {{ localization | mtgTranslate: 'game' }}
      <div class="date">{{ game.datetime | date: 'd MMM, HH:mm' }}</div>
    </div>
    <div class="score" [ngClass]="{highlight: game.teamWinnerId === competitorTeam.id}">
      {{ game.competitorTeam.id === competitorTeam.id ? game.competitorTeamScore : game.teamScore }}
    </div>
  </a>

  <a *ngIf="games[games.length-1].playoffBullitsTeamScore !== null && games[games.length-1].playoffBullitsCompetitorTeamScore !== null"
     (click)="openGamePage(games[games.length-1])" class="game">
    <div class="score" [ngClass]="{highlight: games[games.length-1].playoffBullitsTeamWinnerId === team.id}">
      {{ games[games.length-1].team.id === team.id ? games[games.length-1].playoffBullitsTeamScore : games[games.length-1].playoffBullitsCompetitorTeamScore }}
    </div>
    <div class="title">
      {{ localization | mtgTranslate: 'bullitsSeries' }}
      <div class="date">{{ games[games.length-1].datetime | date: 'd MMM, HH:mm' }}</div>
    </div>
    <div class="score" [ngClass]="{highlight: games[games.length-1].playoffBullitsTeamWinnerId === competitorTeam.id}">
      {{ games[games.length-1].competitorTeam.id === competitorTeam.id ? games[games.length-1].playoffBullitsCompetitorTeamScore : games[games.length-1].playoffBullitsTeamScore }}
    </div>
  </a>


</div>
