export function replaceParams(localeStr: string, params: { [key: string]: string }): string {
  for (const key of Object.keys(params)) {
    localeStr = localeStr.replace(new RegExp(`%${key}%`, 'gi'), params[key]);
  }
  localeStr = localeStr.replace(new RegExp('%.*%', 'gi'), '');
  return localeStr;
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
