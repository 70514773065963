import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FORM_ERROR_LIST, LOCALIZATION_DATA } from './token';
import { Locales } from './locales';
import { map } from 'rxjs/operators';

const localStorageKey = 'lang';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  private localeSubject = new BehaviorSubject<Locales | null>(null);
  private localizationData: { [key: string]: { [key: string]: string } } = {};
  private formErrorLocalization: { [key: string]: { [key: string]: string } } =
    {};

  get locale$() {
    return this.localeSubject.asObservable().pipe(
      map(lang => {
        if (!lang) {
          this.initializeLang();
        }
        return this.localeSubject.value;
      })
    );
  }

  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  localize(key: string): string {
    const localeId = this.getLocale() as string;
    if (!this.localizationData[localeId]) {
      this.localizationData[localeId] = {};
    }
    if (this.localizationData[localeId][key]) {
      return this.localizationData[localeId][key];
    }
    this.localizationData[localeId] = (
      this.injector.get(LOCALIZATION_DATA) as any[]
    ).reduce(
      (acc, item) => ({
        ...acc,
        ...(item[localeId] || {}),
      }),
      {}
    );
    return this.localizationData[localeId][key];
  }

  formErrorLocalize(key: string): string {
    const localeId = this.getLocale() as string;
    if (!this.formErrorLocalization[localeId]) {
      this.formErrorLocalization[localeId] = (this.injector.get(
        FORM_ERROR_LIST
      ) || ({} as any))[localeId];
    }
    return this.formErrorLocalization[localeId][key];
  }

  setLocale(localeId: Locales) {
    if (this.getLocale() !== localeId) {
      this.localeSubject.next(localeId);
      // this.cookieService.set(localStorageKey, '', -1);
      // this.cookieService.set(localStorageKey, localeId, 365, '/');
      localStorage.setItem(localStorageKey, localeId);
    }
  }

  getLocale(): Locales | null {
    if (!this.localeSubject.value) {
      this.initializeLang();
    }
    return this.localeSubject.value;
  }

  private initializeLang(): void {
    if (this.document.location.hostname.endsWith('mtgame-sports.com')) {
      this.localeSubject.next(Locales.en);
    } else {
      this.localeSubject.next(Locales.ru);
    }
  }
}

function getLang(lang: string | null): Locales | null {
  if (!lang) {
    return null;
  }
  // @ts-ignore
  return Locales[Object.keys(Locales)[Object.values(Locales).indexOf(lang)]];
}
