import { BaseModel, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { File } from '@core/models/file';
import { LeagueTeamField } from '@core/models/league-team-field';

@ModelInstance({
  mappingFields: {
    id: 'id',
    league_team_field_id: 'leagueTeamFieldId',
    league_team_field: 'leagueTeamField',
    value: 'value',
    file: 'file'
  },
  relation: {
    file: File,
    leagueTeamField: LeagueTeamField,
  }
})
export class TournamentTeamFieldValue extends BaseModel {
  id: number;
  leagueTeamFieldId: number;
  value: string;
  file: File;
  leagueTeamField: LeagueTeamField;

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
