import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, listField, enumField } from './util';

export enum RugbyFoulSanctions {
  scrum = 1,
  lineout,
  free_kick,
  penalty_shot,
  yellow_card,
  red_card,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    sanction: 'sanction',
  },
  relation: {
    sanction: listField(enumField(RugbyFoulSanctions))
  }
})
export class RugbyFoulType extends BaseModel {
  id: number;
  name: string;
  sanction: RugbyFoulSanctions[];

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
