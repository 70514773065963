import { GameDetailService } from '@core/services/game-detail.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameService } from '@core/services/game.service';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { UserService } from '@core/services/user.service';
import { MediaService } from '@core/services/media.service';
import { updateItemInArray } from '@shared/util/data';
import { Game } from '@core/models/game';
import { TournamentService } from '@core/services/tournament.service';
import { RugbyGameStatistic } from '@core/models/rugby-game-statistic';
import { RugbyGameLog } from '@core/models/rugby-game-log';
import { RugbyGameService } from '@core/services/rugby-game.service';
import { RugbyGameTeamStatistic } from '@core/models/rugby-game-team-statistic';

@Injectable()
export class RugbyGameDetailService extends GameDetailService {
  protected _gameStatisticSubject = new BehaviorSubject<RugbyGameStatistic[]>(undefined);

  get gameLogs$(): Observable<RugbyGameLog[]> {
    return this._gameLogsSubject.pipe(filter(item => item !== undefined));
  }

  get gameStatistic$(): Observable<RugbyGameStatistic[]> {
    return this._gameStatisticSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    protected gameService: GameService,
    protected centrifugoService: CentrifugoService,
    protected userService: UserService,
    protected mediaService: MediaService,
    protected rugbyGameService: RugbyGameService,
    protected tournamentService: TournamentService,
  ) {
    super(gameService, centrifugoService, userService, mediaService, tournamentService);
  }

  dispose() {
    super.dispose();
    this._gameStatisticSubject.next(undefined);
  }

  getGame(gameId): Observable<Game> {
    return this.rugbyGameService.getById(gameId).pipe(
      tap(game => this._gameSubject.next(game))
    );
  }

  getGameLogs(gameId: number): Observable<RugbyGameLog[]> {
    return this.rugbyGameService.getGameLogs(gameId).pipe(
      tap(logs => this._gameLogsSubject.next(logs))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<RugbyGameTeamStatistic> {
    return this.rugbyGameService.getGameTeamStatistic(gameId);
  }

  getUsersStatistic(gameId: number): Observable<RugbyGameStatistic[]> {
    return this.rugbyGameService.getGameUserStatistic(gameId).pipe(
      tap(statistic => this._gameStatisticSubject.next(statistic))
    );
  }

  getGameProtocolFile(type: string): Observable<any> {
    if (!this._gameSubject.value) {
      return;
    }
    return this.rugbyGameService.getGameProtocolFile(this._gameSubject.value.id, type);
  }

  protected updateGameScore() {
    return;
  }

  protected listenCentrifugoEvents(gameId) {
    super.listenCentrifugoEvents(gameId);
    this.centrifugoService.listen(`game_${gameId}`).subscribe(message => {
      switch (message['action']) {
        case 'GAME_STATISTIC_UPDATED': {
          const userStatistic = RugbyGameStatistic.toFront(message['data']);
          return this._gameStatisticSubject.next(updateItemInArray(
            this._gameStatisticSubject.value,
            userStatistic,
            true,
            item => item.gameUserId === userStatistic.gameUserId
          ));
        }
      }
    });
  }

  protected getGameService(): GameManagementHttpInterface {
    return this.rugbyGameService;
  }

  protected gameLogToFront(data: any) {
    return RugbyGameLog.toFront(data);
  }
}
