import { Component, Input } from '@angular/core';
import { TabComponent } from './tab.component';
import { Router } from '@angular/router';

@Component({
  selector: 'mtg-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input()
  public active = 0;
  @Input()
  noPadding: boolean;
  @Input()
  lowerCase: boolean;
  public tabs: TabComponent[] = [];

  constructor(
    private router: Router
  ) {}

  addTab(tab: TabComponent) {
    this.tabs.push(tab);
  }

  removeTab(tab: TabComponent): void {
    this.tabs.splice(this.tabs.indexOf(tab), 1);
  }

  isActive(tab: TabComponent) {
    return this.tabs.indexOf(tab) === this.active;
  }

  showTab(tab: TabComponent) {
    if (tab.route) {
      this.router.navigate(tab.route);
    } else {
      this.active = this.tabs.indexOf(tab);
    }
  }
}
