import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Game, GameResultTypes } from '@core/models/game';
import { map } from 'rxjs/operators';
import { TeamUser } from '@core/models/team-user';
import { GameUser } from '@core/models/game-user';
import { GameLogBase } from '@core/models/game-log-base';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { GameUserLimitations } from '@core/models/game-user-limitation';

@Injectable()
export class GameBaseService {
  protected apiUrl = '/api/v1';
  protected resource = 'game';

  constructor(
    protected httpClient: HttpClient
  ) {}

  getById(gameId: number): Observable<Game> {
    return this.httpClient
      .get(`${this.apiUrl}/${this.resource}/${gameId}/`)
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/${this.resource}/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/${this.resource}/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(result))
      );
  }

  deleteGame(game: Game): Observable<any> {
    return this.httpClient
      .delete(`${this.apiUrl}/${this.resource}/${game.id}/`);
  }

  closeGame(game: Game, resultType?: GameResultTypes): Observable<Game> {
    const payload = {};
    if (resultType) {
      payload['result_type'] = GameResultTypes[resultType];
    }
    return this.httpClient
      .post(`${this.apiUrl}/${this.resource}/${game.id}/close/`, payload)
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  openGame(game: Game): Observable<Game> {
    return this.httpClient
      .post(`${this.apiUrl}/${this.resource}/open/`, {})
      .pipe(map(result => Game.toFront(result)));
  }

  getUsers(gameId: number): Observable<GameUser[]> {
    return this.httpClient
      .get(`${this.apiUrl}/${this.resource}/${gameId}/users/`)
      .pipe(
        map(data => GameUser.toFront(data))
      );
  }

  getUsersLimitation(gameId: number): Observable<GameUserLimitations[]> {
    return this.httpClient
      .get(`${this.apiUrl}/${this.resource}/${gameId}/users_limitation/`)
      .pipe(
        map(data => GameUserLimitations.toFront(data))
      );
  }

  addUser(gameId: number, teamUser: TeamUser): Observable<GameUser> {
    return this.httpClient
      .post(`${this.apiUrl}/${this.resource}/${gameId}/users/`, {team_user: teamUser})
      .pipe(
        map(data => GameUser.toFront(data))
      );
  }

  changeUsers(gameId: number, gameUsers: GameUser[]): Observable<GameUser[]> {
    return this.httpClient
      .post(`${this.apiUrl}/${this.resource}/${gameId}/users/bulk_create/`, GameUser.toBack(gameUsers))
      .pipe(
        map(data => GameUser.toFront(data))
      );
  }
  //
  // getGameLogs(gameId: number): Observable<GameLogBase[]> {
  //   throw new Error('Not implement');
  // }
  //
  // saveGameLog(gameId: number, gameLog: GameLogBase): Observable<GameLogBase> {
  //   throw new Error('Not implement');
  // }
  //
  // updateGameLog(gameLogId: number, data: any): Observable<GameLogBase> {
  //   throw new Error('Not implement');
  // }
  //
  // deleteGameLog(gameLogId: number): Observable<any> {
  //   throw new Error('Not implement');
  // }
  //
  // getGameConfig(gameId: number): Observable<any> {
  //   throw new Error('Not implement');
  // }
  //
  // getGameUserStatistic(gameId: number): Observable<any> {
  //   throw new Error('Not implement');
  // }
  //
  // getGameTeamStatistic(gameId: number): Observable<any> {
  //   throw new Error('Not implement')
  // }
}
