import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    address: 'address',
    league_id: 'leagueId',
    sort: 'sort',
  }
})
export class LeagueCourt extends BaseModel {
  id: number;
  name: string;
  sort: number;
  address: string;
  leagueId: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
