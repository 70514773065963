import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input, Output
} from '@angular/core';
import { Game, GameStatuses } from '@core/models/game';
import { Playoff } from '@core/models/playoff';
import tournamentPlayoffLocalization from '@shared/modules/shared/components/tournament-playoff/tournament-playoff.localization';
import { LocalizationService } from '@shared/modules/localization/localization.service';

@Component({
  selector: 'mtg-tournament-playoff-bracket',
  templateUrl: './tournament-playoff-bracket.component.html',
  styleUrls: [
    './tournament-playoff-bracket.component.scss',
    '../shared/playoff-bracket.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPlayoffBracketComponent {
  @Input()
  games: Game[];
  @Input()
  playoff: Playoff;
  @Input()
  gameRoute = '';
  @Input()
  canManage: boolean;
  @Input()
  openGameInNewWindow: boolean;
  @Output()
  gameForm = new EventEmitter<{playoffNumber: string, gameId: number}>();
  @Input()
  currentRound: any;
  localization = tournamentPlayoffLocalization;
  currentLocale = this.localizationService.getLocale();

  get toursCount(): number {
    return Math.log(this.playoff.settings.teamsCount) / Math.log(2);
  }

  getMatchesCount(i: number): number {
    return this.playoff.settings.teamsCount / Math.pow(2, i + 1);
  }

  generateArray(length: number): any[] {
    return new Array(length);
  }

  getGames(tourNumber: number, gameNumber: number): Game[] {
    return this.games.filter(game => game.playoffNumber === `${tourNumber + 1}_${gameNumber + 1}`).sort((a, b) => a.id - b.id);
  }

  getGameNumber(tour: number, game: number): number {
    return 1 + game + this.playoff.settings.teamsCount - this.playoff.settings.teamsCount / Math.pow(2, tour);
  }

  getNextGameTeam(tour: number, game: number, team: number = 0): any {
    if (tour === 0) {
      return;
    }
    const matchesPrevRound = this.getMatchesCount(tour - 1);
    const gameNumber = this.getGameNumber(tour, game);
    let t = (gameNumber - matchesPrevRound) + game + team;
    if (tour + 1 === this.toursCount) {
      if (game === 1) {
        t = (gameNumber - 1 - matchesPrevRound) + team;
        return this.localization[this.currentLocale].loser + ' ' + t;
      }
    }
    return this.localization[this.currentLocale].winner + ' ' + t;
  }

  getTitle(tour: number, game: number = 0): string {
    const matchesCount = this.getMatchesCount(tour);
    const winnerPlace = this.playoff.winnerPlace;
    let finalTitle = this.localization[this.currentLocale].final;
    let thirdPlaceTitle = this.localization[this.currentLocale].third + ' ' + this.localization[this.currentLocale].place;
    if (winnerPlace > 1) {
      finalTitle = winnerPlace + ' ' + this.localization[this.currentLocale].place;
      thirdPlaceTitle = (winnerPlace + 2) + ' ' + this.localization[this.currentLocale].place;
    }
    if (matchesCount === 4) {
      return this.localization[this.currentLocale].quarterfinals;
    } else if (matchesCount === 2) {
      return this.localization[this.currentLocale].semiFinals;
    } else if (matchesCount === 1) {
      if (game === 0) {
        return finalTitle;
      } else {
        return thirdPlaceTitle;
      }
    } else {
      return `1/${matchesCount} ${this.localization[this.currentLocale].finalEndText}`;
    }
  }

  constructor(
    private localizationService: LocalizationService,
  ) {}
}
