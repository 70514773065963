import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef, EventEmitter,
  HostBinding, Input,
  OnDestroy, Output,
  QueryList,
  Renderer2,
  ViewChild
} from '@angular/core';
import { isTouchDevice } from '@shared/util/device';
import { DataSliderItemComponent } from './data-slider-item.component';

@Component({
  selector: 'mtg-data-slider',
  templateUrl: './data-slider.component.html',
  styleUrls: ['./data-slider.component.scss']
})
export class DataSliderComponent implements AfterViewInit, OnDestroy {
  @Input()
  loading: boolean;
  @ViewChild('content')
  content: ElementRef;
  @ViewChild('slider')
  slider: ElementRef;
  @ContentChildren(DataSliderItemComponent, {read: ElementRef})
  slides: QueryList<ElementRef>;
  @Input()
  arrowsVisible: boolean;
  subscriptions: Function[] = [];
  right: number;
  showArrows: boolean;
  canScrollRight: boolean;
  canScrollLeft: boolean;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostBinding('class.right-shadow')
  get rightShadow() {
    return this.canScrollRight || this.loading;
  }

  @HostBinding('class.left-shadow')
  get leftShadow() {
    return this.canScrollLeft || this.loading;
  }

  @HostBinding('class.touch')
  get isTouch() {
    return isTouchDevice();
  }

  @HostBinding('class.lock-scroll')
  get isScrollLocked() {
    return this.loading;
  }

  @Output()
  scrolledToEnd = new EventEmitter();
  @Output()
  scrolledToStart = new EventEmitter();

  ngAfterViewInit(): void {
    this.initializeSlider();
  }

  ngOnDestroy(): void {
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }

  initializeSlider() {
    setTimeout(() => {
      this.checkSliderWidth();
      this.checkScroll(false);
    });
    this.slides.changes.subscribe(slides => {
      setTimeout(() => {
        this.checkSliderWidth();
        this.checkScroll(false);
      });
    });
    this.subscriptions.push(
      this.renderer.listen('window', 'resize', () => {
        this.checkSliderWidth();
        this.checkScroll(false);
      })
    );
    this.subscriptions.push(
      this.renderer.listen(this.content.nativeElement, 'scroll', () => {
        this.checkScroll();
      })
    );
  }

  checkSliderWidth() {
    this.showArrows = this.content.nativeElement.scrollWidth > this.slider.nativeElement.clientWidth;
    if (!this.showArrows) {
      this.scrolledToEnd.emit();
    }
    this.cdr.markForCheck();
  }

  slideLeft() {
    if (!this.canScrollLeft || this.loading) {
      return;
    }
    let scrollWidth = this.content.nativeElement.scrollLeft - this.slider.nativeElement.clientWidth / 2;
    if (scrollWidth < 0) {
      scrollWidth = 0;
    }
    this.content.nativeElement.scroll({left: scrollWidth, top: 0, behavior: 'smooth'});
  }

  slideRight() {
    if (!this.canScrollRight || this.loading) {
      return;
    }
    let scrollWidth = this.content.nativeElement.scrollLeft + this.slider.nativeElement.clientWidth / 2;
    if (scrollWidth > this.content.nativeElement.scrollWidth) {
      scrollWidth = this.content.nativeElement.scrollWidth;
    }
    this.content.nativeElement.scroll({left: scrollWidth, top: 0, behavior: 'smooth'});
  }

  correctScroll(slidesCount: number, repeat?: boolean) {
    const slide = this.slides.toArray()[slidesCount];
    if (!slide) {
      return;
    }
    const scrollWidth = this.content.nativeElement.scrollLeft + slide.nativeElement.offsetLeft;
    this.content.nativeElement.scroll({left: scrollWidth});
  }

  scrollToSlide(slideIndex: number) {
    const slide = this.slides.toArray()[slideIndex];
    if (!slide) {
      return;
    }
    this.content.nativeElement.scrollLeft = slide.nativeElement.offsetLeft;
  }

  private checkScroll(emitEvent = true) {
    const scrollWidth = this.content.nativeElement.scrollWidth - this.content.nativeElement.scrollLeft;
    this.canScrollRight = scrollWidth > this.content.nativeElement.clientWidth;
    this.canScrollLeft = this.content.nativeElement.scrollLeft > 0;
    const scrollCheck = 10;
    if (emitEvent && (scrollWidth - scrollCheck) <= this.content.nativeElement.clientWidth && this.canScrollLeft) {
      this.scrolledToEnd.emit();
    }
    if (emitEvent && this.content.nativeElement.scrollLeft < scrollCheck) {
      this.scrolledToStart.emit();
    }
    this.cdr.markForCheck();
  }
}
