export const environment = {
  production: true,
  env: 'prod',
  // sentry: 'https://6c4c140595ce46d89777a5817e0e63e3@o237996.ingest.sentry.io/1406697',
  sentry: '',
  centrifugoUrl: 'wss://ws2.mtgame.ru/connection/websocket',
  metrikaCounter: 34795750,
  recaptchaKey: '6LfejroUAAAAANkkrnzijkqtV5C1f3v-7TMAVcyI',
  cdnHost: 'https://cdn.mtgame.ru',
  service: 'public'
};
