import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mtgFileDropZone]'
})
export class FileDropZoneDirective {
  @Output('mtgFileDropZone')
  fileDrop = new EventEmitter<File[]>();

  @HostBinding('class.active')
  dragActive;

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dragActive = false;

    const { dataTransfer } = $event;

    if (dataTransfer.items) {
      const files = [];
      for (let i = 0; i < dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (dataTransfer.items[i].kind === 'file') {
          files.push(dataTransfer.items[i].getAsFile());
        }
      }
      dataTransfer.items.clear();
      this.fileDrop.emit(files);
    } else {
      const files = dataTransfer.files;
      dataTransfer.clearData();
      this.fileDrop.emit(Array.from(files));
    }
  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
  @HostListener('body:drop', ['$event'])
  onBodyDrop(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  dragEnter(event) {
    this.dragActive = true;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragover', ['$event'])
  dragOver(event) {
    this.dragActive = true;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  dragLeave(event) {
    this.dragActive = false;
    event.preventDefault();
    event.stopPropagation();
  }
}
