import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';

export enum GameRugbyPosition {
  front_line = 1,
  second_line,
  back_line,
  halfback,
  three_quarter,
  fullback,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    position: 'position'
  },
  relation: {
    position: enumField(GameRugbyPosition),
  }
})
export class RugbyProfile extends BaseModel {
  id: number;
  userId: number;
  position: GameRugbyPosition;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
