import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    teamNameChanged: 'Команда %old% поменяла название на %new%',
    teamLogoChanged: 'Изменился логотип команды %teamName%',
    teamDataChanged: 'Данные команды обновлены',
    teamInvited: 'Вас пригласили в команду',
    teamInvitedDesc: '%initiator% предложил вам присоединиться к команде %teamName%',
    declineInvite: 'Отказаться',
    acceptInvite: 'Принять предложение',
    teamJoinRequest: 'Новая заявка на вступление в команду %teamName%',
    joinRequestDesc: '%initiator% подал заявку на вступление в команду %teamName%',
    joinRequestDecline: 'Отклонить',
    joinRequestAccept: 'Принять в команду',
    teamJoinDeclinedByTeam: 'К сожалению, ваша заявка на вступлению в команду %teamName% была отклонена 🙁',
    teamJoinDeclinedByUser: '%userName% отклонил предложение присоединиться к %teamName%',
    teamJoinCanceledByTeam: 'К сожалению, команда %teamName% отозвала своё предложение пополнить их ряды 🙁',
    teamJoinCanceledByUser: '%userName% отозвал свою заявку на вступление в %teamName%',
    teamJoinedForTeam: 'В %teamName% пополнение!',
    teamJoinedForTeamDesc: 'Отличая новость! К команде %teamName% присоединился новый игрок - %userName%',
    teamJoinedForUser: 'Вы присоединились к команде %teamName%',
    teamJoinedForUserDesc: 'Поздравляем! Вы вступили в команду %teamName%, и вместе у вас есть все шансы достичь новых высот! 🥳',
    roleChangedForTeam: 'Роль %userName% в команде %teamName% изменена на %role%',
    roleChangedForUser: 'Изменилась ваша роль в команде',
    roleChangedForUserDesc: '%initiator% изменил вашу роль в ${item.team.name} на %role%',
    numberChanged: 'Ваш номер в команде %teamName% изменён на %number%',
    teamUserDeletedForTeam: '%userName% был исключён из команды %teamName%',
    teamUserDeletedForUser: 'Вас исключили из команды 🙁',
    teamUserDeletedForUserDesc: 'К сожалению, команда %teamName% решила расстаться с вами. Но рассматривайте это, как возможность позитивных перемен - мир полон перспектив 😉',
    teamUserLeft: '%userName% покинул команду 🙁',
    teamUserLeftDesc: 'К сожалению, %userName% принял решения покинуть команду %teamName%',
    gameUpdated: 'В матче против %competitor% изменилось время/место',
    gameUpdatedDesc: 'Матч пройдёт %date% в %time%, по адресу: %location%',
    gameInvited: 'Вашей команде %teamName% предстоит матч против %competitor%',
    gameInvitedDesc: 'Матч пройдёт %date% в %time%, по адресу: %location%',
    gameInvitedDecline: 'Пропущу игру',
    gameInvitedAccept: 'Приду на игру',
    gameInviteAccepted: '%userName% придёт на игру против %competitor%!',
    gameInviteDeclined: '%userName% не сможет прийти на игру против %competitor%',
    gameRemind: 'Напоминаем, вам предстоит матч с командой %competitor%',
    gameRemindDesc: 'Матч пройдёт %date% в %time%, по адресу: %location%',
    gameWon: 'Победа над %competitor%!',
    gameWonDesc: 'Поздравляем с победой! Так держать! Не забудьте зайти в раздел статистики и посмотреть как ваша победа выглядит в цифрах 😉',
    gameLose: 'Поражение от %competitor%',
    gameLoseDesc: 'Не расстраивайтесь, все проходят через это. Это лишь ещё один повод стать сильнее. Загляните в статистику, ключ к улучшениям может быть в этих цифрах.',
    gameTechVictory: 'Техническая победа над %competitor%!',
    gameTechVictoryDesc: 'Команда %competitor% не сможет принять участия в матче с вами %date% в %time%',
    gameTechDefeat: 'Вашей команде присудили техническое поражение в матче против команды %competitor%.',
    pollCreated: 'Новый опрос в %teamName%',
    pollCreatedDesc: 'Каждый голос важен и должен быть услышен!',
    pollClosed: 'Опрос в команде %teamName% завершён и все голоса посчитаны. Не забудьте ознакомиться с результатами.',
    teamEventTrainingInvited: 'У команды %teamName% намечается тренировка',
    teamEventTrainingInvitedDesc: 'Тренировка пройдёт %date% в %time%, по адресу %location%',
    teamEventCustomInvited: 'У команды %teamName% намечается событие %eventName%',
    teamEventCustomInvitedDesc: 'Событие %eventName% пройдёт %date% в %time%, по адресу %location%',
    teamEventInviteDecline: 'Не пойду',
    teamEventInviteAccept: 'Пойду',
    teamTrainingAccepted: '%userName% придёт на тренировку %datetime%',
    teamEventAccepted: '%userName% обещал присутствовать на запланированном событии "%eventName%"',
    teamTrainingDeclined: '%userName% пропустит тренировку %datetime%',
    teamEventDeclined: '%userName% не сможет присутствовать на запланированном событии "%eventName%"',
    teamTrainingRemind: 'Напоминаем, у команды %teamName% намечается тренировка',
    teamEventRemind: 'Напоминаем, у команды %teamName намечается событие %eventName%',
    tournamentInvited: 'Команду %teamName% пригласили на турнир %tournamentName%',
    tournamentInvitedDesc: 'Чтобы принять участие в турнире, вам нужно подать заявку и дождаться её рассмотрения организаторами турнира',
    tournamentInviteDecline: 'Отклонить приглашение',
    tournamentInviteAccept: 'Подать заявку',
    tournamentJoined: 'Поздравляем! Ваша команда вступила в турнир %tournamentName%',
    tournamentJoinedDesc: 'Обязательно следите за расписание турнира, изучайте соперника и побеждайте',
    tournamentJoinDeclined: 'К сожалению, заявка на участие вашей команды в турнире %tournamentName% была отклонена',
    tournamentJoinCanceled: 'Ваша команда отозвала заявку на участие в турнире %tournamentName%',
    tournamentUserInvited: '%initiator% добавил вас в заявку на турнир %tournamentName%',
    tournamentUserInvitedDesc: 'Вы сможете принять участие в турнире после одобрения вашей заявки организаторами',
    tournamentUserAcceptedForTeam: '%userName% был допущен до участия в турнире %tournamentName%!',
    tournamentUserAcceptedForUser: 'Поздравляем! Вы стали участником турнира %tournamentName%!',
    tournamentUserAcceptedForUserDesc: 'Обязательно следите за расписанием турнира, изучайте соперника и побеждайте!',
    tournamentUserAdded: 'Поздравляем! Вы стали участником турнира %tournamentName%!',
    tournamentUserAddedDesc: 'Обязательно следите за расписанием турнира, изучайте соперника и побеждайте!',
    tournamentUserDeclinedForTeam: 'К сожалению, %userName% не был допущен до участия в турнире %tournamentName%',
    tournamentUserDeclinedForUser: 'Сожалеем, но вы не были допущены до участие в турнире %tournamentName% 🙁',
    tournamentUserInviteDeleted: '%initiator% исключил вас из заявки на турнир %tournamentName%',
    tournamentUserDeleted: '%initiator% исключил вас из заявки на турнир %tournamentName%',
    tournamentUserDisqualifiedForTeam: '%userName% был отстранён от участия в матчах турнира %tournamentName%',
    tournamentUserDisqualifiedForTeamDesc: 'Организаторы приняли решение отстранить %userName% от игр на',
    match1: 'матч',
    match2: 'матча',
    match3: 'матчей',
    forPeriod: 'период с %start% до %end%',
    forAllTime: 'время проведения турнир',
    tournamentUserDisqualifiedForUser: 'Вы были отстранены от участия в матчах турнира %tournamentName%',
    tournamentUserDisqualifiedForUserDesc: 'Организаторы турнира %tournamentName% приняли решение отстранить вас от игр на',
    tournamentUserDisqualificationUpdatedForTeam: 'Условия дисквалификации игрока %userName% были изменены',
    tournamentUserDisqualificationUpdateForTeamDesc: 'Организаторы турнира %tournamentName% изменили условия дисквалификации %userName% на следующие: ',
    untilEndTournament: 'до окончания турнира',
    tournamentUserDisqualificationUpdatedForUser: 'Условия дисквалификации были изменены',
    tournamentUserDisqualificationUpdatedForUserDesc: 'Организаторы турнира %tournamentName% изменили условия вашей дисквалицикации на следующие:',
    tournamentTeamDeleted: 'Ваша команда снялась с турнира %tournamentName%',
    leagueNews: 'Важная новость от организиторов лиги %leagueName%',
    read: 'Читать',
  },
  [Locales.en]: {
    teamNameChanged: 'Team %old% has changed its name to %new%',
    teamLogoChanged: 'Team logo %teamName% has changed',
    teamDataChanged: 'Team data has been updated',
    teamInvited: 'You have been invited to join the team',
    teamInvitedDesc: '%initiator% has invited you to join team %teamName%',
    declineInvite: 'Refuse',
    acceptInvite: 'Accept',
    teamJoinRequest: 'New application to join team %teamName%',
    joinRequestDesc: '%initiator% has applied to join team %teamName%',
    joinRequestDecline: 'Reject',
    joinRequestAccept: 'Accept',
    teamJoinDeclinedByTeam: 'Unfortunately, your application to join %teamName% has been declined 🙁',
    teamJoinDeclinedByUser: '%userName% declined your offer to join %teamName%',
    teamJoinCanceledByTeam: 'Unfortunately, %teamName% has withdrawn their offer to join 🙁',
    teamJoinCanceledByUser: '%userName% has withdrawn their application to join %teamName%',
    teamJoinedForTeam: '%teamName% has a new addition!',
    teamJoinedForTeamDesc: 'Great news! %teamName% has a new player - %userName%',
    teamJoinedForUser: 'You have joined %teamName%',
    teamJoinedForUserDesc: 'Congratulations! You have joined %teamName%, and together you have every chance to reach new heights! 🥳',
    roleChangedForTeam: '%userName% role in %teamName% has been changed to %role%',
    roleChangedForUser: 'Your role in the team has been changed',
    roleChangedForUserDesc: '%initiator% has changed your role in ${item.team.name} to %role%',
    numberChanged: 'Your number in %teamName% has been changed to %number%',
    teamUserDeletedForTeam: '%userName% has been removed from %teamName%',
    teamUserDeletedForUser: 'You have been removed from the team 🙁',
    teamUserDeletedForUserDesc: 'Unfortunately, %teamName% has decided to part ways with you. But consider this as an opportunity for positive change - the world is full of opportunities 😉',
    teamUserLeft: '%userName% has left the team 🙁',
    teamUserLeftDesc: 'Unfortunately, %userName% has decided to leave %teamName%',
    gameUpdated: 'The match against %competitor% has changed time/location',
    gameUpdatedDesc: 'The match will take place on %date% at %time%, at %location%',
    gameInvited: 'Your team %teamName% will play against %competitor%',
    gameInvitedDesc: 'The match will take place on %date% at %time%, at %location%',
    gameInvitedDecline: 'I will miss the game',
    gameInvitedAccept: 'I will attend the game',
    gameInviteAccepted: '%userName% will be attending the game against %competitor%!',
    gameInviteDeclined: '%userName% will not be able to attend the game against %competitor%',
    gameRemind: 'We remind you that you have a match against %competitor%',
    gameRemindDesc: 'The match will take place on %date% at %time%, at %location%',
    gameWon: 'Victory against %competitor%!',
    gameWonDesc: 'Congratulations on your victory! Keep up the good work! Dont forget to check out the stats section to see how your victory looks in numbers 😉',
    gameLose: 'Loss to %competitor%',
    gameLoseDesc: 'Don\'t be upset, everyone goes through this. It\'s just another reason to become stronger. Take a look at the stats, the key to improvement may be in these numbers.',
    gameTechVictory: 'Technical victory over %competitor%!',
    gameTechVictoryDesc: 'Team %competitor% will not be able to participate in the match with you on %date% at %time%',
    gameTechDefeat: 'Your team has been awarded a technical defeat in the match against team %competitor%.',
    pollCreated: 'New poll in %teamName%',
    pollCreatedDesc: 'Every vote counts and should be heard!',
    pollClosed: 'The poll in team %teamName% has been closed and all votes have been counted. Don\'t forget to check out the results.',
    teamEventTrainingInvited: '%teamName% has a training session scheduled',
    teamEventTrainingInvitedDesc: 'The training session will take place on %date% at %time%, at %location%',
    teamEventCustomInvited: '%teamName% has an event scheduled for %eventName%',
    teamEventCustomInvitedDesc: '%eventName% will take place on %date% at %time%, at %location%',
    teamEventInviteDecline: 'Won\'t go',
    teamEventInviteAccept: 'Will go',
    teamTrainingAccepted: '%userName% will be attending the training session on %datetime%',
    teamEventAccepted: '%userName% promised to attend the scheduled event "%eventName%"',
    teamTrainingDeclined: '%userName% will miss training on %datetime%',
    teamEventDeclined: '%userName% will not be able to attend the scheduled event "%eventName%"',
    teamTrainingRemind: 'We remind you that the team %teamName% has a training session coming up',
    teamEventRemind: 'We remind you that the team %teamName has an event coming up %eventName%',
    tournamentInvited: '%teamName% has been invited to the tournament %tournamentName%',
    tournamentInvitedDesc: 'To participate in the tournament, you must submit an application and wait for it to be reviewed by the tournament organizers',
    tournamentInviteDecline: 'Decline invitation',
    tournamentInviteAccept: 'Submit application',
    tournamentJoined: 'Congratulations! Your team has entered the tournament %tournamentName%',
    tournamentJoinedDesc: 'Be sure to follow the tournament schedule, study your opponent and win',
    tournamentJoinDeclined: 'Unfortunately, your team\'s application to participate in the tournament %tournamentName% has been rejected',
    tournamentJoinCanceled: 'Your team has withdrawn its application to participate in the tournament %tournamentName%',
    tournamentUserInvited: '%initiator% has added you to the application for the tournament %tournamentName%',
    tournamentUserInvitedDesc: 'You will be able to participate in the tournament after your application is approved by the organizers',
    tournamentUserAcceptedForTeam: '%userName% has been accepted to participate in the tournament %tournamentName%!',
    tournamentUserAcceptedForUser: 'Congratulations! You have become a participant of the tournament %tournamentName%!',
    tournamentUserAcceptedForUserDesc: 'Be sure to follow the tournament schedule, study your opponent and win!',
    tournamentUserAdded: 'Congratulations! You have become a participant of the tournament %tournamentName%!',
    tournamentUserAddedDesc: 'Be sure to follow the tournament schedule, study your opponent and win!',
    tournamentUserDeclinedForTeam: 'Unfortunately, %userName% was not allowed to participate in the tournament %tournamentName%',
    tournamentUserDeclinedForUser: 'We are sorry, but you were not allowed to participate in the tournament %tournamentName% 🙁',
    tournamentUserInviteDeleted: '%initiator% excluded you from the application for the tournament %tournamentName%',
    tournamentUserDeleted: '%initiator% excluded you from the application for the tournament %tournamentName%',
    tournamentUserDisqualifiedForTeam: '%userName% was suspended from participating in matches of the tournament %tournamentName%',
    tournamentUserDisqualifiedForTeamDesc: 'The organizers have decided to disqualify %userName% from playing for',
    match1: 'match',
    match2: 'matches',
    match3: 'matches',
    forPeriod: 'period from %start% to %end%',
    forAllTime: 'time of the tournament',
    tournamentUserDisqualifiedForUser: 'You have been disqualified from participating in matches of the tournament %tournamentName%',
    tournamentUserDisqualifiedForUserDesc: 'The organizers of the tournament %tournamentName% have decided to disqualify you from playing for',
    tournamentUserDisqualificationUpdatedForTeam: 'The disqualification conditions for player %userName% have been changed',
    tournamentUserDisqualificationUpdateForTeamDesc: 'The organizers of the tournament %tournamentName% have changed the disqualification conditions %userName% to the following: ',
    untilEndTournament: 'until the end of the tournament',
    tournamentUserDisqualificationUpdatedForUser: 'The disqualification conditions have been changed',
    tournamentUserDisqualificationUpdatedForUserDesc: 'The organizers of the tournament %tournamentName% have changed the conditions of your disqualification to the following:',
    tournamentTeamDeleted: 'Your team has withdrawn from the tournament %tournamentName%',
    leagueNews: 'Important news from the organizers of the league %leagueName%',
    read: 'Read',
  }
};
