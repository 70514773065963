import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: 'canvas[mtgWhiteImageFilter]'
})
export class WhiteImageFilterDirective implements OnChanges {
  @Input('mtgWhiteImageFilter')
  imgSrc: string;

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgSrc && this.imgSrc) {
      this.drawImage();
    }
  }

  private drawImage() {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    const canvas = this.elementRef.nativeElement;
    console.log(canvas.width);
    console.log(canvas.height);
    const ctx = canvas.getContext('2d');
    img.onload = () => {
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < imgData.data.length; i += 4) {
        imgData.data[i] = 255; // Red, 0-255
        imgData.data[i + 1] = 255; // Green, 0-255
        imgData.data[i + 2] = 255; // Blue, 0-255
      }
      ctx.clearRect(0, 0, canvas.width, canvas.height); // clear the original image
      ctx.putImageData(imgData, 0, 0); // paint the new colorised image
    };

    img.src = this.imgSrc;
  }
}
