import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGameService } from '@core/services/tournament-game.service';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { Game } from '@core/models/game';
import { HandballGameConfig } from '@core/models/handball-game-config';
import { HandballGameLog } from '@core/models/handball-game-log';
import { HandballGameStatistic } from '@core/models/handball-game-statistic';
import { HandballGameTeamStatistic } from '@core/models/handball-game-team-statistic';

@Injectable()
export class TournamentHandballGameService extends TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getGame(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/tournament_handball_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<HandballGameTeamStatistic> {
    return this.httpClient.get(`/api/v1/tournament_handball_game/${gameId}/team_statistic/`).pipe(
      map(result => HandballGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<HandballGameStatistic[]> {
    return this.httpClient.get(`/api/v1/tournament_handball_game/${gameId}/user_statistic/`).pipe(
      map(result => HandballGameStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<HandballGameLog[]> {
    return this.httpClient.get(`/api/v1/tournament_handball_game/${gameId}/logs/`).pipe(
      map(result => HandballGameLog.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<HandballGameConfig> {
    return this.httpClient.get(`/api/v1/tournament_handball_game/${gameId}/config/`).pipe(
      map(result => HandballGameConfig.toFront(result))
    );
  }

  getGameProtocolFile(gameId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient.get(`/api/v1/tournament_handball_game/${gameId}/game_protocol/`, {params, responseType: 'blob'});
  }
}
