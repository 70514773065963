import { BaseModel, enumField, ModelInstance, MomentDateField, ToBackHook, ToFrontHook } from './util';
import { City } from './city';
import { File } from './file';
import * as moment from 'moment';
import { League } from './league';
import { Sport } from '@core/models/sport';
import { Team } from '@core/models/team';
import { TournamentSeason } from '@core/models/tournament-season';
import { SeasonTournament } from '@core/models/season-tournament';

export enum TournamentTypes {
  group = 'group',
  elimination = 'elimination',
  fiba = 'fiba'
}

export enum TournamentStatuses {
  open = 1,
  in_progress,
  closed,
}

export enum TournamentGender {
  male= 1,
  female,
  all
}

export enum BasketballStatisticTypes {
  standard = 1,
  light,
  advanced
}

export enum VolleyballStatisticTypes {
  standard = 1,
  light,
  advanced
}

export enum RugbyStatisticTypes {
  standard = 1,
  light = 2
}

export enum WaterpoloStatisticTypes {
  standard = 1,
  light = 2
}

export enum WrestballStatisticTypes {
  standard = 1,
}

export enum GameTimeTypes {
  clean = 1,
  dirty
}

export enum TimerTypes {
  backward = 1,
  forward
}

export enum OvertimeTypes {
  to_score_total = 1,
  to_score_diff,
  time,
  without_overtime,
  to_first_goal,
  to_first_goal_and_bullitts,
  bullitts,
  penalties,
  time_and_penalties,
  time_and_first_goal,
}

export enum SubstituteManageTypes {
  goalkeeper_only = 1,
  additional_screen,
  one_screen,
  split_screen,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    gender: 'gender',
    birth_year_from: 'birthYearFrom',
    birth_year_to: 'birthYearTo',
    max_team_players: 'maxTeamPlayers',
    max_game_players: 'maxGamePlayers',
    type: 'type',
    tours_count: 'toursCount',
    periods_count: 'periodsCount',
    period_time: 'periodTime',
    overtime_type: 'overtimeType',
    overtime_time: 'overtimeTime',
    overtime_score: 'overtimeScore',
    game_type: 'gameType',
    timeout_count: 'timeoutCount',
    overtime_timeout_count: 'overtimeTimeoutCount',
    timeout_time: 'timeoutTime',
    substitute_count: 'substituteCount',
    game_up_to_score: 'gameUpToScore',
    volleyball_score_in_set: 'volleyballScoreInSet',
    volleyball_score_in_last_set: 'volleyballScoreInLastSet',
    manage_team_application: 'manageTeamApplication',
    recruitment_is_open: 'recruitmentIsOpen',
    transfer_is_open: 'transferIsOpen',
    basketball_statistic_type: 'basketballStatisticType',
    volleyball_statistic_type: 'volleyballStatisticType',
    rugby_statistic_type: 'rugbyStatisticType',
    waterpolo_statistic_type: 'waterpoloStatisticType',
    wrestball_statistic_type: 'wrestballStatisticType',
    shot_clock_enabled: 'shotClockEnabled',
    game_time_type: 'gameTimeType',
    with_result_table: 'withResultTable',
    free_substitute_enabled: 'freeSubstituteEnabled',
    substitute_manage_enabled: 'substituteManageEnabled',
    substitute_manage_mode: 'substituteManageMode',
    overtime_periods: 'overtimePeriods',
    bonus_points_enabled: 'bonusPointsEnabled',
    minor_penalty_time: 'minorPenaltyTime',
    major_penalty_time: 'majorPenaltyTime',
    misconduct_penalty_time: 'misconductPenaltyTime',
    game_misconduct_penalty_time: 'gameMisconductPenaltyTime',
    match_penalty_time: 'matchPenaltyTime',
    hide_on_league_main_page: 'hideOnLeagueMainPage',
    wrestler_scrums_count: 'wrestlerScrumsCount',
    wrestlers_count: 'wrestlersCount',
    timer_type: 'timerType',
    game_application_time_disabled: 'gameApplicationTimeDisabled',
    max_game_users: 'maxGameUsers',
  },
  relation: {
    overtimeType: enumField(OvertimeTypes),
    type: enumField(TournamentTypes),
    gender: enumField(TournamentGender),
    basketballStatisticType: enumField(BasketballStatisticTypes),
    volleyballStatisticType: enumField(VolleyballStatisticTypes),
    rugbyStatisticType: enumField(RugbyStatisticTypes),
    waterpoloStatisticType: enumField(WaterpoloStatisticTypes),
    wrestballStatisticType: enumField(WrestballStatisticTypes),
    gameTimeType: enumField(GameTimeTypes),
    timerType: enumField(TimerTypes),
    substituteManageMode: enumField(SubstituteManageTypes),
  }
})
export class TournamentSettings extends BaseModel {
  private _substituteManageMode: SubstituteManageTypes;

  id: number;
  gender: TournamentGender;
  birthYearFrom: number;
  birthYearTo: number;
  maxTeamPlayers: number;
  maxGamePlayers: number;
  type: TournamentTypes;
  toursCount: number;
  periodsCount: number;
  periodTime: number;
  overtimeType: OvertimeTypes;
  overtimeTime: number;
  overtimeScore: number;
  gameType: string;
  timeoutCount: number;
  overtimeTimeoutCount: number;
  timeoutTime: number;
  substituteCount: number;
  gameUpToScore: number;
  volleyballScoreInSet: number;
  volleyballScoreInLastSet: number;
  manageTeamApplication: boolean;
  recruitmentIsOpen: boolean;
  transferIsOpen: boolean;
  basketballStatisticType: BasketballStatisticTypes;
  volleyballStatisticType: VolleyballStatisticTypes;
  rugbyStatisticType: RugbyStatisticTypes;
  waterpoloStatisticType: WaterpoloStatisticTypes;
  wrestballStatisticType: WrestballStatisticTypes;
  shotClockEnabled: boolean;
  gameTimeType: GameTimeTypes;
  withResultTable: boolean;
  freeSubstituteEnabled: boolean;
  substituteManageEnabled: boolean;
  overtimePeriods: number;
  bonusPointsEnabled: boolean;
  minorPenaltyTime: number;
  majorPenaltyTime: number;
  misconductPenaltyTime: number;
  gameMisconductPenaltyTime: number;
  matchPenaltyTime: number;
  hideOnLeagueMainPage: boolean;
  wrestlerScrumsCount: number;
  wrestlersCount: number;
  timerType: TimerTypes;
  gameApplicationTimeDisabled: number;
  maxGameUsers: number;

  get substituteManageMode(): SubstituteManageTypes {
    if (this._substituteManageMode) {
      return this._substituteManageMode;
    }
    if (this.substituteManageEnabled) {
      return SubstituteManageTypes.split_screen;
    }
    return SubstituteManageTypes.goalkeeper_only;
  }

  set substituteManageMode(value: SubstituteManageTypes) {
    this._substituteManageMode = value;
  }

  get genderTitle(): string {
    const yearDiff = new Date().getFullYear() - this.birthYearTo;
    if (this.gender === TournamentGender.male) {
      if (yearDiff < 15) {
        return 'Мальчики';
      }
      if (yearDiff < 21) {
        return 'Юноши';
      }
      return 'Мужчины';
    }
    if (this.gender === TournamentGender.female) {
      if (yearDiff < 15) {
        return 'Девочки';
      }
      if (yearDiff < 21) {
        return 'Девушки';
      }
      return 'Женщины';
    }
    return 'Смешанный';
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    tournament_id: 'tournamentId',
    team: 'team',
    points: 'points',
  },
  relation: {
    team: Team,
  }
})
class TournamentTeam extends BaseModel {
  id: number;
  teamId: number;
  tournamentId: number;
  team: Team;
  points: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    sort: 'sort',
    alias: 'alias',
    logo: 'logo',
    cover: 'cover',
    cover_mobile: 'coverMobile',
    preview_image: 'previewImage',
    city: 'city',
    sport: 'sport',
    location: 'location',
    description: 'description',
    date: 'date',
    price: 'price',
    regulation_files: 'regulationFiles',
    settings: 'settings',
    league: 'league',
    season: 'season',
    season_tournament: 'seasonTournament',
    teams_count: 'teamsCount',
    closest_game_datetime: 'closestGameDatetime',
    status: 'status',
    team_winner: 'teamWinner',
  },
  relation: {
    logo: File,
    cover: File,
    coverMobile: File,
    previewImage: File,
    city: City,
    sport: Sport,
    regulationFile: File,
    settings: TournamentSettings,
    date: MomentDateField,
    league: League,
    season: TournamentSeason,
    seasonTournament: SeasonTournament,
    status: enumField(TournamentStatuses),
    teamWinner: TournamentTeam,
  }
})
export class Tournament extends BaseModel {
  id: number;
  name: string;
  sort: number;
  alias: string;
  logo: File;
  cover: File;
  coverMobile: File;
  previewImage: File;
  city: City;
  sport: Sport;
  location: string;
  description: string;
  date: moment.Moment;
  price: number;
  regulationFiles: File[];
  settings: TournamentSettings;
  league: League;
  season: TournamentSeason;
  seasonTournament: SeasonTournament;
  teamsCount: number;
  closestGameDatetime: string;
  status: TournamentStatuses;
  teamWinner: TournamentTeam;

  getEmptyLogoClass(): string {
    const classList = ['empty-logo'];
    if (this.sport.isBasketball()) {
      classList.push('empty-logo-basketball');
    }
    if (this.sport.isVolleyball()) {
      classList.push('empty-logo-volleyball');
    }
    if (this.sport.isHockey()) {
      classList.push('empty-logo-hockey');
    }
    if (this.sport.isFootball()) {
      classList.push('empty-logo-football');
    }
    if (this.sport.isWaterpolo()) {
      classList.push('empty-logo-waterpolo');
    }
    return classList.join(' ');
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
