import { Injectable } from '@angular/core';
import { of, combineLatest, forkJoin } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { User } from '../models/user';
import { Team } from '../models/team';
import { UserStatistic } from '../models/user-statistic';
import { TeamInvite } from '../models/team-invite';
import { Game } from '../models/game';
import { GameInvite } from '../models/game-invite';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { addItemInArray, deleteItemFromArray, updateItemInArray } from '@shared/util/data';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { TeamService } from '@core/services/team.service';
import { TeamEvent } from '@core/models/team-event';
import { UserPermission } from '@core/models/user-permission';
import { UserProfile } from '@core/models/user-profile';
import { UserAccess } from '@core/models/user-access';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';
import { FootballStatistic } from '@core/models/football-statistic';
import { HandballStatistic } from '@core/models/handball-statistic';
import { TournamentService } from '@core/services/tournament.service';
import { BasketballStatistic } from '@core/models/basketball-statistic';
import { Sport, SportTypes } from '@core/models/sport';
import { RugbyStatistic } from '@core/models/rugby-statistic';
import { HockeyStatistic } from '@core/models/hockey-statistic';
import { WaterpoloStatistic } from '@core/models/waterpolo-statistic';
import { RsvAuthService } from '@core/services/rsv-auth.service';


@Injectable()
export class ProfileService {
  private _userSubject = new BehaviorSubject<User>(undefined);
  private _permissionSubject = new BehaviorSubject<UserPermission>(undefined);
  private _teamsSubject = new BehaviorSubject<Team[]>(undefined);
  private _invitesSubject = new BehaviorSubject<TeamInvite[]>(undefined);
  private _teamInvitesSubject = new BehaviorSubject<TeamInvite[]>(undefined);
  private _userApplicationsSubject = new BehaviorSubject<TeamInvite[]>(undefined);
  private _gamesSubject = new BehaviorSubject<Game[]>(undefined);
  private _gameInvitesSubject = new BehaviorSubject<GameInvite[]>(undefined);
  private _statisticSubject = new BehaviorSubject<UserStatistic>(undefined);
  private _streetballStatisticSubject = new BehaviorSubject<UserStatistic>(undefined);
  private _volleyballStatisticSubject = new BehaviorSubject<VolleyballStatistic>(undefined);
  private _footballStatisticSubject = new BehaviorSubject<FootballStatistic>(undefined);
  private _hockeyStatisticSubject = new BehaviorSubject<HockeyStatistic>(undefined);
  private _handballStatisticSubject = new BehaviorSubject<HandballStatistic>(undefined);
  private _rugbyStatisticSubject = new BehaviorSubject<RugbyStatistic>(undefined);
  private _waterpoloStatisticSubject = new BehaviorSubject<WaterpoloStatistic>(undefined);
  private _teamEventsSubject = new BehaviorSubject<TeamEvent[]>(undefined);
  private _accessSubject = new BehaviorSubject<UserAccess>(undefined);

  get user$() {
    return this._userSubject.pipe(filter(item => item !== undefined));
  }

  get permission$() {
    return this._permissionSubject.pipe(filter(item => item !== undefined));
  }

  get teams$() {
    return this._teamsSubject.pipe(filter(item => item !== undefined));
  }

  get invites$() {
    return this._invitesSubject.pipe(filter(item => item !== undefined));
  }

  get teamInvites$() {
    return this._teamInvitesSubject.pipe(filter(item => item !== undefined));
  }

  get userApplications$() {
    return this._userApplicationsSubject.pipe(filter(item => item !== undefined));
  }

  get games$() {
    return this._gamesSubject.pipe(filter(item => item !== undefined));
  }

  get gameInvites$() {
    return this._gameInvitesSubject.pipe(filter(item => item !== undefined));
  }

  get statistic$() {
    return this._statisticSubject.pipe(filter(item => item !== undefined));
  }

  get streetballStatistic$() {
    return this._streetballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get volleyballStatistic$() {
    return this._volleyballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get footballStatistic$() {
    return this._footballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get hockeyStatistic$() {
    return this._hockeyStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get handballStatistic$() {
    return this._handballStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get rugbyStatistic$() {
    return this._rugbyStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get waterpoloStatistic$() {
    return this._waterpoloStatisticSubject.pipe(filter(item => item !== undefined));
  }

  get teamEvents$() {
    return this._teamEventsSubject.pipe(filter(item => item !== undefined));
  }

  get access$() {
    return this._accessSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private centrifugoService: CentrifugoService,
    private tournamentService: TournamentService,
    private rsvAuthService: RsvAuthService,
  ) {}

  initialize(userId?: number) {
    this.listenCentrifugoEvents(userId);

    return forkJoin([
      this.getProfile(userId),
      this.getAccess(userId)
    ]).pipe(
      mergeMap(([profile, access]) => {
        const loads = [];
        loads.push(this.getUserPermission(userId));
        if (access['edit']) {
          loads.push(this.getInvites());
          loads.push(this.getTeamEvents(userId));
        } else {
          this._teamEventsSubject.next([]);
          this._invitesSubject.next([]);
        }
        if (access['teams']) {
          loads.push(this.getTeams(userId));
        } else {
          this._teamEventsSubject.next([]);
        }
        if (access['statistic']) {
          loads.push(this.getBasketballStatistic(userId));
          loads.push(this.getStreetballStatistic(userId));
          loads.push(this.getVolleyballStatistic(userId));
          loads.push(this.getFootballStatistic(userId));
          loads.push(this.getHockeyStatistic(userId));
          loads.push(this.getHandballStatistic(userId));
          loads.push(this.getRugbyStatistic(userId));
          loads.push(this.getWaterpoloStatistic(userId));
        } else {
          this._statisticSubject.next(new UserStatistic());
        }
        if (access['games']) {
          loads.push(this.getGames(userId));
        } else {
          this._gamesSubject.next([]);
        }
        return forkJoin(loads);
      })
    );
  }

  dispose() {
    if (this._userSubject.value) {
      this._userSubject.next(undefined);
      this._teamsSubject.next(undefined);
      this._invitesSubject.next(undefined);
      this._gamesSubject.next(undefined);
      this._gameInvitesSubject.next(undefined);
      this._teamEventsSubject.next(undefined);
      this._permissionSubject.next(undefined);
      this._accessSubject.next(undefined);
      this._statisticSubject.next(undefined);
    }
  }

  getProfile(userId?: number): Observable<User> {
    return (userId
      ? this.userService.getUserById(userId)
      : this.userService.getCurrentUser()
    ).pipe(
      tap(user => this._userSubject.next(user))
    );
  }

  getAccess(userId?: number): Observable<UserAccess> {
    return this.userService.getAccess(userId).pipe(
      tap(access => this._accessSubject.next(access))
    );
  }

  updateUser(user: User): Observable<User> {
    return this.userService.updateUser(user)
      .pipe(
        tap(newUser => this._userSubject.next(newUser)),
        tap(newUser => this.authService.user$.next(newUser))
      );
  }

  updateUserPhoto(file: any, filename?: string): Observable<User> {
    return this.userService.updateUserPhoto(file, filename).pipe(
      map(photo => {
        const user = this._userSubject.value;
        user.photo = photo;
        return user;
      }),
      tap(newUser => this._userSubject.next(newUser)),
      tap(newUser => this.authService.user$.next(newUser)),
    );
  }

  getUserPermission(userId): Observable<UserPermission> {
    return this.userService.getUserPermissions(userId).pipe(
      tap(data => this._permissionSubject.next(data))
    );
  }

  getTeams(userId?: number): Observable<Team[]> {
    return (!userId
      ? this.authService.user$.pipe(
        take(1),
        map(user => +user.id)
      )
      : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getTeams(_userId)),
      tap(teams => this._teamsSubject.next(teams)),
    );
  }

  getInvites(): Observable<TeamInvite[]> {
    return this.userService.getInvites().pipe(
      tap(invites => this._invitesSubject.next(invites)),
      tap(invites => this._teamInvitesSubject.next(invites.filter(invite => invite.teamAccept))),
      tap(invites => this._userApplicationsSubject.next(invites.filter(invite => invite.userAccept))),
    );
  }

  acceptInvite(inviteId: number): Observable<TeamInvite> {
    return this.userService.acceptInvite(inviteId).pipe(
      tap(result => {
        if (this._userSubject.value) {
          this.getTeams(this._userSubject.value.id).subscribe();
        }
        this._invitesSubject.next(deleteItemFromArray(this._invitesSubject.value, v => v.id === result.id));
        this._teamsSubject.next(addItemInArray(this._teamsSubject.value, result.team));
      }),
    );
  }

  declineInvite(inviteId: number): Observable<TeamInvite> {
    return this.userService.declineInvite(inviteId).pipe(
      tap(result => {
        this._invitesSubject.next(deleteItemFromArray(this._invitesSubject.value, result));
      })
    );
  }

  getBasketballStatistic(userId: number): Observable<BasketballStatistic> {
    return (!userId
      ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
      : of(userId)
    ).pipe(
      switchMap(_userId => this.tournamentService.getBasketballStatistic({
        group_by: 'user',
        user_id: _userId,
        per_game: false,
        sport_id: SportTypes.classic_basketball,
      })),
      map(statistic => statistic[0]),
      tap(statistic => this._statisticSubject.next(statistic)),
    );
  }

  getStreetballStatistic(userId: number): Observable<BasketballStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.tournamentService.getBasketballStatistic({
        group_by: 'user',
        user_id: _userId,
        per_game: false,
        sport_id: SportTypes.streetball,
      })),
      map(statistic => statistic[0]),
      tap(statistic => this._streetballStatisticSubject.next(statistic)),
    );
  }

  getVolleyballStatistic(userId: number): Observable<VolleyballStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.tournamentService.getVolleyballStatistic({
        group_by: 'user',
        user_id: _userId,
        per_game: false,
      })),
      map(statistic => statistic[0]),
      tap(statistic => this._volleyballStatisticSubject.next(statistic)),
    );
  }

  getFootballStatistic(userId: number): Observable<FootballStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getFootballStatistic({
        group_by: 'user',
        user_id: _userId,
        per_game: false,
      })),
      map(statistic => statistic[0]),
      tap(statistic => this._footballStatisticSubject.next(statistic)),
    );
  }

  getHockeyStatistic(userId: number): Observable<HockeyStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getHockeyStatistic(_userId)),
      tap(statistic => this._hockeyStatisticSubject.next(statistic))
    );
  }

  getHandballStatistic(userId: number): Observable<HandballStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getHandballStatistic(_userId)),
      tap(statistic => this._handballStatisticSubject.next(statistic))
    );
  }

  getRugbyStatistic(userId: number): Observable<RugbyStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getRugbyStatistic(_userId)),
      tap(statistic => this._rugbyStatisticSubject.next(statistic))
    );
  }

  getWaterpoloStatistic(userId: number): Observable<WaterpoloStatistic> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getWaterpoloStatistic(_userId)),
      tap(statistic => this._waterpoloStatisticSubject.next(statistic))
    );
  }

  getGames(userId: number): Observable<Game[]> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getGames(_userId)),
      tap(games => this._gamesSubject.next(games)),
    );
  }

  getTeamEvents(userId: number): Observable<TeamEvent[]> {
    return (!userId
        ? this.authService.user$.pipe(
          take(1),
          map(user => +user.id)
        )
        : of(userId)
    ).pipe(
      switchMap(_userId => this.userService.getTeamEvents(_userId)),
      tap(teamEvents => this._teamEventsSubject.next(teamEvents)),
    );
  }

  getGameInvites(): Observable<GameInvite[]> {
    return this.userService.getGameInvites().pipe(
      tap(gameInvites => this._gameInvitesSubject.next(gameInvites))
    );
  }

  acceptGameInvite(inviteId: number): Observable<GameInvite> {
    return this.userService.acceptGameInvite(inviteId).pipe(
      tap(result => this._gameInvitesSubject.next(deleteItemFromArray(this._gameInvitesSubject.value, result))),
      tap(result => this._gamesSubject.next(addItemInArray(this._gamesSubject.value, result.game)))
    );
  }

  declineGameInvite(inviteId: number): Observable<GameInvite> {
    return this.userService.declineGameInvite(inviteId).pipe(
      tap(result => this._gameInvitesSubject.next(deleteItemFromArray(this._gameInvitesSubject.value, result))),
    );
  }

  createTeam(team: Team): Observable<Team> {
    return this.teamService.create(team).pipe(
      tap(result => this._teamsSubject.next(addItemInArray(this._teamsSubject.value, result)))
    );
  }

  connectRsvAccount(authUrl: string): Observable<User> {
    return this.rsvAuthService.login(authUrl).pipe(
      switchMap(data => this.userService.connectRsvAccount(data)),
      tap(user => this._userSubject.next(user)),
    );
  }

  disconnectRsvAccount(): Observable<User> {
    return this.userService.disconnectRsvAccount().pipe(
      tap(user => this._userSubject.next(user))
    );
  }

  private listenCentrifugoEvents(userId) {
    if (this._userSubject.value) {
      this.centrifugoService.unsubscribe(`user_${this._userSubject.value.id}`);
    }

    of(userId).pipe(
      mergeMap(_userId => {
        if (!_userId) {
          return this.authService.user$.pipe(take(1), map(user => user.id));
        } else {
          return of(_userId);
        }
      })
    ).subscribe(_userId => {
      this.centrifugoService.listen(`user_${_userId}`).subscribe(message => {
        switch (message['action']) {
          case 'TEAM_INVITE_CREATED': {
            return this._invitesSubject.next(addItemInArray(this._invitesSubject.value, TeamInvite.toFront(message['data'])));
          }

          case 'TEAM_INVITE_DELETED': {
            return this._invitesSubject.next(
              deleteItemFromArray(this._invitesSubject.value, item => (
                item.teamId === message['data']['team_id'] && item.userId === message['data']['user_id']
              ))
            );
          }

          case 'TEAM_USER_ADDED': {
            return this._teamsSubject.next(
              addItemInArray(this._teamsSubject.value, Team.toFront(message['data']))
            );
          }

          case 'TEAM_USER_DELETED': {
            return this._teamsSubject.next(
              deleteItemFromArray(this._teamsSubject.value, item => item.id === message['data']['team_id'])
            );
          }

          case 'GAME_UPDATED': {
            return this._gamesSubject.next(updateItemInArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'GAME_ARCHIVED':
          case 'GAME_DELETED':
          case 'GAME_USER_DELETED': {
            return this._gamesSubject.next(deleteItemFromArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'GAME_INVITE_SENT': {
            return this._gameInvitesSubject.next(addItemInArray(this._gamesSubject.value, GameInvite.toFront(message['data'])));
          }

          case 'GAME_INVITE_DECLINED': {
            return this._gameInvitesSubject.next(deleteItemFromArray(this._gameInvitesSubject.value, GameInvite.toFront(message['data'])));
          }

          case 'GAME_USER_ADDED': {
            return this._gamesSubject.next(addItemInArray(this._gamesSubject.value, Game.toFront(message['data'])));
          }

          case 'TEAM_EVENT_INVITE_ACCEPTED': {
            return this._teamEventsSubject.next(addItemInArray(this._teamEventsSubject.value, TeamEvent.toFront(message['data'])));
          }
        }
      });
    });
  }
}
