import { TemplateRef } from '@angular/core';

export type ToastType = 'warning' | 'info' | 'success' | 'danger';

export class ToastData {
  type: ToastType;
  text?: string;
  template?: TemplateRef<any>;
  templateContext?: {};
  duration?: number;
}
