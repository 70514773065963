export const HockeyPenaltyTypes = [
  {id: 1, code: 'АГРЕС', title: 'Агрессор в драке'},
  {id: 2, code: 'АТ-В-ГОЛ', title: 'Атака в голову или шею'},
  {id: 3, code: 'АТ-ВР', title: 'Атака вратаря'},
  {id: 4, code: 'АТ-СЗ', title: 'Атака сзади'},
  {id: 5, code: 'БЛОК', title: 'Блокировка'},
  {id: 6, code: 'БР-КЛ', title: 'Бросок клюшки'},
  {id: 7, code: 'ВБ-ШБ', title: 'Выброс шайбы'},
  {id: 8, code: 'ГРУБ', title: 'Грубость'},
  {id: 9, code: 'ДИСЦ', title: 'Дисциплинарный штраф'},
  {id: 10, code: 'ДИС-КН', title: 'Дисциплинарный до конца матча'},
  {id: 11, code: 'ДРАКА', title: 'Драка'},
  {id: 12, code: 'ЗАЧИН', title: 'Зачинщик драки'},
  {id: 13, code: 'ЗД-ИГ', title: 'Задержка игры'},
  {id: 14, code: 'ЗД-КЛ-СП', title: 'Задержка клюшки соперника'},
  {id: 15, code: 'ЗД-КЛ', title: 'Задержка клюшкой'},
  {id: 16, code: 'ЗД-СП', title: 'Задержка соперника'},
  {id: 17, code: 'ЗД-ШБ', title: 'Задержка шайбы руками'},
  {id: 18, code: 'СЛ-КЛ', title: 'Игра со сломанной клюшкой'},
  {id: 19, code: 'ВП-КЛ', title: 'Игра высоко поднятой клюшкой'},
  {id: 20, code: 'КЛ-УД', title: 'Колющий удар'},
  {id: 21, code: 'СК-ШТ', title: 'Малый скамеечный штраф'},
  {id: 22, code: 'МШ', title: 'Матч-штраф'},
  {id: 23, code: 'НП-АТ', title: 'Неправильная атака'},
  {id: 24, code: 'ЧС-СТ', title: 'Нарушение численного состава'},
  {id: 25, code: 'НС-КЛ', title: 'Нестандартная клюшка'},
  {id: 26, code: 'НАР-ЭК', title: 'Опасное снаряжение'},
  {id: 27, code: 'НС-ПВ', title: 'Оскорбление судей и неспортивное поведение'},
  {id: 28, code: 'ОТ-ИГ', title: 'Отказ начать игру'},
  {id: 29, code: 'ОТСЧ', title: 'Отсечение'},
  {id: 30, code: 'ПЛЕВОК', title: 'Плевок'},
  {id: 31, code: 'ПОДН', title: 'Подножка'},
  {id: 32, code: 'ПОДС', title: 'Подсечка'},
  {id: 33, code: 'ПР-ИН', title: 'Предупреждение инфекций'},
  {id: 34, code: 'ПК-СК', title: 'Покидание скамейки во время конфликта'},
  {id: 35, code: 'СД-ВР', title: 'Сдвиг ворот'},
  {id: 36, code: 'СИМ', title: 'Симуляция'},
  {id: 37, code: 'ТЛ-БР', title: 'Толчок на борт'},
  {id: 38, code: 'ТЛ-КЛ', title: 'Толчок клюшкой'},
  {id: 39, code: 'УД-ГОЛ', title: 'Удар головой'},
  {id: 40, code: 'УД-КЛ', title: 'Удар клюшкой'},
  {id: 41, code: 'УД-К-КЛ', title: 'Удар концом клюшки'},
  {id: 42, code: 'УД-КОЛ', title: 'Удар коленом'},
  {id: 43, code: 'УД-ЛОК', title: 'Удар локтем'},
  {id: 44, code: 'УД-НГ', title: 'Удар ногой'},
  {id: 45, code: 'УКУС', title: 'Укус'},
  {id: 46, code: 'КН-ЗР', title: 'Физический контакт со зрителем'},
  {id: 47, code: 'ШТ-ВР', title: 'Штраф вратаря'}
];
