<div class="b-wizard panel" *ngIf="activeSlide">
  <h3 class="h3">{{activeSlide.title}}</h3>
  <div class="wizard-picture">
    <img [src]="activeSlide.image">
  </div>
  <div class="wizard-text">
    <p>{{activeSlide.text}}</p>
    <a class="slide-action" (click)="selectNextSlide()" *ngIf="firstSlideSelected()">
      Экскурсия по сервису <i class="icon-arrow-right"></i>
    </a>
  </div>
  <div class="wizard-controls">
    <div class="control-dots">
      <div [ngClass]="{'active': activeSlide === item}" *ngFor="let item of slides" class="dot"></div>
    </div>
    <div class="arrows">
      <button [disabled]="firstSlideSelected()" (click)="selectPreviousSlide()" class="left">
        <span class="icon-chevron-left"></span>
      </button>
      <button *ngIf="!lastSlideSelected()" (click)="selectNextSlide()" class="right">
        <span class="icon-chevron-right"></span>
      </button>

      <button *ngIf="lastSlideSelected()" (click)="closeWizard()" class="right primary">
        <span class="icon-ok"></span>
      </button>
    </div>
  </div>
  <div class="close-btn" (click)="closeWizard()">
    <i class="icon-close"></i>
  </div>
</div>
