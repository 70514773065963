import { ModelInstance, ToBackHook } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    uid: 'uid',
    token: 'token',
    email: 'email',
    first_name: 'firstName',
    last_name: 'lastName',
    city: 'city',
    new_password: 'newPassword',
    timezone: 'timezone',
  }
})
export class InviteConfirmModel {
  @ToBackHook
  static toBack(modelData: any): any {
    return null;
  }
}
