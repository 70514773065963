import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';
import { isTouchDevice } from '@shared/util/device';
import { PHONE_MASK_LIST } from '@ui-kit/form/components/input/input-mask';
import { LocalizationService } from '@shared/modules/localization/localization.service';

export class TextMaskConfig {
  mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false;
  guide?: boolean;
  placeholderChar?: string;
  pipe?: (conformedValue: string, config: TextMaskConfig) => false | string | object;
  keepCharPositions?: boolean;
  showMask?: boolean;
}

@Component({
  selector: 'mtg-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  private _control: AbstractControl;
  private _onChangeFunc: any;
  private _onTouchedFunc: any;
  private textMaskInputElement;
  private _value;

  @ViewChild('input', {read: ElementRef, static: true})
  input: ElementRef;

  @Input()
  public set value(value: any) {
    if (value === undefined || value === null) {
      value = '';
    }
    this._value = value;
    this.input.nativeElement.value = value;
  }
  public get value(): any {
    return this._value;
  }

  @Input()
  disabled: boolean;
  @Input()
  type = 'text';
  @Input()
  placeholder: string;
  @Input()
  label: string;
  @Input()
  fluid = true;
  @Input()
  required = false;
  @Input()
  hasError = false;
  @Input()
  formControlName: string;
  @Input()
  formControl: AbstractControl;
  @Input()
  textMask: TextMaskConfig;
  @Input()
  phoneMask: boolean;
  @Input()
  readOnly: boolean;
  @Input()
  icon: string;
  @Input()
  min: number;
  @Input()
  pattern: string;
  @Input()
  errors: any;
  @Input()
  prefix: string;
  @Input()
  suffix: string;
  @Input()
  transparent: boolean;
  @Input()
  trim: boolean;
  @Input()
  search: boolean;
  @Input()
  size: 'sm' | 'lg' = 'lg';

  @Output()
  change = new EventEmitter<any>();
  @Output()
  focus = new EventEmitter<any>();
  @Output()
  blur = new EventEmitter<any>();
  @Output()
  valueChange = new EventEmitter<any>();

  showPassword = false;
  isFocused: boolean;
  hasErrorsOnFocus: boolean;

  constructor(
    @Optional() private _parentFormGroup: FormGroupDirective,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    if (this._parentFormGroup) {
      this._control = this.formControl || this._parentFormGroup.control.get(this.formControlName);
    }
    if (this.textMask) {
      this._setupMask();
    }
  }

  @HostBinding('class')
  get className() {
    const value = this.input.nativeElement.value;
    const classList = [];
    if (this.fluid) {
      classList.push('fluid');
    }
    const control = this._control || this.formControl;
    if (!this.isFocused || this.hasErrorsOnFocus) {
      if (control && control.dirty && !control.valid) {
        classList.push('error');
      } else if (this.errors) {
        classList.push('error');
      }
    }
    if (value.toString().length > 0) {
      classList.push('filled');
    }
    if (this.icon) {
      classList.push('with-icon');
    }
    if (this.placeholder) {
      classList.push('with-placeholder');
    }
    if (this.prefix) {
      classList.push('with-prefix');
    }
    if (this.suffix) {
      classList.push('with-suffix');
    }
    if (!this.label) {
      classList.push('without-label');
    }
    if (this.transparent) {
      classList.push('transparent');
    }
    if (this.isFocused) {
      classList.push('focused');
    }
    if (this.size === 'sm') {
      classList.push('size-sm');
    } else {
      classList.push('size-lg');
    }
    return classList.join(' ');
  }

  changeValue(value: any, updateViewValue = false) {
    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(value);
      value = this.input.nativeElement.value;
    } else if (updateViewValue) {
      this.input.nativeElement.value = value;
    }
    if (this.phoneMask) {
      this.input.nativeElement.value = this.getPhoneMaskValue(value);
      value = this.input.nativeElement.value;
    }
    if (this.type === 'number' && value.length > 0) {
      value = +value;
    }
    if (this.type !== 'number' && this.trim) {
      if (value.trim().length !== value.length) {
        value = value.trim();
        this.input.nativeElement.value = value;
      }
    }
    this.change.emit(value);
    this.valueChange.emit(value);
    if (this._onChangeFunc) {
      this._onChangeFunc(value);
    }
    this._value = value;
  }

  onFocus($event: any): void {
    this.isFocused = true;
    this.hasErrorsOnFocus = this.getErrors().length > 0;
    this.focus.emit($event);
    if (isTouchDevice()) {
      document.body.classList.add('input-focused');
    }
  }

  onBlur($event: any): void {
    this.isFocused = false;
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
    if (isTouchDevice()) {
      document.body.classList.remove('input-focused');
    }
    this.blur.emit($event);
  }

  writeValue(value: any): void {
    if (value === null || value === undefined) {
      value = '';
    }
    this._value = value;

    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(value);
    } else {
      if (this.phoneMask) {
        this.input.nativeElement.value = this.getPhoneMaskValue(value);
      } else {
        this.input.nativeElement.value = value;
      }

    }
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getErrors() {
    const control = (this._control || this.formControl);
    let errors = {};
    if (control && control.dirty && control.errors) {
      errors = control.errors;
    } else if (this.errors) {
      errors = this.errors;
    }
    return Object.keys(errors)
      .filter(k => errors[k])
      .reduce((acc, value) => {
        let error = this.localizationService.formErrorLocalize(value);
        // let error = ERRORS_TRANSLATION[value];
        if (!error) {
          return acc;
        }
        if (typeof errors[value] === 'object') {
          for (const errorKey of Object.keys(errors[value])) {
            error = error.replace(`{${errorKey}}`, errors[value][errorKey]);
          }
        }
        acc.push(error);
        return acc;
      }, []);
  }

  getPhoneMaskValue(value: any) {
    if (this.input) {
      let matrix = '+###############';

      PHONE_MASK_LIST.forEach(item => {
        const code = item.code.replace(/[\s+#]/g, '');
        const phone = value.replace(/[\s+#-)(]/g, '');
        const checkVal = phone.substr(0, code.length);
        if (code === checkVal) {
          matrix = item.code;
        }
      });

      let i = 0;
      const val = value.replace(/\D/g, '');

      const newValue = matrix.replace(/(?!\+)./g, function(a) {
        return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
      });
      return newValue;
    }
  }

  _setupMask() {
    if (this.input) {
      this.textMaskInputElement = createTextMaskInputElement(
        Object.assign({inputElement: this.input.nativeElement}, this.textMaskConfig)
      );
    }
  }

  get textMaskConfig() {
    const defaultConfig = {
      mask: [],
      guide: true,
      placeholderChar: '_',
      pipe: undefined,
      keepCharPositions: false,
    };
    return Object.assign(defaultConfig, this.textMask);
  }
}
