import { Component, Input, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mtg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  exportAs: 'mtgMenu',
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent {
  @ViewChild('content', { read: TemplateRef })
  templateRef;

  @Input()
  class: string;

  onItemClicked() {
  }
}
