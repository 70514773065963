import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField, enumField } from './util';
import { Team } from './team';
import * as moment from 'moment';
import { Poll } from './poll';
import { Game } from './game';
import { TeamInvite } from './team-invite';
import { TeamUser } from './team-user';
import { GameInvite } from './game-invite';
import { TeamEvent } from './team-event';
import { TeamEventInvite } from './team-event-invite';
import { Tournament } from './tournament';
import { TournamentInvite } from './tournament-invite';
import { TournamentTeam } from './tournament-team';
import { User } from '@core/models/user';
import { League } from '@core/models/league';
import { LeaguePlayer } from '@core/models/league-player';
import { LeagueNews } from '@core/models/league-news';

export enum NotificationType {
  team_updated = 1,
  team_invited,
  team_join_request,
  team_join_declined_by_team,
  team_join_declined_by_user,
  team_join_canceled_by_team,
  team_join_canceled_by_user,
  team_joined_for_user,
  team_joined_for_team,
  team_user_role_changed_for_user,
  team_user_role_changed_for_team,
  team_user_number_changed,
  team_user_deleted_for_user,
  team_user_deleted_for_team,
  team_user_left,

  game_updated,
  game_invited,
  game_invite_accepted,
  game_invite_declined,
  game_remind,
  game_won,
  game_losing,
  game_tech_defeat,
  game_tech_victory,

  poll_created,
  poll_closed,

  team_event_invited,
  team_event_accepted,
  team_event_declined,
  team_event_remind,

  tournament_invited,
  tournament_joined,
  tournament_join_declined,
  tournament_join_canceled,
  tournament_user_invited,
  tournament_user_accepted_for_team,
  tournament_user_accepted_for_user,
  tournament_user_added,
  tournament_user_declined_for_team,
  tournament_user_declined_for_user,
  tournament_user_invite_deleted,
  tournament_user_deleted,
  tournament_user_disqualified_for_team,
  tournament_user_disqualified_for_user,
  tournament_user_disqualification_updated_for_team,
  tournament_user_disqualification_updated_for_user,
  tournament_team_deleted,
  league_news,

  org_tournament_invite_created,
  org_tournament_invite_declined,
  org_tournament_invite_deleted,
  org_tournament_invite_changed,
  org_tournament_user_deleted,
  org_tournament_user_invited,
  org_tournament_user_has_changes,
  org_tournament_team_leave,
  org_tournament_user_added,
  org_league_player_has_changes,
  org_tournament_team_has_changes,
  org_game_challenge_moderation
}

export enum NotificationServiceEnum {
  public = 1,
  org
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    event_type: 'event',
    data: 'data',
    initiator: 'initiator',
    team: 'team',
    team_invite: 'teamInvite',
    team_user: 'teamUser',
    poll: 'poll',
    game: 'game',
    game_invite: 'gameInvite',
    team_event: 'teamEvent',
    team_event_invite: 'teamEventInvite',
    league: 'league',
    tournament: 'tournament',
    tournament_invite: 'tournamentInvite',
    tournament_team: 'tournamentTeam',
    league_player: 'leaguePlayer',
    league_news: 'leagueNews',
    viewed: 'viewed',
    protected: 'protected',
    popup: 'popup',
    service: 'service',
    created_at: 'createdAt',
  },
  relation: {
    event: enumField(NotificationType),
    initiator: User,
    team: Team,
    teamInvite: TeamInvite,
    teamUser: TeamUser,
    poll: Poll,
    game: Game,
    gameInvite: GameInvite,
    teamEvent: TeamEvent,
    teamEventInvite: TeamEventInvite,
    league: League,
    tournament: Tournament,
    tournamentInvite: TournamentInvite,
    tournamentTeam: TournamentTeam,
    leaguePlayer: LeaguePlayer,
    leagueNews: LeagueNews,
    service: enumField(NotificationServiceEnum),
    createdAt: MomentDateTimeField,
  }
})
export class Notification extends BaseModel {
  id: number;
  event: NotificationType;
  data: any;
  initiator: User;
  team: Team;
  teamInvite: TeamInvite;
  teamUser: TeamUser;
  poll: Poll;
  game: Game;
  gameInvite: GameInvite;
  teamEvent: TeamEvent;
  teamEventInvite: TeamEventInvite;
  league: League;
  tournament: Tournament;
  tournamentInvite: TournamentInvite;
  tournamentTeam: TournamentTeam;
  leaguePlayer: LeaguePlayer;
  leagueNews: LeagueNews;
  viewed: boolean;
  protected: boolean;
  popup: boolean;
  service: NotificationServiceEnum;
  createdAt: moment.Moment;

  get important(): boolean {
    return true;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
