import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGameService } from '@core/services/tournament-game.service';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { Game } from '@core/models/game';
import { WaterpoloGameTeamStatistic } from '@core/models/waterpolo-game-team.statistic';
import { WaterpoloGameStatistic } from '@core/models/waterpolo-game-statistic';
import { WaterpoloGameLog } from '@core/models/waterpolo-game-log';
import { WaterpoloGameConfig } from '@core/models/waterpolo-game-config';

@Injectable()
export class TournamentWaterpoloGameService extends TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getGame(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/tournament_waterpolo_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<WaterpoloGameTeamStatistic> {
    return this.httpClient.get(`/api/v1/tournament_waterpolo_game/${gameId}/team_statistic/`).pipe(
      map(result => WaterpoloGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<WaterpoloGameStatistic[]> {
    return this.httpClient.get(`/api/v1/tournament_waterpolo_game/${gameId}/user_statistic/`).pipe(
      map(result => WaterpoloGameStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<WaterpoloGameLog[]> {
    return this.httpClient.get(`/api/v1/tournament_waterpolo_game/${gameId}/logs/`).pipe(
      map(result => WaterpoloGameLog.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<WaterpoloGameConfig> {
    return this.httpClient.get(`/api/v1/tournament_waterpolo_game/${gameId}/config/`).pipe(
      map(result => WaterpoloGameConfig.toFront(result))
    );
  }
}
