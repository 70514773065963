<label class="checkbox-container">
  <input
    #input
    (change)="change()"
    [checked]="value"
    [disabled]="disabled || readOnly"
    type="checkbox"
  />
  <span>
    <ng-container *ngIf="label">{{label}}</ng-container>
    <ng-container *ngIf="!label"><ng-content></ng-content></ng-container>
  </span>
  <i class="icon-ok"></i>
</label>
