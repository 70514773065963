import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TournamentStage } from '@core/models/tournament-stage';
import { map } from 'rxjs/operators';
import { TournamentStageTeam } from '@core/models/tournament-stage-team';
import { TournamentGroup } from '@core/models/tournament-group';
import { Playoff } from '@core/models/playoff';
import { Game } from '@core/models/game';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { applyGamesFilters, TournamentGamesFilters } from '@core/services/tournament-admin.service';

@Injectable()
export class TournamentStageService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getStage(stageId: number): Observable<TournamentStage> {
    return this.httpClient.get(`/api/v1/tournament_stage/${stageId}/`).pipe(
      map(data => TournamentStage.toFront(data))
    );
  }

  getTeams(stageId: number, includePlayoff = false): Observable<TournamentStageTeam[]> {
    let params = new HttpParams();
    if (includePlayoff) {
      params = params.set('include_playoff', '1');
    }
    return this.httpClient.get(`/api/v1/tournament_stage/${stageId}/teams/`, {params}).pipe(
      map(data => TournamentStageTeam.toFront(data))
    );
  }

  getGroups(stageId: number): Observable<TournamentGroup[]> {
    return this.httpClient.get(`/api/v1/tournament_stage/${stageId}/groups/`).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  getPlayoffs(stageId: number): Observable<Playoff[]> {
    return this.httpClient.get(`/api/v1/tournament_stage/${stageId}/playoff/`).pipe(
      map(data => Playoff.toFront(data))
    );
  }

  getGames(stageId: number, page?: number, size?: number, filters?: TournamentGamesFilters): Observable<PaginatedResponse<Game[]>> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (size) {
      params = params.set('size', size.toString());
    }
    params = applyGamesFilters(filters, params);

    return this.httpClient
      .get(`/api/v1/tournament_stage/${stageId}/games/`, {params, observe: 'response'})
      .pipe(
        map((data: HttpResponse<any>) => {
          return {
            total: +data.headers.get('X-Page-Count'),
            data: Game.toFront(data.body)
          };
        })
      );
  }
}
