import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    title: 'Обратная связь',
    subject: 'Тема обращения',
    message: 'Сообщение',
    save: 'Сохранить',
    error: 'Ошибка',
    partnership: 'Партнёрство',
    comment: 'Комментарий',
    successTitle: 'Обращение отправлено!',
    successDesc: 'Ответ придёт на ваш E-mail, указанный в профиле',
    main: 'На главную',
    closeAlert: 'Вы уверены что хотите закрыть страницу? Все изменения будут потеряны.',
    recaptchaError: 'Пройдите проверку recaptcha',
    requiredFieldsError: 'Заполните обязательные поля формы',
    recaptchaError1: 'Ошибка проверки recaptcha. Попробуйте еще раз',
    internalError: 'Не удалось отправить обратную связь. На севере ведуться работы, свяжитесь с нами позже',
  },
  [Locales.en]: {
    title: 'Feedback',
    subject: 'Subject',
    message: 'Message',
    save: 'Submit',
    error: 'Error',
    partnership: 'Partnership',
    comment: 'Comment',
    successTitle: 'Request sent!',
    successDesc: 'The answer will be sent to your email specified in your profile.',
    main: 'На главную',
    closeAlert: 'Are you sure you want to close this page? All changes will be lost.',
    recaptchaError: 'Pass the recaptcha test',
    requiredFieldsError: 'Fill in the required fields of the form',
    recaptchaError1: 'Recaptcha verification failed. Try again.',
    internalError: 'Failed to send feedback. Work is underway in the north, please contact us later',
  }
};
