import { Directive, ElementRef, AfterViewInit, OnDestroy, NgZone, Input } from '@angular/core';

@Directive({
  selector: 'img[mtgCroppie]',
  exportAs: 'croppie'
})
export class CroppieDirective implements AfterViewInit, OnDestroy {
  @Input('mtgCroppie')
  config: Croppie.CroppieOptions = {};
  defaultOptions: Croppie.CroppieOptions = {
    viewport: {
      width: 150,
      height: 150,
      type: 'circle'
    },
    boundary: {
      width: 300,
      height: 300
    },
    enableOrientation: true,
    enableZoom: true,
    enableExif: true,
  };

  element: HTMLImageElement;

  public croppie: Croppie;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {
    this.element = elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this.croppie = new Croppie(
      this.element,
      Object.assign(this.defaultOptions, this.config)
    );
    this.element.onload = () => {
      setTimeout(() => {
        this.ngZone.run(() => {
          this.croppie.setZoom(-1);
        });
      }, 10);
    };
  }

  ngOnDestroy() {
    if (this.croppie) {
      this.croppie.destroy();
    }
  }
}
