import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGameService } from '@core/services/tournament-game.service';
import { BasketballGameTeamStatistic } from '@core/models/basketball-game-team-statistic';
import { BasketballGameStatistic } from '@core/models/basketball-game-statistic';
import { BasketballGameLog } from '@core/models/basketball-game-log';
import { BasketballGameConfig } from '@core/models/basketball-game-config';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { Game } from '@core/models/game';

@Injectable()
export class TournamentBasketballGameService extends TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getGame(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/tournament_basketball_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<BasketballGameTeamStatistic> {
    return this.httpClient.get(`/api/v1/tournament_basketball_game/${gameId}/team_statistic/`).pipe(
      map(result => BasketballGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<BasketballGameStatistic[]> {
    return this.httpClient.get(`/api/v1/tournament_basketball_game/${gameId}/user_statistic/`).pipe(
      map(result => BasketballGameStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<BasketballGameLog[]> {
    return this.httpClient.get(`/api/v1/tournament_basketball_game/${gameId}/logs/`).pipe(
      map(result => BasketballGameLog.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<BasketballGameConfig> {
    return this.httpClient.get(`/api/v1/tournament_basketball_game/${gameId}/config/`).pipe(
      map(result => BasketballGameConfig.toFront(result))
    );
  }

  getGameProtocolFile(gameId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient.get(`/api/v1/tournament_basketball_game/${gameId}/game_protocol/`, {params, responseType: 'blob'});
  }
}
