<ng-container *ngIf="notification">
  <div class="header">
    <div class="date">
      <div class="new"></div>
      {{ notification.createdAt | mtgDate }}
    </div>
    <div class="actions">
      <a href="javascript:;" (click)="openSettings()"><span class="icon-settings"></span></a>
      <a href="javascript:;" (click)="hide.emit()"><span class="icon-close"></span></a>
    </div>
  </div>
  <div class="content">
    <div class="wrapper" #wrapper>
      <ng-container *ngFor="let item of notifications; let i = index;">
        <div class="item" [@scaleInOut] *ngIf="i === current" [ngClass]="{active: i === current}">
          <div class="logo">
            <img [src]="item.logo" *ngIf="item.logo">
            <div [class]="item.emptyLogoClass" *ngIf="!item.logo && item.emptyLogoClass"></div>
          </div>
          <div class="title" [innerHTML]="item.title"></div>
          <div class="description" [innerHTML]="item.description"></div>
          <div class="action" *ngIf="item.actions && !item.model.viewed">
            <button
              *ngFor="let action of item.actions"
              [mtgButton]="action.buttonColor"
              [mtgButtonType]="action.buttonType"
              (click)="doAction(item, action)"
              [loading]="loadings[item.model.id] === action"
            >{{ action.text }}</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="navigation">
    <a href="javascript:;" (click)="previous()"><span class="icon-chevron-left"></span></a>
    <div class="current">
      <span>{{ current + 1 }}</span>/{{ notifications.length }}
    </div>
    <a href="javascript:;" (click)="next()"><span class="icon-chevron-right"></span></a>
  </div>
  <div class="navigation-mobile">
    <a href="javascript:;" *ngFor="let item of notifications; let i = index;" [ngClass]="{active: i === current}"></a>
  </div>
</ng-container>
