import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    privacyPolicy: 'Политика конфиденциальности',
    feedback: 'Обратная связь',
    rights: 'Все права защищены',
  },
  [Locales.en]: {
    privacyPolicy: 'Privacy policy',
    feedback: 'Feedback',
    rights: 'All rights reserved',
  }
};
