import { Pipe, PipeTransform } from '@angular/core';
import { PHONE_MASK_LIST } from '@ui-kit/form/components/input/input-mask';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: any, ...args): any {
    let matrix = '+###############';

    PHONE_MASK_LIST.forEach(item => {
      const code = item.code.replace(/[\s+]/g, '');
      const phone = value.replace(/[\s#-)(]/g, '');
      if (code.length === phone.length) {
        let isSuccess = false;
        const startCode = code.replace(/[\s#]/g, '');
        const checkVal = phone.substr(0, startCode.length);
        if (startCode === checkVal) {
          isSuccess = true;
        }
        if (isSuccess) {
          matrix = item.code;
        }
      }
    });

    let i = 0;
    const val = value.replace(/\D/g, '');

    value = matrix.replace(/(?!\+)./g, function(a) {
      return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
    });
    return value;

    /*value = value.replace(/\D+/gi, '');
    if (value.length === 10) {
      value = `7${value}`;
    }
    return `+${value.substr(0, 1)} (${value.substr(1, 3)}) ${value.substr(4, 3)}-${value.substr(7, 2)}-${value.substr(9, 2)}`;*/
  }
}
