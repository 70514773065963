import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateField } from '@core/models/util';
import { Team } from '@core/models/team';
import { TournamentTeamUser } from '@core/models/tournament-team-user';
import { TeamUser } from '@core/models/team-user';
import { TournamentTeam } from '@core/models/tournament-team';
import { User } from '@core/models/user';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    team: 'team',
    team_user: 'teamUser',
    tournament_team_user: 'tournamentTeamUser',
    tournament_team: 'tournamentTeam',
    user: 'user',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    serve_hits: 'serveHits',
    serve_faults: 'serveFaults',
    serve_aces: 'serveAces',
    serve_percent: 'servePercent',
    ace_percent: 'acePercent',
    stuff_blocks: 'stuffBlocks',
    block_faults: 'blockFaults',
    block_rebounds: 'blockRebounds',
    block_percent: 'blockPercent',
    attack_spikes: 'attackSpikes',
    attack_faults: 'attackFaults',
    attack_shots: 'attackShots',
    attack_percent: 'attackPercent',
    receives: 'receives',
    receive_faults: 'receiveFaults',
    excellent_receives: 'excellentReceives',
    receive_percent: 'receivePercent',
    serve_receives: 'serveReceives',
    serve_receive_faults: 'serveReceiveFaults',
    excellent_serve_receives: 'excellentServeReceives',
    serve_receive_percent: 'serveReceivePercent',
    points: 'points',
    faults: 'faults',
    newbie: 'newbie',
  },
  relation: {
    team: Team,
    teamUser: TeamUser,
    tournamentTeamUser: TournamentTeamUser,
    tournamentTeam: TournamentTeam,
    user: User,
    month: MomentDateField,
  }
})
export class VolleyballStatistic extends BaseModel {
  team: Team;
  teamUser: TeamUser;
  tournamentTeamUser: TournamentTeamUser;
  tournamentTeam: TournamentTeam;
  user: User;
  month: moment.Moment;
  winLose: boolean;
  gamesCount: number;
  wonGamesCount: number;
  serveHits: number;
  serveFaults: number;
  serveAces: number;
  servePercent: number;
  acePercent: number;
  stuffBlocks: number;
  blockFaults: number;
  blockRebounds: number;
  blockPercent: number;
  attackSpikes: number;
  attackFaults: number;
  attackShots: number;
  attackPercent: number;
  receives: number;
  receiveFaults: number;
  excellentReceives: number;
  receivePercent: number;
  serveReceives: number;
  serveReceiveFaults: number;
  excellentServeReceives: number;
  serveReceivePercent: number;
  points: number;
  faults: number;
  newbie: boolean;

  get totalReceives(): number {
    return this.receives + this.excellentReceives;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
