import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[mtgConfetti]'
})
export class ConfettiDirective implements OnInit {
  @Input('mtgConfetti')
  trigger: 'click' | 'manual' = 'click';
  settings = {
    numConfetti: 150,
    distance: 75,
    colors: ['blue', 'green', 'yellow', 'red', 'pink'],
    shapes: ['square', 'circle', 'rectangle']
  };

  @HostListener('click')
  onClick() {
    if (this.trigger === 'click') {
      this.emit();
    }
  }

  constructor(
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
  }

  getRandomArrayItem(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getRandomFloat(min, max) {
    return Math.random() * (max - min) + min;
  }

  getRotation() {
    return Math.floor(Math.random() * 360) + 1;
  }

  emit() {
    const container = this.elementRef.nativeElement.offsetParent;
    const containerRect = container.getBoundingClientRect();
    const containerData = {
      x: 0,
      y: 0,
      height: containerRect.right - containerRect.left,
      width: containerRect.bottom - containerRect.top
    };

    const start = {
      x: containerData.width / 2,
      y: containerData.height / 2
    };

    const maxY = containerData.y + containerData.height + this.settings.distance;
    const minY = containerData.y - this.settings.distance;

    const maxX = containerData.x + containerData.width + this.settings.distance;
    const minX = containerData.x - this.settings.distance;

    const docFrag = document.createDocumentFragment();

    for (let i = 0; i < this.settings.numConfetti; i++) {
      const confetti = document.createElement('div');
      const color = this.getRandomArrayItem(this.settings.colors);
      const shape = this.getRandomArrayItem(this.settings.shapes);
      const size = this.getRandomInt(8, 4);
      const newX = this.getRandomInt(minX, maxX);
      const newY = this.getRandomInt(minY, maxY);
      confetti.className += 'confetti ' + color + ' ' + shape;
      confetti.style.top = start.y + 'px';
      confetti.style.left = start.x + 'px';
      confetti.style.height = size + 'px';
      confetti.style.width = size + 'px';
      confetti.style.transform = 'rotate(' + this.getRotation() + 'deg)';
      docFrag.appendChild(confetti);
      setTimeout(() => {
        confetti.style.transition = 'all ' + this.getRandomFloat(0.9, 0.5) + 's ease';
        confetti.style.top = newY + 'px';
        confetti.style.left = newX + 'px';
        confetti.style.transform = 'rotate(' + this.getRotation() + 'deg)';

        confetti.addEventListener('transitionend', () => {
          confetti.style.transition = 'all ' + this.getRandomFloat(0.7, 0.5) + ' ease';
          confetti.style.opacity = '0';
          confetti.style.transform = 'rotate(' + this.getRotation() + 'deg)';
          confetti.style.top = parseInt(confetti.style.top, 1) + 10 + 'px';
          setTimeout(() => {
            confetti.remove();
          }, 1000);
        });
      }, 1);
    }
    container.appendChild(docFrag);
  }
}
