import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StatisticFilters, TournamentService } from '@core/services/tournament.service';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';
import { Sport } from '@core/models/sport';

@Component({
  selector: 'mtg-volleyball-statistic-table',
  templateUrl: './volleyball-statistic-table.component.html',
  styleUrls: ['./volleyball-statistic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolleyballStatisticTableComponent implements OnChanges, OnInit {
  @Input()
  sport: Sport;
  @Input()
  filters: StatisticFilters;
  @Input()
  identity: 'team' | 'user' | 'teamUser' | 'tournamentTeam' | 'tournamentTeamUser';
  @Input()
  subTitle: 'month' | 'winLoses';
  @Input()
  route: string;
  @Input()
  sortable: boolean;
  statistic: VolleyballStatistic[];
  statisticHeader: {id: string, title: string}[] = [];
  loaded = false;
  sort = '';

  constructor(
    private tournamentService: TournamentService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.getStatistic();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sport && this.sport) {
      this.buildStatisticHeader();
    }
    if (changes.sortable) {
      this.sort = this.sortable ? 'points' : '';
    }
  }

  getStatistic(): void {
    this.tournamentService.getVolleyballStatistic(this.filters)
      .subscribe(statistic => {
        this.statistic = statistic;
        if (this.subTitle === 'month') {
          this.statistic = this.statistic.sort((a, b) =>
            a.month.isBefore(b.month) ? 1 : -1);
        } else if (this.subTitle === 'winLoses') {
          this.statistic = this.statistic.sort((a, b) =>
            a.winLose ? -1 : 1);
        }
        if (this.sortable) {
          this.sortStatistic();
        }
        this.loaded = true;
        this.cdr.markForCheck();
      });
  }

  changeSort(sort: string): void {
    if (!this.sortable) {
      return;
    }
    this.sort = sort;
    this.sortStatistic();
  }

  sortStatistic(): void {
    this.statistic = this.statistic.sort((a, b) => {
      return a[this.sort] > b[this.sort] ? -1 : 1;
    });
  }

  buildStatisticHeader() {
    this.statisticHeader = [
      {
        id: 'gamesCount',
        title: 'Матчи'
      },
      {
        id: 'points',
        title: 'Очки'
      },
      {
        id: 'attackSpikes',
        title: 'Атаки'
      },
      {
        id: 'stuffBlocks',
        title: 'Блоки'
      },
      {
        id: 'serveAces',
        title: 'Эйсы'
      },
      {
        id: 'excellentServeReceives',
        title: 'Приемы подачи'
      },
      {
        id: 'excellentReceives',
        title: 'Приемы'
      },
      {
        id: 'attackPercent',
        title: '% успешных атак'
      },
      {
        id: 'acePercent',
        title: '% эйсов'
      },
      {
        id: 'blockPercent',
        title: '% успешных блоков'
      },
      {
        id: 'serveReceivePercent',
        title: '% успешных приемов подачи'
      },
      {
        id: 'receivePercent',
        title: '% успешных приемов'
      }
    ];
  }

  getRoute(key: string, value: number): string {
    if (!this.route) {
      return null;
    }
    return this.route.replace(key, value.toString());
  }
}
