import { Injectable } from '@angular/core';
import { TeamEventService } from '@core/services/team-event.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { TeamEvent } from '@core/models/team-event';
import { TeamEventInvite } from '@core/models/team-event-invite';
import { catchError, filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { addItemInArray } from '@shared/util/data';
import { of } from 'rxjs/internal/observable/of';
import { TeamProfileService } from '@core/services/team-profile.service';

@Injectable()
export class TeamEventDetailService {
  private _teamEventSuject = new BehaviorSubject<TeamEvent>(undefined);
  private _teamEventInvitesSubject = new BehaviorSubject<TeamEventInvite[]>(undefined);

  get teamEvent$() {
    return this._teamEventSuject.pipe(filter(item => item !== undefined));
  }

  get teamEventInvites$() {
    return this._teamEventInvitesSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    private teamEventService: TeamEventService,
    private teamProfileService: TeamProfileService,
  ) {}

  initialize(teamEventId: number): Observable<any> {
    return combineLatest(
      this.getTeamEvent(teamEventId),
      this.getTeamEventInvites(teamEventId).pipe(catchError(() => of([])))
    );
  }

  dispose() {
    if (this._teamEventSuject.value !== undefined) {
      this._teamEventSuject.next(undefined);
      this._teamEventInvitesSubject.next(undefined);
    }
  }

  getTeamEvent(teamEventId: number): Observable<TeamEvent> {
    return this.teamEventService.getTeamEvent(teamEventId).pipe(
      tap(teamEvent => this._teamEventSuject.next(teamEvent))
    );
  }

  deleteTeamEvent(teamEventId: number): Observable<any> {
    return this.teamProfileService.deleteEvent(teamEventId);
  }

  getTeamEventInvites(teamEventId: number): Observable<TeamEventInvite[]> {
    return this.teamEventService.getTeamEventInvites(teamEventId).pipe(
      tap(invites => this._teamEventInvitesSubject.next(invites))
    );
  }

  createTeamEventInvite(teamEventId: number, teamUserId: number): Observable<TeamEventInvite> {
    return this.teamEventService.createTeamEventInvite(teamEventId, teamUserId).pipe(
      tap(invite => this._teamEventInvitesSubject.next(addItemInArray(this._teamEventInvitesSubject.value, invite)))
    );
  }
}
