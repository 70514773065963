import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    title: 'Отключить email уведомления',
    success: 'Вы успешно отключили email уведомление MTGame. Вы всегда можете включить уведомления о матчах/события в настройках личного кабинета. ',
    error: 'К сожалению произошла ошибка и нам не удалось отключить уведомления на ваш email адрес. Напишите, пожалуйста, нам о проблеме на info@mtgame.ru.',
  },
  [Locales.en]: {
    title: 'Turn off email notifications',
    success: 'You have successfully disabled MTGame email notifications. You can always enable match/event notifications in your account settings. ',
    error: 'Unfortunately, an error occurred and we were unable to disable notifications to your email address. Please write to us about the problem at info@mtgamesports.com.',
  }
};
