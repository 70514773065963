import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiKitModule } from '@ui-kit/ui-kit.module';
import { SharedModule as AllSharedModule } from '@shared/modules/shared/shared.module';
import { AgreementComponent } from './components/agreement/agreement.component';
import { EntryWizardComponent } from './components/entry-wizard/entry-wizard.component';
import { GameCardComponent } from './components/game-card/game-card.component';
import { MyTeamsComponent } from './components/my-teams/my-teams.component';
import { PollCardComponent } from './components/poll-card/poll-card.component';
import { TeamCardComponent } from './components/team-card/team-card.component';
import { TeamEventCardComponent } from './components/team-event-card/team-event-card.component';
import { TeamFormComponent } from './components/team-form/team-form.component';
import { TournamentGamesTimelineComponent } from './components/tournament-games-timeline/tournament-games-timeline.component';
import { TournamentGameCardComponent } from '@app/shared/components/tournament-game-card/tournament-game-card.component';
import { VolleyballStatisticTableComponent } from '@app/shared/components/volleyball-statistic-table/volleyball-statistic-table.component';
import { BasketballStatisticTableComponent } from '@app/shared/components/basketball-statistic-table/basketball-statistic-table.component';
import { StatisticTableComponent } from '@app/shared/components/statistic-table/statistic-table.component';
import { LocalizationModule } from '@shared/modules/localization/localization.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AllSharedModule,
    UiKitModule,
    RouterModule,
    LocalizationModule,
  ],
  declarations: [
    AgreementComponent,
    EntryWizardComponent,
    GameCardComponent,
    MyTeamsComponent,
    PollCardComponent,
    TeamCardComponent,
    TeamEventCardComponent,
    TeamFormComponent,
    TournamentGameCardComponent,
    TournamentGamesTimelineComponent,
    VolleyballStatisticTableComponent,
    BasketballStatisticTableComponent,
    StatisticTableComponent,
  ],
  exports: [
    AllSharedModule,
    UiKitModule,
    AgreementComponent,
    EntryWizardComponent,
    GameCardComponent,
    MyTeamsComponent,
    PollCardComponent,
    TeamCardComponent,
    TeamEventCardComponent,
    TeamFormComponent,
    TournamentGameCardComponent,
    TournamentGamesTimelineComponent,
    VolleyballStatisticTableComponent,
    BasketballStatisticTableComponent,
    StatisticTableComponent,
  ],
  entryComponents: [
    TeamFormComponent,
    AgreementComponent,
    EntryWizardComponent,
    MyTeamsComponent,
  ]
})
export class SharedModule {}
