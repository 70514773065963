import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[mtgSticky]'
})
export class StickyDirective implements AfterViewInit, OnDestroy {
  @Input()
  top: number;
  @Input()
  left: number;
  @Input('mtgSticky')
  stickTo: 'top' | 'left' = 'top';
  unsubscribe: Function;
  parent: HTMLElement;

  constructor(
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const node = this.getScrollParent(this.elementRef.nativeElement);
      this.parent = node;
      const func = () => {
        this.check();
      };
      if (node) {
        node.addEventListener('scroll', func);
        this.unsubscribe = () => {
          node.removeEventListener('scroll', func);
        };
      } else {
        window.addEventListener('scroll', func);
        this.unsubscribe = () => {
          window.removeEventListener('scroll', func);
        };
      }
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  resize($event) {
    this.check();
  }

  check() {
    if (this.stickTo === 'left') {
      const position = this.elementRef.nativeElement.parentNode.getBoundingClientRect();
      let parentPositionLeft;
      if (this.parent) {
        parentPositionLeft = this.parent.getBoundingClientRect().left;
      } else {
        parentPositionLeft = 0;
      }
      const left = +this.left + (parentPositionLeft - position.left);
      if (left > 0) {
        this.elementRef.nativeElement.style.position = 'relative';
        this.elementRef.nativeElement.style.left = `${left}px`;
        this.elementRef.nativeElement.classList.add('stick');
      } else {
        this.elementRef.nativeElement.style.position = null;
        this.elementRef.nativeElement.style.left = null;
        this.elementRef.nativeElement.classList.remove('stick');
      }
    } else {
      const position = this.elementRef.nativeElement.parentNode.getBoundingClientRect();
      let parentPositionTop;
      if (this.parent) {
        parentPositionTop = this.parent.getBoundingClientRect().top;
      } else {
        parentPositionTop = 0;
      }
      const top = +this.top + (parentPositionTop - position.top);
      if (top > 0) {
        this.elementRef.nativeElement.style.position = 'relative';
        this.elementRef.nativeElement.style.top = `${top}px`;
        this.elementRef.nativeElement.classList.add('stick');
      } else {
        this.elementRef.nativeElement.style.position = null;
        this.elementRef.nativeElement.style.top = null;
        this.elementRef.nativeElement.classList.remove('stick');
      }
    }
  }

  getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (this.stickTo === 'left') {
      if (node.scrollWidth > node.clientWidth) {
        const overflowX = window.getComputedStyle(node).overflowX;
        const isScrollable = overflowX !== 'visible' && overflowX !== 'hidden';
        if (isScrollable) {
          return node;
        }
      }
    } else {
      if (node.scrollHeight > node.clientHeight) {
        const overflowY = window.getComputedStyle(node).overflowY;
        const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
        if (isScrollable) {
          return node;
        }
      }
    }
    return this.getScrollParent(node.parentNode);
  }
}
