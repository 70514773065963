export function updateItemInArray(data: any[], item: any, force = false, checkFunction?: Function): any[] {
  if (!Array.isArray(data)) {
    return data;
  }
  return data.map(v => {
    if (checkFunction && isFunction(checkFunction)) {
      if (!checkFunction(v)) {
        return v;
      }
    } else if (v.id !== item.id) {
      return v;
    }
    if (v.updatedAt && item.updatedAt && !force) {
      return v.updatedAt > item.updatedAt ? v : item;
    }
    return item;
  });
}

export function updateItemsInArray(data: any[], items: any[], force: boolean = false, checkFunction?: Function): any[] {
  items.forEach(item => {
    data = updateItemInArray(data, item, force, checkFunction);
  });
  return data;
}

export function patchItemInArray(data: any[], item: any, checkFunction?: Function): any[] {
  if (!Array.isArray(data)) {
    return data;
  }
  return data.map(v => {
    if (checkFunction && isFunction(checkFunction)) {
      if (!checkFunction(v)) {
        return v;
      }
    } else if (v.id !== item.id) {
      return v;
    }
    return Object.assign(v, item);
  });
}

export function addItemInArray(data: any[], item: any, atEnd: boolean = false, checkFunction?: Function): any[] {
  if (!Array.isArray(data)) {
    return [item];
  }
  if (data.find(i => isFunction(checkFunction) ? checkFunction(i) : i.id === item.id)) {
    return updateItemInArray(data, item, false, checkFunction);
  }

  return atEnd
    ? [...data, item]
    : [item, ...data];
}

export function addItemsInArray(data: any[], items: any[], atEnd: boolean = false, checkFunction?: Function): any[] {
  items.forEach(item => {
    data = addItemInArray(data, item, atEnd, checkFunction);
  });
  return data;
}

export function deleteItemFromArray(data: any[], item: any): any[] {
  if (!Array.isArray(data)) {
    return [];
  }
  if (isFunction(item)) {
    return data.filter(i => !item(i));
  } else {
    return data.filter(v => v.id !== item.id);
  }
}

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
