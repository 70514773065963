<ng-template #defaultTabLabel let-tab="tab">{{ tab.heading }}</ng-template>
<div class="tabs" [ngClass]="{'lowercase': lowerCase}">
  <div class="header">
    <a href="javascript:;"
       *ngFor="let tab of tabs"
       [ngClass]="{active: tab.isActive()}"
       (click)="showTab(tab)"
    >
      <ng-container
        *ngTemplateOutlet="(tab.label ? tab.label: defaultTabLabel);context: {tab: tab}"></ng-container>
      <span class="badge" *ngIf="tab.badge && tab.badge !== '0'">{{tab.badge}}</span>
    </a>
  </div>
  <div class="content" [ngClass]="{'no-padding': noPadding}">
    <ng-content></ng-content>
  </div>
</div>
