import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from './util';
import { TeamUserRole } from './team-user';

@ModelInstance({
  mappingFields: {
    can_edit: 'canEdit',
    can_view_users: 'canViewUsers',
    can_manage_permission: 'canManagePermission',
    can_change_owner: 'canChangeOwner',
    can_delete: 'canDelete',
    can_manage_invites: 'canManageInvites',
    can_view_invites: 'canViewInvites',
    can_manage_polls: 'canManagePolls',
    can_view_polls: 'canViewPolls',
    can_answer_poll: 'canAnswerPoll',
    can_manage_games: 'canManageGames',
    can_view_games: 'canViewGames',
    can_view_statistic: 'canViewStatistic',
    can_promote_users: 'canPromoteUsers',
    can_edit_users: 'canEditUsers',
    can_delete_users: 'canDeleteUsers',
    can_view_events: 'canViewEvents',
    can_manage_events: 'canManageEvents',
    can_view_tournaments: 'canViewTournaments',
    can_manage_tournaments: 'canManageTournaments',
    can_manage_game_application: 'canManageGameApplication',
    role: 'role'
  },
  relation: {
    role: enumField(TeamUserRole)
  }
})
export class TeamAccess extends BaseModel {
  canEdit: boolean;
  canViewUsers: boolean;
  canManagePermission: boolean;
  canChangeOwner: boolean;
  canDelete: boolean;
  canManageInvites: boolean;
  canViewInvites: boolean;
  canManagePolls: boolean;
  canViewPolls: boolean;
  canAnswerPoll: boolean;
  canManageGames: boolean;
  canViewGames: boolean;
  canViewStatistic: boolean;
  canPromoteUsers: boolean;
  canEditUsers: boolean;
  canDeleteUsers: boolean;
  canViewEvents: boolean;
  canManageEvents: boolean;
  canViewTournaments: boolean;
  canManageTournaments: boolean;
  canManageGameApplication: boolean;
  role: TeamUserRole;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
