import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface ImageConfig {
  positionX?: 'left' | 'center' | 'right' | number;
  positionY?: 'top' | 'center' | 'bottom' | number;
  width?: number;
  height?: number;
  backgroundSize?: 'cover' | 'contain';
}

const defaultImageConfig: ImageConfig = {
  positionX: 'center',
  positionY: 'center',
  backgroundSize: 'contain',
};

@Directive({
  selector: 'canvas[mtgMaskImage]'
})
export class MaskImageDirective implements OnChanges {
  @Input()
  maskImageSrc: string;
  @Input()
  color: string;
  @Input()
  imageSrc: string;
  @Input()
  image: any;
  @Input()
  imageConfig: ImageConfig = defaultImageConfig;
  @Input()
  maskImageConfig: ImageConfig = defaultImageConfig;

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.imageConfig = Object.assign({}, defaultImageConfig, this.imageConfig);
    this.maskImageConfig = Object.assign({}, defaultImageConfig, this.maskImageConfig);
    await this.updateCanvas();
  }

  private async updateCanvas(): Promise<void> {
    if (!this.maskImageSrc) {
      return;
    }
    if (!this.color && !this.imageSrc && !this.image) {
      return;
    }
    const maskImageBlob = await downloadImage(this.maskImageSrc);
    const maskImage = await preloadImage(URL.createObjectURL(maskImageBlob));
    const canvas = this.elementRef.nativeElement;
    const ctx = canvas.getContext('2d');
    let maskSizeRatio = canvas.width / canvas.height;
    let maskImageHeight = canvas.height;
    let maskImageWidth = canvas.width;
    let maskTop = 0;
    let maskLeft = 0;
    if (this.maskImageConfig) {
      maskSizeRatio = maskImage.width / maskImage.height;
      maskImageHeight = maskImage.height;
      maskImageWidth = maskImage.width;
      if (this.maskImageConfig.height) {
        maskImageHeight = this.maskImageConfig.height;
        if (!this.maskImageConfig.width) {
          maskImageWidth = maskImageHeight * maskSizeRatio;
        }
      }
      if (this.maskImageConfig.width) {
        maskImageWidth = this.maskImageConfig.width;
        if (!this.maskImageConfig.height) {
          maskImageHeight = maskImageWidth / maskSizeRatio;
        }
      }
      if (this.maskImageConfig.positionY === 'center') {
        maskTop = (canvas.height - maskImageHeight) / 2;
      } else if (this.maskImageConfig.positionY === 'bottom') {
        maskTop = canvas.height - maskImageHeight;
      } else if (Math.abs(+this.maskImageConfig.positionY) > 0) {
        maskTop = +this.maskImageConfig.positionY;
      }
      if (this.maskImageConfig.positionX === 'center') {
        maskLeft = (canvas.width - maskImageWidth) / 2;
      } else if (this.maskImageConfig.positionX === 'right') {
        maskLeft = canvas.width - maskImageWidth;
      } else if (Math.abs(+this.maskImageConfig.positionX) > 0) {
        maskLeft = +this.maskImageConfig.positionX;
      }
      if (this.maskImageConfig.width && this.maskImageConfig.height && this.maskImageConfig.backgroundSize === 'contain') {
        const newMaskImageWidth = maskImageHeight * maskSizeRatio;
        maskLeft += (maskImageWidth - newMaskImageWidth) / 2;
        maskImageWidth = newMaskImageWidth;
      }
    }
    ctx.clearRect(maskLeft, maskTop, maskImageWidth, maskImageHeight);
    ctx.save();
    ctx.drawImage(maskImage, maskLeft, maskTop, maskImageWidth, maskImageHeight);
    ctx.globalCompositeOperation = 'source-in';
    if (this.color) {
      ctx.fillStyle = this.color;
      ctx.fillRect(maskLeft, maskTop, maskImageWidth, maskImageHeight);
      ctx.restore();
    }
    if (this.imageSrc) {
      const imageBlob = await downloadImage(this.imageSrc);
      const image = await preloadImage(URL.createObjectURL(imageBlob));
      const sizeRatio = image.width / image.height;
      let imageHeight = canvas.height;
      let imageWidth = imageHeight * sizeRatio;
      let top = 0;
      let left = 0;
      if (this.imageConfig) {
        if (this.imageConfig.height) {
          imageHeight = this.imageConfig.height;
          if (!this.imageConfig.width) {
            imageWidth = imageHeight * sizeRatio;
          }
        }
        if (this.imageConfig.width) {
          imageWidth = this.imageConfig.width;
          if (!this.imageConfig.height) {
            imageHeight = imageWidth / sizeRatio;
          }
        }
        if (this.imageConfig.positionY === 'center') {
          top = (canvas.height - imageHeight) / 2;
        } else if (this.imageConfig.positionY === 'bottom') {
          top = canvas.height - imageHeight;
        } else if (Math.abs(+this.imageConfig.positionY) > 0) {
          top = +this.imageConfig.positionY;
        }
        if (this.imageConfig.positionX === 'center') {
          left = (canvas.width - imageWidth) / 2;
        } else if (this.imageConfig.positionX === 'right') {
          left = canvas.width - imageWidth;
        } else if (Math.abs(+this.imageConfig.positionX) > 0) {
          left = +this.imageConfig.positionX;
        }
        if (this.imageConfig.width && this.imageConfig.height && this.imageConfig.backgroundSize === 'contain') {
          const newImageWidth = imageHeight * sizeRatio;
          left += (imageWidth - newImageWidth) / 2;
          imageWidth = newImageWidth;
        }
      }
      ctx.drawImage(image, left, top, imageWidth, imageHeight);
    }
    if (this.image) {
      const image = await preloadImage(URL.createObjectURL(this.image));
      ctx.drawImage(image, 0, 0);
    }
    ctx.restore();
  }
}

function downloadImage(imageSrc: string): Promise<any> {
  return fetch(imageSrc + `?t=${new Date().getTime()}`, {
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  }).then(response => response.blob());
}

function preloadImage(imageSrc: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = imageSrc;
  });
}
