import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from '@ui-kit/accordion/accordion.component';

@Component({
  selector: 'mtg-accordion-content',
  templateUrl: './accordion-content.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionContentComponent implements OnInit {
  visible = false;

  constructor(private accordionComponent: AccordionComponent) {}

  ngOnInit() {
    this.accordionComponent
      .showContent$
      .subscribe(value => this.visible = value);
  }
}
