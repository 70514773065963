import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mtg-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true
    }
  ]
})
export class SwitcherComponent implements ControlValueAccessor {
  private _onChangeFunc: any;
  private _onTouchedFunc: any;
  currentValue: any;

  @Input() value: boolean;
  @Input() label = '';
  @Input() disabled: boolean;
  @Input() formControlName: string;

  constructor() {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change() {
    this.value = !this.value;

    if (this._onChangeFunc) {
      this._onChangeFunc(this.value);
    }
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
  }
}
