import { Component } from '@angular/core';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { DialogConfig } from '@ui-kit/dialog/dialog-config';
import sharedLocalization from '@shared/modules/shared/shared.localization';

@Component({
  selector: 'mtg-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  message: string = this.dialogConfig['data']['message'];
  localization = sharedLocalization;

  constructor(
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
  ) {}

  close(value: boolean) {
    this.dialogRef.close(value);
  }
}
