import { Directive, HostBinding, HostListener } from '@angular/core';
import { MenuComponent } from './menu.component';

@Directive({
  selector: '[mtgMenuItem]'
})
export class MenuItemDirective {
  constructor(
    private menuComponent: MenuComponent
  ) {}

  @HostBinding('class.menu-item') mainClass = true;
  @HostBinding('class.btn') btnClass = true;

  @HostListener('click')
  onClick() {
    this.menuComponent.onItemClicked();
  }
}
