import { Locales } from './locales';

export const FORMS_LOCALIZATION = {
  [Locales.ru]: {
    required: 'Поле обязательно для заполнения',
    email: 'Email введен неверно',
    emailTaken: 'Email уже зарегистрирован в системе',
    minlength: 'Должно быть больше {requiredLength} символов',
    min: 'Должно быть больше {min}',
    mismatch: 'Неверное подтверждение',
    time: 'Время введено неверно',
    emailExists: 'Email занят',
    invalidDate: 'Дата введена неверно',
    invalidPhone: 'Телефон введен неверно',
    pattern: 'Введены запрещенные символы',
    invalidUrl: 'Ссылка введена неверно',
    groupExist: 'Группа с таким названием уже есть'
  },
  [Locales.en]: {
    required: 'Field is required',
    email: 'Email is not valid',
    emailTaken: 'The email is already exists in the system',
    minlength: 'There must be more than {requiredLength} characters',
    min: 'There must be more than {min}',
    mismatch: 'Incorrect confirmation',
    time: 'The time is entered incorrectly',
    emailExists: 'Email exists',
    invalidDate: 'Invalid date',
    invalidPhone: 'Invalid phone',
    pattern: 'Forbidden characters have been introduced',
    invalidUrl: 'Invalid link',
    groupExist: 'There is already a group with that name'
  },
};
