import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Notification as NotificationModel, NotificationServiceEnum } from '@core/models/notification';
import { NotificationAllowTypes, NotificationSettings } from '@core/models/notification-settings';
import { NotificationFilter } from '@shared/modules/notification/interfaces/notification-filter';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { OrgNotificationService } from '@core/services/org-notification.service';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { Observable } from 'rxjs/internal/Observable';
import { PaginationResponse } from '@core/services/pagination-response.interface';
import {
  addItemInArray,
  addItemsInArray,
  deleteItemFromArray,
  patchItemInArray,
  updateItemInArray,
  updateItemsInArray
} from '@shared/util/data';
import { NotificationBaseService } from '@core/services/notification-base.service';
import { NotificationItem } from '@shared/modules/notification/interfaces/notification-item';
import { NotificationTemplateService } from '@shared/modules/notification/services/notification-template.service';

@Injectable({providedIn: 'root'})
export class NotificationDataService {
  private service: 'public' | 'org';
  private userId: number;
  private page = 1;
  private size = 20;
  private total: number;

  private _notificationsCountSubject = new BehaviorSubject<number>(undefined);
  private _notificationsSubject = new BehaviorSubject<NotificationItem[]>(undefined);
  private _popupNotificationsSubject = new BehaviorSubject<NotificationItem[]>(undefined);
  private _notificationSettingsSubjects = new BehaviorSubject<NotificationSettings>(undefined);
  private _notificationsVisibleSubject = new BehaviorSubject<boolean>(false);
  private _popupNotificationsVisibleSubject = new BehaviorSubject<boolean>(false);
  private _notificationsFilterSubject = new BehaviorSubject<NotificationFilter>(undefined);
  private _loadingSubject = new BehaviorSubject<boolean>(false);

  get notificationsCount$() {
    return this._notificationsCountSubject.pipe(filter(item => item !== undefined));
  }

  get notifications$() {
    return this._notificationsSubject.pipe(filter(item => item !== undefined));
  }

  get popupNotifications$() {
    return this._popupNotificationsSubject.pipe(filter(item => item !== undefined));
  }

  get notificationSettings$() {
    return this._notificationSettingsSubjects.pipe(filter(item => item !== undefined));
  }

  get notificationsVisible$() {
    return this._notificationsVisibleSubject;
  }

  get popupNotificationsVisible$() {
    return this._popupNotificationsVisibleSubject;
  }

  get notificationsFilter$() {
    return this._notificationsFilterSubject.pipe(filter(item => item !== undefined));
  }

  get loading$() {
    return this._loadingSubject;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private centrifugoService: CentrifugoService,
    private notificationService: NotificationService,
    private orgNotificationService: OrgNotificationService,
    private notificationTemplatesService: NotificationTemplateService,
  ) {
  }

  initialize(service: 'org' | 'public') {
    this.service = service;

    this.authService.user$.pipe(
      take(1),
      filter(user => !!user),
    ).subscribe(user => {
      this.userId = user.id;
      this.listenCentrifugo(user.id);
    });

    this.initializeNotificationFilter();

    return combineLatest([
      this.getNotificationsCount(),
      this.getPopupNotifications(),
      this.getNotificationSettings(),
    ]).pipe(
      tap(() => {
        const notificationSettings = this._notificationSettingsSubjects.value;
        const popupNotifications = this._popupNotificationsSubject.value;
        if (notificationSettings.popup && popupNotifications.length > 0) {
          setTimeout(() => {
            this.showPopupNotifications();
          }, 1000);
        }
      })
    );
  }

  dispose() {
    this.centrifugoService.unsubscribe(`notifications_${this.userId}`);
    this._notificationsCountSubject.next(undefined);
    this._notificationsSubject.next(undefined);
    this._notificationsVisibleSubject.next(false);
    this._popupNotificationsSubject.next(undefined);
    this._popupNotificationsVisibleSubject.next(false);
    this._notificationSettingsSubjects.next(undefined);
    this._notificationsFilterSubject.next(undefined);
  }

  initializeNotificationFilter(): void {
    const savedFilter = localStorage.getItem('notification_filter') as NotificationFilter;
    if (savedFilter) {
      this._notificationsFilterSubject.next(savedFilter);
    } else {
      this._notificationsFilterSubject.next('important');
    }
  }

  setNotificationFilter(value: NotificationFilter): Observable<PaginationResponse<NotificationModel>> {
    localStorage.setItem('notification_filter', value);
    this._notificationsFilterSubject.next(value);
    this.page = 1;
    return this.getNotifications();
  }

  getNotificationsCount(): Observable<number> {
    return this.getService().getCount().pipe(
      tap(notificationsCount => this._notificationsCountSubject.next(notificationsCount))
    );
  }

  getNotifications(): Observable<PaginationResponse<NotificationModel>> {
    let filters = {};
    if (this._notificationsFilterSubject.value === 'important') {
      filters = {important: true};
    } else if (this._notificationsFilterSubject.value === 'information') {
      filters = {important: false};
    }
    this._loadingSubject.next(true);
    return this.getService().getList(this.page, this.size, filters).pipe(
      tap(paginationResponse => {
        this.total = paginationResponse.count;
        if (this.page > 1) {
          this._notificationsSubject.next(
            addItemsInArray(this._notificationsSubject.value, this.formatNotifications(paginationResponse.data), true)
          );
        } else {
          this._notificationsSubject.next(this.formatNotifications(paginationResponse.data));
        }
      }),
      finalize(() => this._loadingSubject.next(false))
    );
  }

  getNextPage(): Observable<PaginationResponse<NotificationModel>> {
    if (this._loadingSubject.value) {
      return new Observable(observer => {
        observer.error('loading');
        observer.complete();
      });
    }
    const pagesCount = Math.ceil(this.total / this.size);
    if (pagesCount <= this.page) {
      return new Observable(observer => {
        observer.error('invalid page');
        observer.complete();
      });
    }
    this.page++;
    return this.getNotifications();
  }

  getPopupNotifications(): Observable<NotificationItem[]> {
    return this.getService().getList(1, 5, {popup: true, not_viewed: true}).pipe(
      map(response => this.formatNotifications(response.data)),
      tap(notifications => this._popupNotificationsSubject.next(notifications)),
    );
  }

  formatNotifications(notifications: NotificationModel[]): NotificationItem[] {
    return notifications.map(item => this.notificationTemplatesService.formatNotification(item));
  }

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.getService().getNotificationSettings().pipe(
      tap(result => this._notificationSettingsSubjects.next(result))
    );
  }

  updateNotificationSettings(data: NotificationSettings): Observable<NotificationSettings> {
    return this.getService().updateNotificationSettings(data).pipe(
      tap(result => this._notificationSettingsSubjects.next(result)),
    );
  }

  markNotificationsViewed(): Observable<NotificationModel[]> {
    return this.getService().markAllViewed().pipe(
      tap(notifications => this._notificationsSubject.next(
        updateItemsInArray(this._notificationsSubject.value, this.formatNotifications(notifications)))
      ),
      tap(() => this._notificationsCountSubject.next(this._notificationsSubject.value.filter(item => !item.model.viewed).length))
    );
  }

  markNotificationViewed(notificationId: number, viewed: boolean): Observable<NotificationModel> {
    return this.getService().markViewed(notificationId, viewed).pipe(
      tap(notification => this._notificationsSubject.next(
        updateItemInArray(this._notificationsSubject.value, this.notificationTemplatesService.formatNotification(notification)))
      ),
      tap(() => {
        this._notificationsCountSubject.next(this._notificationsSubject.value.filter(item => !item.model.viewed).length);
      })
    );
  }

  markViewedProtected(notification: NotificationModel): void {
    notification.viewed = true;
    const notificationData = this.notificationTemplatesService.formatNotification(notification);
    this._notificationsSubject.next(updateItemInArray(this._notificationsSubject.value, notificationData));
    this._popupNotificationsSubject.next(deleteItemFromArray(this._popupNotificationsSubject.value, notificationData));
  }

  showNotifications(): Observable<PaginationResponse<NotificationModel>> {
    this.page = 1;
    this._notificationsSubject.next([]);
    this._notificationsVisibleSubject.next(true);
    return this.getNotifications();
  }

  hideNotifications(): void {
    this._notificationsVisibleSubject.next(false);
  }

  showPopupNotifications(): void {
    this._popupNotificationsVisibleSubject.next(true);
  }

  hidePopupNotifications(): void {
    this._popupNotificationsVisibleSubject.next(false);
  }

  private listenCentrifugo(userId: number): void {
    this.centrifugoService.listen(`notifications_${userId}`).subscribe(message => {
      switch (message['action']) {
        case 'NOTIFICATION_CREATED': {
          const notification = NotificationModel.toFront(message['data']);
          if (notification.service !== NotificationServiceEnum[this.service]) {
            return;
          }
          const notificationData = this.notificationTemplatesService.formatNotification(notification);
          this._notificationsSubject.next(
            addItemInArray(this._notificationsSubject.value || [], notificationData)
          );
          this._notificationsCountSubject.next(message['notifications_count'][this.service]);
          if (notification.popup) {
            this._popupNotificationsSubject.next(addItemInArray(this._popupNotificationsSubject.value, notificationData));
          }
          this.notify(notification);
          return;
        }

        case 'NOTIFICATION_DELETED': {
          this._notificationsSubject.next(
            deleteItemFromArray(this._notificationsSubject.value || [], item => item.id === message['data']['id'])
          );
          this._popupNotificationsSubject.next(
            deleteItemFromArray(this._popupNotificationsSubject.value || [], item => item.id === message['data']['id'])
          );
          this._notificationsCountSubject.next(message['notifications_count']['public']);
          return;
        }

        case 'NOTIFICATION_VIEWED': {
          const notification = (this._notificationsSubject.value || []).find(item => item.id === message['data']['id']);
          if (notification) {
            notification.model.viewed = message['data']['viewed'];
            this._notificationsSubject.next(patchItemInArray(this._notificationsSubject.value || [], notification));
          }
          this._popupNotificationsSubject.next(
            deleteItemFromArray(this._popupNotificationsSubject.value || [], item => item.id === message['data']['id'])
          );
          this._notificationsCountSubject.next(message['notifications_count'][this.service]);
          return;
        }

        case 'NOTIFICATIONS_VIEWED': {
          let notifications = this._notificationsSubject.value || [];
          const notificationIds = message['data'].map(item => item.id);
          for (const item of message['data']) {
            const notification = notifications.find(row => row.id === item.id);
            if (notification) {
              notification.model.viewed = item.viewed;
              notifications = patchItemInArray(notifications, notification);
            }
          }
          this._notificationsSubject.next(notifications);
          this._popupNotificationsSubject.next(
            deleteItemFromArray(this._popupNotificationsSubject.value || [], item => notificationIds.indexOf(item.id) > -1)
          );
          this._notificationsCountSubject.next(message['notifications_count'][this.service]);
          return;
        }
      }
    });
  }

  private notify(notification: NotificationModel): void {
    const notificationSettings = this._notificationSettingsSubjects.value;
    if (notificationSettings.popup && notification.protected) {
      this.showPopupNotifications();
    }
    this.notifyWithSound(notification);
    this.notifyBrowser(notification);
  }

  private notifyWithSound(notification: NotificationModel): void {
    const notificationSettings = this._notificationSettingsSubjects.value;
    if (notificationSettings.sound === NotificationAllowTypes.none) {
      return;
    }
    if (notificationSettings.sound === NotificationAllowTypes.important && !notification.important) {
      return;
    }
    new Audio('/assets/notify.m4a').play();
  }

  private notifyBrowser(notification: NotificationModel): void {
    const notificationSettings = this._notificationSettingsSubjects.value;
    if (notificationSettings.browser === NotificationAllowTypes.none) {
      return;
    }
    if (notificationSettings.browser === NotificationAllowTypes.important && !notification.important) {
      return;
    }

    Notification.requestPermission().then(result => {
      if (result === 'granted') {
        const notificationData = this.notificationTemplatesService.formatNotification(notification);
        const options = {
          body: notificationData.description,
          icon: notification.team.logo.path
        };
        const n = new Notification(notificationData.title, options);
        n.onclick = () => {
          n.close();
          if (notificationData.route) {
            window.open(notificationData.route.join('/'));
          }
        };
      }
    });
  }

  private getService(): NotificationBaseService {
    return this.service === 'org'
      ? this.orgNotificationService
      : this.notificationService;
  }
}
