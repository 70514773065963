import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@ui-kit/toast/toast.service';
import { LoaderService } from '@core/services/loader.service';
import { forkJoin, of } from 'rxjs';
import { distinctUntilChanged, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { handleError } from '@shared/util/errors';
import { FileService } from '@core/services/file.service';
import { FeedbackService } from '@core/services/feedback.service';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { Observable } from 'rxjs/internal/Observable';
import { ConfirmDialogComponent } from '@shared/modules/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { Metrika } from 'ng-yandex-metrika';
import { AuthService } from '@core/services/auth.service';
import { markFormGroupTouched } from '@shared/util/form';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import feedbackLocalization from '@app/layout/components/feedback/feedback.localization';

@UntilDestroy
@Component({
  selector: 'mtg-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  form: FormGroup;
  feedbackSent = false;
  isChanged = false;

  localization = feedbackLocalization[this.localizationService.getLocale()];

  FEEDBACK_TYPES = [
    this.localization.error,
    this.localization.partnership,
    this.localization.comment,
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toasts: ToastService,
    private loaderService: LoaderService,
    private fileService: FileService,
    private feedbackService: FeedbackService,
    private dialogRef: DialogRef,
    private dialogService: DialogService,
    private metrika: Metrika,
    private authService: AuthService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      theme: [null, Validators.required],
      message: [null, Validators.required],
      files: [null],
      email: new FormControl({value: null, disabled: true}, [Validators.required, Validators.email]),
      captcha: new FormControl({value: null, disabled: true}, [Validators.required])
    });
    this.form.valueChanges.pipe(
      takeUntil(componentDestroyed(this)),
      distinctUntilChanged()
    ).subscribe(() => {
      this.isChanged = true;
    });
    this.dialogRef.addBeforeClose(this.canClose());
    this.authService.user$.pipe(take(1)).subscribe(user => {
      if (!user) {
        this.form.get('email').enable();
        this.form.get('captcha').enable();
      }
    });
  }

  ngOnDestroy(): void {
  }

  canClose(): Observable<boolean> {
    return Observable.create(observer => {
      if (!this.isChanged || this.feedbackSent) {
        observer.next(true);
        observer.complete();
        return;
      }
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          message: this.localization.closeAlert
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          observer.next(res);
        } else {
          observer.error(res);
        }
        observer.complete();
      });
    });
  }

  sendFeedback(): void {
    if (!this.form.valid) {
      markFormGroupTouched(this.form);
      if (this.form.get('captcha').enabled && !this.form.get('captcha').value) {
        this.toasts.danger(this.localization.recaptchaError);
      } else {
        this.toasts.danger(this.localization.requiredFieldsError);
      }
      return;
    }

    this.loaderService.show();
    const formData = this.form.value;

    (formData.files && formData.files.length
        ? forkJoin(formData.files.map(file => this.fileService.upload(file)))
        : of([])
    )
      .pipe(
        switchMap(files =>
          this.feedbackService.sendFeedback({...formData, files})
        ),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(feedback => {
        this.feedbackSent = true;
        this.metrika.fireEvent('feedbackSent');
      }, error => {
        if (error.error.error === 'captcha mismatch') {
          this.toasts.danger(this.localization.recaptchaError1);
        } else {
          handleError(error);
          this.toasts.danger(this.localization.internalError);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }
}
