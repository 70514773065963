import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';

export enum GameWaterpoloPosition {
  goalkeeper = 1,
  defensemen,
  center_forward,
  mobile_forward
}

export enum WaterpoloWorkHand {
  left = 1,
  right
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    position: 'position',
    work_hand: 'workHand'
  },
  relation: {
    position: enumField(GameWaterpoloPosition),
    workHand: enumField(WaterpoloWorkHand)
  }
})
export class WaterpoloProfile extends BaseModel {
  id: number;
  userId: number;
  position: GameWaterpoloPosition;
  workHand: WaterpoloWorkHand;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
