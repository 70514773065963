import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGroup } from '@core/models/tournament-group';

@Injectable()
export class TournamentGroupService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  getGroupById(groupId: number): Observable<TournamentGroup> {
    return this.httpClient.get(`/org/api/v1/tournament_group/${groupId}/`).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  createGroup(tournamentId: number, group: TournamentGroup, teamIds?: number[]): Observable<TournamentGroup> {
    const payload = TournamentGroup.toBack(group);
    payload['team_ids'] = teamIds;
    return this.httpClient.post(`/org/api/v1/tournament/${tournamentId}/groups/`, payload).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  updateGroup(group: TournamentGroup, teamIds?: number[]): Observable<TournamentGroup> {
    const payload = TournamentGroup.toBack(group);
    payload['team_ids'] = teamIds;
    return this.httpClient.patch(`/org/api/v1/tournament_group/${group.id}/`, payload).pipe(
      map(data => TournamentGroup.toFront(data))
    );
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_group/${groupId}/`);
  }

}
