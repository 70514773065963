import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { TooltipConfig } from './tooltip-config';

@Component({
  selector: 'mtg-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @ViewChild('host', { read: ViewContainerRef })
  private _viewContainerRef: ViewContainerRef;

  constructor(
    private tooltipConfig: TooltipConfig
  ) {}

  get content() {
    if (typeof this.tooltipConfig.content === 'string') {
      return null;
    }
    return this.tooltipConfig.content;
  }

  get text() {
    if (typeof this.tooltipConfig.content !== 'string') {
      return null;
    }
    return this.tooltipConfig.content;
  }

  get position() {
    return this.tooltipConfig.position;
  }

  get size() {
    return this.tooltipConfig.size;
  }

  get positionX() {
    return this.tooltipConfig.positionX;
  }
}
