import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Poll, PollAnswer } from '@core/models/poll';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class PollService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getPoll(pollId: number): Observable<Poll> {
    return this.httpClient
      .get(`/api/v1/poll/${pollId}/`)
      .pipe(
        map(data => Poll.toFront(data))
      );
  }

  updatePoll(poll: Poll): Observable<Poll> {
    return this.httpClient
      .patch(`/api/v1/poll/${poll.id}/`, Poll.toBack(poll))
      .pipe(
        map(data => Poll.toFront(data))
      );
  }

  saveAnswer(pollId: number, answers: PollAnswer[]): Observable<PollAnswer[]> {
    return this.httpClient
      .post(`/api/v1/poll/${pollId}/answers/`, PollAnswer.toBack(answers))
      .pipe(
        map(data => PollAnswer.toFront(data))
      );
  }

  archivePoll(pollId: number): Observable<Poll> {
    return this.httpClient
      .post(`/api/v1/poll/${pollId}/archive/`, {})
      .pipe(
        map(data => Poll.toFront(data))
      );
  }

  closePoll(pollId: number): Observable<Poll> {
    return this.httpClient
      .post(`/api/v1/poll/${pollId}/close/`, {})
      .pipe(
        map(data => Poll.toFront(data))
      );
  }

  deletePoll(pollId: number): Observable<any> {
    return this.httpClient
      .delete(`/api/v1/poll/${pollId}/`);
  }

  resendNotification(pollId: number, userId: number): Observable<any> {
    return this.httpClient
      .post(`/api/v1/poll/${pollId}/resend_notification/`, {user_id: userId});
  }
}
