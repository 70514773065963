import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from '@core/models/util';
import { File } from '@core/models/file';

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    cover: 'cover',
    sort: 'sort',
  },
  relation: {
    cover: File
  }
})
export class TournamentDivision extends BaseModel {
  id: number;
  name: string;
  cover: File;
  sort: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
