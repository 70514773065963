import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Locales } from '@shared/modules/localization/locales';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import { formatDate } from '@angular/common';

const localization = {
  [Locales.ru]: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    tomorrow: 'Завтра',
  },
  [Locales.en]: {
    today: 'Today',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
  }
};


@Pipe({
  name: 'mtgDate'
})
export class DatePipe implements PipeTransform {
  constructor(
    private localizationService: LocalizationService,
  ) {
  }
  transform(value: any, ...args): any {
    const days = moment().startOf('day').diff(moment(value).startOf('day'), 'days');
    if (days === 0) {
      return localization[this.localizationService.getLocale()].today + ', ' + value.format('HH:mm');
    }
    if (days === 1) {
      return localization[this.localizationService.getLocale()].yesterday + ', ' + value.format('HH:mm');
    }
    if (days === -1) {
      return localization[this.localizationService.getLocale()].tomorrow + ', ' + value.format('HH:mm');
    }
    /*return formatDate(
      value,
      'DD.MM.YYYY, HH:mm',
      this.localizationService.getLocale() as string
    );*/
    return value.format('DD.MM.YYYY, HH:mm');
  }
}
