import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mtg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  public value: any;
  private _onChangeFunc: any;
  private _onTouchedFunc: any;

  @Input() label = '';

  @Input() formControlName: string;

  @Input() disabled: boolean;

  @Input() readOnly: boolean;

  @ViewChild('input', {read: ElementRef, static: true})
  input: ElementRef;

  constructor() {}

  writeValue(value: any): void {
    this.value = value;
    this.input.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFunc = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change() {
    if (this.readOnly || this.disabled) {
      return;
    }
    this.value = !this.value ? true : null;
    if (this._onChangeFunc) {
      this._onChangeFunc(this.value);
    }
    if (this._onTouchedFunc) {
      this._onTouchedFunc();
    }
  }
}
