import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { loadScript } from '@shared/util/scripts';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import { Locales } from '@shared/modules/localization/locales';

@Injectable()
export class RecaptchaService {
  private _loadedSubject;

  constructor(
    private localizationService: LocalizationService,
  ) {
  }

  load() {
    if (!this._loadedSubject) {
      this._loadedSubject = new ReplaySubject<boolean>(1);
      window['recaptchaLoaded'] = () => {
        this._loadedSubject.next(true);
      };
      const lang = this.localizationService.getLocale() === Locales.en ? 'en' : 'ru';
      loadScript(`https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit&hl=${lang}`);
    }

    return this._loadedSubject;
  }
}
