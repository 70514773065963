import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from './util';
import { GameTimeTypes, OvertimeTypes, TimerTypes } from './tournament';

@ModelInstance({
  mappingFields: {
    periods_count: 'periodsCount',
    period_time: 'periodTime',
    overtime_type: 'overtimeType',
    overtime_time: 'overtimeTime',
    max_game_players: 'maxGamePlayers',
    timeout_count: 'timeoutCount',
    overtime_timeout_count: 'overtimeTimeoutCount',
    timeout_time: 'timeoutTime',
    game_time_type: 'gameTimeType',
    substitute_manage_enabled: 'substituteManageEnabled',
    minor_penalty_time: 'minorPenaltyTime',
    major_penalty_time: 'majorPenaltyTime',
    misconduct_penalty_time: 'misconductPenaltyTime',
    game_misconduct_penalty_time: 'gameMisconductPenaltyTime',
    timer_type: 'timerType',
  },
  relation: {
    overtimeType: enumField(OvertimeTypes),
    gameTimeType: enumField(GameTimeTypes),
    timerType: enumField(TimerTypes),
  }
})
export class HockeyGameConfig extends BaseModel {
  periodsCount: number;
  periodTime: number;
  overtimeType: OvertimeTypes;
  overtimeTime: number;
  maxGamePlayers: number;
  timeoutCount: number;
  overtimeTimeoutCount: number;
  timeoutTime: number;
  gameTimeType: GameTimeTypes;
  substituteManageEnabled: boolean;
  minorPenaltyTime: number;
  majorPenaltyTime: number;
  misconductPenaltyTime: number;
  gameMisconductPenaltyTime: number;
  timerType: TimerTypes;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}

  get overtimePeriod(): number {
    return this.periodsCount + 1;
  }

  get bullitsPeriod(): number {
    return this.periodsCount + 2;
  }

  get playoffBullitsPeriod(): number {
    return this.periodsCount + 3;
  }
}

