import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SeasonTournament } from '@core/models/season-tournament';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class OrgSeasonTournamentService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getList(leagueId: number): Promise<SeasonTournament[]> {
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/season_tournaments/`).pipe(
      map(response => SeasonTournament.toFront(response)),
    ).toPromise();
  }

  getById(id: number): Promise<SeasonTournament> {
    return this.httpClient.get(`/org/api/v1/season_tournament/${id}/`).pipe(
      map(response => SeasonTournament.toFront(response)),
    ).toPromise();
  }

  create(leagueId: number, data: any): Promise<SeasonTournament> {
    return this.httpClient.post(
      `/org/api/v1/league/${leagueId}/season_tournaments/`,
      SeasonTournament.toBack(data)
    ).pipe(
      map(response => SeasonTournament.toFront(response)),
    ).toPromise();
  }

  update(id: number, data: any): Promise<SeasonTournament> {
    return this.httpClient.patch(
      `/org/api/v1/season_tournament/${id}/`,
      SeasonTournament.toBack(data),
    ).pipe(
      map(response => SeasonTournament.toFront(response)),
    ).toPromise();
  }

  delete(id: number): Promise<any> {
    return this.httpClient.delete(`/org/api/v1/season_tournament/${id}/`).toPromise();
  }
}
