import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from './util';

export enum UserPermissionTypes {
  denied = 1,
  for_all,
  for_users,
  for_team
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    personal_data: 'personalData',
    games: 'games',
    teams: 'teams',
    statistic: 'statistic'
  },
  relation: {
    personalData: enumField(UserPermissionTypes),
    games: enumField(UserPermissionTypes),
    teams: enumField(UserPermissionTypes),
    statistic: enumField(UserPermissionTypes)
  }
})
export class UserPermission extends BaseModel {
  id: number;
  personalData: UserPermissionTypes;
  games: UserPermissionTypes;
  teams: UserPermissionTypes;
  statistic: UserPermissionTypes;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
