<div class="table-new scroll" *ngIf="loaded">
  <div class="table-row header" mtgSticky="top" [top]="64">
    <div class="table-cell identity" *ngIf="identity" mtgSticky="left" [top]="64" [left]="0">
      <ng-container *ngIf="['user', 'teamUser', 'tournamentTeamUser'].indexOf(identity) > -1">Игрок</ng-container>
      <ng-container *ngIf="['team', 'tournamentTeam'].indexOf(identity) > -1">Команда</ng-container>
    </div>
    <div
      *ngFor="let head of statisticHeader"
      (click)="changeSort(head.id)"
      [ngClass]="{lead: sort === head.id, sortable: sortable}"
      class="table-cell"
    >
      {{ head.title }}
    </div>
  </div>
  <ng-container *ngFor="let item of statistic; let i = index;">
    <div class="subtitle" *ngIf="subTitle === 'month'">{{ item.month | date: 'MMM yyyy' }}</div>
    <div class="subtitle" *ngIf="subTitle === 'winLoses'">{{ item.winLose ? 'Победы' : 'Поражения' }}</div>
    <div class="table-row">
      <div class="table-cell identity" *ngIf="identity">
        <div class="number">{{ i + 1 }}</div>
        <ng-container *ngIf="identity === 'tournamentTeamUser'">
          <div class="photo">
            <a [routerLink]="getRoute(':tournamentUserId', item.tournamentTeamUser.id)">
              <img [src]="item.tournamentTeamUser.leaguePlayer.photo.path" *ngIf="item.tournamentTeamUser.leaguePlayer.photo.path" class="circle-image">
              <div class="empty-photo" *ngIf="!item.tournamentTeamUser.leaguePlayer.photo.path">
                {{ item.tournamentTeamUser.leaguePlayer.initials }}
              </div>
            </a>
          </div>
          <div class="info">
            <a [routerLink]="getRoute(':tournamentUserId', item.tournamentTeamUser.id)">
              <div class="name">{{ item.tournamentTeamUser.leaguePlayer.shortName }}<span class="text-color-muted">&nbsp;#{{ item.tournamentTeamUser.teamUser.number }}</span></div>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="identity === 'teamUser'">
          <div class="photo">
            <a [routerLink]="getRoute(':userId', item.teamUser.user.id)">
              <img [src]="item.teamUser.user.photo.path" *ngIf="item.teamUser.user.photo.path" class="circle-image">
              <div class="empty-photo" *ngIf="!item.teamUser.user.photo.path">
                {{ item.teamUser.user.initials }}
              </div>
            </a>
          </div>
          <div class="info">
            <a [routerLink]="getRoute(':userId', item.teamUser.user.id)">
              <div class="name">{{ item.teamUser.user.shortName }}<span class="number">&nbsp;#{{ item.tournamentTeamUser.teamUser.number }}</span></div>
              <div class="text-color-muted">{{ item.teamUser.team.name }}</div>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="identity === 'team'">
          <div class="photo">
            <img [src]="item.team.logo.path" *ngIf="item.team.logo.path">
            <div [class]="item.team.getEmptyLogoClass()" *ngIf="!item.team.logo.path"></div>
          </div>
          <div class="info">
            <div class="name">{{ item.team.name }}</div>
            <div class="text-color-muted">{{ item.team.city | mtgCityName }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="identity === 'tournamentTeam'">
          <div class="photo">
            <img [src]="item.tournamentTeam.team.logo.path" *ngIf="item.tournamentTeam.team.logo.path">
            <div [class]="item.tournamentTeam.team.getEmptyLogoClass()" *ngIf="!item.tournamentTeam.team.logo.path"></div>
          </div>
          <div class="info">
            <div class="name">{{ item.tournamentTeam.team.name }}</div>
            <div class="text-color-muted">{{ item.tournamentTeam.team.city | mtgCityName }}</div>
          </div>
        </ng-container>
      </div>
      <div class="table-cell identity-hidden" *ngIf="identity"></div>
      <div
        *ngFor="let head of statisticHeader"
        [ngClass]="{lead: sort === head.id}"
        class="table-cell"
      >
        <div class="hidden-title">{{ head.title }}</div>
        {{ item[head.id] || 0 }}
      </div>
    </div>
  </ng-container>
</div>
<mtg-spinner *ngIf="!loaded"></mtg-spinner>
