import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Game } from '@core/models/game';
import { map } from 'rxjs/operators';
import { GameUser } from '@core/models/game-user';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { GameLogBase } from '@core/models/game-log-base';

@Injectable()
export class TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {}

  getGame(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<any> {
    throw new Error('Not implement');
  }

  getGameUserStatistic(gameId: number): Observable<any> {
    throw new Error('Not implement');
  }

  getGameUsers(gameId: number): Observable<GameUser[]> {
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/users/`).pipe(
      map(result => GameUser.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<GameLogBase[]> {
    throw new Error('Not implement');
  }

  getGameConfig(gameId: number): Observable<any> {
    throw new Error('Not implement');
  }
}
