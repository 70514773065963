import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    titleNew: 'Новая команда',
    titleEdit: 'Ваша команда',
    name: 'Название команды',
    sport: 'Вид спорта',
    country: 'Страна',
    city: 'Город',
    privacySettings: 'Настройка приватности',
    statistic: 'Все видят статистику команды',
    games: 'Все видят игры команды',
    events: 'Все видят события команды',
    polls: 'Все видят опросы команды',
    save: 'Сохранить',
    create: 'Создать',
    delete: 'Удалить команду',
    successTitle: 'Команда создана',
    invitePlayers: 'Пригласить игроков',
    mainPage: 'На главную',
    error: 'Произошла ошибка. Проверьте доступность интернета и попробуйте ещё раз',
    closeAlert: 'Вы уверены что хотите закрыть страницу? Все изменения будут потеряны.',
    teamUpdated: 'Команда обновлена',
    deleteTeamPrompt: 'Вы уверены, что хотите удалить команду',
  },
  [Locales.en]: {
    titleNew: 'New team',
    titleEdit: 'Your team',
    name: 'Team name',
    sport: 'Sport',
    country: 'Country',
    city: 'City',
    privacySettings: 'Setting up privacy',
    statistic: 'Everyone can see the team\'s statistics.',
    games: 'Everyone sees the team\'s games',
    events: 'Everyone sees the team\'s events',
    polls: 'Everyone can see the team\'s polls',
    save: 'Save',
    create: 'Create',
    delete: 'Delete team',
    successTitle: 'The team has been created',
    invitePlayers: 'Invite players',
    mainPage: 'To the main page',
    error: 'An error has occurred. Check your internet connection and try again.',
    closeAlert: 'Are you sure you want to close this page? All changes will be lost.',
    teamUpdated: 'Team updated',
    deleteTeamPrompt: 'Are you sure you want to delete team'
  }
};
