import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'mtg-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  exportAs: 'mtgTypeahead',
})
export class TypeaheadComponent {
  @ViewChild(TemplateRef)
  template: TemplateRef<HTMLElement>;

  @Output()
  change = new EventEmitter();

  selectedItem(value: any) {
    this.change.emit(value);
  }
}
