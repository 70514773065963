import { ModelInstance, ToFrontHook, ToBackHook, BaseModel, listField } from './util';
import { Locales } from '@shared/modules/localization/locales';

export enum SportTypes {
  basketball = 1,
  volleyball = 2,
  classic_basketball = 3,
  streetball = 4,
  classic_volleyball = 5,
  beach_volleyball = 6,
  hockey = 7,
  ice_hockey = 8,
  hockey_with_ball = 9,
  football = 10,
  classic_football = 11,
  mini_football = 12,
  handball = 13,
  handball_classic = 14,
  rugby = 15,
  rugby15 = 16,
  rugby7 = 17,
  basketball_1x1 = 18,
  waterpolo = 19,
  classic_waterpolo = 20,
  mini_waterpolo = 21,
  media_basket = 22,
  moneyball_basket = 23,
  wrestball= 24,
  wrestball_classic = 25,
  winline_basket = 26,
  dota = 27,
  dota_classic = 28,
  cs = 29,
  cs_classic = 30,
}

export function translateSportName(sportId: number, locale: Locales): string {
  if (locale === Locales.ru) {
    switch (sportId) {
      case SportTypes.basketball:
        return 'Баскетбол';
      case SportTypes.volleyball:
        return 'Волейбол';
      case SportTypes.classic_basketball:
        return 'Баскетбол';
      case SportTypes.streetball:
        return 'Баскетбол 3x3';
      case SportTypes.classic_volleyball:
        return 'Волейбол';
      case SportTypes.beach_volleyball:
        return 'Пляжный волейбол';
      case SportTypes.hockey:
        return 'Хоккей';
      case SportTypes.ice_hockey:
        return 'Хоккей';
      case SportTypes.hockey_with_ball:
        return 'Хоккей с мячом';
      case SportTypes.football:
        return 'Футбол';
      case SportTypes.classic_football:
        return 'Футбол';
      case SportTypes.mini_football:
        return 'Мини-футбол';
      case SportTypes.handball:
        return 'Гандбол';
      case SportTypes.handball_classic:
        return 'Гандбол';
      case SportTypes.rugby:
        return 'Рэгби';
      case SportTypes.rugby15:
        return 'Рэгби 15';
      case SportTypes.rugby7:
        return 'Рэгби 7';
      case SportTypes.waterpolo:
        return 'Водное поло';
      case SportTypes.classic_waterpolo:
        return 'Водное поло';
      case SportTypes.mini_waterpolo:
        return 'Мини водное поло';
      case SportTypes.media_basket:
        return 'Медиа баскет';
      case SportTypes.moneyball_basket:
        return 'Moneyball баскет';
      case SportTypes.wrestball:
        return 'Рестбол';
      case SportTypes.wrestball_classic:
        return 'Рестбол';
      case SportTypes.winline_basket:
        return 'Winline баскет';
      case SportTypes.dota:
        return 'Dota';
      case SportTypes.dota_classic:
        return 'Dota';
      case SportTypes.cs:
        return 'Counter-Strike';
      case SportTypes.cs_classic:
        return 'Counter-Strike';
    }
  }
  if (locale === Locales.en) {
    switch (sportId) {
      case SportTypes.basketball:
        return 'Basketball';
      case SportTypes.volleyball:
        return 'Volleyball';
      case SportTypes.classic_basketball:
        return 'Basketball';
      case SportTypes.streetball:
        return 'Basketball 3x3';
      case SportTypes.classic_volleyball:
        return 'Volleyball';
      case SportTypes.beach_volleyball:
        return 'Beach volleyball';
      case SportTypes.hockey:
        return 'Hockey';
      case SportTypes.ice_hockey:
        return 'Hockey';
      case SportTypes.hockey_with_ball:
        return 'Ball hockey';
      case SportTypes.football:
        return 'Football';
      case SportTypes.classic_football:
        return 'Football';
      case SportTypes.mini_football:
        return 'Mini-football';
      case SportTypes.handball:
        return 'Handball';
      case SportTypes.handball_classic:
        return 'Handball';
      case SportTypes.rugby:
        return 'Rugby';
      case SportTypes.rugby15:
        return 'Rugby 15';
      case SportTypes.rugby7:
        return 'Rugby 7';
      case SportTypes.waterpolo:
        return 'Waterpolo';
      case SportTypes.classic_waterpolo:
        return 'Waterpolo';
      case SportTypes.mini_waterpolo:
        return 'Waterpolo mini';
      case SportTypes.media_basket:
        return 'Media basket';
      case SportTypes.moneyball_basket:
        return 'Moneyball basket';
      case SportTypes.wrestball:
        return 'Wrestball';
      case SportTypes.wrestball_classic:
        return 'Wrestball';
      case SportTypes.winline_basket:
        return 'Winline basket';
      case SportTypes.dota:
        return 'Dota';
      case SportTypes.dota_classic:
        return 'Dota';
      case SportTypes.cs:
        return 'Counter-Strike';
      case SportTypes.cs_classic:
        return 'Counter-Strike';
    }
  }
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    parent_id: 'parentId',
    is_hidden: 'isHidden',
  }
})
class SubSport extends BaseModel {
  id: number;
  name: string;
  isHidden: boolean;
  parentId: number;

  @ToFrontHook
  static toFront(data: any): any {
    return null;
  }

  @ToBackHook
  static toBack(sport: Sport): any {
    return null;
  }
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    parent_id: 'parentId',
    sub_sports: 'subSports',
    is_hidden: 'isHidden',
  },
  relation: {
    subSports: listField(SubSport),
  }
})
export class Sport extends BaseModel {
  id: number;
  name: string;
  isHidden: boolean;
  parentId: number;
  subSports: SubSport[];

  isBasketball() {
    return this.id === SportTypes.basketball || this.parentId === SportTypes.basketball;
  }

  isClassicBasketball() {
    return this.id === SportTypes.classic_basketball;
  }

  isStreetball() {
    return this.id === SportTypes.streetball;
  }

  isBasketball1x1() {
    return this.id === SportTypes.basketball_1x1;
  }

  isMediaBasket() {
    return this.id === SportTypes.media_basket;
  }

  isMoneyballBasket() {
    return this.id === SportTypes.moneyball_basket;
  }

  isWinlineBasket() {
    return this.id === SportTypes.winline_basket;
  }

  isVolleyball() {
    return this.id === SportTypes.volleyball || this.parentId === SportTypes.volleyball;
  }

  isClassicVolleyball() {
    return this.id === SportTypes.classic_volleyball;
  }

  isBeachVolleyball() {
    return this.id === SportTypes.beach_volleyball;
  }

  isHockey() {
    return this.id === SportTypes.hockey || this.parentId === SportTypes.hockey;
  }

  isIceHockey() {
    return this.id === SportTypes.ice_hockey;
  }

  isHockeyWithBall() {
    return this.id === SportTypes.hockey_with_ball;
  }

  isFootball() {
    return this.id === SportTypes.football || this.parentId === SportTypes.football;
  }

  isClassicFootball() {
    return this.id === SportTypes.classic_football;
  }

  isMiniFootball() {
    return this.id === SportTypes.mini_football;
  }

  isHandball() {
    return this.id === SportTypes.handball || this.parentId === SportTypes.handball;
  }

  isRugby() {
    return this.id === SportTypes.rugby || this.parentId === SportTypes.rugby;
  }

  isRugby15() {
    return this.id === SportTypes.rugby15;
  }

  isRugby7() {
    return this.id === SportTypes.rugby7;
  }

  isWaterpolo() {
    return this.id === SportTypes.waterpolo || this.parentId === SportTypes.waterpolo;
  }

  isClassicWaterpolo() {
    return this.id === SportTypes.classic_waterpolo;
  }

  isMiniWaterpolo() {
    return this.id === SportTypes.mini_waterpolo;
  }

  isWrestball() {
    return this.id === SportTypes.wrestball || this.parentId === SportTypes.wrestball;
  }

  isDota() {
    return this.id === SportTypes.dota || this.parentId === SportTypes.dota;
  }

  isCs() {
    return this.id === SportTypes.cs || this.parentId === SportTypes.cs;
  }

  @ToFrontHook
  static toFront(data: any): any {
    return null;
  }

  @ToBackHook
  static toBack(sport: Sport): any {
    return null;
  }
}
