import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TournamentRound } from '@core/models/tournament-round';
import { map } from 'rxjs/internal/operators';
import { TournamentGroup } from '@core/models/tournament-group';
import { Playoff } from '@core/models/playoff';
import { TournamentRoundTeam } from '@core/models/tournament-round-team';
import { Game } from '@core/models/game';
import { applyGamesFilters, TournamentGamesFilters } from '@core/services/tournament-admin.service';
import { PaginatedResponse } from '@core/services/paginated-response.interface';

@Injectable()
export class OrgTournamentRoundApi {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getList(tournamentId: number): Promise<TournamentRound[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/rounds/`).pipe(
      map(response => TournamentRound.toFront(response)),
    ).toPromise();
  }

  getById(roundId: number): Promise<TournamentRound> {
    return this.httpClient.get(`/org/api/v1/tournament_round/${roundId}/`).pipe(
      map(response => TournamentRound.toFront(response)),
    ).toPromise();
  }

  create(tournamentId: number, round: TournamentRound): Promise<TournamentRound> {
    return this.httpClient.post(`/org/api/v1/tournament/${tournamentId}/rounds/`, TournamentRound.toBack(round)).pipe(
      map(response => TournamentRound.toFront(response)),
    ).toPromise();
  }

  update(roundId: number, data: any): Promise<TournamentRound> {
    return this.httpClient.patch(`/org/api/v1/tournament_round/${roundId}/`, TournamentRound.toBack(data)).pipe(
      map(response => TournamentRound.toFront(response)),
    ).toPromise();
  }

  delete(roundId: number): Promise<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_round/${roundId}/`).toPromise();
  }

  getGroups(roundId: number): Promise<TournamentGroup[]> {
    return this.httpClient.get(`/org/api/v1/tournament_round/${roundId}/groups/`).pipe(
      map(response => TournamentGroup.toFront(response)),
    ).toPromise();
  }

  createGroup(roundId: number, data: any, teamIds: number[]): Promise<TournamentGroup> {
    data = TournamentGroup.toBack(data);
    data['team_ids'] = teamIds;
    return this.httpClient.post(`/org/api/v1/tournament_round/${roundId}/groups/`, data).pipe(
      map(response => TournamentGroup.toFront(response)),
    ).toPromise();
  }

  getPlayoffs(roundId: number, divisionId?: number): Promise<Playoff[]> {
    let params = new HttpParams();
    if (divisionId) {
      params = params.set('division_id', divisionId.toString());
    }
    return this.httpClient.get(`/org/api/v1/tournament_round/${roundId}/playoffs/`, {params}).pipe(
      map(response => Playoff.toFront(response)),
    ).toPromise();
  }

  addTeam(roundId: number, roundTeam: TournamentRoundTeam): Promise<TournamentRoundTeam> {
    return this.httpClient.post(
      `/org/api/v1/tournament_round/${roundId}/teams/`,
      TournamentRoundTeam.toBack(roundTeam)
    ).pipe(
      map(response => TournamentRoundTeam.toFront(response)),
    ).toPromise();
  }

  deleteTeam(tournamentRoundTeamId: number): Promise<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_round_team/${tournamentRoundTeamId}/`).toPromise();
  }

  getTeams(roundId: number, divisionId?: number): Promise<TournamentRoundTeam[]> {
    let params = new HttpParams();
    if (divisionId) {
      params = params.set('division_id', divisionId.toString());
    }
    return this.httpClient.get(`/org/api/v1/tournament_round/${roundId}/teams/`, {params}).pipe(
      map(response => TournamentRoundTeam.toFront(response)),
    ).toPromise();
  }

  async updateTeam(teamId: number, data: any): Promise<TournamentRoundTeam> {
    return this.httpClient.patch(`/org/api/v1/tournament_round_team/${teamId}/`, TournamentRoundTeam.toBack(data)).pipe(
      map(response => TournamentRoundTeam.toFront(response)),
    ).toPromise();
  }

  getGames(roundId: number, page?: number, size?: number, filters?: TournamentGamesFilters): Promise<PaginatedResponse<Game[]>> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (size) {
      params = params.set('size', size.toString());
    }
    params = applyGamesFilters(filters, params);

    return this.httpClient.get(`/org/api/v1/tournament_round/${roundId}/games/`, {params, observe: 'response'}).pipe(
      map(response => ({
        data: Game.toFront(response.body),
        total: +response.headers.get('x-total-count'),
      }))
    ).toPromise();
  }

  createGame(tournamentRoundId: number, data: any): Promise<Game> {
    return this.httpClient.post(`/org/api/v1/tournament_round/${tournamentRoundId}/games/`, Game.toBack(data)).pipe(
      map(response => Game.toFront(response)),
    ).toPromise();
  }

  generateTours(tournamentRoundId: number, divisionId?: number): Promise<Game[]> {
    return this.httpClient.post(`/org/api/v1/tournament_round/${tournamentRoundId}/generate_tours/`, {
      division_id: divisionId,
    }).pipe(
      map(response => Game.toFront(response)),
    ).toPromise();
  }

  createPlayoff(tournamentRoundId: number, data: any): Promise<Playoff> {
    return this.httpClient.post(`/org/api/v1/tournament_round/${tournamentRoundId}/playoffs/`, Playoff.toBack(data)).pipe(
      map(response => Playoff.toFront(response)),
    ).toPromise();
  }

  copyTeams(tournamentRoundId: number, fromRoundId: number): Promise<any> {
    return this.httpClient.post(
      `/org/api/v1/tournament_round/${tournamentRoundId}/copy_teams/`,
      {from_round_id: fromRoundId}
    )
      .toPromise();
  }
}
