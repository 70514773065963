import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, DateTimeField, enumField } from './util';
import { GameLogBase } from './game-log-base';
import { GameUser } from './game-user';
import { RugbyFoulSanctions, RugbyFoulType } from './rugby-foul-type';

export enum RugbyGameLogTypes {
  enter_game = 1,
  exit_game,
  try,
  penalty_try,
  conversion_goal,
  conversion_miss,
  penalty_miss,
  penalty_goal,
  drop_goal,
  drop_goal_miss,
  free_kick,
  foul,
  yellow_card,
  red_card,
  offload,
  tackle,
  handling_error,
  carries_over_gainline,
  bleeding_injury,
  head_injury,
  end_game_penalty_miss,
  end_game_penalty_goal,

  // team actions
  timeout,
  tap_penalty,
  penalty_kick_to_touch,
  scrum_won,
  scrum_lost,
  scrum_won_free,
  lineout_won,
  lineout_lost,
  quick_throw,
  ruck_won,
  ruck_lost,
  maul_won,
  maul_lost,
  team_foul,
}

export const RUGBY_GAME_LOG_TYPE_POINTS = {
  [RugbyGameLogTypes.penalty_try]: 7,
  [RugbyGameLogTypes.try]: 5,
  [RugbyGameLogTypes.conversion_goal]: 2,
  [RugbyGameLogTypes.penalty_goal]: 3,
  [RugbyGameLogTypes.drop_goal]: 3,
  [RugbyGameLogTypes.end_game_penalty_goal]: 1, // Не влияет на общий счет
};

export const RUGBY_TEAM_LOG_TYPES = [
  RugbyGameLogTypes.timeout, RugbyGameLogTypes.scrum_won, RugbyGameLogTypes.scrum_lost, RugbyGameLogTypes.scrum_won_free,
  RugbyGameLogTypes.lineout_won, RugbyGameLogTypes.lineout_lost,
  RugbyGameLogTypes.quick_throw, RugbyGameLogTypes.ruck_won, RugbyGameLogTypes.ruck_lost,
  RugbyGameLogTypes.maul_won, RugbyGameLogTypes.maul_lost, RugbyGameLogTypes.team_foul,
  RugbyGameLogTypes.penalty_kick_to_touch, RugbyGameLogTypes.tap_penalty,
];

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    group: 'group',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    foul_type: 'foulType',
    foul_sanction: 'foulSanction',
  },
  relation: {
    datetime: DateTimeField,
    logType: enumField(RugbyGameLogTypes),
    foulType: RugbyFoulType,
    foulSanction: enumField(RugbyFoulSanctions),
  }
})
export class RugbyGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  group: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: RugbyGameLogTypes;
  datetime: Date;
  time: number;
  period: number;
  active = true;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  foulType: RugbyFoulType;
  foulSanction: RugbyFoulSanctions;

  compare(model: RugbyGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      return this.datetime.getTime() < model.datetime.getTime() ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  isScoreType(): boolean {
    return RUGBY_GAME_LOG_TYPE_POINTS[this.logType] !== undefined;
  }

  scorePoints(): number {
    return RUGBY_GAME_LOG_TYPE_POINTS[this.logType];
  }

  isAfter(log: RugbyGameLog): boolean {
    if (this.time === log.time && this.period === log.period) {
      return this.id > log.id;
    }
    if (this.period === log.period) {
      return this.time > log.time;
    }
    return this.period > log.period;
  }

  isFoulType(): boolean {
    return [RugbyGameLogTypes.team_foul, RugbyGameLogTypes.foul].indexOf(this.logType) > -1;
  }

  isTeamType(): boolean {
    return RUGBY_TEAM_LOG_TYPES.includes(this.logType);
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
