import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { StepperComponent } from './stepper.component';

@Component({
  selector: 'mtg-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  @HostBinding('class.disabled')
  disabled;

  @Input()
  @HostBinding('class.succeed')
  succeed;

  number: number;

  constructor(
    private stepperComponent: StepperComponent,
  ) {}

  ngOnInit(): void {
    this.stepperComponent.addStep(this);
    this.number = this.stepperComponent.getStepNumber(this);
  }
}
