import { enumField, ModelInstance, ToFrontHook, ToBackHook, MomentDateTimeField, BaseModel } from './util';
import * as moment from 'moment';
import { GameUser } from './game-user';
import { GameLogBase } from '@core/models/game-log-base';

export enum GameLogTypes {
  enter_game = 1,
  exit_game,
  remove_game,
  two_point_made,
  three_point_made,
  free_throw_made,
  two_point_attempt,
  three_point_attempt,
  free_throw_attempt,
  assist,
  block,
  rebound,
  offensive_rebound,
  defensive_rebound,
  steal,
  turnover,
  personal_foul,
  technical_foul,
  unsportsmanlike_foul,
  one_point_attempt,
  one_point_made,
  timeout,
  four_point_attempt,
  four_point_made,
  dunk_attempt,
  dunk_made,
  shootout,
  shootout_won,
  shootout_lost,
  moneyball_free_throw_attempt,
  moneyball_free_throw_made,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    is_bench: 'isBench',
    shootout_points: 'shootoutPoints',
    is_additional_point: 'isAdditionalPoint',
  },
  relation: {
    datetime: MomentDateTimeField,
    logType: enumField(GameLogTypes),
  }
})
export class BasketballGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: GameLogTypes;
  datetime: moment.Moment;
  time: number;
  period: number;
  active = true;
  combined: BasketballGameLog;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  isBench: boolean;
  shootoutPoints: boolean;
  isAdditionalPoint: boolean;

  isPointsType() {
    return [
      GameLogTypes.two_point_made,
      GameLogTypes.three_point_made,
      GameLogTypes.free_throw_made,
      GameLogTypes.one_point_made,
      GameLogTypes.four_point_made,
      GameLogTypes.dunk_made,
      GameLogTypes.shootout_won,
      GameLogTypes.moneyball_free_throw_made,
    ].indexOf(this.logType) > -1;
  }

  isReboundType() {
    return [
      GameLogTypes.rebound,
      GameLogTypes.offensive_rebound,
      GameLogTypes.defensive_rebound
    ].indexOf(this.logType) > -1;
  }

  isFoulType() {
    return [
      GameLogTypes.personal_foul,
      GameLogTypes.technical_foul,
      GameLogTypes.unsportsmanlike_foul
    ].indexOf(this.logType) > -1;
  }


  getScore() {
    const scoreMapping = {
      [GameLogTypes.one_point_made]: this.isAdditionalPoint ? 2 : 1,
      [GameLogTypes.free_throw_made]: 1,
      [GameLogTypes.two_point_made]: this.isAdditionalPoint ? 3 : 2,
      [GameLogTypes.three_point_made]: this.isAdditionalPoint ? 4 : 3,
      [GameLogTypes.four_point_made]: this.isAdditionalPoint ? 5 : 4,
      [GameLogTypes.dunk_made]: this.isAdditionalPoint ? 4 : 3,
    };
    if (this.logType === GameLogTypes.shootout_won) {
      return this.shootoutPoints;
    }
    return scoreMapping[this.logType] || 0;
  }

  compare(model: BasketballGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      return this.datetime.isBefore(model.datetime) ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
