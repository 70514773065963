import { AfterViewInit, Directive, ElementRef, Injector, Input, OnDestroy, Renderer2, TemplateRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip.component';
import { TooltipConfig } from './tooltip-config';

@Directive({
  selector: '[mtgTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  private _overlayRef: OverlayRef;
  private _subscriptions: Function[] = [];

  @Input('mtgTooltip')
  content: string|TemplateRef<any>;

  @Input('mtgTooltipTrigger')
  trigger: 'click' | 'hover' | 'manual' = 'hover';

  @Input('mtgTooltipPosition')
  position: 'top' | 'bottom' = 'top';

  @Input('mtgTooltipPositionX')
  positionX: 'start' | 'center' | 'end' = 'center';

  @Input('mtgTooltipSize')
  size: 'xs' | 'md' | 'lg';

  @Input('mtgTooltipVisible')
  set isVisible(visible: boolean) {
    if (visible) {
      this.showTooltip();
    } else {
      this.hideTooltip();
    }
  }

  constructor(
    private _overlay: Overlay,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private _injector: Injector,
  ) {}

  ngAfterViewInit(): void {
    if (this.trigger === 'click') {
      this._subscriptions.push(
        this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
          if (this._overlayRef) {
            this.hideTooltip();
          } else {
            this.showTooltip();
          }
        })
      );
    }
    if (this.trigger === 'hover') {
      this._subscriptions.push(
        this.renderer.listen(this.elementRef.nativeElement, 'mouseover', (event) => {
          this.showTooltip();
        })
      );
      this._subscriptions.push(
        this.renderer.listen(this.elementRef.nativeElement, 'mouseout', (event) => {
          this.hideTooltip();
        })
      );
    }
  }

  ngOnDestroy(): void {
    for (const unsubscribe of this._subscriptions) {
      unsubscribe();
    }
    this.hideTooltip();
  }

  showTooltip() {
    if (this._overlayRef) {
      return;
    }

    this._overlayRef = this._overlay.create({
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      panelClass: 'cdk-overlay-tooltip'
    });
    const weekmap = new WeakMap();
    const config = new TooltipConfig();
    config.content = this.content;
    config.position = this.position;
    config.positionX = this.positionX;
    config.size = this.size;
    weekmap.set(TooltipConfig, config);
    const injector = new PortalInjector(this._injector, weekmap);
    const componentPortal = new ComponentPortal(TooltipComponent, null, injector);
    this._overlayRef.attach(componentPortal);
  }

  hideTooltip() {
    if (!this._overlayRef) {
      return;
    }
    this._overlayRef.dispose();
    this._overlayRef = null;
  }

  getPositionStrategy() {
    const positions = [];
    if (this.position === 'top') {
      positions.push({
        originX: this.positionX,
        originY: 'top',
        overlayX: this.positionX,
        overlayY: 'bottom'
      });
      positions.push({
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      });
      positions.push({
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
      });
    }
    if (this.position === 'bottom') {
      positions.push({
        originX: this.positionX,
        originY: 'bottom',
        overlayX: this.positionX,
        overlayY: 'top'
      });
    }
    return this._overlay.position()
      .flexibleConnectedTo(this.elementRef.nativeElement)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions(positions);
  }
}
