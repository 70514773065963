import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateField } from '@core/models/util';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    id: 'id',
    tournament_team_user_id: 'tournamentTeamUserId',
    date_from: 'dateFrom',
    date_to: 'dateTo',
    matches_count: 'matchesCount',
    reason: 'reason',
  },
  relation: {
    dateFrom: MomentDateField,
    dateTo: MomentDateField,
  }
})
export class TournamentDisqualification extends BaseModel {
  id: number;
  tournamentTeamUserId: number;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  matchesCount: number;
  reason: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
