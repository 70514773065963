import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    title1: 'Перетащите файлы в эту область или',
    title2: 'загрузите с устройства',
    filesizeError: 'Файл %fileName% нельзя загрузить потому, что он больше %maxFileSize%МБ',
  },
  [Locales.en]: {
    title1: 'Drag and drop files to this area or',
    title2: 'download from your device',
    filesizeError: 'File %fileName% cannot be loaded because it is larger than %maxFileSize%MB',
  }
};
