import { ModelInstance, ToBackHook, ToFrontHook } from './util';

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    height: 'height',
    weight: 'weight'
  }
})
export class UserProfile {
  id: number;
  userId: number;
  height: number;
  weight: number;

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
