import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { LeagueBanner } from '@core/models/league-banner';


@Injectable()
export class OrgLeagueBannerService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getBannerList(leagueId: number, page: number, size: number): Observable<PaginatedResponse<LeagueBanner[]>> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/banners/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: LeagueBanner.toFront(response.body)
      })));
  }

  getBannerById(bannerId: number): Observable<LeagueBanner> {
    return this.httpClient.get(`/org/api/v1/league_banners/${bannerId}/`)
      .pipe(map(result => LeagueBanner.toFront(result)));
  }

  createBanner(leagueId: number, data: any): Observable<LeagueBanner> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/banners/`, LeagueBanner.toBack(data))
      .pipe(map(result => LeagueBanner.toFront(result)));
  }

  updateBanner(bannerId: number, data: any): Observable<LeagueBanner> {
    return this.httpClient.patch(`/org/api/v1/league_banners/${bannerId}/`, LeagueBanner.toBack(data))
      .pipe(map(result => LeagueBanner.toFront(result)));
  }

  deleteBanner(bannerId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_banners/${bannerId}/`);
  }
}
