import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField, enumField, listField } from './util';
import * as moment from 'moment';
import { Team } from './team';
import { BasketballGameConfig } from './basketball-game-config';
import { VolleyballGameConfig } from '@core/models/volleyball-game-config';
import { LeagueCourt } from '@core/models/league-court';
import { MediaItem } from '@core/models/media-item';
import { HockeyGameConfig } from '@core/models/hockey-game-config';
import { FootballGameConfig } from '@core/models/football-game-config';
import { HandballGameConfig } from '@core/models/handball-game-config';
import { RugbyGameConfig } from '@core/models/rugby-game-config';
import { Playoff } from '@core/models/playoff';
import { File } from '@core/models/file';
import { TournamentGroup } from '@core/models/tournament-group';
import { WaterpoloGameConfig } from '@core/models/waterpolo-game-config';
import { WrestballGameConfig } from '@core/models/wrestball-game-config';
import { TournamentSettings } from '@core/models/tournament';

export enum GameStatuses {
  open = 1,
  in_progress,
  closed,
  archived,
  draft
}

export enum GameResultTypes {
  team_won = 1,
  competitor_team_won,
  team_technical_defeat,
  competitor_team_technical_defeat,
  draw
}

@ModelInstance({
  mappingFields: {
    result_type: 'resultType',
    team_score: 'teamScore',
    competitor_team_score: 'competitorTeamScore'
  },
  relation: {
    resultType: enumField(GameResultTypes),
  }
})
export class GameCloseResult extends BaseModel {
  resultType: GameResultTypes;
  teamScore: number;
  competitorTeamScore: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    season_id: 'seasonId',
    league_id: 'leagueId',
    logo: 'logo',
    name: 'name',
    settings: 'settings',
  },
  relation: {
    logo: File,
    settings: TournamentSettings,
  }
})
export class TournamentShort extends BaseModel {
  id: number;
  seasonId: number;
  leagueId: number;
  logo: File;
  name: string;
  settings: TournamentSettings;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    tournament_id: 'tournamentId',
    logo: 'logo',
    name: 'name',
  },
  relation: {
    logo: File,
  }
})
export class TournamentTeam extends BaseModel {
  id: number;
  teamId: number;
  tournamentId: number;
  logo: File;
  name: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    team_bet: 'teamBet',
    competitor_team_bet: 'competitorTeamBet',
    bet_link: 'betLink',
    liga_stavok_id: 'ligaStavokId',
    external_ids: 'externalIds',
    mhl_carousel: 'mhlCarousel',
  }
})
export class GameAdditionalData extends BaseModel {
  teamBet: number;
  competitorTeamBet: number;
  betLink: string;
  ligaStavokId: number;
  externalIds: number[];
  mhlCarousel: boolean;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    status: 'status',
    team: 'team',
    team_id: 'teamId',
    tournament_team_id: 'tournamentTeamId',
    tournament_team: 'tournamentTeam',
    competitor_team: 'competitorTeam',
    competitor_team_id: 'competitorTeamId',
    competitor_tournament_team_id: 'competitorTournamentTeamId',
    competitor_tournament_team: 'competitorTournamentTeam',
    competitor_team_name: 'competitorTeamName',
    datetime: 'datetime',
    location: 'location',
    team_score: 'teamScore',
    competitor_team_score: 'competitorTeamScore',
    result_type: 'resultType',
    playoff_number: 'playoffNumber',
    tournament: 'tournament',
    tournament_id: 'tournamentId',
    tournament_tour: 'tournamentTour',
    game_config: 'gameConfig',
    basketball_game_config: 'basketballGameConfig',
    volleyball_game_config: 'volleyballGameConfig',
    hockey_game_config: 'hockeyGameConfig',
    football_game_config: 'footballGameConfig',
    handball_game_config: 'handballGameConfig',
    waterpolo_game_config: 'waterpoloGameConfig',
    rugby_game_config: 'rugbyGameConfig',
    score_by_period: 'scoreByPeriod',
    playoff_stage: 'playoffStage',
    playoff_round: 'playoffRound',
    tournament_stage_id: 'tournamentStageId',
    tournament_playoff_id: 'tournamentPlayoffId',
    tournament_court: 'tournamentCourt',
    tournament_group: 'tournamentGroup',
    tournament_playoff: 'tournamentPlayoff',
    tournament_round_id: 'tournamentRoundId',
    division_id: 'divisionId',
    media_count: 'mediaCount',
    media: 'media',
    additional_data: 'additionalData',
  },
  relation: {
    status: enumField(GameStatuses),
    team: Team,
    tournamentTeam: TournamentTeam,
    competitorTeam: Team,
    competitorTournamentTeam: TournamentTeam,
    datetime: MomentDateTimeField,
    resultType: enumField(GameResultTypes),
    basketballGameConfig: BasketballGameConfig,
    volleyballGameConfig: VolleyballGameConfig,
    hockeyGameConfig: HockeyGameConfig,
    footballGameConfig: FootballGameConfig,
    handballGameConfig: HandballGameConfig,
    waterpoloGameConfig: WaterpoloGameConfig,
    wrestballGameConfig: WrestballGameConfig,
    rugbyGameConfig: RugbyGameConfig,
    tournamentCourt: LeagueCourt,
    tournamentGroup: TournamentGroup,
    media: listField(MediaItem),
    tournamentPlayoff: Playoff,
    tournament: TournamentShort,
    additionalData: GameAdditionalData,
  }
})
export class Game extends BaseModel {
  private _basketballGameConfig: BasketballGameConfig;
  private _volleyballGameConfig: VolleyballGameConfig;
  private _hockeyGameConfig: HockeyGameConfig;
  private _footballGameConfig: FootballGameConfig;
  private _handballGameConfig: HandballGameConfig;
  private _rugbyGameConfig: RugbyGameConfig;
  private _waterpoloGameConfig: WaterpoloGameConfig;
  private _wrestballGameConfig: WrestballGameConfig;

  get playoffBullitsTeamScore(): number | null {
    const score = this.team.sport.isHockey() || this.team.sport.isWaterpolo() ? this.getPeriodScore(this.gameConfig.playoffBullitsPeriod) : null;

    return score ? score[0] : null;
  }

  get playoffBullitsCompetitorTeamScore(): number | null {
    const score = this.team.sport.isHockey() || this.team.sport.isWaterpolo() ? this.getPeriodScore(this.gameConfig.playoffBullitsPeriod) : null;

    return score ? score[1] : null;
  }

  get playoffBullitsTeamWinnerId(): number | null {
    if (this.playoffBullitsTeamScore === null
      || this.playoffBullitsCompetitorTeamScore === null
      || this.playoffBullitsTeamScore === this.playoffBullitsCompetitorTeamScore) {
      return null;
    }
    if (this.team.id) {
      return this.playoffBullitsTeamScore > this.playoffBullitsCompetitorTeamScore ? this.team.id : this.competitorTeam.id;
    } else {
      return this.playoffBullitsTeamScore > this.playoffBullitsCompetitorTeamScore ? this.teamId : this.competitorTeamId;
    }
  }

  get scoreByPeriodList(): { period: number, teamScore: number, competitorTeamScore: number }[] {
    if (!this.scoreByPeriod) {
      return [];
    }
    return Object.keys(this.scoreByPeriod).map(period => ({
      period: +period,
      teamScore: this.scoreByPeriod[period][0],
      competitorTeamScore: this.scoreByPeriod[period][1]
    }));
  }

  get isClosed(): boolean {
    return this.status === GameStatuses.closed;
  }

  get isTeamWon() {
    return this.teamScore > this.competitorTeamScore;
  }

  get isCompetitorTeamWon() {
    return this.competitorTeamScore > this.teamScore;
  }

  get teamWinnerId() {
    if (this.teamScore === this.competitorTeamScore) {
      return null;
    }
    if (this.team.id) {
      return this.teamScore > this.competitorTeamScore ? this.team.id : this.competitorTeam.id;
    } else {
      return this.teamScore > this.competitorTeamScore ? this.teamId : this.competitorTeamId;
    }
  }
  id: number;
  status: GameStatuses;
  team: Team;
  tournamentTeam: TournamentTeam;
  teamId: number;
  tournamentTeamId: number;
  competitorTeam: Team;
  competitorTournamentTeam: TournamentTeam;
  competitorTeamId: number;
  competitorTournamentTeamId: number;
  competitorTeamName: string;
  datetime: moment.Moment;
  location: string;
  teamScore: number;
  competitorTeamScore: number;
  archive: boolean;
  playoffNumber: string;
  playoffRound: number;
  tournamentId: number;
  tournament: TournamentShort;
  tournamentTour: number;
  _gameConfig: any;
  scoreByPeriod: any;
  playoffStage: string;
  tournamentStageId: number;
  tournamentPlayoffId: number;
  tournamentCourt: LeagueCourt;
  tournamentGroup: TournamentGroup;
  tournamentRoundId: number;
  divisionId: number;
  mediaCount: number;
  media: MediaItem[];
  tournamentPlayoff: Playoff;
  gameConfig: any;
  additionalData: GameAdditionalData;

  get basketballGameConfig(): BasketballGameConfig {
    if (this._basketballGameConfig && this._basketballGameConfig.periodsCount) {
      return this._basketballGameConfig;
    }
    if (this.gameConfig) {
      this._basketballGameConfig = BasketballGameConfig.toFront(this.gameConfig);
    }
    return this._basketballGameConfig;
  }

  set basketballGameConfig(value) {
    this._basketballGameConfig = value;
  }

  get volleyballGameConfig(): VolleyballGameConfig {
    if (this._volleyballGameConfig && this._volleyballGameConfig.setsCount) {
      return this._volleyballGameConfig;
    }
    if (this.gameConfig) {
      this._volleyballGameConfig = VolleyballGameConfig.toFront(this.gameConfig);
    }
    return this._volleyballGameConfig;
  }

  set volleyballGameConfig(value) {
    this._volleyballGameConfig = value;
  }

  get hockeyGameConfig(): HockeyGameConfig {
    if (this._hockeyGameConfig && this._hockeyGameConfig.periodsCount) {
      return this._hockeyGameConfig;
    }
    if (this.gameConfig) {
      this._hockeyGameConfig = HockeyGameConfig.toFront(this.gameConfig);
    }
    return this._hockeyGameConfig;
  }

  set hockeyGameConfig(value) {
    this._hockeyGameConfig = value;
  }

  get handballGameConfig(): HandballGameConfig {
    if (this._handballGameConfig && this._handballGameConfig.periodsCount) {
      return this._handballGameConfig;
    }
    if (this.gameConfig) {
      this._handballGameConfig = HandballGameConfig.toFront(this.gameConfig);
    }
    return this._handballGameConfig;
  }

  set handballGameConfig(value) {
    this._handballGameConfig = value;
  }

  get footballGameConfig(): FootballGameConfig {
    if (this._footballGameConfig && this._footballGameConfig.periodsCount) {
      return this._footballGameConfig;
    }
    if (this.gameConfig) {
      this._footballGameConfig = FootballGameConfig.toFront(this.gameConfig);
    }
    return this._footballGameConfig;
  }

  set footballGameConfig(value) {
    this._footballGameConfig = value;
  }

  get rugbyGameConfig(): RugbyGameConfig {
    if (this._rugbyGameConfig && this._rugbyGameConfig.periodsCount) {
      return this._rugbyGameConfig;
    }
    if (this.gameConfig) {
      this._rugbyGameConfig = RugbyGameConfig.toFront(this.gameConfig);
    }
    return this._rugbyGameConfig;
  }

  set rugbyGameConfig(value) {
    this._rugbyGameConfig = value;
  }

  get waterpoloGameConfig(): WaterpoloGameConfig {
    if (this._waterpoloGameConfig && this._waterpoloGameConfig.periodsCount) {
      return this._waterpoloGameConfig;
    }
    if (this.gameConfig) {
      this._waterpoloGameConfig = WaterpoloGameConfig.toFront(this.gameConfig);
    }
    return this._waterpoloGameConfig;
  }

  set waterpoloGameConfig(value) {
    this._waterpoloGameConfig = value;
  }

  get wrestballGameConfig(): WrestballGameConfig {
    if (this._wrestballGameConfig && this._wrestballGameConfig.periodsCount) {
      return this._wrestballGameConfig;
    }
    if (this.gameConfig) {
      this._wrestballGameConfig = WrestballGameConfig.toFront(this.gameConfig);
    }
    return this._wrestballGameConfig;
  }

  set wrestballGameConfig(value) {
    this._wrestballGameConfig = value;
  }

  get playoffStageNumber(): number {
    if (!this.playoffNumber) {
      return null;
    }
    const [playoffStage, playoffNumber] = this.playoffNumber.split('_');
    return +playoffNumber;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}

  getPeriodScore(period: number): number[] | null {
    return this.scoreByPeriod && this.scoreByPeriod[period] ? this.scoreByPeriod[period] : null;
  }
}
