import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

class MetaTag {
  name: string;
  content: string;

  constructor(name: string, content: string) {
    this.name = name;
    this.content = content;
  }
}

@Injectable()
export class SocialMetaService {
  private urlMeta = 'og:url';
  private titleMeta = 'og:title';
  private descriptionMeta = 'og:description';
  private imageMeta = 'og:image';
  private secureImageMeta = 'og:image:secure_url';

  constructor(
    private meta: Meta
  ) {
  }

  addMetaTags(url: string, title: string, description: string, image: string): void {
    const tags: MetaTag[] = [
      new MetaTag(this.urlMeta, url),
      new MetaTag(this.titleMeta, title),
      new MetaTag(this.descriptionMeta, description),
      new MetaTag(this.imageMeta, image),
      new MetaTag(this.secureImageMeta, image),
    ];
    this.setTags(tags);
  }

  setTags(tags: MetaTag[]): void {
    tags.forEach(tag => {
      this.meta.updateTag({property: tag.name, content: tag.content});
    });
  }

  cleanMetaTags(): void {
    const tags = [this.urlMeta, this.titleMeta, this.descriptionMeta, this.imageMeta, this.secureImageMeta];
    tags.forEach(tag => {
      this.meta.removeTag(`property="${tag}"`);
    });
  }
}
