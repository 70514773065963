import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from '@core/models/util';
import { generateArray } from '@shared/util/util';


export enum PlayoffTypes {
  double_elimination = 'double_elimination',
  elimination = 'elimination'
}


export enum PlayoffFormationTypes {
  common = 'common',
  by_standings = 'by_standings'
}

export interface PlayoffStage {
  title: string;
  value: number;
  round: number;
}

@ModelInstance({
  mappingFields: {
    rounds: 'rounds',
    final_rounds: 'finalRounds',
    third_place_rounds: 'thirdPlaceRounds',
    teams_count: 'teamsCount',
    type: 'type',
    formation_type: 'formationType',
    min_user_games_count: 'minUserGamesCount',
    min_user_time: 'minUserTime',
    min_goalkeeper_user_games_count: 'minGoalkeeperUserGamesCount',
    min_goalkeeper_user_time: 'minGoalkeeperUserTime',
  },
  relation: {
    type: enumField(PlayoffTypes),
    formationType: enumField(PlayoffFormationTypes),
  }
})
export class PlayoffSettings extends BaseModel {
  rounds: number;
  finalRounds: number;
  thirdPlaceRounds: number;
  teamsCount: number;
  type: PlayoffTypes;
  formationType: PlayoffFormationTypes;
  minUserGamesCount: number;
  minUserTime: number;
  minGoalkeeperUserGamesCount: number;
  minGoalkeeperUserTime: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    settings: 'settings',
    tournament_id: 'tournamentId',
    tournament_stage_id: 'tournamentStageId',
    is_official: 'isOfficial',
    sort: 'sort',
    tournament_round_id: 'tournamentRoundId',
    division_id: 'divisionId',
    winner_place: 'winnerPlace',
  },
  relation: {
    settings: PlayoffSettings
  }
})
export class Playoff extends BaseModel {
  id: number;
  name: string;
  settings: PlayoffSettings;
  tournamentId: number;
  tournamentStageId: number;
  isOfficial: boolean;
  sort: number;
  tournamentRoundId: number;
  divisionId: number;
  winnerPlace: number;

  private _stages: PlayoffStage[] = [];

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}

  get stagesCount(): number {
    return Math.log(this.settings.teamsCount) / Math.log(2);
  }

  getPlayoffStages() {
    if (this._stages.length === 0 && this.settings.teamsCount) {
      if (this.settings.type === PlayoffTypes.double_elimination) {
        const stagesCount = Math.log(this.settings.teamsCount) / Math.log(2) + 1;
        const semifinalRound = stagesCount - 1;
        this._stages = generateArray(stagesCount).map(stage => {
          let title = '';
          if (stage === stagesCount) {
            title = 'Финал';
          } else if (stage === semifinalRound) {
            title = 'Полуфинал';
          } else {
            title = `Раунд ${stage}`;
          }
          return {
            value: stage,
            title: title,
            round: stage,
          };
        });
        const loserStagesCount = ((stagesCount - 2) * 2) - 2;
        const loserStages = generateArray(loserStagesCount).map(stage => ({
          value: `l_${stage}`,
          title: 'Проигравшие. Раунд ' + stage,
          round: 0,
        }));
        this._stages = this._stages.concat(...(loserStages as any[]));
      } else {
        const stagesCount = Math.log(this.settings.teamsCount) / Math.log(2);
        this._stages = generateArray(stagesCount).map(stage => {
          const playoffStage = this.settings.teamsCount / 2 ** stage;
          let title = '';
          if (playoffStage === 1) {
            title = 'Финал';
          } else {
            title = `1 / ${playoffStage}`;
          }
          return {
            value: stage,
            title: title,
            round: playoffStage,
          };
        });
      }
    }
    return this._stages;
  }
}
