import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Team } from '@core/models/team';
import { MyTeamsComponent } from '@app/shared/components/my-teams/my-teams.component';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { TeamInvite } from '@core/models/team-invite';
import * as RoutingMap from '@app/routing-map';
import { TeamAccess } from '@core/models/team-access';
import { TeamUserRole } from '@core/models/team-user';
import { SportTypes } from '@core/models/sport';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import teamCardLocalization from '@app/shared/components/team-card/team-card.localization';

@Component({
  selector: 'mtg-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
})
export class TeamCardComponent {
  @Input()
  team: Team;
  @Input()
  detail: boolean;
  @Input()
  access: TeamAccess;
  @Input()
  isMyTeamsLinkVisible: boolean;
  @Input()
  invite: TeamInvite;
  @Output()
  join = new EventEmitter<void>();
  @Output()
  leave = new EventEmitter<void>();
  teamRoles = TeamUserRole;
  sportTypes = SportTypes;
  localization = teamCardLocalization[this.localizationService.getLocale()];

  constructor(
    private dialogService: DialogService,
    private localizationService: LocalizationService,
  ) {}

  openMyTeams() {
    this.dialogService.open(MyTeamsComponent, {
      isBackButtonVisible: true
    });
  }

  get editTeamRoute(): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      this.team.id.toString(),
      RoutingMap.ROUTE_TEAM_EDIT
    ];
  }

  get teamPrivacySettingsRoute(): string[] {
    return [
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id.toString(), RoutingMap.ROUTE_TEAM_PRIVACY_SETTINGS
    ];
  }
}
