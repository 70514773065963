import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mtg-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnDestroy {
  showContent$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  ngOnDestroy() {
    this.showContent$.complete();
  }

  toggleContent() {
    this.showContent$.next(!this.showContent$.value);
  }
}
