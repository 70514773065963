import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Game } from '@core/models/game';
import { TournamentService } from '@core/services/tournament.service';
import { DataSliderComponent } from '@shared/modules/shared/components/data-slider/data-slider.component';
import { generateArray } from '@shared/util/util';
import { handleError } from '@shared/util/errors';
import { Observable } from 'rxjs';
import { Tournament, TournamentTypes } from '@core/models/tournament';

interface GameTimelineStage {
  tournamentTour: number;
  playoffStage: number;
  tournamentStageId?: number;
}

@Component({
  selector: 'mtg-tournament-games-timeline',
  templateUrl: './tournament-games-timeline.component.html',
  styleUrls: ['./tournament-games-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentGamesTimelineComponent implements OnChanges {
  @Input()
  tournament: Tournament;
  @Input()
  gameRoute: string;
  games: Game[] = [];
  minStage: GameTimelineStage;
  maxStage: GameTimelineStage;
  stages: GameTimelineStage[];
  loading: boolean;
  initialized: boolean;
  @ViewChild(DataSliderComponent)
  dataSlider: DataSliderComponent;

  constructor(
    private tournamentService: TournamentService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tournament && this.tournament) {
      this.initialize();
    }
  }

  initialize(): void {
    this.games = [];
    this.stages = [];
    this.minStage = null;
    this.maxStage = null;
    this.initialized = false;
    this.tournamentService.getGamesStages(this.tournament.id)
      .subscribe(response => {
        if (this.tournament.settings.type === TournamentTypes.fiba) {
          this.stages = response['stages'].reduce((acc, item) => {
            return acc.concat(
              generateArray(item['tours_count'])
                .map(tournamentTour => ({playoffStage: null, tournamentTour, tournamentStageId: item['stage_id']}))
                .concat(
                  generateArray(item['playoff_stages_count'])
                    .map(playoffStage => ({playoffStage, tournamentTour: null, tournamentStageId: item['stage_id']}))
                )
            );
          }, []);
        } else {
          this.stages = generateArray(response['tours_count'])
            .map(tournamentTour => ({playoffStage: null, tournamentTour, tournamentStageId: null}))
            .concat(
              generateArray(response['playoff_stages_count'])
                .map(playoffStage => ({playoffStage, tournamentTour: null, tournamentStageId: null}))
            );
        }
        this.minStage = this.stages.find(stage => stage.tournamentStageId === response['tournament_stage_id'] &&
          (
            (stage.tournamentTour && stage.tournamentTour === response['current_tour']) ||
            (stage.playoffStage && stage.playoffStage === +response['current_playoff_stage'])
          )
        );
        if (!this.minStage && this.stages.length > 0) {
          this.minStage = this.stages[0];
        }
        this.maxStage = this.minStage;
        this.games = [];
        if (this.minStage) {
          this.loadGames(this.minStage).subscribe(() => {
            const stageIndex = this.stages.indexOf(this.minStage);
            if (stageIndex > 0) {
              this.minStage = this.stages[stageIndex - 1];
              this.loadGames(this.minStage, true).subscribe(() => {
                setTimeout(() => {
                  this.initialized = true;
                  this.cdr.markForCheck();
                });
              });
            } else {
              this.initialized = true;
            }
          });
        } else {
          this.initialized = true;
        }
        this.cdr.markForCheck();
      });
  }

  loadGames(stage: GameTimelineStage, previous?: boolean): Observable<Game[]> {
    return new Observable<Game[]>(observer => {
      this.tournamentService.getGames(
        this.tournament.id, 1, 1000,
        {tournamentTour: stage.tournamentTour, playoffStage: stage.playoffStage},
        stage.tournamentStageId
      )
        .subscribe(response => {
          observer.next(response.data);
          observer.complete();

          if (response.data.length === 0) {
            this.loading = false;
            this.cdr.markForCheck();
            if (previous) {
              this.loadGamesPreviousStage();
            } else {
              this.loadGamesNextStage();
            }
            return;
          }

          if (previous) {
            this.games = [...response.data, ...this.games];
            if (this.initialized) {
              this.dataSlider.correctScroll(response.data.length);
            } else {
              setTimeout(() => {
                if (this.dataSlider) {
                  this.dataSlider.correctScroll(response.data.length);
                }
              });
            }
          } else {
            this.games = [...this.games, ...response.data];
          }
          this.loading = false;
          this.cdr.markForCheck();
        }, error => {
          handleError(error);
          observer.error(error);
          this.loading = false;
          this.cdr.markForCheck();
        });
    });
  }

  loadGamesNextStage(): void {
    const stageIndex = this.stages.indexOf(this.maxStage);
    if (stageIndex === this.stages.length - 1 || this.loading || !this.initialized) {
      return;
    }
    this.loading = true;
    this.maxStage = this.stages[stageIndex + 1];
    this.loadGames(this.maxStage).subscribe();
  }

  loadGamesPreviousStage(): void {
    const stageIndex = this.stages.indexOf(this.minStage);
    if (stageIndex === 0 || this.loading || !this.initialized) {
      return;
    }
    this.loading = true;
    this.minStage = this.stages[stageIndex - 1];
    this.loadGames(this.minStage, true).subscribe();
  }

  getGameRoute(gameId: number): string {
    return this.gameRoute.replace(':gameId', gameId.toString());
  }
}
