import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateField } from '@core/models/util';
import { Team } from '@core/models/team';
import { TournamentTeamUser } from '@core/models/tournament-team-user';
import { TeamUser } from '@core/models/team-user';
import { TournamentTeam } from '@core/models/tournament-team';
import { User } from '@core/models/user';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    team: 'team',
    team_user: 'teamUser',
    tournament_team_user: 'tournamentTeamUser',
    tournament_team: 'tournamentTeam',
    user: 'user',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    points: 'points',
    shot_misses: 'shotMisses',
    shots_on_goal: 'shotsOnGoal',
    shots_on_goal_percent: 'shotsOnGoalPercent',
    shots_blocked: 'shotsBlocked',
    shots: 'shots',
    goals: 'goals',
    goals_percent: 'goalsPercent',
    total_goals: 'totalGoals',
    assists: 'assists',
    penalty_goals: 'penaltyGoals',
    penalty_attempts: 'penaltyAttempts',
    penalty_saves: 'penaltySaves',
    penalty_goals_against: 'penaltyGoalsAgainst',
    penalty_shots_against: 'penaltyShotsAgainst',
    penalty_saves_percent: 'penaltySavesPercent',
    small_penalty_goals: 'smallPenaltyGoals',
    small_penalty_attempts: 'smallPenaltyAttempts',
    small_penalty_saves: 'smallPenaltySaves',
    small_penalty_goals_against: 'smallPenaltyGoalsAgainst',
    small_penalty_shots_against: 'smallPenaltyShotsAgainst',
    small_penalty_saves_percent: 'smallPenaltySavesPercent',
    saves: 'saves',
    saves_percent: 'savesPercent',
    total_saves: 'totalSaves',
    goals_against: 'goalsAgainst',
    total_goals_against: 'totalGoalsAgainst',
    shots_against: 'shotsAgainst',
    safety_rate: 'safetyRate',
    fouls: 'fouls',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    perfect_passes: 'perfectPasses',
    losses: 'losses',
    steals: 'steals',
    outs: 'outs',
    block_shots: 'blockShots',
    corners: 'corners',
    free_kicks: 'freeKicks',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    auto_goals: 'autoGoals',
    auto_goals_against: 'autoGoalsAgainst',
    end_game_penalty_goals: 'endGamePenaltyGoals',
    end_game_penalty_misses: 'endGamePenaltyMisses',
    end_game_penalty_saves: 'endGamePenaltySaves',
    duels_won: 'duelsWon',
    duels_lost: 'duelsLost',
    double_goals: 'doubleGoals',
    double_goals_against: 'doubleGoalsAgainst',
    newbie: 'newbie'
  },
  relation: {
    team: Team,
    teamUser: TeamUser,
    tournamentTeamUser: TournamentTeamUser,
    tournamentTeam: TournamentTeam,
    user: User,
    month: MomentDateField,
  }
})
export class FootballStatistic extends BaseModel {
  team: Team;
  teamUser: TeamUser;
  tournamentTeamUser: TournamentTeamUser;
  tournamentTeam: TournamentTeam;
  user: User;
  month: moment.Moment;
  winLose: boolean;
  gamesCount: number;
  wonGamesCount: number;
  points: number;
  shotMisses: number;
  shotsOnGoal: number;
  shotsBlocked: number;
  shotsOnGoalPercent: number;
  shots: number;
  goals: number;
  totalGoals: number;
  goalsPercent: number;
  assists: number;
  penaltyGoals: number;
  penaltyAttempts: number;
  penaltyGoalsPercent: number;
  penaltySaves: number;
  penaltyGoalsAgainst: number;
  penaltyShotsAgainst: number;
  penaltySavesPercent: number;
  smallPenaltyGoals: number;
  smallPenaltyAttempts: number;
  smallPenaltyGoalsPercent: number;
  smallPenaltySaves: number;
  smallPenaltyGoalsAgainst: number;
  smallPenaltyShotsAgainst: number;
  smallPenaltySavesPercent: number;
  saves: number;
  savesPercent: number;
  totalSaves: number;
  goalsAgainst: number;
  totalGoalsAgainst: number;
  shotsAgainst: number;
  safetyRate: number;
  fouls: number;
  yellowCards: number;
  redCards: number;
  perfectPasses: number;
  losses: number;
  steals: number;
  outs: number;
  gameTime: number;
  plusMinus: number;
  blockShots: number;
  corners: number;
  freeKicks: number;
  autoGoals: number;
  autoGoalsAgainst: number;
  endGamePenaltyGoals: number;
  endGamePenaltyMisses: number;
  endGamePenaltySaves: number;
  duelsWon: number;
  duelsLost: number;
  doubleGoals: number;
  doubleGoalsAgainst: number;
  newbie: boolean;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
