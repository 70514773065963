import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '@core/models/user';
import { RegistrationModel } from '@shared/modules/authorization/models/registration.model';
import { Organization } from '@core/models/organization';

@Injectable()
export class OrgAuthService {

  constructor(
    private httpClient: HttpClient
  ) {}

  registration(data: RegistrationModel): Observable<User> {
    return this.httpClient
      .post('/org/api/v1/registration/', RegistrationModel.toBack(data))
      .pipe(
        map(response => User.toFront(response)),
      );
  }

  myOrganization(): Observable<Organization> {
    return this.httpClient.get(`/org/api/v1/organization/my/`).pipe(
      map(data => Organization.toFront(data))
    );
  }
}
