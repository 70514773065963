import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRsvLogin } from '@shared/modules/authorization/models/social-auth';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

const redirectUri = window.location.origin + '/assets/oauth/rsv.html';
const apiId = 6256084;
const eventAuthResult = 'rsv-auth-result';
const scope = '';

@Injectable()
export class RsvAuthService {
  private interval: any;

  constructor(
    private httpClient: HttpClient,
  ) {}

  login(authUrl: string): Observable<IRsvLogin> {
    return this.makeRsvLogin(authUrl);
  }

  private makeRsvLogin(authUrl: string): Observable<IRsvLogin> {
    return Observable.create(observer => {
      const loginWindow = window.open(authUrl, 'rsv_auth', 'popup=1,width=400,height=400');

      const handleLogin = (evt: CustomEvent) => {
        window.removeEventListener(eventAuthResult, handleLogin);
        clearInterval(this.interval);

        if (!evt.detail) {
          observer.error();
          return;
        }

        observer.next({
          code: evt.detail,
          redirectUri,
        });
        observer.complete();
      };

      this.interval = setInterval(() => {
        if (loginWindow && loginWindow.closed) {
          window.removeEventListener(eventAuthResult, handleLogin);
          observer.error();
          clearInterval(this.interval);
        }
      }, 100);

      window.addEventListener(eventAuthResult, handleLogin);
    });
  }

  getRsvAuthUrl(): Observable<string> {
    const params = new HttpParams()
      .set('redirect_uri', redirectUri);
    return this.httpClient.get<string>('/api/v1/auth/rsv_url/', { params });
  }
}
