import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, listField } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    type: 'type',
    url: 'url',
    width: 'width',
    height: 'height'
  }
})
class VkPhotoSize extends BaseModel {
  type: string;
  url: string;
  width: number;
  height: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    sizes: 'sizes'
  },
  relation: {
    size: listField(VkPhotoSize)
  }
})
export class VkPhoto extends BaseModel {
  id: number;
  sizes: VkPhotoSize[];

  get listPhoto() {
    return this.sizes.find(item => item.type === 'r');
  }

  get detailPhoto() {
    return this.sizes.find(item => item.type === 'z') || this.listPhoto;
  }

  get originalPhoto() {
    return this.sizes.find(item => item.type === 'w') || this.detailPhoto;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
