import { Directive } from '@angular/core';

@Directive({
  selector: '[mtgToggle]',
  exportAs: 'mtgToggle'
})
export class ToggleDirective {
  isVisible = false;

  toggle() {
    this.isVisible = !this.isVisible;
  }
}
