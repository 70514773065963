import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { VolleyballGameStatistic } from '@core/models/volleyball-game-statistic';
import { VolleyballGameTeamStatistic } from '@core/models/volleyball-game-team-statistic';
import { VolleyballGameLog } from '@core/models/volleyball-game-log';
import { VolleyballGameConfig } from '@core/models/volleyball-game-config';
import { TournamentGameService } from '@core/services/tournament-game.service';

@Injectable()
export class TournamentVolleyballGameService extends TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_volleyball_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getGameTeamStatistic(gameId: number): Observable<VolleyballGameTeamStatistic> {
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/team_statistic/`).pipe(
      map(result => VolleyballGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<VolleyballGameStatistic[]> {
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/user_statistic/`).pipe(
      map(result => VolleyballGameStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<VolleyballGameLog[]> {
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/logs/`).pipe(
      map(result => VolleyballGameLog.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<VolleyballGameConfig> {
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/config/`).pipe(
      map(result => VolleyballGameConfig.toFront(result))
    );
  }

  getGameProtocolFile(gameId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient.get(`/api/v1/${this.resource}/${gameId}/game_protocol/`, {params, responseType: 'blob'});
  }
}
