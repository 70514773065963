export function isValidJson(data: any): boolean {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
}

export function generateArray(length: number): number[] {
  if (length <= 0) {
    return [];
  }
  return Array.apply(null, Array(length)).map((_, i) => i + 1);
}


export function getArrayChunks(array: any[], length: number): any[][] {
  const chunks = [];
  const chunkLength = Math.ceil(array.length / length);
  if (chunkLength === 0) {
    return [array];
  }
  for (let i = 0; i < chunkLength; i++) {
    chunks.push(array.slice(i * length, (i + 1) * length));
  }
  return chunks;
}

export function isRoundsEnabled(leagueId: number): boolean {
  return [12, 14, 19, 34, 37].includes(leagueId);
}
