import * as moment from 'moment';
import { HockeyPenaltyTypes } from '@core/localization/hockey-penalty-types';

export const MODEL_MAPPING_FIELDS_KEY = '_mapping_fields_';
export const MODEL_RELATION_KEY = '_relation_';
export const MODEL_TO_FRONT_KEY = '_to_front_';
export const MODEL_TO_BACK_KEY = '_to_back_';

type ParserInterface = (dto: any, path?: GraphPathInterface) => any;

interface ModelTypeInterface {
  new(data?: any);
}

export interface ModelInterface {
  toFront(dto: any): any;
  toBack(dto: any): any;
}

export abstract class BaseModel {
  constructor(data?: {[key: string]: any}) {
    if (data) {
      for (const key of Object.keys(data)) {
        this[key] = data[key];
      }
    }
  }

  static toFront(dto: any): any {
    return undefined;
  }

  static toBack(dto: any): any {
    return undefined;
  }
}

interface GraphPathInterface {
  [key: string]: any;
}

export function enumField(enumClass) {
  return {
    toFront: (value) => {
      if (isNaN(+value)) {
        return enumClass[value];
      } else {
        return value;
      }
    },
    toBack: (value) => {
      if (value === null) {
        return value;
      }
      if (isNaN(+value)) {
        return value;
      } else {
        return enumClass[value];
      }
    }
  };
}

export function listField(modelClass) {
  return {
    toFront: (value) => {
      if (!Array.isArray(value)) {
        return [];
      }
      return value.map(item => modelClass.toFront(item));
    },
    toBack: (value) => {
      if (!Array.isArray(value)) {
        return [];
      }
      return value.map(item => modelClass.toBack(item));
    }
  };
}

export function penaltyTypeField() {
  return {
    toFront: (data) => {
      if (!data) {
        return null;
      }
      if (typeof data === 'object') {
        return data;
      }
      return HockeyPenaltyTypes.find(item => item.id === data);
    },
    toBack: (data) => {
      if (!data) {
        return null;
      }
      if (typeof data === 'object') {
        return data.id;
      }
      return data;
    }
  };
}

export class DateField extends BaseModel {
  static toFront(date: any): any {
    if (date) {
      return new Date(date);
    } else {
      return undefined;
    }
  }
  static toBack(date: Date): any {
    if (date) {
      const dateUtc = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds())
      );
      return dateUtc.toISOString().slice(0, 10);
    } else {
      return undefined;
    }
  }
}

export class DateTimeField extends BaseModel {
  static toFront(date: any): any {
    if (date) {
      return new Date(date);
    } else {
      return undefined;
    }
  }
  static toBack(date: Date): any {
    if (date) {
      const dateUtc = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds())
      );
      return dateUtc.toISOString().slice(0, 19);
    } else {
      return undefined;
    }
  }
}

export class MomentDateField extends BaseModel {
  static toFront(date: string): moment.Moment {
    return date && moment(date) || null;
  }

  static toBack(date: moment.Moment): string {
    return date && date.format('YYYY-MM-DD') || null;
  }
}

export class MomentDateTimeField extends BaseModel {
  static toFront(date: string): moment.Moment {
    return date && moment(date) || null;
  }

  static toBack(date: moment.Moment): string {
    return date && date.toISOString() || null;
  }
}

@ModelInstance({
  mappingFields: {
    value: 'value',
    unrestricted_value: 'unrestricted_value',
    data: 'data'
  }
})
export class DadataField extends BaseModel {
  value: string;
  unrestricted_value: string;
  data: Object;

  @ToFrontHook
  static toFront(data: any) {
    return undefined;
  }

  @ToBackHook
  static toBack(data: any) {
    return undefined;
  }
}

/**
 * Decorator for model entity
 * @param mappingFields - fields to map from dto
 * @param relation - custom parsing for fields
 * @returns {(ctor:Function)=>ctor}
 * @constructor
 */
export function ModelInstance(
  config: {
    mappingFields?: { [key: string]: string },
    relation?: { [key: string]: BaseModel }
  }
) {
  return function (target: ModelTypeInterface) {
    if (config) {
      if (config.mappingFields) {
        target[MODEL_MAPPING_FIELDS_KEY] = config.mappingFields;
      }
      if (config.relation) {
        target[MODEL_RELATION_KEY] = config.relation;
      }
      target[MODEL_TO_FRONT_KEY] = (dto: any, path: GraphPathInterface) => {
        return toFront(target, dto, path);
      };
      target[MODEL_TO_BACK_KEY] = (dto: any, path: GraphPathInterface) => {
        return toBack(target, dto, path);
      };
    }
  };
}

/**
 * Decorator for to front mapping method hook
 * @param target - the object on which the method is defined
 * @param key - the key for the property (a string name or symbol)
 * @param descriptor - a property descriptor
 * @returns {{value: ((...args:any[])=>(any|undefined))}} - result of parsing
 * @constructor
 */
export const ToFrontHook = (target: ModelTypeInterface, key: string | symbol, descriptor: TypedPropertyDescriptor<Function>) => {
  return {
    value: function (...args: any[]) {

      const parseMethod = target[MODEL_TO_FRONT_KEY] as ParserInterface;
      if (parseMethod) {
        return parseMethod(args[0], (args.length > 1 ? args[1] : undefined));
      }
      return undefined;
    }
  };
};

/**
 * Decorator for to back mapping method hook
 * @param target - the object on which the method is defined
 * @param key - the key for the property (a string name or symbol)
 * @param descriptor - a property descriptor
 * @returns {{value: ((...args:any[])=>(any|undefined))}} - result of parsing
 * @constructor
 */
export const ToBackHook = (target: ModelTypeInterface, key: string | symbol, descriptor: TypedPropertyDescriptor<Function>) => {
  return {
    value: function (...args: any[]) {

      const parseMethod = target[MODEL_TO_BACK_KEY] as ParserInterface;
      if (parseMethod) {
        return parseMethod(args[0]);
      }
      return undefined;
    }
  };
};

function extractValue(key, data) {
  if (!data) {
    return undefined;
  }

  const keyParts = key.split('.');
  if (keyParts.length === 1) {
    if (key in data) {
      return data[key];
    } else {
      return undefined;
    }
  }

  for (const k of keyParts) {
    if (data && k in data) {
      data = data[k];
    } else {
      return undefined;
    }
  }

  return data;
}

export function toFront(modelType: ModelTypeInterface, data: any, path?: GraphPathInterface): any {
  // Check if recursion is required
  if (Array.isArray(data)) {
    return data.map(item => {
      return toFront(modelType, item);
    });
  }

  const instance = new modelType();

  // Mapping fields
  const mappingFields = modelType[MODEL_MAPPING_FIELDS_KEY] as string[];
  const relation = modelType[MODEL_RELATION_KEY] as { [key: string]: ModelInterface };
  if (mappingFields) {
    for (const backField of Object.keys(mappingFields)) {
      const frontField = mappingFields[backField];
      const value = extractValue(backField, data);
      if (relation && relation.hasOwnProperty(frontField)) {
        instance[frontField] = relation[frontField].toFront(value);
      } else {
        instance[frontField] = value;
      }
    }
  }

  return instance;
}

export function toBack(modelType: ModelTypeInterface, instance: any, path?: GraphPathInterface): any {
  if (!instance) {
    return instance;
  }

  // Check if recursion is required
  if (Array.isArray(instance)) {
    return instance.map(item => {
      return toBack(modelType, item);
    });
  }

  const data = {};
  const mappingFields = modelType[MODEL_MAPPING_FIELDS_KEY] as string[];
  const relation = modelType[MODEL_RELATION_KEY] as { [key: string]: ModelInterface };
  if (mappingFields) {
    for (const backField of Object.keys(mappingFields)) {
      const frontField = mappingFields[backField];
      if (relation && relation.hasOwnProperty(frontField) && instance[frontField] !== undefined) {
        data[backField] = relation[frontField].toBack(instance[frontField]);
      } else {
        data[backField] = instance[frontField];
      }
    }
  }

  return data;
}
