import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { File } from '@core/models/file';

@Injectable()
export class FileService {
  constructor(
    private httpClient: HttpClient
  ) {}

  upload(fileData: any, filename?: string, width?: number, height?: number): Observable<File> {
    let params = new HttpParams();
    if (width && height) {
      params = params.set('width', width.toString()).set('height', height.toString());
    }
    const formData = new FormData();
    formData.append('file', fileData, filename);

    return this.httpClient
      .post('/api/v1/file/', formData, {params})
      .pipe(
        map(data => File.toFront(data))
      );
  }

  createZipArchive(files: {file: any, filename: string}[]): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file.file, file.filename);
    }
    return this.httpClient
      .post(`/api/v1/file/zip_archive/`, formData, {responseType: 'blob'});
  }
}
