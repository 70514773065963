import { BaseModel, ModelInstance, ToFrontHook, ToBackHook } from '@core/models/util';
import { TeamUser } from '@core/models/team-user';

@ModelInstance({
  mappingFields: {
    id: 'id',
    tournament_team_invite_id: 'tournamentTeamInviteId',
    tournament_team_id: 'tournamentTeamId',
    team_user: 'teamUser',
    accepted: 'accepted',
    additional_data: 'additionalData',
  },
  relation: {
    teamUser: TeamUser
  }
})
export class TournamentTeamUserInvite extends BaseModel {
  id: number;
  tournamentTeamInviteId: number;
  tournamentTeamId: number;
  teamUser: TeamUser;
  accepted: boolean;
  additionalData: any;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
