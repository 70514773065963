import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

@ModelInstance({
  mappingFields: {
    tournament__sport_id: 'tournamentSportId',
    total_games_count: 'totalGamesCount',
    closed_games_count: 'closedGamesCount',
  },
  relation: {}
})
export class LoyaltyProgramLeagueStatistic extends BaseModel {
  tournamentSportId: number;
  totalGamesCount: number;
  closedGamesCount: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
