import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

interface LimitationValue {
  current: number;
  required: number;
}

@ModelInstance({
  mappingFields: {
    min_user_games_count: 'minUserGamesCount',
    min_user_time: 'minUserTime',
    min_goalkeeper_user_games_count: 'minGoalkeeperUserGamesCount',
    min_goalkeeper_user_time: 'minGoalkeeperUserTime',
    manual: 'manual',
  },
})
export class GameUserLimitationTypes extends BaseModel {
  minUserGamesCount: LimitationValue;
  minUserTime: LimitationValue;
  minGoalkeeperUserGamesCount: LimitationValue;
  minGoalkeeperUserTime: LimitationValue;
  manual: boolean;

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}


@ModelInstance({
  mappingFields: {
    tournament_team_user_id: 'tournamentTeamUserId',
    limitations: 'limitations'
  },
  relation: {
    limitations: GameUserLimitationTypes
  }
})
export class GameUserLimitations extends BaseModel {
  tournamentTeamUserId: number;
  limitations: GameUserLimitationTypes;

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
