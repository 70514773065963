import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mtg-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input()
  current: number;
  @Input()
  count: number;
  @Input()
  visibleCount = 6;
  @Input()
  size: 'sm' | 'md' = 'md';
  @Output()
  change = new EventEmitter<number>();
  pages: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const pages = [];
    let count = Math.max(this.visibleCount - (this.count - this.current) - 1, Math.ceil(this.visibleCount / 2));
    for (let i = 0; i < count; i++) {
      if (this.current - i > 0) {
        pages.push(this.current - i);
      }
    }
    count = this.visibleCount - pages.length;
    for (let i = 1; i < count; i++) {
      if (this.current + i <= this.count) {
        pages.push(this.current + i);
      }
    }
    this.pages = pages.sort((a, b) => a - b);
  }

  changePage(page: number) {
    this.change.emit(page);
  }
}
