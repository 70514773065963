import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    error: 'Возникла проблема, мы уже её решаем. Попробуйте позднее',
  },
  [Locales.en]: {
    error: 'There was a problem, we are already solving it. Try again later',
  }
};
