import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateField } from './util';
import { User } from './user';
import * as moment from 'moment';
import { Team } from './team';

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    user: 'user',
    team: 'team',
    team_id: 'teamId',
    user_accept: 'userAccept',
    team_accept: 'teamAccept',
    users_count: 'usersCount',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    score_sum: 'scoreSum',
    created_at: 'createdAt',
    updated_at: 'updatedAt',
  },
  relation: {
    user: User,
    team: Team,
    createdAt: MomentDateField,
    updateAt: MomentDateField,
  }
})
export class TeamInvite extends BaseModel {
  id: number;
  userId: number;
  user: User;
  teamId: number;
  team: Team;
  userAccept: boolean;
  teamAccept: boolean;
  usersCount: number;
  gamesCount: number;
  wonGamesCount: number;
  scoreSum: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
