import {
  BaseModel,
  ModelInterface,
  ToFrontHook,
  ToBackHook,
  enumField,
  ModelInstance,
  DateTimeField
} from '@core/models/util';

export enum LeagueTokenTypes {
  vk_access_token = 1
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    token: 'token',
    token_type: 'tokenType',
    expires_at: 'expiresAt',
  },
  relation: {
    tokenType: enumField(LeagueTokenTypes),
    expiresAt: DateTimeField,
  }
})
export class LeagueToken extends BaseModel {
  id: number;
  token: string;
  tokenType: LeagueTokenTypes;
  _expiresAt: Date;
  expiresIn: number;

  set expiresAt(value: Date) {
    this._expiresAt = value;
    this.expiresIn = (this._expiresAt.getTime() - (new Date()).getTime()) / 1000;
  }

  get expiresAt(): Date {
    return this._expiresAt;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
