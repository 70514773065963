import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField } from '@core/models/util';
import * as moment from 'moment';


@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    updated_at: 'updatedAt',
    points: 'points',
    pp_shot_misses: 'ppShotMisses',
    pp_shots_on_goal: 'ppShotsOnGoal',
    pp_shots_blocked: 'ppShotsBlocked',
    ev_shot_misses: 'evShotMisses',
    ev_shots_on_goal: 'evShotsOnGoal',
    ev_shots_blocked: 'evShotsBlocked',
    sh_shot_misses: 'shShotMisses',
    sh_shots_on_goal: 'shShotsOnGoal',
    sh_shots_blocked: 'shShotsBlocked',
    pp_goals: 'ppGoals',
    ev_goals: 'evGoals',
    sh_goals: 'shGoals',
    pp_assists: 'ppAssists',
    ev_assists: 'evAssists',
    sh_assists: 'shAssists',
    shootout_attempts: 'shootoutAttempts',
    shootout_goals: 'shootoutGoals',
    block_shots: 'blockShots',
    face_off_losses: 'faceOffLosses',
    face_off_wins: 'faceOffWins',
    saves: 'saves',
    goals_against: 'goalsAgainst',
    safety_rate: 'safetyRate',
    penalty_minutes: 'penaltyMinutes',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    after_game_shootout_attempts: 'afterGameShootoutAttempts',
    after_game_shootout_goals: 'afterGameShootoutGoals',
    shootout_save: 'shootoutSaves',
    after_game_shootout_saves: 'afterGameShootoutSaves',
    shootout_goals_against: 'shootoutGoalsAgainst',
    after_game_shootout_goals_against: 'afterGameShootoutGoalsAgainst'
  },
  relation: {
    updatedAt: MomentDateTimeField,
  }
})
export class HockeyGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  ppShotMisses: number;
  ppShotsOnGoal: number;
  ppShotsBlocked: number;
  evShotMisses: number;
  evShotsOnGoal: number;
  evShotsBlocked: number;
  shShotMisses: number;
  shShotsOnGoal: number;
  shShotsBlocked: number;
  ppGoals: number;
  evGoals: number;
  shGoals: number;
  ppAssists: number;
  evAssists: number;
  shAssists: number;
  shootoutAttempts: number;
  shootoutGoals: number;
  blockShots: number;
  faceOffLosses: number;
  faceOffWins: number;
  saves: number;
  goalsAgainst: number;
  safetyRate: number;
  penaltyMinutes: number;
  gameTime: number;
  plusMinus: number;
  updatedAt: moment.Moment;
  afterGameShootoutAttempts: number;
  afterGameShootoutGoals: number;
  shootoutSaves: number;
  afterGameShootoutSaves: number;
  shootoutGoalsAgainst: number;
  afterGameShootoutGoalsAgainst: number;

  get id(): number {
    return this.gameUserId;
  }

  get shotsOnGoal(): number {
    return (this.evShotsOnGoal || 0) + (this.ppShotsOnGoal || 0) + (this.shShotsOnGoal || 0);
  }

  get shotsBlocked(): number {
    return (this.evShotsBlocked || 0) + (this.ppShotsBlocked || 0) + (this.shShotsBlocked || 0);
  }

  get shotMisses(): number {
    return (this.evShotMisses || 0) + (this.ppShotMisses || 0) + (this.shShotMisses || 0);
  }

  get ppShots(): number {
    return (this.ppShotMisses || 0) + (this.ppShotsOnGoal || 0) + (this.ppShotsBlocked || 0);
  }

  get evShots(): number {
    return (this.evShotMisses || 0) + (this.evShotsOnGoal || 0) + (this.evShotsBlocked || 0);
  }

  get shShots(): number {
    return (this.shShotMisses || 0) + (this.shShotsOnGoal || 0) + (this.shShotsBlocked || 0);
  }

  get shots(): number {
    return this.ppShots + this.evShots + this.shShots + (this.shootoutAttempts || 0);
  }

  get totalShots(): number {
    return this.shots + (this.shootoutAttempts || 0);
  }

  get goals(): number {
    return (this.ppGoals || 0) + (this.shGoals || 0) + (this.evGoals || 0);
  }

  get totalGoals(): number {
    return this.goals + (this.shootoutGoals || 0);
  }

  get assists(): number {
    return (this.ppAssists || 0) + (this.evAssists || 0) + (this.shAssists || 0);
  }

  get faceOffs(): number {
    return (this.faceOffWins || 0) + (this.faceOffLosses || 0);
  }

  get shootOuts(): number {
    return (this.shootoutAttempts || 0) + (this.shootoutGoals || 0);
  }

  get shotsAgainst(): number {
    return (this.saves || 0) + (this.goalsAgainst || 0);
  }

  get shootoutShotsAgainst(): number {
    return (this.shootoutSaves || 0) + (this.shootoutGoalsAgainst || 0);
  }

  get totalShotsAgainst(): number {
    return this.shotsAgainst + this.shootoutShotsAgainst;
  }

  get savesPercent(): number {
    if (!this.shotsAgainst || !this.saves) {
      return 0;
    }
    return Math.round(1000 * this.saves / this.shotsAgainst) / 10;
  }

  get totalSaves(): number {
    return (this.saves || 0) + (this.shootoutSaves || 0);
  }

  get totalSavesPercent(): number {
    if (!this.totalShotsAgainst || !this.totalSaves) {
      return 0;
    }
    return Math.round(1000 * this.totalSaves / this.totalShotsAgainst) / 10;
  }

  get totalGoalsAgainst(): number {
    return (this.goalsAgainst || 0) + (this.shootoutGoalsAgainst || 0);
  }

  get gameMinutes(): number {
    return Math.floor(this.gameTime / 60);
  }

  get goalsPercent(): number {
    if (!this.totalGoals || !this.totalShots) {
      return 0;
    }
    return Math.round(1000 * this.totalGoals / this.totalShots) / 10;
  }

  get faceOffPercent(): number {
    if (!this.faceOffWins || !this.faceOffs) {
      return 0;
    }
    return Math.round(1000 * this.faceOffWins / this.faceOffs) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
