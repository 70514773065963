import { TeamEventTypes } from '@core/models/team-event-types';
import { Locales } from '@shared/modules/localization/locales';

export const TeamEventTypeLocalization = {
  [Locales.ru]: {
    [TeamEventTypes.training]: 'Тренировка',
    [TeamEventTypes.other]: 'Другое'
  },
  [Locales.en]: {
    [TeamEventTypes.training]: 'Workout',
    [TeamEventTypes.other]: 'Other'
  }
};
