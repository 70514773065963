import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, MomentDateField, enumField } from './util';
import { TournamentTeamUser } from '@core/models/tournament-team-user';
import { Team } from '@core/models/team';
import { TeamUser } from '@core/models/team-user';
import * as moment from 'moment';
import { User } from '@core/models/user';
import { TournamentTeam } from '@core/models/tournament-team';


@ModelInstance({
  mappingFields: {
    tournament_team_user: 'tournamentTeamUser',
    team: 'team',
    team_user: 'teamUser',
    user: 'user',
    tournament_team: 'tournamentTeam',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    lose_games_count: 'loseGamesCount',
    points: 'points',
    pp_shots: 'ppShots',
    pp_shot_misses: 'ppShotMisses',
    pp_shots_on_goal: 'ppShotsOnGoal',
    pp_shots_blocked: 'ppShotsBlocked',
    ev_shots: 'evShots',
    ev_shot_misses: 'evShotMisses',
    ev_shots_on_goal: 'evShotsOnGoal',
    ev_shots_blocked: 'evShotsBlocked',
    sh_shots: 'shShots',
    sh_shot_misses: 'shShotMisses',
    sh_shots_on_goal: 'shShotsOnGoal',
    sh_shots_blocked: 'shShotsBlocked',
    pp_goals_percent: 'ppGoalsPercent',
    pp_goals: 'ppGoals',
    ev_goals_percent: 'evGoalsPercent',
    ev_goals: 'evGoals',
    sh_goals_percent: 'shGoalsPercent',
    goals: 'goals',
    sh_goals: 'shGoals',
    shot_misses: 'shotMisses',
    shots_on_goal: 'shotsOnGoal',
    shots_blocked: 'shotsBlocked',
    shots: 'shots',
    goals_percent: 'goalsPercent',
    shots_against: 'shotsAgainst',
    saves_percent: 'savesPercent',
    assists: 'assists',
    pp_assists: 'ppAssists',
    ev_assists: 'evAssists',
    sh_assists: 'shAssists',
    shootout_attempts: 'shootoutAttempts',
    shootout_goals: 'shootoutGoals',
    block_shots: 'blockShots',
    face_off_losses: 'faceOffLosses',
    face_off_wins: 'faceOffWins',
    face_offs: 'faceOffs',
    face_off_percent: 'faceOffPercent',
    saves: 'saves',
    goals_against: 'goalsAgainst',
    safety_rate: 'safetyRate',
    penalty_minutes: 'penaltyMinutes',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    newbie: 'newbie',
    total_goals: 'totalGoals',
    total_shots: 'totalShots',
    total_goals_percent: 'totalGoalsPercent',
    after_game_shootout_attempts: 'afterGameShootoutAttempts',
    after_game_shootout_goals: 'afterGameShootoutGoals',
    total_saves: 'totalSaves',
    shootout_saves: 'shootoutSaves',
    shootout_shots_against: 'shootoutShotsAgainst',
    total_shots_against: 'totalShotsAgainst',
    total_saves_percent: 'totalSavesPercent',
    shootout_goals_against: 'shootoutGoalsAgainst',
    total_goals_against: 'totalGoalsAgainst',
    rank: 'rank',
    efficiency: 'efficiency',
    steals: 'steals',
    drawn_fouls: 'drawnFouls',
    sharp_passes: 'sharpPasses',
  },
  relation: {
    tournamentTeamUser: TournamentTeamUser,
    team: Team,
    teamUser: TeamUser,
    user: User,
    tournamentTeam: TournamentTeam,
    month: MomentDateField
  }
})
export class HockeyStatistic extends BaseModel {
  tournamentTeamUser: TournamentTeamUser;
  team: Team;
  teamUser: TeamUser;
  user: User;
  tournamentTeam: TournamentTeam;
  month: moment.Moment;
  winLose: boolean;
  gamesCount: number;
  wonGamesCount: number;
  loseGamesCount: number;
  points: number;
  ppShots: number;
  ppShotMisses: number;
  ppShotsOnGoal: number;
  ppShotsBlocked: number;
  evShots: number;
  evShotMisses: number;
  evShotsOnGoal: number;
  evShotsBlocked: number;
  shShots: number;
  shShotMisses: number;
  shShotsOnGoal: number;
  shShotsBlocked: number;
  ppGoalsPercent: number;
  ppGoals: number;
  evGoalsPercent: number;
  evGoals: number;
  shGoalsPercent: number;
  shGoals: number;
  goals: number;
  shotMisses: number;
  shotsOnGoal: number;
  shotsBlocked: number;
  shots: number;
  goalsPercent: number;
  assists: number;
  ppAssists: number;
  evAssists: number;
  shAssists: number;
  shootoutAttempts: number;
  shootoutGoals: number;
  blockShots: number;
  faceOffLosses: number;
  faceOffWins: number;
  faceOffs: number;
  faceOffPercent: number;
  savesPercent: number;
  saves: number;
  shotsAgainst: number;
  goalsAgainst: number;
  safetyRate: number;
  penaltyMinutes: number;
  gameTime: number;
  plusMinus: number;
  newbie: boolean;
  totalGoals: number;
  totalGoalsPercent: any;
  totalShots: number;
  afterGameShootoutAttempts: number;
  afterGameShootoutGoals: number;
  totalSavesPercent: number;
  shootoutSaves: number;
  totalSaves: number;
  shootoutShotsAgainst: number;
  totalShotsAgainst: number;
  shootoutGoalsAgainst: number;
  totalGoalsAgainst: number;
  rank: number;
  efficiency: number;
  drawnFouls: number;
  sharpPasses: number;

  get userMinutes(): string {
    const minutes = Math.floor(this.gameTime / 60);
    const seconds = Math.floor(this.gameTime - minutes * 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  get gamesWonPercent(): number {
    if (!this.gamesCount) {
      return 0;
    }
    return Math.floor(1000 * this.wonGamesCount / this.gamesCount) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
