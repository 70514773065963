import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from '@core/models/util';
import { TournamentTeam } from '@core/models/tournament-team';
import { TournamentGroup } from '@core/models/tournament-group';

@ModelInstance({
  mappingFields: {
    id: 'id',
    tournament_team: 'tournamentTeam',
    group: 'group',
    tournament_stage_id: 'tournamentStageId',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    score_sum: 'scoreSum',
    missed_sum: 'missedSum',
    last_games_count: 'lastGamesCount',
    last_games_won: 'lastGamesWon',
    points: 'points',
    mt_points: 'mtPoints',
    final_standing: 'finalStanding',
    received_points: 'receivedPoints',
  },
  relation: {
    tournamentTeam: TournamentTeam,
    group: TournamentGroup
  }
})
export class TournamentStageTeam extends BaseModel {
  id: number;
  tournamentTeam: TournamentTeam;
  group: TournamentGroup;
  tournamentStageId: number;
  gamesCount: number;
  wonGamesCount: number;
  scoreSum: number;
  missedSum: number;
  lastGamesCount: number;
  lastGamesWon: number;
  points: number;
  mtPoints: number;
  finalStanding: number;
  receivedPoints: number;

  get gamesWonPercent(): number {
    if (!this.gamesCount) {
      return 0;
    }
    return Math.floor(1000 * this.wonGamesCount / this.gamesCount) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
