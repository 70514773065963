export interface IDialogConfig {
  closeLink?: string;
  isBackButtonVisible?: boolean;
  isCloseButtonVisible?: boolean;
  ignoreBackdropClick?: boolean;
  data?: any;
  closeOnEsc?: boolean;
  hasBackdrop?: boolean;
  backdropClass?: string;
  fullScreen?: boolean;
}

export class DialogConfig implements IDialogConfig {
  closeLink = '';
  isBackButtonVisible = false;
  isCloseButtonVisible = false;
  ignoreBackdropClick = false;
  data: any;
  closeOnEsc = true;
  hasBackdrop = true;
  backdropClass = 'mtg-overlay-backdrop';
  fullScreen = false;

  constructor(data: IDialogConfig) {
    for (const key of Object.keys(data)) {
      this[key] = data[key];
    }
  }
}
