import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, DateTimeField, enumField } from './util';
import { GameLogBase } from './game-log-base';
import { GameUser } from './game-user';

export enum HandballGameLogTypes {
  enter_game = 1,
  exit_game,
  shot_miss,
  shot_on_goal,
  shot_blocked,
  goal,
  assist,
  penalty_miss,
  penalty_shot_on_goal,
  penalty_goal,
  save,
  penalty_save,
  foul,
  yellow_card,
  red_card,
  two_minute_foul,
  turnover,
  steal,
  block_shot,
  timeout
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    is_goalie: 'isGoalie',
  },
  relation: {
    datetime: DateTimeField,
    logType: enumField(HandballGameLogTypes)
  }
})
export class HandballGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: HandballGameLogTypes;
  datetime: Date;
  time: number;
  period: number;
  active = true;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  isGoalie: boolean;

  compare(model: HandballGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      return this.datetime.getTime() < model.datetime.getTime() ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  isScoreType(): boolean {
    return [HandballGameLogTypes.goal, HandballGameLogTypes.penalty_goal].indexOf(this.logType) > -1;
  }

  isAfter(log: HandballGameLog): boolean {
    if (this.time === log.time && this.period === log.period) {
      return this.id > log.id;
    }
    if (this.period === log.period) {
      return this.time > log.time;
    }
    return this.period > log.period;
  }

  isFoulType(): boolean {
    return [HandballGameLogTypes.foul, HandballGameLogTypes.yellow_card, HandballGameLogTypes.red_card].indexOf(this.logType) > -1;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
