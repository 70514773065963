import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    tour: 'тур',
    match: 'Матч',
    matchIsOver: 'Матч завершен',
    dateNotSet: 'Дата матча не назначена',
    courtNotSet: 'Площадка будет объявлена позже',
    error: 'Возникла проблема, мы уже её решаем. Попробуйте позднее',
  },
  [Locales.en]: {
    tour: 'tour',
    match: 'Match',
    matchIsOver: 'The match is over',
    dateNotSet: 'The match date has not been set.',
    courtNotSet: 'The location will be announced later.',
    error: 'There was a problem, we are already solving it. Try again later',
  }
};
