import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    players: 'Игроков',
    wins: 'Побед',
    matchesPlayed1: 'Сыграно',
    matchesPlayed2: 'матчей',
    goals: 'Голов',
    pucks: 'Шайб',
    points: 'Очков',
    perSeason: 'за сезон',
    joinRequest: 'Подать заявку на вступление',
    requestSent: 'Вы отправили заявку на вступление',
    invited: 'Вас пригласили в команду',
  },
  [Locales.en]: {
    players: 'Players',
    wins: 'Wins',
    matchesPlayed1: 'Matches',
    matchesPlayed2: 'played',
    goals: 'Goals',
    pucks: 'Pucks',
    points: 'Points',
    perSeason: 'per season',
    joinRequest: 'Apply for membership',
    requestSent: 'You have submitted your application for membership.',
    invited: 'You have been invited to join the team',
  }
};
