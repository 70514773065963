import { Injectable } from '@angular/core';
import { NotificationBaseService } from '@core/services/notification-base.service';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationService extends NotificationBaseService {
  protected apiUrl = '/api/v1';

  unsubscribe(uid: string, token: string): Observable<any> {
    return this.httpClient.post('/api/v1/unsubscribe/', {uid, token});
  }
}
