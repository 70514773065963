import { Component, HostListener, Input } from '@angular/core';
import { TypeaheadComponent } from '@ui-kit/form/components/typeahead/typeahead.component';

@Component({
  selector: 'mtg-typeahead-option',
  templateUrl: './typeahead-option.component.html',
  styleUrls: ['./typeahead-option.component.scss'],
})
export class TypeaheadOptionComponent {
  @Input()
  value: any;

  @HostListener('click')
  onClick() {
    this.typeahead.selectedItem(this.value);
  }

  constructor(
    private typeahead: TypeaheadComponent,
  ) {}
}
