<mtg-data-slider
  *ngIf="games.length > 0"
  [loading]="loading"
  (scrolledToEnd)="loadGamesNextStage()"
  (scrolledToStart)="loadGamesPreviousStage()"
  [ngClass]="{hidden: !initialized}"
>
  <mtg-data-slider-item *ngFor="let game of games">
    <a [routerLink]="getGameRoute(game.id)">
      <mtg-tournament-game-card
        [game]="game"
      ></mtg-tournament-game-card>
    </a>
  </mtg-data-slider-item>
</mtg-data-slider>
<mtg-spinner *ngIf="!initialized || loading"></mtg-spinner>
