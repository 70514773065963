import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField } from './util';
import { League } from '@core/models/league';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    league: 'league',
    interest: 'interest',
    valid_until: 'validUntil',
  },
  relation: {
    league: League,
    validUntil: MomentDateTimeField,
  }
})
export class LoyaltyProgram extends BaseModel {
  id: number;
  userId: number;
  league: League;
  interest: number;
  validUntil: moment.Moment;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
