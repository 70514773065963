import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatisticFilters } from '@core/services/tournament.service';
import { Sport } from '@core/models/sport';
import { Tournament } from '@core/models';

@Component({
  selector: 'mtg-statistic-table',
  templateUrl: './statistic-table.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticTableComponent {
  @Input()
  sport: Sport;
  @Input()
  filters: StatisticFilters;
  @Input()
  identity: 'team' | 'user' | 'teamUser' | 'tournamentTeam' | 'tournamentTeamUser';
  @Input()
  subTitle: 'month' | 'winLoses';
  @Input()
  route: string;
  @Input()
  sortable: boolean;
  @Input()
  tournament: Tournament;
}
