import {
  ɵComponentType as ComponentType,
  ɵDirectiveType as DirectiveType,
  ɵComponentDef as ComponentDef,
  ɵDirectiveDef as DirectiveDef
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

export interface OnDestroyLike {
  ngOnDestroy(): void;
  [key: string]: any;
}

export function componentDestroyed(component: OnDestroyLike): Observable<void> {
  if ((component.constructor as any).__componentDestroyed$) {
    return (component.constructor as any).__componentDestroyed$;
  }
  throw new Error('Use @UntilDestroy decorator in class');
}

export function UntilDestroy(cmpType: any) {
  const def = getDef(cmpType as any);
  const original = (def as any).onDestroy;
  cmpType.__componentDestroyed$ = new Subject<void>();
  (def as any).onDestroy = function() {
    cmpType.__componentDestroyed$.next();
    cmpType.__componentDestroyed$.complete();
    original.apply(this);
  };
}

export function getDef<T>(
  type: DirectiveType<T> | ComponentType<T>
): DirectiveDef<T> | ComponentDef<T> {
  return (type as ComponentType<T>).ɵcmp || (type as DirectiveType<T>).ɵdir;
}
