import { Injectable } from '@angular/core';
import { NotificationTemplate } from '@shared/modules/notification/interfaces/notification-template';
import { Notification } from '@core/models/notification';
import { PluralizePipe } from '@shared/modules/shared/pipes/pluralize.pipe';
import { NotificationItem } from '@shared/modules/notification/interfaces/notification-item';
import * as moment from 'moment';
import { DatePipe } from '@shared/modules/shared/pipes/date.pipe';

@Injectable()
export class NotificationTemplateService {
  protected data: {[key: number]: NotificationTemplate} = {};

  formatNotification(notification: Notification): NotificationItem {
    const template = this.data[notification.event];
    if (!template) {
      return {
        id: notification.id,
        logo: null,
        emptyLogoClass: null,
        title: null,
        description: null,
        route: null,
        actions: [],
        model: notification
      };
    }
    return {
      id: notification.id,
      logo: template.logo(notification),
      emptyLogoClass: template.emptyLogoClass(notification),
      title: template.title(notification),
      description: template.description(notification),
      route: template.route ? template.route(notification) : null,
      externalLink: template.externalLink ? template.externalLink(notification) : '',
      actions: template.actions,
      model: notification
    };
  }

  pluralize(value: number, variants: string[]): string {
    return new PluralizePipe().transform(value, variants);
  }

  /*date(value: moment.Moment): string {
    return (new DatePipe()).transform(value);
  }*/
}
