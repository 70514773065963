import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from '@core/models/notification';
import { UserService } from '@core/services/user.service';
import { TeamService } from '@core/services/team.service';
import { TeamEventService } from '@core/services/team-event.service';
import { AuthService } from '@core/services/auth.service';
import { handleError } from '@shared/util/errors';
import { ToastService } from '@ui-kit/toast/toast.service';
import { NotificationDataService } from '@shared/modules/notification/services/notification-data.service';
import { NotificationFilter } from '@shared/modules/notification/interfaces/notification-filter';
import { NotificationTemplateService } from '@shared/modules/notification/services/notification-template.service';
import { NotificationItem } from '@shared/modules/notification/interfaces/notification-item';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { combineLatest } from 'rxjs';
import { NotificationSettings } from '@core/models/notification-settings';
import notificationLocalization from '@shared/modules/notification/notification.localization';
import { LocalizationService } from '@shared/modules/localization/localization.service';

@UntilDestroy
@Component({
  selector: 'mtg-notifications-container',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsContainerComponent implements OnInit, OnDestroy {
  @Input()
  service: 'public' | 'org';
  @Input()
  notificationSettingsRoute: string[];
  popupNotifications: NotificationItem[] = [];
  isMobile: boolean;
  settings: NotificationSettings;
  localization = notificationLocalization;
  currentLocale = this.localizationService.getLocale();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private teamEventService: TeamEventService,
    public notificationDataService: NotificationDataService,
    private notificationTemplatesService: NotificationTemplateService,
    private router: Router,
    private toastService: ToastService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private localizationService: LocalizationService,
  ) {
  }

  ngOnInit(): void {
    this.notificationDataService.initialize(this.service)
      .subscribe(() => {}, error => {
        handleError(error);
      });
    this.notificationDataService.notificationSettings$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(settings => {
        this.settings = settings;
      });
    this.notificationDataService.popupNotifications$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(notifications => {
        if (this.popupNotifications.length > 0 && notifications.length === 0) {
          this.notificationDataService.hidePopupNotifications();
          setTimeout(() => {
            this.popupNotifications = notifications;
            this.cdr.markForCheck();
          }, 500);
        } else {
          this.popupNotifications = notifications;
        }
        this.cdr.markForCheck();
      });
    combineLatest([
      this.breakpointObserver.observe(['(max-width: 700px)']).pipe(takeUntil(componentDestroyed(this))),
      this.notificationDataService.popupNotificationsVisible$.pipe(takeUntil(componentDestroyed(this))),
      this.notificationDataService.notificationsVisible$.pipe(takeUntil(componentDestroyed(this)))
    ]).subscribe(([isMobile, popupNotificationsVisible, notificationsVisible]) => {
      if (isMobile.matches && (popupNotificationsVisible || notificationsVisible)) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = null;
      }
    });
  }

  ngOnDestroy(): void {
  }

  changeFilter(filter: NotificationFilter): void {
    this.notificationDataService.setNotificationFilter(filter)
      .subscribe(() => {}, error => {
        handleError(error);
        this.toastService.danger(this.localization[this.currentLocale].error);
      });
  }

  nextPage() {
    this.notificationDataService.getNextPage()
      .subscribe(() => {}, error => {});
  }

  markViewed(item: Notification): void {
    this.notificationDataService.markNotificationViewed(item.id, true)
      .subscribe(() => {}, error => {
        handleError(error);
        this.toastService.danger(this.localization[this.currentLocale].error);
      });
  }

  markAllViewed(): void {
    this.notificationDataService.markNotificationsViewed()
      .subscribe(() => {}, error => {
        handleError(error);
        this.toastService.danger(this.localization[this.currentLocale].error);
      });
  }

  hideNotifications(): void {
    this.notificationDataService.hideNotifications();
  }

  hidePopupNotifications(): void {
    this.notificationDataService.hidePopupNotifications();
  }
}
