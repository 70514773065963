import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

const token = 'f301c0e7fb1d936a58d1ef8e8feb186fc50fc3c9';

@Injectable()
export class DadataService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getAddressSuggestions(query: string): Observable<any> {
    return this.httpClient
      .post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {query, count: 10});
  }
}
