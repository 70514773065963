import { enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';

export enum TeamEventInviteStatuses {
  accepted = 1,
  declined = 2
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_event_id: 'teamEventId',
    team_user_id: 'teamUserId',
    status: 'status',
  },
  relation: {
    status: enumField(TeamEventInviteStatuses)
  }
})
export class TeamEventInvite {
  id: number;
  teamEventId: number;
  teamUserId: number;
  status: TeamEventInviteStatuses;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
