import { ModelInstance, ToBackHook, ToFrontHook, BaseModel } from '@core/models/util';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam',
  },
  relation: {
    team: VolleyballStatistic,
    competitorTeam: VolleyballStatistic,
  }
})
export class VolleyballGameTeamStatistic {
  team: VolleyballStatistic;
  competitorTeam: VolleyballStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
