<div class="layout">
  <div class="content">
    <mtg-header></mtg-header>

    <ng-content></ng-content>

    <div class="footer">
      <a href="javascript:;" class="link" [routerLink]="agreementRoute">{{ localization.privacyPolicy }}</a>
      <a href="javascript:;" class="link" (click)="openFeedbackForm()">{{ localization.feedback }}</a>
      <!--<a href="#">Публичная оферта</a>-->
      <div class="copyright">
        MTGame © {{ year }} • {{ localization.rights }}
      </div>
    </div>
  </div>
</div>
