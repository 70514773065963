import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalizationService } from './localization.service';
import { Locales } from './locales';

interface LocalizationData {[key: string]: string}
type LocalizationType = { [key in Locales]: LocalizationData };

@Pipe({
  name: 'mtgTranslate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  private langSubscription: Subscription | null;
  private lastKey: any;
  private value: string;

  constructor(
    private localizationService: LocalizationService,
    private cdr: ChangeDetectorRef
  ) {}

  transform<T extends LocalizationType>(
    value: T,
    key: keyof T[Locales.ru]
  ): any {
    if (this.lastKey === key) {
      return this.value;
    }
    if (!this.langSubscription) {
      this.langSubscription = this.localizationService.locale$.subscribe(
        localeId => {
          if (this.lastKey && localeId) {
            // @ts-ignore
            this.value = value[localeId][key];
            this.cdr.markForCheck();
          }
        }
      );
    }
    this.lastKey = key;
    // @ts-ignore
    this.value = value[this.localizationService.getLocale() || 'ru-RU'][key];
    return this.value;
  }
}
