const resourcesRegistry: Map<string, HTMLScriptElement | HTMLLinkElement> = new Map();

export function loadScript(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject();
    }

    if (resourcesRegistry.has(url)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    script.onerror = reject;
    script.onload = () => {
      resourcesRegistry.set(url, script);
      resolve();
    };

    document.head.appendChild(script);
  });
}
