import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[mtgIsVisible]',
  exportAs: 'isVisible'
})
export class IsVisibleDirective implements OnInit, OnChanges {
  private subscription: () => void;
  @Input()
  parent: HTMLElement;
  isVisible: boolean;

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parent && this.parent) {
      if (this.subscription) {
        this.subscription();
      }
      this.check();
      this.subscription = this.renderer2.listen(this.parent, 'scroll', () => {
        this.check();
      });
    }
  }

  check() {
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    const parentPosition = this.parent.getBoundingClientRect();
    this.isVisible = position.top >= parentPosition.top && position.bottom <= parentPosition.bottom;
  }
}
