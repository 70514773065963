import { Component } from '@angular/core';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import pageNotFoundLocalization from '@app/layout/components/page-not-found/page-not-found.localization';

@Component({
  selector: 'mtg-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: []
})
export class PageNotFoundComponent {
  localization = pageNotFoundLocalization[this.localizationService.getLocale()];

  constructor(
    private localizationService: LocalizationService,
  ) {
  }
}
