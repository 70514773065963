import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField } from '@core/models/util';
import * as moment from 'moment';


@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    updated_at: 'updatedAt',
    points: 'points',
    shot_misses: 'shotMisses',
    shots_on_goal: 'shotsOnGoal',
    shots_blocked: 'shotsBlocked',
    goals: 'goals',
    assists: 'assists',
    corners: 'corners',
    free_kicks: 'freeKicks',
    penalty_goals: 'penaltyGoals',
    penalty_attempts: 'penaltyAttempts',
    penalty_goals_against: 'penaltyGoalsAgainst',
    penalty_saves: 'penaltySaves',
    small_penalty_goals: 'smallPenaltyGoals',
    small_penalty_attempts: 'smallPenaltyAttempts',
    small_penalty_goals_against: 'smallPenaltyGoalsAgainst',
    small_penalty_saves: 'smallPenaltySaves',
    saves: 'saves',
    goals_against: 'goalsAgainst',
    safety_rate: 'safetyRate',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    fouls: 'fouls',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    perfect_passes: 'perfectPasses',
    losses: 'losses',
    steals: 'steals',
    outs: 'outs',
    block_shots: 'blockShots',
    auto_goals: 'autoGoals',
    auto_goals_against: 'autoGoalsAgainst',
    end_game_penalty_goals: 'endGamePenaltyGoals',
    end_game_penalty_misses: 'endGamePenaltyMisses',
    end_game_penalty_saves: 'endGamePenaltySaves',
    duels_won: 'duelsWon',
    duels_lost: 'duelsLost',
    double_goals: 'doubleGoals',
    double_goals_against: 'doubleGoalsAgainst',
  },
  relation: {
    updatedAt: MomentDateTimeField,
  }
})
export class FootballGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  shotMisses: number;
  shotsOnGoal: number;
  shotsBlocked: number;
  goals: number;
  assists: number;
  blockShots: number;
  corners: number;
  freeKicks: number;
  penaltyGoals: number;
  penaltyAttempts: number;
  penaltyGoalsAgainst: number;
  penaltySaves: number;
  smallPenaltyGoals: number;
  smallPenaltyAttempts: number;
  smallPenaltyGoalsAgainst: number;
  smallPenaltySaves: number;
  saves: number;
  goalsAgainst: number;
  safetyRate: number;
  fouls: number;
  yellowCards: number;
  redCards: number;
  perfectPasses: number;
  losses: number;
  steals: number;
  outs: number;
  gameTime: number;
  plusMinus: number;
  autoGoals: number;
  autoGoalsAgainst: number;
  endGamePenaltyGoals: number;
  endGamePenaltyMisses: number;
  endGamePenaltySaves: number;
  duelsWon: number;
  duelsLost: number;
  doubleGoals: number;
  doubleGoalsAgainst: number;
  updatedAt: moment.Moment;

  get id(): number {
    return this.gameUserId;
  }

  get shots(): number {
    return (this.shotMisses || 0) + (this.shotsOnGoal || 0) + (this.shotsBlocked || 0);
  }


  get totalGoals(): number {
    return Math.floor(10 * (
      (this.goals || 0) +
      (this.penaltyGoals || 0) +
      (this.smallPenaltyGoals || 0) +
      ((this.doubleGoals || 0) * 2)
    )) / 10;
  }

  get goalsPercent(): number {
    if (!this.shots || !this.totalGoals) {
      return 0;
    }
    return Math.round(1000 * this.totalGoals / this.shots) / 10;
  }

  get shotsOnGoalPercent(): number {
    if (!this.shotsOnGoal || !this.shots) {
      return 0;
    }
    return Math.round(1000 * this.shotsOnGoal / this.shots) / 10;
  }

  get penalties(): number {
    return (this.penaltyGoals || 0) + (this.penaltyAttempts || 0);
  }

  get penaltyGoalsPercent(): number {
    if (!this.penaltyAttempts || !this.penaltyGoals) {
      return 0;
    }
    return Math.round(1000 * this.penaltyGoals / this.penaltyAttempts) / 10;
  }

  get smallPenalties(): number {
    return (this.smallPenaltyGoals || 0) + (this.smallPenaltyAttempts || 0);
  }

  get smallPenaltyGoalsPercent(): number {
    if (!this.smallPenaltyAttempts || !this.smallPenaltyGoals) {
      return 0;
    }
    return Math.round(1000 * this.smallPenaltyGoals / this.smallPenaltyAttempts) / 10;
  }

  get shotsAgainst(): number {
    return Math.floor(10 * ((this.saves || 0) + (this.goalsAgainst || 0) + (this.doubleGoalsAgainst || 0))) / 10;
  }

  get penaltyShotsAgainst(): number {
    return (this.penaltySaves || 0) + (this.penaltyGoalsAgainst || 0);
  }

  get smallPenaltyShotsAgainst(): number {
    return (this.smallPenaltySaves || 0) + (this.smallPenaltyGoalsAgainst || 0);
  }

  get totalGoalsAgainst(): number {
    return Math.floor(10 * (
      (this.goalsAgainst || 0) +
      (this.penaltyGoalsAgainst || 0) +
      (this.smallPenaltyGoalsAgainst || 0) +
      (this.autoGoalsAgainst || 0) +
      ((this.doubleGoalsAgainst || 0) * 2)
    )) / 10;
  }


  get totalSaves(): number {
    return (this.saves || 0) + (this.penaltySaves || 0) + (this.smallPenaltySaves || 0);
  }

  get savesPercent(): number {
    if (!this.shotsAgainst || !this.saves) {
      return 0;
    }
    return Math.round(1000 * this.saves / this.shotsAgainst) / 10;
  }

  get penaltySavesPercent(): number {
    if (!this.penaltyShotsAgainst || !this.penaltySaves) {
      return 0;
    }
    return Math.round(1000 * this.penaltySaves / this.penaltyShotsAgainst) / 10;
  }

  get smallPenaltySavesPercent(): number {
    if (!this.smallPenaltyShotsAgainst || !this.smallPenaltySaves) {
      return 0;
    }
    return Math.round(1000 * this.smallPenaltySaves / this.smallPenaltyShotsAgainst) / 10;
  }

  get gameMinutes(): number {
    return Math.floor(this.gameTime / 60);
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
