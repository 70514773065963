import { ModelInstance, ToFrontHook, ToBackHook, BaseModel, listField } from './util';
import { File } from './file';
import { Sport } from './sport';
import { User } from './user';
import { City } from './city';


@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    logo: 'logo',
    tournament_id: 'tournamentId',
  },
  relation: {
    logo: File
  }
})
export class TournamentTeam extends BaseModel {
  id: number;
  name: string;
  logo: File;
  tournamentId: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}


@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    sport: 'sport',
    logo: 'logo',
    owner: 'owner',
    city: 'city',
    users_count: 'usersCount',
    won_games_count: 'wonGamesCount',
    games_count: 'gamesCount',
    score_sum: 'scoreSum',
    created_by_league_id: 'createdByLeagueId',
    season_tournament_teams: 'seasonTournamentTeams',
    unique_code: 'uniqueCode'
  },
  relation: {
    sport: Sport,
    logo: File,
    owner: User,
    city: City,
    seasonTournamentTeams: listField(TournamentTeam),
  }
})
export class Team extends BaseModel {
  id: number;
  name: string;
  sport: Sport;
  logo: File;
  owner: User;
  city: City;
  usersCount: number;
  wonGamesCount: number;
  gamesCount: number;
  scoreSum: number;
  createdByLeagueId: number;
  seasonTournamentTeams: TournamentTeam[];
  uniqueCode: string;

  getEmptyLogoClass(): string {
    const classList = ['empty-logo'];
    if (this.sport.isBasketball()) {
      classList.push('empty-logo-basketball');
    }
    if (this.sport.isVolleyball()) {
      classList.push('empty-logo-volleyball');
    }
    if (this.sport.isHockey()) {
      classList.push('empty-logo-hockey');
    }
    if (this.sport.isFootball()) {
      classList.push('empty-logo-football');
    }
    if (this.sport.isHandball()) {
      classList.push('empty-logo-handball');
    }
    if (this.sport.isWaterpolo()) {
      classList.push('empty-logo-waterpolo');
    }
    return classList.join(' ');
  }

  @ToFrontHook
  static toFront(data: any): any {
    return null;
  }

  @ToBackHook
  static toBack(team: Team): any {
    return null;
  }
}
