import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, enumField } from './util';
import { Locales } from '@shared/modules/localization/locales';

export enum Country {
  RU = 'RU',
  BL = 'BL',
  AM = 'AM',
  KZ = 'KZ',
  KG = 'KG',
  USA = 'USA',
  GE = 'GE',
  AZ = 'AZ',
  CHN = 'CHN',
  EGY = 'EGY',
  IRN = 'IRN'
}

export const CountryLocalization = {
  [Locales.ru]: {
    [Country.RU]: 'Россия',
    [Country.BL]: 'Беларусь',
    [Country.AM]: 'Армения',
    [Country.KZ]: 'Казахстан',
    [Country.KG]: 'Кыргызстан',
    [Country.USA]: 'США',
    [Country.GE]: 'Грузия',
    [Country.AZ]: 'Азербайджан',
    [Country.CHN]: 'Китай',
    [Country.EGY]: 'Египет',
    [Country.IRN]: 'Иран',
  },
  [Locales.en]: {
    [Country.RU]: 'Russia',
    [Country.BL]: 'Belarus',
    [Country.AM]: 'Armenia',
    [Country.KZ]: 'Kazakhstan',
    [Country.KG]: 'Kyrgyzstan',
    [Country.USA]: 'USA',
    [Country.GE]: 'Georgia',
    [Country.AZ]: 'Azerbaijan',
    [Country.CHN]: 'China',
    [Country.EGY]: 'Egypt',
    [Country.IRN]: 'Iran',
  },
};

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    name_en: 'nameEn',
    country: 'country'
  },
  relation: {
    country: enumField(Country)
  }
})
export class City extends BaseModel {
  id: number;
  name: string;
  nameEn: string;
  country: Country;

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
