import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from './util';
import { GameTimeTypes, OvertimeTypes, TimerTypes } from './tournament';


@ModelInstance({
  mappingFields: {
    periods_count: 'periodsCount',
    period_time: 'periodTime',
    overtime_type: 'overtimeType',
    overtime_time: 'overtimeTime',
    overtime_periods: 'overtimePeriods',
    max_game_players: 'maxGamePlayers',
    timeout_count: 'timeoutCount',
    overtime_timeout_count: 'overtimeTimeoutCount',
    timeout_time: 'timeoutTime',
    game_time_type: 'gameTimeType',
    substitute_count: 'substituteCount',
    free_substitute_enabled: 'freeSubstituteEnabled',
    substitute_manage_enabled: 'substituteManageEnabled',
    timer_type: 'timerType',
  },
  relation: {
    overtimeType: enumField(OvertimeTypes),
    gameTimeType: enumField(GameTimeTypes),
    timerType: enumField(TimerTypes),
  }
})
export class FootballGameConfig extends BaseModel {
  periodsCount: number;
  periodTime: number;
  overtimeType: OvertimeTypes;
  overtimeTime: number;
  overtimePeriods: number;
  maxGamePlayers: number;
  timeoutCount: number;
  overtimeTimeoutCount: number;
  timeoutTime: number;
  gameTimeType: GameTimeTypes;
  substituteCount: number;
  freeSubstituteEnabled: boolean;
  substituteManageEnabled: boolean;
  timerType: TimerTypes;

  get penaltyPeriod(): number {
    if (this.overtimeType !== OvertimeTypes.time_and_penalties) {
      return +this.periodsCount + 1;
    }
    return +this.periodsCount + this.overtimePeriods + 1;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
