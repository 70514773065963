<label>
  <textarea
      #textarea
      (input)="changeValue($event.target.value)"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [placeholder]="placeholder || ''"
  ></textarea>
  <div class="error-text" *ngIf="getErrors().length">
    <div *ngFor="let error of getErrors()">{{ error }}</div>
  </div>
  <span class="placeholder" *ngIf="label">
      {{ label }}
      {{ required ? '*' : '' }}
  </span>
</label>
