<div class="panel match-card">
  <header>
    <div class="match-time" *ngIf="game.datetime">
      <strong>{{ game.datetime | date: 'dd MMM' }}</strong> {{ game.datetime | date: 'HH:mm' }}
    </div>
    <div class="match-time" *ngIf="!game.datetime"><strong>Дата не назначена</strong></div>
<!--    <a href="javascript:;" (click)="shareVk()"-->
<!--       *ngIf="game.status === gameStatuses.closed"-->
<!--       mtgStopPropagation-->
<!--    >-->
<!--      <i class="icon icon-share"></i>-->
<!--    </a>-->
    <div class="match-badge" *ngIf="!game.playoffStage">
      {{ game.tournamentTour }} тур
    </div>
    <div class="match-badge" *ngIf="game.playoffStage">
      {{ game.playoffStage }}
    </div>
  </header>
  <div class="match-info">
    <div class="team" [ngClass]="{lost: game.status === gameStatuses.closed && game.teamScore < game.competitorTeamScore}">
      <div class="team-logo">
        <img [src]="game.team.logo.path" *ngIf="game.team.logo.path">
        <div [class]="game.team.getEmptyLogoClass()" *ngIf="!game.team.logo.path"></div>
        <div *ngIf="game.status === gameStatuses.closed && game.teamScore > game.competitorTeamScore" class="trophy"></div>
      </div>
      <div class="team-score">
        {{ game.status === gameStatuses.open || game.status === gameStatuses.draft
          ? '--'
          : game.teamScore
        }}
      </div>
      <div class="team-name">
        {{ game.team.name }}
      </div>
    </div>
    <div class="delimiter">
      <ng-container *ngIf="game.status !== gameStatuses.open && game.status !== gameStatuses.draft">:</ng-container>
      <i class="icon icon-flash" *ngIf="game.status === gameStatuses.open || game.status === gameStatuses.draft"></i>
    </div>
    <div class="team" [ngClass]="{lost: game.status === gameStatuses.closed && game.competitorTeamScore < game.teamScore}">
      <div class="team-logo">
        <img [src]="game.competitorTeam.logo.path" *ngIf="game.competitorTeam.logo.path">
        <div [class]="game.competitorTeam.getEmptyLogoClass()" *ngIf="!game.competitorTeam.logo.path"></div>
        <div *ngIf="game.status === gameStatuses.closed && game.competitorTeamScore > game.teamScore" class="trophy"></div>
      </div>
      <div class="team-score">
        {{ game.status === gameStatuses.open || game.status === gameStatuses.draft
          ? '--'
          : game.competitorTeamScore
        }}
      </div>
      <div class="team-name">
        {{ game.competitorTeam.name }}
      </div>
    </div>
  </div>
</div>
