import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BasketballStatistic } from '@core/models/basketball-statistic';
import { StatisticFilters, TournamentService } from '@core/services/tournament.service';
import { Sport } from '@core/models/sport';
import { Tournament } from '@core/models';
import { BasketballStatisticTypes } from '@core/models/tournament';

@Component({
  selector: 'mtg-basketball-statistic-table',
  templateUrl: './basketball-statistic-table.component.html',
  styleUrls: ['./basketball-statistic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketballStatisticTableComponent implements OnChanges, OnInit {
  @Input()
  sport: Sport;
  @Input()
  filters: StatisticFilters;
  @Input()
  identity: 'team' | 'user' | 'teamUser' | 'tournamentTeam' | 'tournamentTeamUser';
  @Input()
  subTitle: 'month' | 'winLoses';
  @Input()
  route: string;
  @Input()
  sortable: boolean;
  @Input()
  tournament: Tournament;
  statistic: BasketballStatistic[];
  statisticHeader: {id: string, title: string}[] = [];
  loaded = false;
  sort = 'points';

  constructor(
    private tournamentService: TournamentService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.getStatistic();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sport && this.sport) {
      this.buildStatisticHeader();
    }
    if (changes.sortable) {
      this.sort = this.sortable ? 'points' : '';
    }
  }

  getStatistic(): void {
    this.tournamentService.getBasketballStatistic(this.filters)
      .subscribe(statistic => {
        this.statistic = statistic;
        if (this.subTitle === 'month') {
          this.statistic = this.statistic.sort((a, b) =>
            a.month.isBefore(b.month) ? 1 : -1);
        } else if (this.subTitle === 'winLoses') {
          this.statistic = this.statistic.sort((a, b) =>
            a.winLose ? -1 : 1);
        }
        if (this.sortable) {
          this.sortStatistic();
        }
        this.loaded = true;
        this.cdr.markForCheck();
      });
  }

  changeSort(sort: string): void {
    if (!this.sortable) {
      return;
    }
    this.sort = sort;
    this.sortStatistic();
  }

  sortStatistic(): void {
    this.statistic = this.statistic.sort((a, b) => {
      return a[this.sort] > b[this.sort] ? -1 : 1;
    });
  }

  buildStatisticHeader() {
    this.statisticHeader = [
      {
        id: 'gamesCount',
        title: 'Матчи'
      },
      ...(this.filters.group_by === 'month'
        ? [
          {
            id: 'wonGamesCount',
            title: 'Победы',
          },
        ] :
          []
      ),
      {
        id: 'points',
        title: 'Очки'
      },
      ...this.tournament.settings.basketballStatisticType !== BasketballStatisticTypes.light
        ? [
            {
              id: 'rebounds',
              title: 'Подборы'
            },
            {
              id: 'assists',
              title: 'Передачи'
            },
            {
              id: 'blocks',
              title: 'Блокшоты'
            },
            {
              id: 'steals',
              title: 'Перехваты'
            },
            {
              id: 'turnovers',
              title: 'Потери'
            },
          ]
        : [],
      ...(this.sport.isStreetball()
          ? [
            {
              id: 'onePointsMade',
              title: '1 очковых',
            },
            {
              id: 'onePointPercent',
              title: '% 1 очковых',
            },
            {
              id: 'twoPointsMade',
              title: '2 очковые'
            },
            {
              id: 'twoPointPercent',
              title: '% 2 очковых'
            }
          ]
          : [
            {
              id: 'twoPointsMade',
              title: '2 очковые'
            },
            {
              id: 'twoPointPercent',
              title: '% 2 очковых'
            },
            {
              id: 'threePointsMade',
              title: '3 очковые'
            },
            {
              id: 'threePointPercent',
              title: '% 3 очковых'
            },
          ]
      ),
      {
        id: 'freeThrowsMade',
        title: 'штрафных'
      },
      {
        id: 'freeThrowPercent',
        title: '% штрафных'
      }
    ];
  }

  getRoute(key: string, value: number): string {
    if (!this.route) {
      return null;
    }
    return this.route.replace(key, value.toString());
  }
}
