import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';
import { Team } from './team';

@ModelInstance({
  mappingFields: {
    team: 'team',
    games_count: 'gamesCount',
    points: 'points',
    two_points_made: 'twoPointsMade',
    three_points_made: 'threePointsMade',
    free_throws_made: 'freeThrowsMade',
    one_points_made: 'onePointsMade',
    two_point_attempts: 'twoPointAttempts',
    three_point_attempts: 'threePointAttempts',
    free_throw_attempts: 'freeThrowAttempts',
    one_point_attempts: 'onePointAttempts',
    two_point_percent: 'twoPointPercent',
    three_point_percent: 'threePointPercent',
    free_throw_percent: 'freeThrowPercent',
    one_point_percent: 'onePointPercent',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    offensive_rebounds: 'offensiveRebounds',
    defensive_rebounds: 'defensiveRebounds',
    steals: 'steals',
    turnovers: 'turnovers',
    personal_fouls: 'personalFouls',
    technical_fouls: 'technicalFouls',
    unsportsmanlike_fouls: 'unsportsmanlikeFouls',
    true_shooting_attempts: 'trueShootingAttempts',
    true_shooting_percent: 'trueShootingPercent',
  },
  relation: {
    team: Team
  }
})
export class TournamentTeamStatistic extends BaseModel {
  team: Team;
  gamesCount: number;
  points: number;
  twoPointsMade: number;
  threePointsMade: number;
  freeThrowsMade: number;
  onePointsMade: number;
  twoPointAttempts: number;
  threePointAttempts: number;
  freeThrowAttempts: number;
  onePointAttempts: number;
  twoPointPercent: number;
  threePointPercent: number;
  freeThrowPercent: number;
  onePointPercent: number;
  assists: number;
  blocks: number;
  rebounds: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  steals: number;
  turnovers: number;
  personalFouls: number;
  technicalFouls: number;
  unsportsmanlikeFouls: number;
  trueShootingAttempts: number;
  trueShootingPercent: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
