import { Component, Input } from '@angular/core';
import { Game, GameStatuses } from '@core/models/game';
import * as RoutingMap from '@app/routing-map';
import { Team } from '@core/models/team';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '@ui-kit/toast/toast.service';
import { SportTypes } from '@core/models/sport';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import gameCardLocalization from '@app/shared/components/game-card/game-card.localization';

@Component({
  selector: 'mtg-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
})
export class GameCardComponent {
  @Input()
  game: Game;
  @Input()
  team: Team;
  @Input()
  canManageGames: boolean;
  @Input()
  isProfile: boolean;
  gameStatuses = GameStatuses;
  sportTypes = SportTypes;
  localization = gameCardLocalization[this.localizationService.getLocale()]

  constructor(
    private httpClient: HttpClient,
    private toastsService: ToastService,
    private localizationService: LocalizationService,
  ) {}

  gameDetailRoute(gameId: number): string[] {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id.toString(), RoutingMap.ROUTE_GAME_ROOT, gameId.toString()];
  }

  editGameRoute(gameId: number): string[] {
    return [RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE, this.team.id.toString(), RoutingMap.ROUTE_GAME_ROOT, gameId.toString(),
      RoutingMap.ROUTE_GAME_EDIT];
  }

  get showCalendarUrl(): boolean {
    return [this.gameStatuses.closed, this.gameStatuses.archived].indexOf(this.game.status) < 0;
  }

  downloadCalendarFile() {
    return this.httpClient
      .get(`/api/v1/game/${this.game.id}/event.ics`, { responseType: 'blob' })
      .subscribe(response => {
        const fileUrl = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = `event.ics`;
        a.click();
      }, error => {
        this.toastsService.danger(this.localization.error);
      });
  }
}
