import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from '@core/models/util';
import { WaterpoloStatistic } from '@core/models/waterpolo-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: WaterpoloStatistic,
    competitorTeam: WaterpoloStatistic
  }
})
export class WaterpoloGameTeamStatistic extends BaseModel {
  team: WaterpoloStatistic;
  competitorTeam: WaterpoloStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
