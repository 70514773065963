import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, DateTimeField } from './util';

@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    points: 'points',
    free_throws_made: 'freeThrowsMade',
    two_points_free_throw_made: 'twoPointsFreeThrowMade',
    three_points_free_throw_made: 'threePointsFreeThrowMade',
    total_free_throw_made: 'totalFreeThrowMade',
    two_points_made: 'twoPointsMade',
    three_points_made: 'threePointsMade',
    one_points_made: 'onePointsMade',
    dunks_made: 'dunksMade',
    two_point_attempts: 'twoPointAttempts',
    three_point_attempts: 'threePointAttempts',
    free_throw_attempts: 'freeThrowAttempts',
    two_point_free_throw_attempts: 'twoPointFreeThrowAttempts',
    three_point_free_throw_attempts: 'threePointFreeThrowAttempts',
    total_free_throw_attempts: 'totalFreeThrowAttempts',
    one_point_attempts: 'onePointAttempts',
    dunk_attempts: 'dunkAttempts',
    one_point_percent: 'onePointPercent',
    two_point_percent: 'twoPointPercent',
    three_point_percent: 'threePointPercent',
    free_throw_percent: 'freeThrowPercent',
    total_free_throw_percent: 'totalFreeThrowPercent',
    dunks_made_percent: 'dunksMadePercent',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    offensive_rebounds: 'offensiveRebounds',
    defensive_rebounds: 'defensiveRebounds',
    steals: 'steals',
    turnovers: 'turnovers',
    personal_fouls: 'personalFouls',
    drawn_fouls: 'drawnFouls',
    drawn_wrestler_attempts: 'drawnWrestlerAttempts',
    drawn_wrestler_won_attempts: 'drawnWrestlerWonAttempts',
    drawn_wrestler_lost_attempts: 'drawnWrestlerLostAttempts',
    wrestler_scrums_won: 'wrestlerScrumsWon',
    wrestler_scrums_lost: 'wrestlerScrumsLost',
    wrestler_scrums_tie: 'wrestlerScrumsTie',
    wrestler_scrum_points: 'wrestlerScrumPoints',
    wrestler_scrums_counterattack: 'wrestlerScrumsCounterattack',
    wrestler_scrums_counterattack_points: 'wrestlerScrumsCounterattackPoints',
    scrums_won: 'scrumsWon',
    scrums_lost: 'scrumsLost',
    scrums_tie: 'scrumsTie',
    scrum_points: 'scrumPoints',
    scrums_ball_advantage_won: 'scrumsBallAdvantageWon',
    scrums_ball_advantage_lost: 'scrumsBallAdvantageLost',
    scrums_ball_advantage_tie: 'scrumsBallAdvantageTie',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    updated_at: 'updatedAt',
  },
  relation: {
    updatedAt: DateTimeField,
  }
})
export class WrestballGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  freeThrowsMade: number;
  twoPointsFreeThrowMade: number;
  threePointsFreeThrowMade: number;
  totalFreeThrowMade: number;
  twoPointsMade: number;
  threePointsMade: number;
  onePointsMade: number;
  dunksMade: number;
  twoPointAttempts: number;
  threePointAttempts: number;
  freeThrowAttempts: number;
  twoPointFreeThrowAttempts: number;
  threePointFreeThrowAttempts: number;
  totalFreeThrowAttempts: number;
  onePointAttempts: number;
  dunkAttempts: number;
  assists: number;
  blocks: number;
  rebounds: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  steals: number;
  turnovers: number;
  personalFouls: number;
  technicalFouls: number;
  unsportsmanlikeFouls: number;
  disqualificationFouls: number;
  drawnFouls: number;
  drawnWrestlerAttempts: number;
  drawnWrestlerWonAttempts: number;
  drawnWrestlerLostAttempts: number;
  wrestlerScrumsWon: number;
  wrestlerScrumsLost: number;
  wrestlerScrumsTie: number;
  wrestlerScrumPoints: number;
  wrestlerScrumsCounterattack: number;
  wrestlerScrumsCounterattackPoints: number;
  scrumsWon: number;
  scrumsLost: number;
  scrumsTie: number;
  scrumPoints: number;
  scrumsBallAdvantageWon: number;
  scrumsBallAdvantageLost: number;
  scrumsBallAdvantageTie: number;
  yellowCards: number;
  redCards: number;
  gameTime: number;
  plusMinus: number;
  updatedAt: Date;

  get id(): number {
    return this.gameUserId;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}

  get totalFouls(): number {
    return (this.personalFouls || 0)
      + (this.technicalFouls || 0)
      + (this.unsportsmanlikeFouls || 0)
      + (this.disqualificationFouls || 0)
      + (this.yellowCards || 0)
      + (this.redCards || 0);
  }

  set onePointPercent(v: number) {}

  get onePointPercent(): number {
    if (!this.onePointsMade || !this.onePointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.onePointsMade / this.onePointAttempts) / 10;
  }

  set twoPointPercent(v: number) {}

  get twoPointPercent(): number {
    if (!this.twoPointsMade || !this.twoPointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.twoPointsMade / this.twoPointAttempts) / 10;
  }

  set threePointPercent(v: number) {}

  get threePointPercent(): number {
    if (!this.threePointsMade || !this.threePointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.threePointsMade / this.threePointAttempts) / 10;
  }

  set freeThrowPercent(v: number) {}

  get freeThrowPercent(): number {
    if (!this.freeThrowsMade || !this.freeThrowAttempts) {
      return 0;
    }
    return Math.round(1000 * this.freeThrowsMade / this.freeThrowAttempts) / 10;
  }

  set totalFreeThrowPercent(v: number) {}

  get totalFreeThrowPercent(): number {
    if (!this.totalFreeThrowMade || !this.totalFreeThrowAttempts) {
      return 0;
    }
    return Math.round(1000 * this.totalFreeThrowMade / this.totalFreeThrowAttempts) / 10;
  }

  set dunksMadePercent(v: number) {}

  get dunksMadePercent(): number {
    return !this.dunksMade || !this.dunkAttempts ? 0 : Math.round(1000 * this.dunksMade / this.dunkAttempts) / 10;
  }

  get totalRebounds(): number {
    return Math.floor(10 * ((this.offensiveRebounds || 0) + (this.defensiveRebounds || 0))) / 10;
  }

  get wrestlerScrums(): number {
    return (this.wrestlerScrumsWon || 0)
      + (this.wrestlerScrumsLost || 0)
      + (this.wrestlerScrumsTie || 0)
      + (this.wrestlerScrumsCounterattack || 0);
  }

  get wrestlerScrumsWonPercent(): number {
    if (!this.wrestlerScrumsWon || !this.wrestlerScrums) {
      return 0;
    }
    return Math.round(1000 * this.wrestlerScrumsWon / this.wrestlerScrums) / 10;
  }

  get scrums(): number {
    return (this.scrumsWon || 0) + (this.scrumsLost || 0) + (this.scrumsTie || 0);
  }

  get scrumsWonPercent(): number {
    if (!this.scrumsWon || !this.scrums) {
      return 0;
    }
    return Math.round(1000 * this.scrumsWon / this.scrums) / 10;
  }

  get scrumsBallAdvantageTotal(): number {
    return (this.scrumsBallAdvantageWon || 0) + (this.scrumsBallAdvantageLost || 0) + (this.scrumsBallAdvantageTie || 0);
  }

  get scrumsBallAdvantageWonPercent(): number {
    if (!this.scrumsBallAdvantageWon || !this.scrumsBallAdvantageTotal) {
      return 0;
    }
    return Math.round(1000 * this.scrumsBallAdvantageWon / this.scrumsBallAdvantageTotal) / 10;
  }

  get totalScrums(): number {
    return (this.wrestlerScrums || 0) + (this.scrums || 0) + (this.scrumsBallAdvantageTotal || 0);
  }
}
