import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { GameService } from './game.service';
import { Game } from '../models/game';
import { GameUser } from '../models/game-user';
import { GameInvite } from '../models/game-invite';
import { TeamUser } from '../models/team-user';
import { addItemInArray, deleteItemFromArray, updateItemInArray } from '@shared/util/data';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { UserService } from '@core/services/user.service';
import { forkJoin } from 'rxjs';
import { GameLogBase } from '@core/models/game-log-base';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { MediaService } from '@core/services/media.service';
import { MediaItem } from '@core/models/media-item';
import { GameUserLimitations } from '@core/models/game-user-limitation';
import { TournamentService } from '@core/services/tournament.service';
import { TournamentTeamUser } from '@core/models/tournament-team-user';

@Injectable()
export class GameDetailService {
  protected _gameSubject = new BehaviorSubject<Game>(undefined);
  protected _gameUsersSubject = new BehaviorSubject<GameUser[]>(undefined);
  protected _gameInvitesSubject = new BehaviorSubject<GameInvite[]>(undefined);
  protected _gameLogsSubject = new BehaviorSubject<any>(undefined);
  protected _mediaSubject = new BehaviorSubject<MediaItem[]>(undefined);

  get game$() {
    return this._gameSubject.pipe(filter(item => item !== undefined));
  }

  get gameUsers$() {
    return this._gameUsersSubject.pipe(filter(item => item !== undefined));
  }

  get gameInvites$() {
    return this._gameInvitesSubject.pipe(filter(item => item !== undefined));
  }

  get gameLogs$(): Observable<any> {
    return this._gameLogsSubject.pipe(filter(item => item !== undefined));
  }

  get media$() {
    return this._mediaSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    protected gameService: GameService,
    protected centrifugoService: CentrifugoService,
    protected userService: UserService,
    protected mediaService: MediaService,
    protected tournamentService: TournamentService,
  ) {}

  initialize(gameId: number): Observable<any> {
    this.listenCentrifugoEvents(gameId);

    return forkJoin([
      this.getGame(gameId),
      this.getGameUsers(gameId),
      this.getGameLogs(gameId),
      this.getGameInvites(gameId),
      this.getGameMedia(gameId)
    ]);
  }

  dispose() {
    if (this._gameSubject.value !== undefined) {
      this.centrifugoService.unsubscribe(`game_${this._gameSubject.value.id}`);
      this._gameSubject.next(undefined);
      this._gameUsersSubject.next(undefined);
      this._gameInvitesSubject.next(undefined);
      this._gameLogsSubject.next(undefined);
    }
  }

  getGame(gameId): Observable<Game> {
    return this.gameService.getById(gameId).pipe(
      tap(game => this._gameSubject.next(game))
    );
  }

  updateGameCompetitorTeamScore(gameId: number, competitorTeamScore: number): Observable<Game> {
    return this.gameService.updateGameCompetitorTeamScore(gameId, competitorTeamScore).pipe(
      tap(game => this._gameSubject.next(game)),
    );
  }

  getTournamentTeamUsers(tournamentTeamId: number): Observable<TournamentTeamUser[]> {
    return this.tournamentService.getTeamUsers(tournamentTeamId);
  }

  getGameUsers(gameId: number): Observable<GameUser[]> {
    return this.gameService.getUsers(gameId).pipe(
      tap(gameUsers => this._gameUsersSubject.next(gameUsers))
    );
  }

  getGameInvites(gameId: number): Observable<GameInvite[]> {
    return this.gameService.getGameInvites(gameId).pipe(
      tap(gameInvites => this._gameInvitesSubject.next(gameInvites))
    );
  }

  getUsersLimitation(gameId: number): Observable<GameUserLimitations[]> {
    return this.gameService.getUsersLimitation(gameId);
  }

  getGameMedia(gameId: number): Observable<MediaItem[]> {
    return this.mediaService.getGameMedia(gameId).pipe(
      tap(media => this._mediaSubject.next(media))
    );
  }

  changeUsers(teamId: number, gameId: number, gameUsers: GameUser[]): Observable<GameUser[]> {
    return this.gameService.changeUsers(gameId, gameUsers).pipe(
      tap(users => {
        let currentUsers = this._gameUsersSubject.value.filter(u => u.teamUser.teamId !== teamId);
        for (const u of users) {
          currentUsers = addItemInArray(currentUsers, u);
        }
        this._gameUsersSubject.next(currentUsers);
      })
    );
  }

  sendInvite(gameId: number, teamUser: TeamUser): Observable<GameInvite> {
    return this.gameService.sendInvite(gameId, teamUser).pipe(
      tap(gameInvite => this._gameInvitesSubject.next(addItemInArray(this._gameInvitesSubject.value, gameInvite)))
    );
  }

  acceptInvite(inviteId: number): Observable<GameInvite> {
    return this.userService.acceptGameInvite(inviteId).pipe(
      tap(gameInvite => this._gameInvitesSubject.next(updateItemInArray(this._gameInvitesSubject.value, gameInvite)))
    );
  }

  declineInvite(inviteId: number): Observable<GameInvite> {
    return this.userService.declineGameInvite(inviteId).pipe(
      tap(gameInvite => this._gameInvitesSubject.next(updateItemInArray(this._gameInvitesSubject.value, gameInvite)))
    );
  }

  getGameLogs(gameId: number): Observable<GameLogBase[]> {
    return this.getGameService().getGameLogs(gameId).pipe(
      tap(gameLogs => this._gameLogsSubject.next(gameLogs))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<{team: any, competitorTeam: any}> {
    throw new Error('not implement');
  }

  protected updateGameScore() {
    throw new Error('not implement');
  }

  protected listenCentrifugoEvents(gameId) {
    this.centrifugoService.listen(`game_${gameId}`).subscribe(message => {
      switch (message['action']) {
        case 'GAME_UPDATED': {
          return this._gameSubject.next(Game.toFront(message['data']));
        }

        case 'GAME_CLOSED': {
          return this._gameSubject.next(Game.toFront(message['data']));
        }

        case 'GAME_ARCHIVED': {
          return this._gameSubject.next(Game.toFront(message['data']));
        }

        case 'GAME_SCORE_UPDATED': {
          const game = Object.assign(new Game(), this._gameSubject.value);
          game.teamScore = message['data']['team_score'];
          game.competitorTeamScore = message['data']['competitor_team_score'];
          game.scoreByPeriod = message['data']['score_by_period'];
          this._gameSubject.next(game);
          return;
        }

        case 'GAME_INVITE_SENT': {
          return this._gameInvitesSubject.next(addItemInArray(this._gameInvitesSubject.value, GameInvite.toFront(message['data'])));
        }

        case 'GAME_INVITE_DECLINED': {
          return this._gameInvitesSubject.next(updateItemInArray(this._gameInvitesSubject.value, GameInvite.toFront(message['data'])));
        }

        case 'GAME_USER_ADDED': {
          return this._gameUsersSubject.next(addItemInArray(this._gameUsersSubject.value, GameUser.toFront(message['data'])));
        }

        case 'GAME_LOG_ADDED': {
          return this._gameLogsSubject.next(addItemInArray(this._gameLogsSubject.value, this.gameLogToFront(message['data'])));
        }

        case 'GAME_LOG_UPDATED': {
          return this._gameLogsSubject.next(updateItemInArray(this._gameLogsSubject.value, this.gameLogToFront(message['data'])));
        }

        case 'GAME_LOG_DELETED': {
          return this._gameLogsSubject.next(deleteItemFromArray(this._gameLogsSubject.value, this.gameLogToFront(message['data'])));
        }
      }
    });
  }

  protected gameLogToFront(data: any) {
    throw new Error('not implement');
  }

  protected getGameService(): GameManagementHttpInterface {
    throw new Error('Not implement');
  }
}
