import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { SelectComponent } from './select.component';
import { OptionGroupComponent } from '@ui-kit/form/components/select/option-group.component';

@Component({
  selector: 'mtg-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent implements AfterViewInit, OnInit {
  @Input()
  value: any;
  @Input()
  selected: boolean;
  @HostBinding('class.hidden')
  hidden: boolean;

  constructor(
    private element: ElementRef,
    private selectComponent: SelectComponent,
    private cdr: ChangeDetectorRef,
    @Optional() private optionGroupComponent: OptionGroupComponent,
  ) {}

  ngOnInit(): void {
    this.selectComponent.search$.subscribe((search: string) => {
      this.hidden = search && this.viewValue.toLowerCase().indexOf(search.toLowerCase()) < 0;
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    if (this.optionGroupComponent) {
      this.optionGroupComponent.addOption(this);
    }
    this.selectComponent.addOption(this);
  }

  @HostBinding('class.active')
  get isActive() {
    if (this.selectComponent.multi) {
      return this.selectComponent.value.indexOf(this.value) > -1;
    } else {
      return this.selectComponent.value === this.value;
    }
  }

  @HostBinding('class.multi')
  get isMulti() {
    return this.selectComponent.multi;
  }

  @HostBinding('class.with-group')
  get withGroup() {
    return !!this.optionGroupComponent;
  }

  @HostListener('click')
  onClick() {
    this.selectComponent.changeValue(this.value);
  }

  get viewValue(): string {
    return (this.element.nativeElement.textContent || '').trim();
  }
}
