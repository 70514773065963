import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { LeagueTeamField } from '@core/models/league-team-field';


@Injectable()
export class OrgLeagueTeamFieldService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getTeamFieldList(leagueId: number, page: number, size: number): Observable<PaginatedResponse<LeagueTeamField[]>> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/league_team_fields/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: LeagueTeamField.toFront(response.body)
      })));
  }

  getTeamFieldById(teamFieldId: number): Observable<LeagueTeamField> {
    return this.httpClient.get(`/org/api/v1/league_team_fields/${teamFieldId}/`)
      .pipe(map(result => LeagueTeamField.toFront(result)));
  }

  createTeamField(leagueId: number, data: any): Observable<LeagueTeamField> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/league_team_fields/`, LeagueTeamField.toBack(data))
      .pipe(map(result => LeagueTeamField.toFront(result)));
  }

  updateTeamField(teamFieldId: number, data: any): Observable<LeagueTeamField> {
    return this.httpClient.patch(`/org/api/v1/league_team_fields/${teamFieldId}/`, LeagueTeamField.toBack(data))
      .pipe(map(result => LeagueTeamField.toFront(result)));
  }

  deleteTeamField(teamFieldId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_team_fields/${teamFieldId}/`);
  }
}
