import { Component, Inject } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { PwaPromptService } from '@shared/modules/pwa-prompt/pwa-prompt.service';
import { IPwaPromptSettings, PWA_SETTINGS } from '@shared/modules/pwa-prompt/settings';

@Component({
  selector: 'mtg-pwa-prompt',
  templateUrl: './pwa-prompt.component.html',
  styleUrls: ['./pwa-prompt.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ bottom: '-100px'}),
        animate('.5s ease-in', style({ bottom: 0 }))
      ]),
      transition(':leave', [
        style({ bottom: 0 }),
        animate('0.5s ease-out', style({ bottom: '-100px' }))
      ])
    ])
  ]
})
export class PwaPromptComponent {
  constructor(
    private pwaPromptService: PwaPromptService,
    @Inject(PWA_SETTINGS) private pwaSettings: IPwaPromptSettings,
  ) {}

  get appName(): string {
    return this.pwaSettings.appName;
  }

  get appLogo(): string {
    return this.pwaSettings.appLogoPath;
  }

  public closePwaPrompt(event) {
    event.stopPropagation();
    this.pwaPromptService.hidePrompt();
  }

  public startPwaInstallation() {
    this.pwaPromptService.installPwa().then();
  }
}
