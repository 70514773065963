import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MediaItem } from '@core/models/media-item';
import { map } from 'rxjs/operators';
import { Game } from '@core/models/game';

@Injectable()
export class MediaService {
  constructor(private httpClient: HttpClient) {}

  getMedia(mediaId: number): Observable<MediaItem> {
    return this.httpClient.get(`/api/v1/media/${mediaId}/`).pipe(
      map(result => MediaItem.toFront(result))
    );
  }

  getGameMediaById(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/game_media/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameMedia(gameId: number): Observable<MediaItem[]> {
    return this.httpClient.get(`/api/v1/tournament_game/${gameId}/media/`).pipe(
      map(result => MediaItem.toFront(result))
    );
  }

  createZip(mediaIds: number[]): Observable<any> {
    return this.httpClient
      .post(`/api/v1/media/zip/`, mediaIds, { responseType: 'blob' });
  }
}
