import { FormGroup } from '@angular/forms';

export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    control.markAsDirty();

    if (control instanceof FormGroup) {
      markFormGroupTouched(control);
    } else if (control.controls) {
      control.controls.forEach(c => {
        if (c instanceof FormGroup) {
          markFormGroupTouched(c);
        } else {
          c.markAsTouched();
          c.markAsDirty();
        }
      });
    }
  });
}
