import { ModelInstance, ToFrontHook, ToBackHook, BaseModel } from './util';
import { File } from './file';
import { Sport } from './sport';
import { User } from './user';
import { City } from './city';

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    sport: 'sport',
    logo: 'logo',
    team_photo: 'teamPhoto',
    team_uniform: 'teamUniform',
    owner: 'owner',
    city: 'city',
    tournament: 'tournament',
  },
  relation: {
    sport: Sport,
    logo: File,
    owner: User,
    city: City,
    teamPhoto: File,
    teamUniform: File
  }
})
export class LeagueTeamForm extends BaseModel {
  id: number;
  name: string;
  sport: Sport;
  logo: File;
  teamPhoto: File;
  teamUniform: File;
  owner: User;
  city: City;
  tournament: number;

  @ToFrontHook
  static toFront(data: any): any {
    return null;
  }

  @ToBackHook
  static toBack(team: LeagueTeamForm): any {
    return null;
  }
}
