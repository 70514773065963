import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Game, GameResultTypes } from '@core/models/game';
import { map } from 'rxjs/operators';
import { OrgGameService } from '@core/services/org-game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { WaterpoloGameStatistic } from '@core/models/waterpolo-game-statistic';
import { WaterpoloGameTeamStatistic } from '@core/models/waterpolo-game-team.statistic';
import { WaterpoloGameLog } from '@core/models/waterpolo-game-log';
import { WaterpoloGameConfig } from '@core/models/waterpolo-game-config';

@Injectable()
export class OrgWaterpoloGameService extends OrgGameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(id: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/waterpolo_game/${id}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/waterpolo_game/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/waterpolo_game/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(result))
      );
  }

  getGameUserStatistic(gameId: number): Observable<WaterpoloGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/waterpolo_game/${gameId}/user_statistic/`).pipe(
      map(result => WaterpoloGameStatistic.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<WaterpoloGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/waterpolo_game/${gameId}/team_statistic/`).pipe(
      map(result => WaterpoloGameTeamStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<WaterpoloGameLog[]> {
    return this.httpClient
      .get(`${this.apiUrl}/waterpolo_game/${gameId}/logs/`)
      .pipe(
        map(data => WaterpoloGameLog.toFront(data))
      );
  }

  saveGameLog(gameId: number, gameLog: WaterpoloGameLog): Observable<WaterpoloGameLog> {
    return this.httpClient
      .post(`${this.apiUrl}/waterpolo_game/${gameId}/logs/`, WaterpoloGameLog.toBack(gameLog))
      .pipe(
        map(data => WaterpoloGameLog.toFront(data))
      );
  }

  updateGameLog(gameLogId: number, data: any): Observable<WaterpoloGameLog> {
    return this.httpClient
      .patch(`${this.apiUrl}/waterpolo_game_log/${gameLogId}/`, WaterpoloGameLog.toBack(data))
      .pipe(
        map(result => WaterpoloGameLog.toFront(result))
      );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient
      .delete(`${this.apiUrl}/waterpolo_game_log/${gameLogId}/`);
  }

  getGameConfig(gameId: number): Observable<WaterpoloGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/waterpolo_game/${gameId}/config/`).pipe(
      map(result => WaterpoloGameConfig.toFront(result))
    );
  }

  updateGameConfig(gameId: number, data: any): Observable<WaterpoloGameConfig> {
    return this.httpClient.patch(`${this.apiUrl}/waterpolo_game/${gameId}/config/`, WaterpoloGameConfig.toBack(data)).pipe(
      map(result => WaterpoloGameConfig.toFront(result))
    );
  }

  closeGame(game: Game, resultType?: GameResultTypes): Observable<Game> {
    const payload = {};
    if (resultType) {
      payload['result_type'] = GameResultTypes[resultType];
    }
    return this.httpClient.post(`${this.apiUrl}/waterpolo_game/${game.id}/close/`, payload).pipe(
      map(result => Game.toFront(result))
    );
  }
}
