import { ModelInstance, ToBackHook, BaseModel } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    uid: 'uid',
    token: 'token',
    password: 'password',
    password_confirm: 'passwordConfirm',
  }
})
export class ChangePasswordModel extends BaseModel {
  uid: string;
  token: string;
  password: string;
  passwordConfirm: string;

  @ToBackHook
  static toBack(model: ChangePasswordModel): any {}
}
