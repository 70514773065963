import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef } from './dialog-ref';
import { DialogConfig } from './dialog-config';
import { Router } from '@angular/router';

@Component({
  selector: 'mtg-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @ViewChild('host', {read: ViewContainerRef, static: true})
  private _viewContainerRef: ViewContainerRef;

  @ViewChild('container')
  public containerElementRef: ElementRef;

  @ViewChild('content')
  public contentElementRef: ElementRef;

  constructor(
    private router: Router,
    private dialogRef: DialogRef<DialogComponent>,
    private dialogConfig: DialogConfig
  ) {}

  ngOnInit(): void {
    if (this.dialogConfig.closeOnEsc) {
      this.dialogRef.getOverlayRef().keydownEvents().subscribe(event => {
        if (event.code === 'Escape') {
          this.close();
        }
      });
    }
  }

  attachView(view) {
    this._viewContainerRef.detach();
    return this._viewContainerRef
      .insert(view);
  }

  get isBackButtonVisible() {
    return this.dialogConfig.isBackButtonVisible;
  }

  get isCloseButtonVisible() {
    return this.dialogConfig.isCloseButtonVisible;
  }

  get isFullScreen() {
    return this.dialogConfig.fullScreen;
  }

  backdropClicked($event) {
    if (!this.dialogConfig.ignoreBackdropClick) {
      this.close();
    }
  }

  close() {
    this.dialogRef.beforeClose().subscribe(res => {
      if (!res) {
        return;
      }

      if (this.dialogConfig.closeLink) {
        this.router.navigateByUrl(this.dialogConfig.closeLink);
      } else {
        this.dialogRef.close();
      }
    }, error => {});
  }
}
