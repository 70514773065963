import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'mtg-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input()
  title: string;
  @Input()
  badge: number|string;
  @Input()
  opened: boolean;
  @Input()
  expansion: boolean;
  @HostBinding('class.panel')
  defaultClass = true;
  @Output()
  stateChanged = new EventEmitter();

  toggle() {
    this.opened = !this.opened;
    this.stateChanged.emit(this.opened);
  }
}
