import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, MomentDateField, enumField } from './util';
import { TournamentTeamUser } from '@core/models/tournament-team-user';
import { Team } from '@core/models/team';
import { TeamUser } from '@core/models/team-user';
import * as moment from 'moment';
import { User } from '@core/models/user';
import { TournamentTeam } from '@core/models/tournament-team';


@ModelInstance({
  mappingFields: {
    tournament_team_user: 'tournamentTeamUser',
    team: 'team',
    team_user: 'teamUser',
    user: 'user',
    tournament_team: 'tournamentTeam',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    lose_games_count: 'loseGamesCount',
    points: 'points',
    pp_shot_misses: 'ppShotMisses',
    pp_shots_on_goal: 'ppShotsOnGoal',
    pp_shots_blocked: 'ppShotsBlocked',
    ev_shot_misses: 'evShotMisses',
    ev_shots_on_goal: 'evShotsOnGoal',
    ev_shots_blocked: 'evShotsBlocked',
    sh_shot_misses: 'shShotMisses',
    sh_shots_on_goal: 'shShotsOnGoal',
    sh_shots_blocked: 'shShotsBlocked',
    pp_total_shots_on_goal: 'ppTotalShotsOnGoal',
    ev_total_shots_on_goal: 'evTotalShotsOnGoal',
    sh_total_shots_on_goal: 'shTotalShotsOnGoal',
    pp_goals: 'ppGoals',
    ev_goals: 'evGoals',
    sh_goals: 'shGoals',
    pp_counterattack_goals: 'ppCounterAttackGoals',
    pp_counterattack_on_goals: 'ppCounterAttackOnGoals',
    pp_counterattack_misses: 'ppCounterAttackMisses',
    pp_counterattack_shots_blocked: 'ppCounterAttackShotsBlocked',
    ev_counterattack_goals: 'evCounterAttackGoals',
    ev_counterattack_on_goals: 'evCounterAttackOnGoals',
    ev_counterattack_misses: 'evCounterAttackMisses',
    ev_counterattack_shots_blocked: 'evCounterAttackShotsBlocked',
    sh_counterattack_goals: 'shCounterAttackGoals',
    sh_counterattack_on_goals: 'shCounterAttackOnGoals',
    sh_counterattack_misses: 'shCounterAttackMisses',
    sh_counterattack_shots_blocked: 'shCounterAttackShotsBlocked',
    pp_maneuver_goals: 'ppManeuverGoals',
    pp_maneuver_on_goals: 'ppManeuverOnGoals',
    pp_maneuver_misses: 'ppManeuverMisses',
    pp_maneuver_shots_blocked: 'ppManeuverShotsBlocked',
    ev_maneuver_goals: 'evManeuverGoals',
    ev_maneuver_on_goals: 'evManeuverOnGoals',
    ev_maneuver_misses: 'evManeuverMisses',
    ev_maneuver_shots_blocked: 'evManeuverShotsBlocked',
    sh_maneuver_goals: 'shManeuverGoals',
    sh_maneuver_on_goals: 'shManeuverOnGoals',
    sh_maneuver_misses: 'shManeuverMisses',
    sh_maneuver_shots_blocked: 'shManeuverShotsBlocked',
    pp_center_forward_goals: 'ppCenterForwardGoals',
    pp_center_forward_on_goals: 'ppCenterForwardOnGoals',
    pp_center_forward_misses: 'ppCenterForwardMisses',
    pp_center_forward_shots_blocked: 'ppCenterForwardShotsBlocked',
    ev_center_forward_goals: 'evCenterForwardGoals',
    ev_center_forward_on_goals: 'evCenterForwardOnGoals',
    ev_center_forward_misses: 'evCenterForwardMisses',
    ev_center_forward_shots_blocked: 'evCenterForwardShotsBlocked',
    sh_center_forward_goals: 'shCenterForwardGoals',
    sh_center_forward_on_goals: 'shCenterForwardOnGoals',
    sh_center_forward_misses: 'shCenterForwardMisses',
    sh_center_forward_shots_blocked: 'shCenterForwardShotsBlocked',
    pp_assists: 'ppAssists',
    ev_assists: 'evAssists',
    sh_assists: 'shAssists',
    shootout_attempts: 'shootoutAttempts',
    shootout_goals: 'shootoutGoals',
    after_game_shootout_attempts: 'afterGameShootoutAttempts',
    after_game_shootout_goals: 'afterGameShootoutGoals',
    block_shots: 'blockShots',
    face_off_losses: 'faceOffLosses',
    face_off_wins: 'faceOffWins',
    pp_saves: 'ppSaves',
    sh_saves: 'shSaves',
    ev_saves: 'evSaves',
    pp_counterattack_saves: 'ppCounterAttackSaves',
    sh_counterattack_saves: 'shCounterAttackSaves',
    ev_counterattack_saves: 'evCounterAttackSaves',
    pp_maneuver_saves: 'ppManeuverSaves',
    sh_maneuver_saves: 'shManeuverSaves',
    ev_maneuver_saves: 'evManeuverSaves',
    pp_center_forward_saves: 'ppCenterForwardSaves',
    sh_center_forward_saves: 'shCenterForwardSaves',
    ev_center_forward_saves: 'evCenterForwardSaves',
    pp_free_kick_saves: 'ppFreeKickSaves',
    sh_free_kick_saves: 'shFreeKickSaves',
    ev_free_kick_saves: 'evFreeKickSaves',
    pp_corner_saves: 'ppCornerSaves',
    sh_corner_saves: 'shCornerSaves',
    ev_corner_saves: 'evCornerSaves',
    pp_total_game_saves: 'ppTotalGameSaves',
    sh_total_game_saves: 'shTotalGameSaves',
    ev_total_game_saves: 'evTotalGameSaves',
    shootout_saves: 'shootoutSaves',
    after_game_shootout_saves: 'afterGameShootoutSaves',
    pp_goals_against: 'ppGoalsAgainst',
    sh_goals_against: 'shGoalsAgainst',
    ev_goals_against: 'evGoalsAgainst',
    goals_against: 'goalsAgainst',
    pp_counterattack_goals_against: 'ppCounterAttackGoalsAgainst',
    sh_counterattack_goals_against: 'shCounterAttackGoalsAgainst',
    ev_counterattack_goals_against: 'evCounterAttackGoalsAgainst',
    counterattack_goals_against: 'counterAttackGoalsAgainst',
    pp_maneuver_goals_against: 'ppManeuverGoalsAgainst',
    sh_maneuver_goals_against: 'shManeuverGoalsAgainst',
    ev_maneuver_goals_against: 'evManeuverGoalsAgainst',
    maneuver_goals_against: 'maneuverGoalsAgainst',
    pp_center_forward_goals_against: 'ppCenterForwardGoalsAgainst',
    sh_center_forward_goals_against: 'shCenterForwardGoalsAgainst',
    ev_center_forward_goals_against: 'evCenterForwardGoalsAgainst',
    center_forward_goals_against: 'centerForwardGoalsAgainst',
    pp_free_kick_goals_against: 'ppFreeKickGoalsAgainst',
    sh_free_kick_goals_against: 'shFreeKickGoalsAgainst',
    ev_free_kick_goals_against: 'evFreeKickGoalsAgainst',
    free_kick_goals_against: 'freeKickGoalsAgainst',
    pp_corner_goals_against: 'ppCornerGoalsAgainst',
    sh_corner_goals_against: 'shCornerGoalsAgainst',
    ev_corner_goals_against: 'evCornerGoalsAgainst',
    corner_goals_against: 'cornerGoalsAgainst',
    shootout_goals_against: 'shootoutGoalsAgainst',
    after_game_shootout_goals_against: 'afterGameShootoutGoalsAgainst',
    safety_rate: 'safetyRate',
    penalty_minutes: 'penaltyMinutes',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    fouls: 'fouls',
    minor_penalties: 'minorPenalties',
    major_penalties: 'majorPenalties',
    match_penalties: 'matchPenalties',
    penalty_fouls: 'penaltyFouls',
    turnovers: 'turnovers',
    attack_fouls: 'attackFouls',
    personal_fouls: 'personalFouls',
    drawn_fouls: 'drawnFouls',
    drawn_turnovers: 'drawnTurnovers',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    losses: 'losses',
    steals: 'steals',
    pp_free_kick_goals: 'ppFreeKickGoals',
    pp_free_kick_on_goals: 'ppFreeKickOnGoals',
    pp_free_kick_misses: 'ppFreeKickMisses',
    pp_free_kick_shots_blocked: 'ppFreeKickShotsBlocked',
    sh_free_kick_goals: 'shFreeKickGoals',
    sh_free_kick_on_goals: 'shFreeKickOnGoals',
    sh_free_kick_misses: 'shFreeKickMisses',
    sh_free_kick_shots_blocked: 'shFreeKickShotsBlocked',
    ev_free_kick_goals: 'evFreeKickGoals',
    ev_free_kick_on_goals: 'evFreeKickOnGoals',
    ev_free_kick_misses: 'evFreeKickMisses',
    ev_free_kick_shots_blocked: 'evFreeKickShotsBlocked',
    free_kick_passes: 'freeKickPasses',
    free_kick_selfs: 'freeKickSelfs',
    pp_corner_goals: 'ppCornerGoals',
    pp_corner_on_goals: 'ppCornerOnGoals',
    pp_corner_misses: 'ppCornerMisses',
    pp_corner_shots_blocked: 'ppCornerShotsBlocked',
    sh_corner_goals: 'shCornerGoals',
    sh_corner_on_goals: 'shCornerOnGoals',
    sh_corner_misses: 'shCornerMisses',
    sh_corner_shots_blocked: 'shCornerShotsBlocked',
    ev_corner_goals: 'evCornerGoals',
    ev_corner_on_goals: 'evCornerOnGoals',
    ev_corner_misses: 'evCornerMisses',
    ev_corner_shots_blocked: 'evCornerShotsBlocked',
    corner_passes: 'cornerPasses',
    corner_selfs: 'cornerSelfs',
    tackles: 'tackles',
    player_efficiency: 'playerEfficiency',

    pp_shots: 'ppShots',
    ev_shots: 'evShots',
    sh_shots: 'shShots',
    pp_goals_percent: 'ppGoalsPercent',
    sh_goals_percent: 'shGoalsPercent',
    ev_goals_percent: 'evGoalsPercent',
    total_pp_goals: 'totalPpGoals',
    total_sh_goals: 'totalShGoals',
    total_ev_goals: 'totalEvGoals',
    goals: 'goals',
    total_goals: 'totalGoals',
    position_attack_goals: 'positionAttackGoals',
    position_attack_shots_on_goal: 'positionAttackShotsOnGoal',
    position_attack_shots_miss: 'positionAttackShotsMiss',
    position_attack_shots: 'positionAttackShots',
    position_attack_goals_percent: 'positionAttackGoalsPercent',
    total_counterattack_goals: 'totalCounterAttackGoals',
    counterattack_shot_on_goals: 'counterAttackShotOnGoals',
    counterattack_shot_misses: 'counterAttackShotMisses',
    counterattack_shots: 'counterAttackShots',
    counterattack_goals_percent: 'counterAttackGoalsPercent',
    total_maneuver_goals: 'totalManeuverGoals',
    maneuver_shot_misses: 'maneuverShotMisses',
    maneuver_shot_on_goals: 'maneuverShotOnGoals',
    maneuver_shots: 'maneuverShots',
    maneuver_goals_percent: 'maneuverGoalsPercent',
    total_center_forward_goals: 'totalCenterForwardGoals',
    center_forward_shot_on_goals: 'centerForwardShotOnGoals',
    center_forward_shot_misses: 'centerForwardShotMisses',
    center_forward_shots: 'centerForwardShots',
    center_forward_goals_percent: 'centerForwardGoalsPercent',
    total_free_kick_goals: 'totalFreeKickGoals',
    free_kick_shot_misses: 'freeKickShotMisses',
    free_kick_on_goals: 'freeKickOnGoals',
    total_free_kicks: 'totalFreeKicks',
    free_kick_goals_percent: 'freeKickGoalsPercent',
    total_corner_goals: 'totalCornerGoals',
    total_corners: 'totalCorners',
    total_in_game_goals: 'totalInGameGoals',
    in_game_shot_misses: 'inGameShotMisses',
    total_pp_shot_misses: 'totalPpShotMisses',
    shot_misses: 'shotMisses',
    total_pp_shots_on_goal: 'totalPpShotsOnGoal',
    in_game_shots_on_goals: 'inGameShotsOnGoals',
    shots_on_goal: 'shotsOnGoal',
    shots_blocked: 'shotsBlocked',
    shots: 'shots',
    shots_on_goal_percent: 'shotsOnGoalPercent',
    total_shots: 'totalShots',
    goals_percent: 'goalsPercent',
    total_goals_percent: 'totalGoalsPercent',
    face_offs: 'faceOffs',
    face_off_percent: 'faceOffPercent',
    total_fouls: 'totalFouls',
    total_penalties: 'totalPenalties',
    shootout_shots: 'shootoutShots',
    shootout_goals_percent: 'shootoutGoalsPercent',

    /*goalie stat*/
    position_attack_saves: 'positionAttackSaves',
    position_attack_shots_against: 'positionAttackShotsAgainst',
    position_attack_saves_percent: 'positionAttackSavesPercent',
    counterattack_saves: 'counterAttackSaves',
    counterattack_shots_against: 'counterAttackShotsAgainst',
    counterattack_saves_percent: 'counterAttackSavesPercent',
    center_forward_saves: 'centerForwardSaves',
    center_forward_shots_against: 'centerForwardShotsAgainst',
    center_forward_saves_percent: 'centerForwardSavesPercent',
    maneuver_saves: 'maneuverSaves',
    maneuver_shots_against: 'maneuverShotsAgainst',
    maneuver_saves_percent: 'maneuverSavesPercent',
    free_kick_saves: 'freeKickSaves',
    free_kick_shots_against: 'freeKickShotsAgainst',
    free_kick_saves_percent: 'freeKickSavesPercent',
    corner_saves: 'cornerSaves',
    corner_shots_against: 'cornerShotsAgainst',
    corner_saves_percent: 'cornerSavesPercent',
    in_game_saves: 'inGameSaves',
    saves: 'saves',
    total_saves: 'totalSaves',
    shootout_shots_against: 'shootoutShotsAgainst',
    shootout_saves_percent: 'shootoutSavesPercent',
    game_shots_against: 'gameShotsAgainst',
    saves_percent: 'savesPercent',
    total_shots_against: 'totalShotsAgainst',
    total_saves_percent: 'totalSavesPercent',
    in_game_goals_against: 'inGameGoalsAgainst',
    total_goals_against: 'totalGoalsAgainst',
    total_sh_saves: 'totalShSaves',
    total_sh_goals_against: 'totalShGoalsAgainst',
    total_sh_shots_against: 'totalShShotsAgainst',
    total_sh_saves_percent: 'totalShSavesPercent',
    total_ev_saves: 'totalEvSaves',
    total_ev_goals_against: 'totalEvGoalsAgainst',
    total_ev_shots_against: 'totalEvShotsAgainst',
    total_ev_saves_percent: 'totalEvSavesPercent',
    newbie: 'newbie',
    rank: 'rank',
  },
  relation: {
    tournamentTeamUser: TournamentTeamUser,
    team: Team,
    teamUser: TeamUser,
    user: User,
    tournamentTeam: TournamentTeam,
    month: MomentDateField
  }
})
export class WaterpoloStatistic extends BaseModel {
  tournamentTeamUser: TournamentTeamUser;
  team: Team;
  teamUser: TeamUser;
  user: User;
  tournamentTeam: TournamentTeam;
  month: moment.Moment;
  winLose: boolean;
  gamesCount: number;
  wonGamesCount: number;
  loseGamesCount: number;
  points: number;
  ppShotMisses: number;
  ppShotsOnGoal: number;
  ppShotsBlocked: number;
  evShotMisses: number;
  evShotsOnGoal: number;
  evShotsBlocked: number;
  shShotMisses: number;
  shShotsOnGoal: number;
  shShotsBlocked: number;
  ppTotalShotsOnGoal: number;
  evTotalShotsOnGoal: number;
  shTotalShotsOnGoal: number;
  ppGoals: number;
  evGoals: number;
  shGoals: number;
  ppCounterAttackGoals: number;
  ppCounterAttackOnGoals: number;
  ppCounterAttackMisses: number;
  ppCounterAttackShotsBlocked: number;
  evCounterAttackGoals: number;
  evCounterAttackOnGoals: number;
  evCounterAttackMisses: number;
  evCounterAttackShotsBlocked: number;
  shCounterAttackGoals: number;
  shCounterAttackOnGoals: number;
  shCounterAttackMisses: number;
  shCounterAttackShotsBlocked: number;
  ppManeuverGoals: number;
  ppManeuverOnGoals: number;
  ppManeuverMisses: number;
  ppManeuverShotsBlocked: number;
  evManeuverGoals: number;
  evManeuverOnGoals: number;
  evManeuverMisses: number;
  evManeuverShotsBlocked: number;
  shManeuverGoals: number;
  shManeuverOnGoals: number;
  shManeuverMisses: number;
  shManeuverShotsBlocked: number;
  ppCenterForwardGoals: number;
  ppCenterForwardOnGoals: number;
  ppCenterForwardMisses: number;
  ppCenterForwardShotsBlocked: number;
  evCenterForwardGoals: number;
  evCenterForwardOnGoals: number;
  evCenterForwardMisses: number;
  evCenterForwardShotsBlocked: number;
  shCenterForwardGoals: number;
  shCenterForwardOnGoals: number;
  shCenterForwardMisses: number;
  shCenterForwardShotsBlocked: number;
  ppAssists: number;
  evAssists: number;
  shAssists: number;
  shootoutAttempts: number;
  shootoutGoals: number;
  afterGameShootoutAttempts: number;
  afterGameShootoutGoals: number;
  blockShots: number;
  faceOffLosses: number;
  faceOffWins: number;
  ppSaves: number;
  shSaves: number;
  evSaves: number;
  ppCounterAttackSaves: number;
  shCounterAttackSaves: number;
  evCounterAttackSaves: number;
  ppManeuverSaves: number;
  shManeuverSaves: number;
  evManeuverSaves: number;
  ppCenterForwardSaves: number;
  shCenterForwardSaves: number;
  evCenterForwardSaves: number;
  ppFreeKickSaves: number;
  shFreeKickSaves: number;
  evFreeKickSaves: number;
  ppCornerSaves: number;
  shCornerSaves: number;
  evCornerSaves: number;
  ppTotalGameSaves: number;
  shTotalGameSaves: number;
  evTotalGameSaves: number;
  shootoutSaves: number;
  afterGameShootoutSaves: number;
  ppGoalsAgainst: number;
  shGoalsAgainst: number;
  evGoalsAgainst: number;
  goalsAgainst: number;
  ppCounterAttackGoalsAgainst: number;
  shCounterAttackGoalsAgainst: number;
  evCounterAttackGoalsAgainst: number;
  counterAttackGoalsAgainst: number;
  ppManeuverGoalsAgainst: number;
  shManeuverGoalsAgainst: number;
  evManeuverGoalsAgainst: number;
  maneuverGoalsAgainst: number;
  ppCenterForwardGoalsAgainst: number;
  shCenterForwardGoalsAgainst: number;
  evCenterForwardGoalsAgainst: number;
  centerForwardGoalsAgainst: number;
  ppFreeKickGoalsAgainst: number;
  shFreeKickGoalsAgainst: number;
  evFreeKickGoalsAgainst: number;
  freeKickGoalsAgainst: number;
  ppCornerGoalsAgainst: number;
  shCornerGoalsAgainst: number;
  evCornerGoalsAgainst: number;
  cornerGoalsAgainst: number;
  shootoutGoalsAgainst: number;
  afterGameShootoutGoalsAgainst: number;
  safetyRate: number;
  penaltyMinutes: number;
  gameTime: number;
  plusMinus: number;
  fouls: number;
  minorPenalties: number;
  majorPenalties: number;
  matchPenalties: number;
  penaltyFouls: number;
  turnovers: number;
  attackFouls: number;
  personalFouls: number;
  drawnFouls: number;
  drawnTurnovers: number;
  yellowCards: number;
  redCards: number;
  losses: number;
  steals: number;
  ppFreeKickGoals: number;
  ppFreeKickOnGoals: number;
  ppFreeKickMisses: number;
  ppFreeKickShotsBlocked: number;
  shFreeKickGoals: number;
  shFreeKickOnGoals: number;
  shFreeKickMisses: number;
  shFreeKickShotsBlocked: number;
  evFreeKickGoals: number;
  evFreeKickOnGoals: number;
  evFreeKickMisses: number;
  evFreeKickShotsBlocked: number;
  freeKickPasses: number;
  freeKickSelfs: number;
  ppCornerGoals: number;
  ppCornerOnGoals: number;
  ppCornerMisses: number;
  ppCornerShotsBlocked: number;
  shCornerGoals: number;
  shCornerOnGoals: number;
  shCornerMisses: number;
  shCornerShotsBlocked: number;
  evCornerGoals: number;
  evCornerOnGoals: number;
  evCornerMisses: number;
  evCornerShotsBlocked: number;
  cornerPasses: number;
  cornerSelfs: number;
  tackles: number;
  playerEfficiency: number;

  /*additional statistic*/
  ppShots: number;
  shShots: number;
  evShots: number;
  ppGoalsPercent: number;
  shGoalsPercent: number;
  evGoalsPercent: number;
  totalPpGoals: number;
  totalShGoals: number;
  totalEvGoals: number;
  goals: number;
  totalGoals: number;
  positionAttackGoals: number;
  positionAttackShotsOnGoal: number;
  positionAttackShotsMiss: number;
  positionAttackShots: number;
  positionAttackGoalsPercent: number;
  totalCounterAttackGoals: number;
  counterAttackShotOnGoals: number;
  counterAttackShotMisses: number;
  counterAttackShots: number;
  counterAttackGoalsPercent: number;
  totalManeuverGoals: number;
  maneuverShotMisses: number;
  maneuverShotOnGoals: number;
  maneuverShots: number;
  maneuverGoalsPercent: number;
  totalCenterForwardGoals: number;
  centerForwardShotOnGoals: number;
  centerForwardShotMisses: number;
  centerForwardShots: number;
  centerForwardGoalsPercent: number;
  totalFreeKickGoals: number;
  freeKickShotMisses: number;
  freeKickOnGoals: number;
  totalFreeKicks: number;
  freeKickGoalsPercent: number;
  totalCornerGoals: number;
  totalCorners: number;
  totalInGameGoals: number;
  inGameShotMisses: number;
  totalPpShotMisses: number;
  shotMisses: number;
  totalPpShotsOnGoal: number;
  inGameShotsOnGoals: number;
  shotsOnGoal: number;
  shotsBlocked: number;
  shots: number;
  shotsOnGoalPercent: number;
  totalShots: number;
  goalsPercent: number;
  totalGoalsPercent: number;
  faceOffs: number;
  faceOffPercent: number;
  totalFouls: number;
  totalPenalties: number;
  shootoutShots: number;
  shootoutGoalsPercent: number;

  /*goalie stat*/
  positionAttackSaves: number;
  positionAttackShotsAgainst: number;
  positionAttackSavesPercent: number;
  counterAttackSaves: number;
  counterAttackShotsAgainst: number;
  counterAttackSavesPercent: number;
  centerForwardSaves: number;
  centerForwardShotsAgainst: number;
  centerForwardSavesPercent: number;
  maneuverSaves: number;
  maneuverShotsAgainst: number;
  maneuverSavesPercent: number;
  freeKickSaves: number;
  freeKickShotsAgainst: number;
  freeKickSavesPercent: number;
  cornerSaves: number;
  cornerShotsAgainst: number;
  cornerSavesPercent: number;
  inGameSaves: number;
  saves: number;
  totalSaves: number;
  shootoutShotsAgainst: number;
  shootoutSavesPercent: number;
  gameShotsAgainst: number;
  savesPercent: number;
  totalShotsAgainst: number;
  totalSavesPercent: number;
  inGameGoalsAgainst: number;
  totalGoalsAgainst: number;
  totalShSaves: number;
  totalShGoalsAgainst: number;
  totalShShotsAgainst: number;
  totalShSavesPercent: number;
  totalEvSaves: number;
  totalEvGoalsAgainst: number;
  totalEvShotsAgainst: number;
  totalEvSavesPercent: number;

  rank: number;

  get assists(): number {
    return (this.ppAssists || 0) + (this.shAssists || 0) + (this.evAssists || 0);
  }

  get goalpass(): number {
    return (this.totalGoals || 0) + (this.assists || 0);
  }

  get userMinutes(): string {
    if (!this.gameTime) {
      return '00:00';
    }
    const minutes = Math.floor(this.gameTime / 60);
    const seconds = Math.floor(this.gameTime - minutes * 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  get penaltyTime(): string {
    if (!this.penaltyMinutes) {
      return '00:00';
    }
    const minutes = Math.floor(this.penaltyMinutes / 60);
    const seconds = Math.floor(this.penaltyMinutes - minutes * 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  get gamesWonPercent(): number {
    if (!this.gamesCount) {
      return 0;
    }
    return Math.floor(1000 * this.wonGamesCount / this.gamesCount) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
