import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalizationHttpInterceptor implements HttpInterceptor {
  constructor(
    private localizationService: LocalizationService,
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.set('x-locale', this.localizationService.getLocale())
    });
    return next.handle(req);
  }
}
