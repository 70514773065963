import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

@ModelInstance({
  mappingFields: {
    loyalty_programs_count: 'loyaltyProgramsCount',
    active_loyalty_programs_count: 'activeLoyaltyProgramsCount',
    total_games_count: 'totalGamesCount',
    closed_games_count: 'closedGamesCount',
  },
  relation: {}
})
export class LoyaltyProgramsStatistics extends BaseModel {
  loyaltyProgramsCount: number;
  activeLoyaltyProgramsCount: number;
  totalGamesCount: number;
  closedGamesCount: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
