import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthService } from '@core/services/auth.service';
import { User } from '@core/models/user';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { addItemInArray, deleteItemFromArray } from '@shared/util/data';
import { Team } from '@core/models/team';
import { UserService } from '@core/services/user.service';

@Injectable()
export class LayoutService {
  private _userSubject = new BehaviorSubject<User>(undefined);
  private _teamsSubject = new BehaviorSubject<Team[]>(undefined);

  get user$() {
    return this._userSubject.pipe(filter(item => item !== undefined));
  }

  get teams$() {
    return this._teamsSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private centrifugoService: CentrifugoService,
  ) {}

  initialize() {
    this.authService.user$.pipe(
      take(1),
      filter(user => !!user),
    ).subscribe(user => {
      this.listenCentrifugo(user.id);
    });

    return combineLatest([
      this.subscribeUser(),
      this.getTeams(),
    ]);
  }

  dispose() {
    if (this._userSubject.value) {
      this.centrifugoService.unsubscribe(`user_${this._userSubject.value.id}`);
    }
    this._userSubject.next(undefined);
    this._teamsSubject.next(undefined);
  }

  subscribeUser(): Observable<User> {
    return this.authService.user$.pipe(
      tap(user => {
        this._userSubject.next(user);
      }),
    );
  }

  getTeams(): Observable<Team[]> {
    return this.authService.user$.pipe(
      take(1),
      switchMap(user => this.userService.getTeams(user.id)),
      tap(teams => this._teamsSubject.next(teams))
    );
  }

  private listenCentrifugo(userId: number): void {
    this.centrifugoService.listen(`user_${userId}`).subscribe(message => {
      switch (message['action']) {
        case 'TEAM_USER_ADDED': {
          return this._teamsSubject.next(
            addItemInArray(this._teamsSubject.value, Team.toFront(message['data']))
          );
        }

        case 'TEAM_USER_DELETED': {
          return this._teamsSubject.next(
            deleteItemFromArray(this._teamsSubject.value, item => item.id === message['data']['id'])
          );
        }
      }
    });
  }
}
