import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suggestHighlight'
})
export class SuggestHighlightPipe implements PipeTransform {
  transform(value: any, positions: number[][]): any {
    const pre = '<span class="text-color">';
    const post = '</span>';
    let index = 0;
    for (const numbers of positions) {
      numbers[0] +=  index * (pre + post).length;
      numbers[1] += index * (pre + post).length;
      value = [
        value.slice(0, numbers[0]),
        pre,
        value.slice(numbers[0], numbers[1]),
        post,
        value.slice(numbers[1])
      ].join('');
      index++;
    }
    return value;
  }
}
