<a (click)="openGameRoute(firstGame.tournamentPlayoffId, firstGame.id)"  *ngIf="games.length > 0">
  <div class="game-flex" [ngClass]="{single: gameRound === 0 && (!firstGame.competitorTeam.id || !firstGame.team.id)}">
    <div class="game-number">{{ textGameNumber }}</div>
    <div class="game" [ngClass]="{final: isFinal(), closed: isClosed}">
      <div class="content">
        <div class="team" *ngIf="firstGame.tournamentTeam.id">
          <div class="logo">
            <img [src]="firstGame.tournamentTeam.logo.path" *ngIf="firstGame.tournamentTeam.logo.path">
            <div [class]="firstGame.team.getEmptyLogoClass()" *ngIf="!firstGame.tournamentTeam.logo.path"></div>
          </div>
          <div class="name">{{ firstGame.tournamentTeam.name }}</div>
        </div>
        <div class="team gray" *ngIf="!firstGame.tournamentTeam.id">{{ winnerTeam }}</div>
        <div class="information">
          <div *ngIf="lastGame.datetime">{{ lastGame.datetime | date: 'd MMM, HH:mm' }}</div>
          <div class="time" *ngIf="!lastGame.datetime">{{ localization | mtgTranslate: 'dateDefault' }}</div>
        </div>
        <div class="team" *ngIf="firstGame.competitorTournamentTeam.id">
          <div class="logo">
            <img [src]="firstGame.competitorTournamentTeam.logo.path" *ngIf="firstGame.competitorTournamentTeam.logo.path">
            <div [class]="firstGame.competitorTeam.getEmptyLogoClass()" *ngIf="!firstGame.competitorTournamentTeam.logo.path"></div>
          </div>
          <div class="name">{{ firstGame.competitorTournamentTeam.name }}</div>
        </div>
        <div class="team gray" *ngIf="!firstGame.competitorTournamentTeam.id">{{ winnerCompetitorTeam }}</div>
      </div>
      <ng-container *ngIf="games.length > 1">
        <div class="score" *ngIf="isClosed">
          <div class="value" [ngClass]="{highlight: teamWinnerId === firstGame.team.id}">{{ teamScore }}</div>
          <div class="value" [ngClass]="{highlight: teamWinnerId === firstGame.competitorTeam.id}">{{ competitorTeamScore }}</div>
        </div>
        <div class="score" *ngIf="!firstGame.isClosed" [ngClass]="{gray: !firstGame.teamScore && !firstGame.competitorTeamScore}">
          <div class="value highlight" *ngIf="gameRound === 0 && !firstGame.competitorTeam.id">-</div>
          <div class="value highlight" *ngIf="gameRound === 0 && !firstGame.team.id">-</div>
        </div>
        <div class="score" *ngIf="!isClosed && firstGame.isClosed">
          <div class="value">{{ teamScore }}</div>
          <div class="value">{{ competitorTeamScore }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="games.length <= 1">
        <div class="score" *ngIf="isClosed">
          <div class="value" [ngClass]="{highlight: teamWinnerId === firstGame.team.id}">{{ firstGame.teamScore }}</div>
          <div class="value" [ngClass]="{highlight: teamWinnerId === firstGame.competitorTeam.id}">{{ firstGame.competitorTeamScore }}</div>
        </div>
        <div class="score" *ngIf="!firstGame.isClosed" [ngClass]="{gray: !firstGame.teamScore && !firstGame.competitorTeamScore}">
          <div class="value highlight" *ngIf="gameRound === 0 && !firstGame.competitorTeam.id">-</div>
          <div class="value highlight" *ngIf="gameRound === 0 && !firstGame.team.id">-</div>
        </div>
        <div class="score" *ngIf="!isClosed && firstGame.isClosed">
          <div class="value">{{ firstGame.teamScore }}</div>
          <div class="value">{{ firstGame.competitorTeamScore }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</a>
<div class="game-flex" *ngIf="games.length === 0" [ngClass]="{final: isFinal()}">
  <div class="game-number">{{ textGameNumber }}</div>
  <div class="game">
    <div class="content disable" (click)="canManage && gameRound !== 0 && createGameForm()">
      <a href="javascript:;" class="btn btn-link game-form-link" *ngIf="canManage && gameRound === 0" (click)="createGameForm()">{{ localization | mtgTranslate: 'addGame' }}</a>
      <ng-container *ngIf="gameRound !== 0 || isLoserBracketGame">
        <div class="team gray">{{ winnerTeam }}</div>
        <div class="information gray">{{ localization | mtgTranslate: 'dateDefault' }}</div>
        <div class="team gray">{{ winnerCompetitorTeam }}</div>
      </ng-container>
    </div>
  </div>
</div>
