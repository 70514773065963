import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';
import { TeamUser } from './team-user';

@ModelInstance({
  mappingFields: {
    team_user: 'teamUser',
    games_count: 'gamesCount',
    points: 'points',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    steals: 'steals',
    personal_fouls: 'personalFouls',
  },
  relation: {
    teamUser: TeamUser,
  }
})
export class TeamUserStatistic extends BaseModel {
  teamUser: TeamUser;
  gamesCount: number;
  points: number;
  assists: number;
  blocks: number;
  rebounds: number;
  steals: number;
  personalFouls: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
