import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, enumField } from './util';
import { User } from './user';


export enum OrganizationStatuses {
  active = 1,
  blocked,
  not_confirmed
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    owner_id: 'ownerId',
    owner: 'owner',
    status: 'status',
    name: 'name',
    phone: 'phone',
    email: 'email',
  },
  relation: {
    owner: User,
    status: enumField(OrganizationStatuses)
  }
})
export class Organization extends BaseModel {
  id: number;
  ownerId: number;
  owner: User;
  status: OrganizationStatuses;
  name: string;
  phone: string;
  email: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
