import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { AuthService } from '@core/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mtg-entry-wizard',
  styleUrls: ['./entry-wizard.component.scss'],
  templateUrl: './entry-wizard.component.html'
})
export class EntryWizardComponent implements OnInit {
  currentSlide = 0;
  slides = [
    {
      title: 'Добро пожаловать!',
      text: ', мы рады приветствовать вас в сервисе MTGame. Давайте начнём наше знакомство с обещания: мы пообещаем, что сделаем вашу спортивную жизнь насыщенней, а вы - что будете снисходительно относиться к багам в нашем сервисе 😌',
      image: '/assets/wizards/entry/slide-1.png',
    },
    {
      title: 'Ваша персональная карточка игрока',
      text: 'Заполните профиль максимально полно. Это ваш первый шаг в мир серьёзного спорта, поэтому важно всё сделать правильно...',
      image: '/assets/wizards/entry//slide-2.png',
    },
    {
      title: 'С хорошей командой - успех найдет вас сам',
      text: 'Выберите броское название, сочное лого, соберите группу надежных товарищей и создайте свою команду. Ну, не обязательно в этом порядке...',
      image: '/assets/wizards/entry//slide-3.png',
    },
    {
      title: 'Победы и цифры, которые за ними стоят',
      text: 'Проводите тренировочные матчи и бросайте вызов другим командам. Определяйте зоны развития с помощью нашего инструмента по сбору статистики и принимайте правильные решения! По возможности...',
      image: '/assets/wizards/entry//slide-4.png',
    },
    {
      title: 'Коммуникации - это важно!',
      text: 'Вы можете собирать обратную связь с помощью опросов, анонимных и не очень. Это позволит вам принимать популярные в команде решения!',
      image: '/assets/wizards/entry//slide-5.png',
    },
    {
      title: 'Держите руку на пульсе',
      text: 'Система автоматических оповещений и E-mail рассылок поможет вам и вашей команде не пропустить ни одного значимого события. Ненавязчиво и своевременно.',
      image: '/assets/wizards/entry//slide-6.png',
    },
    {
      title: 'Дальше - больше!',
      text: 'Впереди нас ждёт много нового. Матчи с судьями, профессиональные тренировки, турниры, МИРОВОЕ ГОСПОДСТВО! Кхм..увлеклись... Как бы там ни было, мы надеемся пройти этот не самый короткий путь вместе с вами. Так что вперёд! К новым горизонтам!',
      image: '/assets/wizards/entry//slide-7.png',
    }
  ];

  constructor(
    private dialogRef: DialogRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    for (const slide of this.slides) {
      this.preloadImage(slide.image);
    }

    this.authService.user$.pipe(take(1)).subscribe(user => {
      this.slides[0]['text'] = user.firstName + this.slides[0]['text'];
    });
  }

  get activeSlide() {
    return this.slides[this.currentSlide];
  }

  firstSlideSelected(): boolean {
    return this.currentSlide === 0;
  }

  lastSlideSelected(): boolean {
    return this.currentSlide === this.slides.length - 1;
  }

  selectNextSlide() {
    if (this.lastSlideSelected()) {
      return;
    }
    this.currentSlide++;
  }

  selectPreviousSlide() {
    if (this.firstSlideSelected()) {
      return;
    }
    this.currentSlide--;
  }

  closeWizard() {
   this.dialogRef.close();
  }

  private preloadImage(url: string) {
    const img = new Image();
    img.src = url;
  }
}
