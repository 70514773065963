import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from '@core/models/util';
import { BasketballStatistic } from '@core/models/basketball-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: BasketballStatistic,
    competitorTeam: BasketballStatistic
  }
})
export class BasketballGameTeamStatistic extends BaseModel {
  team: BasketballStatistic;
  competitorTeam: BasketballStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
