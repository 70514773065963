<div *ngIf="!feedbackSent" class="b-feedback-form panel">
  <div class="h3">{{ localization.title }}</div>
  <form [formGroup]="form">
    <div class="field">
      <mtg-select
        [required]="true"
        [placeholder]="localization.subject"
        formControlName="theme"
      >
        <mtg-option *ngFor="let theme of FEEDBACK_TYPES" [value]="theme">
          {{ theme }}
        </mtg-option>
      </mtg-select>
    </div>
    <div class="field">
      <mtg-textarea
        [required]="true"
        formControlName="message"
        [label]="localization.message"
      ></mtg-textarea>
    </div>
    <div class="field" *ngIf="form.get('email').enabled">
      <mtg-input
        formControlName="email"
        label="Email"
        [required]="true"
      ></mtg-input>
    </div>
    <div class="field" *ngIf="form.get('captcha').enabled">
      <mtg-recaptcha
        formControlName="captcha"
        *ngIf="form.get('captcha').enabled"
      ></mtg-recaptcha>
    </div>
    <div class="field">
      <mtg-file-input formControlName="files"></mtg-file-input>
    </div>
    <footer>
      <button mtgButton="primary" (click)="sendFeedback()">
        {{ localization.save }}
      </button>
    </footer>
  </form>
</div>

<div class="succeed" *ngIf="feedbackSent">
  <div class="icon"><i class="icon-ok"></i></div>
  <div class="title">{{ localization.successTitle }}</div>
  <div class="text-muted" *ngIf="!form.get('email').enabled">
    {{ localization.successDesc }}
  </div>
  <a class="btn-link text-muted" (click)="close()">{{ localization.main }}</a>
</div>

