import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { WorkHand } from '@core/models/basketball-profile';

export enum GameHandballPosition {
  goalkeeper = 1,
  winger,
  pivot,
  centre_back,
  fullback
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    position: 'position',
    work_hand: 'workHand'
  },
  relation: {
    position: enumField(GameHandballPosition),
    workHand: enumField(WorkHand)
  }
})
export class HandballProfile extends BaseModel {
  id: number;
  userId: number;
  position: GameHandballPosition;
  workHand: WorkHand;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
