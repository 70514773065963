import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { File } from '@core/models/file';
import { User } from '@core/models/user';
import { Observable } from 'rxjs/internal/Observable';
import { UserProfile } from '@core/models/user-profile';
import { Team } from '@core/models/team';
import { TeamInvite } from '@core/models/team-invite';
import { UserStatistic } from '@core/models/user-statistic';
import { Game } from '@core/models/game';
import { GameInvite } from '@core/models/game-invite';
import { TeamEvent } from '@core/models/team-event';
import { UserPermission } from '@core/models/user-permission';
import { UserAccess } from '@core/models/user-access';
import { BasketballProfile } from '@core/models/basketball-profile';
import { VolleyballProfile } from '@core/models/volleyball-profile';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';
import { AuthService } from '@core/services/auth.service';
import { HockeyProfile } from '@core/models/hockey-profile';
import { FootballProfile } from '@core/models/football-profile';
import { FootballStatistic } from '@core/models/football-statistic';
import { HandballProfile } from '@core/models/handball-profile';
import { HandballStatistic } from '@core/models/handball-statistic';
import { RugbyProfile } from '@core/models/rugby-profile';
import { RugbyStatistic } from '@core/models/rugby-statistic';
import { StatisticFilters } from '@core/services/tournament.service';
import { WaterpoloProfile } from '@core/models/waterpolo-profile';
import { WaterpoloStatistic } from '@core/models/waterpolo-statistic';
import { HockeyStatistic } from '@core/models/hockey-statistic';
import { IRsvLogin } from '@shared/modules/authorization/models/social-auth';

@Injectable()
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  getCurrentUser(): Observable<User> {
    return this.httpClient
      .get('/api/v1/user/current/')
      .pipe(
        map(data => User.toFront(data))
      );
  }

  getUserById(userId: number): Observable<User> {
    return this.httpClient
      .get(`/api/v1/user/${userId}/`)
      .pipe(
        map(data => User.toFront(data))
      );
  }

  getAccess(userId: number): Observable<UserAccess> {
    return this.httpClient.get(`/api/v1/user/${userId || 'current'}/access/`).pipe(
      map(data => UserAccess.toFront(data))
    );
  }

  updateUser(user: User): Observable<User> {
    return this.httpClient
      .patch(`/api/v1/user/${user.id}/`, User.toBack(user))
      .pipe(
        map(data => User.toFront(data))
      );
  }

  updateUserPhoto(file: any, filename?: string): Observable<File> {
    const formData = new FormData();
    formData.append('file', file, filename);
    return this.httpClient
      .post(`/api/v1/user/current/photo/`, formData)
      .pipe(
        map(data => File.toFront(data))
      );
  }

  changePassword(userId: number, oldPassword: string, password: string, passwordConfirm: string): Observable<any> {
    const payload = {
      old_password: oldPassword,
      password: password,
      password_confirm: passwordConfirm
    };
    return this.httpClient
      .post(`/api/v1/user/${userId}/change_password/`, payload);
  }

  changeEmail(userId: number, email: string, password: string): Observable<any> {
    const payload = {email, password};
    return this.httpClient
      .post(`/api/v1/user/${userId}/change_email/`, payload);
  }

  changeEmailConfirm(userId, email: string, password: string, token: number): Observable<any> {
    const payload = {email, password, token};
    return this.httpClient
      .post(`/api/v1/user/${userId}/change_email_confirm/`, payload)
      .pipe(
        tap(user => this.authService.user$.next(user))
      );
  }

  getUserProfile(userId: number): Observable<UserProfile> {
    return this.httpClient
      .get(`/api/v1/user/${userId}/profile/`)
      .pipe(
        map(data => UserProfile.toFront(data))
      );
  }

  getBasketballProfile(userId: number): Observable<BasketballProfile> {

    return this.httpClient
        .get(`/api/v1/user/${userId}/basketball_profile/`)
        .pipe(
            map(data => BasketballProfile.toFront(data))
        );
  }

  getVolleyballProfile(userId: number): Observable<VolleyballProfile> {

    return this.httpClient
        .get(`/api/v1/user/${userId}/volleyball_profile/`)
        .pipe(
            map(data => VolleyballProfile.toFront(data))
        );
  }

  getHockeyProfile(userId: number): Observable<HockeyProfile> {

    return this.httpClient
      .get(`/api/v1/user/${userId}/hockey_profile/`)
      .pipe(
        map(data => HockeyProfile.toFront(data))
      );
  }

  getHandballProfile(userId: number): Observable<HandballProfile> {

    return this.httpClient
      .get(`/api/v1/user/${userId}/handball_profile/`)
      .pipe(
        map(data => HockeyProfile.toFront(data))
      );
  }

  getFootballProfile(userId: number): Observable<FootballProfile> {

    return this.httpClient
      .get(`/api/v1/user/${userId}/football_profile/`)
      .pipe(
        map(data => FootballProfile.toFront(data))
      );
  }

  getWaterpoloProfile(userId: number): Observable<WaterpoloProfile> {

    return this.httpClient
      .get(`/api/v1/user/${userId}/waterpolo_profile/`)
      .pipe(
        map(data => FootballProfile.toFront(data))
      );
  }

  updateUserProfile(userId: number, userProfile: UserProfile): Observable<UserProfile> {
    return this.httpClient
      .patch(`/api/v1/user/${userId}/profile/`, UserProfile.toBack(userProfile))
      .pipe(
        map(data => UserProfile.toFront(data))
      );
  }

  updateUserBasketballProfile(userId: number, basketballProfile: BasketballProfile): Observable<BasketballProfile> {
    return this.httpClient
        .patch(`/api/v1/user/${userId}/basketball_profile/`, BasketballProfile.toBack(basketballProfile))
        .pipe(
            map(data => BasketballProfile.toFront(data))
        );
  }

  deleteUserBasketballProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/basketball_profile/`);
  }

  updateUserVolleyballProfile(userId: number, volleyballProfile: VolleyballProfile): Observable<VolleyballProfile> {
    return this.httpClient
        .patch(`/api/v1/user/${userId}/volleyball_profile/`, VolleyballProfile.toBack(volleyballProfile))
        .pipe(
            map(data => VolleyballProfile.toFront(data))
        );
  }

  deleteUserVolleyballProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/volleyball_profile/`);
  }

  updateUserHockeyProfile(userId: number, hockeyProfile: HockeyProfile): Observable<HockeyProfile> {
    return this.httpClient
      .patch(`/api/v1/user/${userId}/hockey_profile/`, HockeyProfile.toBack(hockeyProfile))
      .pipe(
        map(data => HockeyProfile.toFront(data))
      );
  }

  deleteUserHockeyProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/hockey_profile/`);
  }

  updateUserFootballProfile(userId: number, footballProfile: FootballProfile): Observable<FootballProfile> {
    return this.httpClient
      .patch(`/api/v1/user/${userId}/football_profile/`, FootballProfile.toBack(footballProfile))
      .pipe(
        map(data => FootballProfile.toFront(data))
      );
  }

  deleteUserFootballProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/football_profile/`);
  }

  updateUserHandballProfile(userId: number, profile: HandballProfile): Observable<HandballProfile> {
    return this.httpClient
      .patch(`/api/v1/user/${userId}/handball_profile/`, HandballProfile.toBack(profile))
      .pipe(
        map(data => HandballProfile.toFront(data))
      );
  }

  deleteUserHandballProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/handball_profile/`);
  }

  updateUserRugbyProfile(userId: number, profile: RugbyProfile): Observable<RugbyProfile> {
    return this.httpClient
      .patch(`/api/v1/user/${userId}/rugby_profile/`, RugbyProfile.toBack(profile))
      .pipe(
        map(data => RugbyProfile.toFront(data))
      );
  }

  deleteUserRugbyProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/rugby_profile/`);
  }

  updateUserWaterpoloProfile(userId: number, profile: WaterpoloProfile): Observable<WaterpoloProfile> {
    return this.httpClient
      .patch(`/api/v1/user/${userId}/waterpolo_profile/`, WaterpoloProfile.toBack(profile))
      .pipe(
        map(data => WaterpoloProfile.toFront(data))
      );
  }

  deleteUserWaterpoloProfile(userId: number): Observable<any> {
    return this.httpClient.delete(`/api/v1/user/${userId}/waterpolo_profile/`);
  }

  getUserPermissions(userId?: number): Observable<UserPermission> {
    return this.httpClient
      .get(`/api/v1/user/${userId || 'current'}/permission/`).pipe(
        map(data => UserPermission.toFront(data))
      );
  }

  updateUserPermission(data: any): Observable<UserPermission> {
    return this.httpClient
      .patch(`/api/v1/user/current/permission/`, UserPermission.toBack(data)).pipe(
        map(result => UserPermission.toFront(result))
      );
  }

  getTeams(userId: number): Observable<Team[]> {
    return this.httpClient
      .get(`/api/v1/user/${userId}/teams/`)
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  getInvites(): Observable<TeamInvite[]> {
    return this.httpClient
      .get('/api/v1/user/current/invites/')
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  acceptInvite(inviteId: number): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/user/current/invites/${inviteId}/accept/`, {})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  declineInvite(inviteId: number): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/user/current/invites/${inviteId}/decline/`, {})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  getFootballStatistic(filters?: StatisticFilters): Observable<FootballStatistic> {
    let params = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      }

      if (filters.per_game !== undefined) {
        params = params.set('per_game', filters.per_game ? '1' : '0');
      }
    }

    return this.httpClient.get(`/api/v1/football_statistic/`, {params}).pipe(
      map(result => FootballStatistic.toFront(result))
    );
  }

  getHandballStatistic(userId: number): Observable<HandballStatistic> {
    const params = new HttpParams()
      .set('user_id', userId.toString())
      .set('group_by', 'user').set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/handball_statistic/`, {params})
      .pipe(
        map(data => HandballStatistic.toFront(data[0]))
      );
  }

  getRugbyStatistic(userId: number): Observable<RugbyStatistic> {
    const params = new HttpParams()
      .set('user_id', userId.toString())
      .set('group_by', 'user').set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/rugby_statistic/`, {params})
      .pipe(
        map(data => RugbyStatistic.toFront(data[0]))
      );
  }

  getHockeyStatistic(userId: number): Observable<HockeyStatistic> {
    const params = new HttpParams()
      .set('user_id', userId.toString())
      .set('group_by', 'user').set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/hockey_statistic/`, {params})
      .pipe(
        map(data => HockeyStatistic.toFront(data[0]))
      );
  }

  getWaterpoloStatistic(userId: number): Observable<WaterpoloStatistic> {
    const params = new HttpParams()
      .set('user_id', userId.toString())
      .set('group_by', 'user').set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/waterpolo_statistic/`, {params})
      .pipe(
        map(data => WaterpoloStatistic.toFront(data[0]))
      );
  }

  getGames(userId: number): Observable<Game[]> {
    return this.httpClient
      .get(`/api/v1/user/${userId}/games/`)
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  getGameInvites(): Observable<GameInvite[]> {
    return this.httpClient
      .get(`/api/v1/user/current/game_invites/`)
      .pipe(
        map(data => GameInvite.toFront(data))
      );
  }

  acceptGameInvite(inviteId: number): Observable<GameInvite> {
    return this.httpClient
      .post(`/api/v1/user/current/game_invites/${inviteId}/accept/`, {})
      .pipe(
        map(data => GameInvite.toFront(data)),
      );
  }

  declineGameInvite(inviteId: number): Observable<GameInvite> {
    return this.httpClient
      .post(`/api/v1/user/current/game_invites/${inviteId}/decline/`, {})
      .pipe(
        map(data => GameInvite.toFront(data))
    );
  }

  searchUsers(query: string, expand?: boolean): Observable<User[]> {
    let params = new HttpParams().set('search', query);
    if (expand) {
      params = params.set('expand', '1');
    }
    return this.httpClient
      .get('/api/v1/user/', {params})
      .pipe(
        map(data => User.toFront(data))
      );
  }

  setWizardShowed(wizard: string): Observable<User> {
    return this.httpClient
      .put('/api/v1/user/current/wizards/', {wizard})
      .pipe(
        map(data => User.toFront(data)),
        tap(user => this.authService.user$.next(user))
      );
  }

  getTeamEvents(userId: number): Observable<TeamEvent[]> {
    return this.httpClient
      .get(`/api/v1/user/current/team_events/`)
      .pipe(
        map(data => TeamEvent.toFront(data))
      );
  }

  connectRsvAccount(data: IRsvLogin): Observable<User> {
    return this.httpClient
      .post(
        '/api/v1/user/current/rsv_account/',
        {
          code: data.code,
          redirect_uri: data.redirectUri,
        }
      )
      .pipe(
        map(response => User.toFront(response))
      );
  }

  disconnectRsvAccount(): Observable<User> {
    return this.httpClient.delete('/api/v1/user/current/rsv_account/').pipe(
      map(data => User.toFront(data)),
    );
  }
}
