import { Directive, HostListener, Input } from '@angular/core';
import { Metrika } from 'ng-yandex-metrika';

@Directive({
  selector: '[mtgFireEvent]'
})
export class FireEventDirective {
  @Input('mtgFireEvent')
  event: string;

  @HostListener('click')
  onClick() {
    if (this.event) {
      this.metrika.fireEvent(this.event);
    }
  }

  constructor(
    private metrika: Metrika
  ) {}
}
