import { Component, ElementRef, forwardRef, OnInit } from '@angular/core';
import { RecaptchaService } from '@shared/modules/recaptcha/recaptcha.service';
import { environment } from '@env/environment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mtg-recaptcha',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true
    }
  ]
})
export class RecaptchaComponent implements OnInit, ControlValueAccessor {
  private value: any;
  private _onTouchFunc: Function;
  private _onChangeFunc: Function;

  constructor(
    private recaptchaService: RecaptchaService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.recaptchaService.load().subscribe(() => {
      window.grecaptcha.render(this.elementRef.nativeElement, {
        sitekey: environment.recaptchaKey,
        callback: this.callback.bind(this)
      });
    });
  }

  callback(value: any) {
    this.value = value;
    if (this._onTouchFunc) {
      this._onTouchFunc();
    }
    if (this._onChangeFunc) {
      this._onChangeFunc(value);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnTouched(fn: any): void {
    this._onTouchFunc = fn;
  }

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }
}
