import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';

export enum GameHockeyPosition {
  defensemen = 1,
  forward,
  goaltender
}

export enum HockeyWorkHand {
  left = 1,
  right
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    position: 'position',
    work_hand: 'workHand'
  },
  relation: {
    position: enumField(GameHockeyPosition),
    workHand: enumField(HockeyWorkHand)
  }
})
export class HockeyProfile extends BaseModel {
  id: number;
  userId: number;
  position: GameHockeyPosition;
  workHand: HockeyWorkHand;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
