<mtg-input
  [fluid]="fluid"
  [value]="formattedValue"
  [label]="label"
  [required]="required"
  [textMask]="dateMask"
  [errors]="errors"
  [transparent]="transparent"
  [disabled]="disabled"
  (valueChange)="inputChanged($event)"
></mtg-input>
<ng-container *ngIf="disabled">
<span class="datepicker-trigger icon-table2"
></span>
</ng-container>
<ng-container *ngIf="!disabled">
<span class="datepicker-trigger icon-table2"
      [owlDateTimeTrigger]="datePicker"
></span>
</ng-container>
<input
  tabIndex="-1"
  type="text"
  [owlDateTimeFilter]="filterDate"
  [owlDateTime]="datePicker"
  class="hidden"
  (dateTimeChange)="dateChanged($event)"
>
<owl-date-time
  pickerType="calendar"
  firstDayOfWeek="1"
  #datePicker
></owl-date-time>
