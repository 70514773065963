import { Subject } from 'rxjs/internal/Subject';
import { OverlayRef } from '@angular/cdk/overlay';
import { DialogComponent } from './dialog.component';
import { ElementRef, ViewRef } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin, of } from 'rxjs';

export class DialogRef<T = any> {
  private instance: DialogComponent;
  private _closeEventEmitter = new Subject<any>();
  private _beforeClose: Observable<boolean>[] = [of(null)];

  constructor(private overlayRef: OverlayRef) {}

  close(data?: any) {
    this.overlayRef.dispose();
    this._closeEventEmitter.next(data);
    this._closeEventEmitter.complete();
  }

  addBeforeClose(checkObservable: Observable<boolean>) {
    this._beforeClose.push(checkObservable);
  }

  beforeClose(): Observable<any> {
    return forkJoin(this._beforeClose);
  }

  afterClosed(): Observable<any> {
    return this._closeEventEmitter;
  }

  setInstance(instance: DialogComponent) {
    this.instance = instance;
  }

  getOverlayRef(): OverlayRef {
    return this.overlayRef;
  }

  setOverlayRef(overlayRef: OverlayRef) {
    this.overlayRef = overlayRef;
  }

  getDialogContainerElement(): ElementRef {
    return this.instance.containerElementRef;
  }

  attachView(viewRef: ViewRef) {
    this.instance.attachView(viewRef);
  }
}
