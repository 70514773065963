import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pluralize',
  pure: false
})
export class PluralizePipe implements PipeTransform {
  transform(value: any, variants: string[]) {
    if (isNaN(+value)) {
      return value;
    }

    let number = +value % 100;
    if (number > 9 && number < 15) {
      return `${value} ${variants[2]}`;
    }
    number = number % 10;
    if (number === 1) {
      return `${value} ${variants[0]}`;
    } else if (number > 0 && number < 5) {
      return `${value} ${variants[1]}`;
    } else {
      return `${value} ${variants[2]}`;
    }
  }
}
