import { Routes } from '@angular/router';
import { AppLayoutComponent } from '@app/layout/app-layout.component';
import * as RoutingMap from '@app/routing-map';
import { PageNotFoundComponent } from '@app/layout/components/page-not-found/page-not-found.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { environment } from '@env/environment';
import { AgreementComponent } from '@app/shared/components/agreement/agreement.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

export const ROUTES: Routes = [
  {
    path: RoutingMap.ROUTE_AUTHENTICATION_ROOT,
    loadChildren: () => import('@shared/modules/authorization/authorization.module').then(m => m.AuthorizationModule),
  },
  {
    path: 'stream-widgets',
    loadChildren: () => import('./stream-widgets/stream-widgets.module').then(m => m.StreamWidgetsModule),
  },
  {
    path: RoutingMap.ROUTE_POLICY,
    component: AgreementComponent
  },
  {
    path: 'unsubscribe',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: UnsubscribeComponent,
      }
    ]
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: RoutingMap.ROUTE_PROFILE
      },
      {
        path: RoutingMap.ROUTE_PROFILE,
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: RoutingMap.ROUTE_TEAM_ROOT,
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule)
      },
      {
        path: '**',
        component: PageNotFoundComponent
      },
    ]
  },
  ...(environment.env !== 'prod'
  ? [
        {
          path: 'demo',
          loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)
        },
    ]
  : []
  )
];
