import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiKitModule } from '@ui-kit/ui-kit.module';
import { SharedModule } from '@shared/modules/shared/shared.module';
import { NotificationsComponent } from '@shared/modules/notification/components/notifications/notifications.component';
import { NotificationItemComponent } from '@shared/modules/notification/components/notification-item/notification-item.component';
import { NotificationPopupComponent } from '@shared/modules/notification/components/notification-popup/notification-popup.component';
import {
  NotificationsContainerComponent
} from '@shared/modules/notification/components/notifications-container/notifications-container.component';
import { NotificationBellComponent } from '@shared/modules/notification/components/notification-bell/notification-bell.component';
import { NotificationTemplateService } from '@shared/modules/notification/services/notification-template.service';
import { LocalizationModule } from '@shared/modules/localization/localization.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UiKitModule,
    SharedModule,
    LocalizationModule
  ],
  declarations: [
    NotificationsContainerComponent,
    NotificationsComponent,
    NotificationItemComponent,
    NotificationPopupComponent,
    NotificationBellComponent,
  ],
  providers: [
    NotificationTemplateService,
  ],
  exports: [
    NotificationsContainerComponent,
    NotificationBellComponent,
  ]
})
export class NotificationModule {}
