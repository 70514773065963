<div class="content" *ngIf="locale === locales.ru">
  <div class="agreement-box panel">
    <h5>Политика конфиденциальности применяется и размещается на веб-сайтах:<br/>
      <a href="https://org.mtgame.ru" target="_blank">https://org.mtgame.ru</a>, <a href="https://stat.mtgame.ru" target="_blank">https://stat.mtgame.ru</a>, <a href="https://mtgame.ru">https://mtgame.ru</a>
    </h5>
    <p>
    Политика конфиденциальности - обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным законом от 27.07.2006. №152-ФЗ «О персональных данных» (далее – ФЗ-152). Настоящая Политика определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных пользователей сайта с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну. Оператор обязан опубликовать или иным образом обеспечить неограниченный доступ к настоящей Политике обработки персональных данных в соответствии с ч. 2 ст. 18.1. ФЗ 152. Настоящая политика размещена на официальных веб-сайтах «Правообладателя», указанных выше в открытом доступе и предназначена для всех пользователей сайта. Пользуясь сайтом, пользователь, пользователь-организатор подтверждает, что ознакомился с настоящей политикой и полностью согласен со всеми ее условиями. Помимо терминов, изложенных в договоре, в настоящем документы используются следующие
    </p>

    <h5>Термины и определения</h5>
    <p>
    Оператор персональных данных – “Правообладатель”.
    </p>
    <p>
    «Пользователь», «Пользователь-организатор» - лица, использующие ресурсы, перечисленные в указанной Политике о конфиденциальности, в том числе закрытые части ресурсов (где доступ предоставляется по отдельному договору).
    </p>
    <p>
    Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
    </p>
    <p>
    Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
    </p>
    <h5>1.ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>
        1.1. Обработка персональных данных у Оператора осуществляется на основе следующих принципов:
        <ul>
          <li>− законности и справедливости;</li>
          <li>− ограничение обработки персональных данных достижением конкретных, заранее определенных и законных целей;</li>
          <li>− недопущение обработки персональных данных, несовместимой с целями сбора персональных данных;</li>
          <li>− недопущение объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
          <li>− обработка только тех персональных данных, которые отвечают целям их обработки;</li>
          <li>− соответствие содержания и объема обрабатываемых персональных данных заявленным целям обработки;</li>
          <li>− недопущение обработки персональных данных, избыточных по отношению к заявленным целям их обработки;</li>
          <li>− обеспечение точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;</li>
          <li>− уничтожение либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.</li>

        </ul>
      </li>
    </ul>

    <h5>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ:</h5>
    <ul>
      <li>2.1. Обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</li>
      <li>2.2. Оказание Оператором физическим и юридическим лицам услуг, связанных с хозяйственной деятельностью Оператора, включая контакты Оператора с такими лицами, в том числе по электронной почте, по телефону, по адресу, представленным соответствующим лицом.</li>
      <li>2.3. Направление консультаций, ответов обратившимся лицам с помощью средств связи и указанных ими контрактных данных.</li>
      <li>2.4. Продвижение товаров, работ, услуг Оператора на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи (при условии предварительного согласия субъекта персональных данных).</li>
    </ul>
    <h5>3.СОБИРАЕМЫЕ ДАННЫЕ</h5>
    <ul>
      <li>3.1. В целях оказания услуг Пользователю, Пользователю-организатору, а также в иных целях, указанных в Политике, «Правообладатель» может запрашивать следующие данные: фамилию, имя, отчество, электронный адрес, номер телефона, номер мобильного телефона, антропометрические данные. «Правообладатель» также регистрирует данные о покупке совершенной Пользователем, Пользователем-организатором. Пользователь, пользователь-организатор используя сайт, дает свое согласие на обработку указанных данных. Пользователь вправе в любое время отозвать данное согласие, направив письменно уведомление об отзыве по адресу info@mtgame.ru.</li>
      <li>3.2. В случае отзыва согласия на обработку персональных данных «Правообладатель» прекращает обработку и уничтожает персональные данные в течение пяти рабочих дней с момента получения отзыва. При этом после удаления персональных данных пользователя (пользователя-организатора), «Правообладатель» не оказывает ему более услуги и не направляет рассылку, а также не возмещает стоимость оплаченных и не полученных в связи с этим услуг.</li>
    </ul>
    <h5>4. ОБЕСПЕЧЕНИЕ ИСПОЛНИТЕЛЕМ ПРАВ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>4.1. Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и другими нормативно-правовыми актами, регламентирующими обработку персональных данных.</li>
      <li>4.2. «Правообладатель» обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
      <li>4.3. «Правообладатель» обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, по запросу.</li>
      <li>4.4. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.</li>
      <li>4.5. В случае представления интересов субъекта персональных данных представителем полномочия представителя подтверждаются доверенностью, оформленной в установленном порядке.</li>

      <li>4.6. В случаях предоставления субъектом персональных данных письменного согласия на использование персональных данных для такого согласия достаточно простой письменной формы.</li>
      <li>4.7. «Правообладатель» гарантирует безопасность и конфиденциальность используемых персональных данных.</li>
      <li>4.8. Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи допускается только при условии предварительного согласия субъекта персональных данных.</li>
    </ul>
    <h5>5. ПОЛУЧЕНИЕ, ОБРАБОТКА, ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>5.1. «Правообладатель» устанавливает следующий порядок получения персональных данных при регистрации и заказе услуг на сайте «Правообладателя»: субъект персональных данных указывает: Ф.И.О., телефон, эл.почту, дату рождения, мессенджеры и другую информацию, необходимую для идентификации.</li>
      <li>5.2. При обращении за получением услуг «Правообладателя» Пользователь, Пользователь-организатор указывает установленные соответствующими формами данные.</li>
      <li>5.3. «Правообладатель» не получает и не обрабатывает персональные данные клиента о его расовой принадлежности, политических взглядах, религиозных и философских убеждениях, состоянии здоровья, интимной жизни, если законом не предусмотрено иное.</li>
      <li>5.4.В случаях, непосредственно связанных с вопросами трудовых отношений, в соответствии со ст. 24 Конституции Российской Федерации «Правообладатель» вправе получать и обрабатывать данные о частной жизни клиента только с его письменного согласия.</li>
      <li>5.5. В случае принятия оферты, размещённой на сайте «Правообладателя», либо заключения другого договора с «Правообладателем», обработка персональных данных осуществляется для исполнения соответствующего договора, вступившего в силу вследствие принятия условий оферты либо заключения другого договора соответственно.</li>
      <li>5.6. «Правообладатель» вправе обрабатывать персональные данные пользователей -физических лиц только с их согласия на использование персональных данных.</li>
      <li>
        5.7. Согласие Заказчика на обработку персональных данных не требуется в следующих случаях:
        <ul>
          <li>5.7.1. Персональные данные являются общедоступными.</li>
          <li>5.7.2. Обработка персональных данных осуществляется на основании федерального закона, устанавливающего ее цель, условия получения персональных данных и круг субъектов, персональные данные которых подлежат обработке.</li>
          <li>5.7.3. По требованию полномочных государственных органов - в случаях, предусмотренных федеральным законом.</li>
          <li>5.7.4. Обработка персональных данных в целях исполнения договора, заключенного с «Правообладателем».</li>
          <li>5.7.5. Обработка персональных данных осуществляется для статистических или иных научных целей при условии обязательного обезличивания персональных данных.</li>
          <li>5.7.6. Обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов Пользователя, если получение его согласия невозможно.</li>

        </ul>
      </li>
      <li>
        5.8. «Правообладатель» обеспечивает безопасное хранение персональных данных, в том числе:
        <ul>
          <li>5.8.1. Хранение, комплектование, учет и использование содержащих персональные данные документов организуется в форме обособленного архива «Правообладателя».</li>

          <li>5.8.2. Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</li>
        </ul>
      </li>
    </ul>
    <h5>6. ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>6.1. Персональные данные передаются «Правообладателем» с соблюдением следующих требований:
        <ul>
          <li>6.1.1. запрещается сообщать персональные данные третьей стороне без письменного согласия клиента, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни, здоровью клиента, а также в других случаях, предусмотренных законами;</li>
          <li>6.1.2. не сообщать персональные данные в коммерческих целях без письменного согласия субъекта таких данных;</li>
          <li>6.1.3. предупредить лиц, получающих персональные данные, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требовать от этих лиц подтверждения того, что это правило соблюдено;</li>
          <li>6.1.4. разрешать доступ к персональным данным только специально уполномоченным лицам, при этом указанные лица должны иметь право получать только те персональные данные, которые необходимы для выполнения конкретных функций;</li>
          <li>6.1.5. не запрашивать информацию о состоянии здоровья клиента, за исключением тех сведений, которые относятся к вопросу о возможности выполнения клиентом обязательств по договору с Оператором;</li>
          <li>6.1.6. передавать персональные данные клиента его представителям в порядке, установленном Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</li>

        </ul>
      </li>
    </ul>
    <h5>7. ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</h5>
    <ul>
      <li>7.1. Право доступа к персональным данным имеют:
        <ul>
          <li>- руководитель «Правообладателя»;</li>
          <li>- работающие с определенным клиентом работники «Правообладателя»;</li>
          <li>- работники бухгалтерии;</li>
          <li>- работники, осуществляющие техническое обеспечение деятельности «Правообладателя».</li>
        </ul>
      </li>
      <li>7.2. Пользователи  в целях обеспечения защиты персональных данных имеют следующие права:
        <ul>
          <li>7.2.1. на полную информацию об их персональных данных и обработке этих данных;</li>
          <li>7.2.2. на свободный бесплатный доступ к своим персональным данным, включая право на получение копий любой записи, содержащей персональные данные, за исключением случаев, предусмотренных федеральным законом;</li>
          <li>7.2.3. на определение своих представителей для защиты своих персональных данных;</li>
          <li>7.2.4. на требование об исключении или исправлении неверных или неполных персональных данных, а также данных, обработанных с нарушением требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
        </ul>
      </li>
      <li>7.3. Копировать и делать выписки персональных данных разрешается исключительно в служебных целях с разрешения руководителя.</li>

    </ul>
    <h5>8. ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ</h5>
    <ul>
      <li>8.1. «Правообладатель» вправе вносить изменения в Политику по своему усмотрению и без предварительного уведомления Пользователя сайта. Поэтому Пользователю Сайта рекомендуется при следующем посещении сайта заново перечитать условия и обратить внимание на возможные изменения или поправки. Новая редакция Политики вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики.</li>
    </ul>
    <h5>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h5>
    <ul>
      <li>9.1. Ни при каких обстоятельствах «Правообладатель» не несет ответственность перед пользователем или перед любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, вызванный в связи с использованием сайта, содержимого сайта или иных материалов, к которым Пользователь, пользователь-организатор получили доступ с помощью сайта, даже если «Правообладатель» предупреждал или указывал на возможность такого вреда.</li>
      <li>9.2. «Правообладатель» не несет ответственности за потерю данных вследствие действий третьих лиц, в том числе хостинг-провайдера «Правообладателя», ошибок программного обеспечения, ненадежности каналов связи, а также незаконных действий хакеров и прочих злоумышленников. В случае обнаружения утери пользовательских данных «Правообладатель» обязуется уведомить пользователей в течение 24 часов с момента установления факта утери, а также приложить все возможные усилия для уменьшения негативных последствий для Пользователей и идентификации ответственных.</li>
      <li>9.3. Недействительность отдельных норм настоящей Политики конфиденциальности, если таковое будет признано решением суда или иного уполномоченного государственного органа, не влечет ее недействительности в целом.</li>
    </ul>
    <h5>10. ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ НОРМ, РЕГУЛИРУЮЩИХ ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>10.1. Лица, виновные в нарушении порядка обращения с персональными данными, несут дисциплинарную, административную, гражданско-правовую или уголовную ответственность в соответствии с федеральными законами.</li>
    </ul>

  </div>
</div>

<div class="content" *ngIf="locale === locales.en">
  <div class="agreement-box panel">
    <h5>The Privacy Policy is applied and posted on the   websites:<br/>
      <a href="https://org.mtgame-sports.com" target="_blank">https://org.mtgame-sports.com</a>, <a href="https://stat.mtgame-sports.com" target="_blank">https://stat.mtgame-sports.com</a>, <a href="https://mtgame.ru">https://mtgame-sports.com</a>
    </h5>
    <p>
      The Privacy Policy for the Processing of
      Personal Data (hereinafter referred to as the
      Policy) has been developed in accordance with
      Federal Law No. 152-FZ dated 07/27/2006 "On
      Personal Data" (hereinafter referred to as FZ 152). This Policy defines the procedure for
      processing personal data and measures to ensure
      the security of personal data of users of the
      website in order to protect the rights and
      freedoms of a person and citizen when
      processing his personal data, including the
      protection of the rights to privacy, personal and
      family secrets. The operator is obliged to
      publish or otherwise provide unrestricted access
      to this Personal Data Processing Policy in
      accordance with Part 2 of Article 18.1. Federal
      Law 152. This policy is posted on the official
      websites of the "Copyright Holder", listed above
      in the public domain and is intended for all users
      of the website. By using the website, the user,
      the organizer user confirms that he has read this
      policy and fully agrees with all its terms. In
      addition to the terms set out in the agreement,
      the following documents are used in this
      document:
    </p>

    <h5>Terms and definitions</h5>
    <p>
      Personal data is any information related directly
      or indirectly to a specific or identifiable
      individual (the subject of personal data).
    </p>
    <p>
      Personal data processing is any action
      (operation) or set of actions (operations)
      performed with or without the use of automation
      tools with personal data, including collection,
      recording, systematization, accumulation,
      storage, clarification (updating, modification),
      extraction, use, transfer (distribution, provision,
      access), depersonalization, blocking, deletion,
      destruction of personal data.
    </p>
    <h5>1.PRINCIPLES OF PERSONAL DATA PROCESSING</h5>
    <ul>
      <li>
        1.1. The processing of personal data by the
        Operator is carried out on the basis of the
        following principles:
        <ul>
          <li>− legality and fairness;</li>
          <li>
            − limiting the processing of personal data to the
            achievement of specific, predetermined and
            legitimate goals;
          </li>
          <li>
            − preventing the processing of personal data
            incompatible with the purposes of collecting
            personal data;
          </li>
          <li>
            − preventing the unification of databases
            containing personal data, the processing of
            which is carried out for purposes incompatible
            with each other;
          </li>
          <li>
            − processing only those personal data that meet
            the purposes of their processing;
          </li>
          <li>
            − compliance of the content and volume of the
            processed personal data with the stated purposes
            of processing;
          </li>
          <li>
            − preventing the processing of personal data that
            is excessive in relation to the stated purposes of
            their processing;
          </li>
          <li>
            − ensuring the accuracy, sufficiency and
            relevance of personal data in relation to the
            purposes of personal data processing;
          </li>
          <li>
            − destruction or depersonalization of personal
            data upon achievement of the purposes of their
            processing or in case of loss of the need to
            achieve these goals, if it is impossible for the
            Operator to eliminate violations of personal data,
            unless otherwise provided by federal law
          </li>

        </ul>
      </li>
    </ul>

    <h5>2.  PURPOSES OF PERSONAL DATA PROCESSING:</h5>
    <ul>
      <li>2.1. Ensuring the protection of human and civil
        rights and freedoms during the processing of his
        personal data, including the protection of the
        rights to privacy, personal and family secrets. </li>
      <li>2.2. Provision by the Operator to individuals and
        legal entities of services related to the Operator's
        business activities, including the Operator's
        contacts with by such persons, including by email, by phone, at the address provided by the
        relevant person.</li>
      <li>2.3. Sending consultations, responses to the
        persons who applied using the means of
        communication and the contractual data
        specified by them</li>
      <li>2.4. Promotion of the Operator's goods, works,
        and services on the market by making direct
        contacts with a potential consumer using means
        of communication (subject to the prior consent
        of the personal data subject).
      </li>
    </ul>
    <h5>3.DATA COLLECTED</h5>
    <ul>
      <li>3.1. In order to provide services to the User, the
        User-organizer, as well as for other purposes
        specified in the Policy, the "Copyright Holder"
        may request the following data: surname, first
        name, patronymic, email address, phone number,
        mobile phone number, anthropometric data. The
        "Copyright Holder" also registers data on the
        purchase made by the User, the Organizer User.
        The user, the organizer user, using the website,
        gives his consent to the processing of the
        specified data. The User has the right to revoke
        this consent at any time by sending a written
        notice of revocation to info@mtgame-sports.com .</li>
      <li>3.2. In case of revocation of consent to the
        processing of personal data, the "Copyright
        Holder" stops processing and delete personal
        data within five working days from the date of
        receipt of the revocation. At the same time, after
        deleting the personal data of the user (the user organizer), the "Copyright Holder" no longer
        provides him with services and does not send a
        newsletter, and also does not reimburse the cost
        of services paid and not received in connection
        with this.</li>
    </ul>
    <h5>4. ENSURING THE RIGHTS OF THE PERSONAL DATA SUBJECT BY THE CONTRACTOR</h5>
    <ul>
      <li>4.1. Personal data subjects or their
        representatives have the rights provided for by
        Federal Law No. 152-FZ dated 07/27/2006 "On
        Personal Data" and other regulatory legal acts
        regulating the processing of personal data. </li>
      <li>4.2. The "Copyright holder" ensures the rights of
        personal data subjects in accordance with the
        procedure established by Chapters 3 and 4 of
        Federal Law No. 152-FZ dated 07/27/2006 "On
        Personal Data". </li>
      <li>4.3. The "Copyright holder" is obliged to
        provide the personal data subject or his
        representative with the opportunity to familiarize
        himself with personal data free of charge, upon
        request.</li>
      <li>4.4. The right of a personal data subject to
        access his personal data may be restricted in
        accordance with federal laws.</li>
      <li>4.5. In the case of representation of the interests
        of the personal data subject by a representative,
        the powers of the representative are confirmed
        by a power of attorney issued in accordance with
        the established procedure.</li>

      <li>4.6. In cases where the subject of personal data
        provides written consent to the use of personal
        data, a simple written form is sufficient for such
        consent.</li>
      <li>4.7. The "Copyright Holder" guarantees the
        security and confidentiality of the personal data
        used.</li>
      <li>4.8. Processing of personal data for the purpose
        of promoting goods, works, and services on the
        market by making direct contacts with a
        potential consumer using means of
        communication is allowed only with the prior
        consent of the personal data subject.</li>
    </ul>
    <h5>5. RECEIPT, PROCESSING, STORAGE OF PERSONAL DATA</h5>
    <ul>
      <li>5.1. The "Copyright holder" establishes the
        following procedure for obtaining personal data
        when registering and ordering services on the
        "Copyright holder" website: the subject of
        personal data indicates: Full name, phone, email, date of birth, messengers and other
        information necessary for identification.</li>
      <li>5.2. When applying for the services of the
        "Copyright holder" The User, the Organizer
        User, indicates the data set by the corresponding
        forms</li>
      <li>5.3. The "Copyright Holder" does not receive or
        process the client's personal data about his race,
        political views, religious and philosophical
        beliefs, health status, intimate life, unless
        otherwise provided by law.</li>
      <li>5.4. In cases directly related to issues of labor
        relations, in accordance with Article 24 of the
        Constitution of the Russian Federation, the
        "Copyright holder" has the right to receive and
        process data about the client's private life only
        with his written consent.</li>
      <li>5.5. In case of acceptance of the offer posted on
        the website of the "Copyright holder" or
        conclusion of another agreement with the
        "Copyright holder", personal data processing is
        carried out to fulfill the relevant agreement,
        which entered into force as a result of
        acceptance of the terms of the offer or
        conclusion of another agreement, respectively.</li>
      <li>5.6. The "Copyright holder" has the right to
        process personal data of users -individuals only
        with their consent to the use of personal data.</li>
      <li>
        5.7. The consent to the processing of personal data is not required in the following cases:
        <ul>
          <li>5.7.1. Personal data are publicly available.</li>
          <li>5.7.2. Personal data processing is carried out on
            the basis of a federal law establishing its
            purpose, conditions for obtaining personal data
            and the range of subjects whose personal data
            are subject to.</li>
          <li>5.7.3. At the request of authorized state bodies -
            in cases provided for by federal law.</li>
          <li>5.7.4. Processing of personal data for the
            purpose of fulfilling the contract concluded with
            the "Copyright holder".</li>
          <li>5.7.5. Processing of personal data is carried out
            for statistical or other scientific purposes, subject
            to mandatory depersonalization of personal data.</li>
          <li>5.7.6. Processing of personal data is necessary to
            protect the life, health or other vital interests of
            the User, if obtaining his consent it is
            impossible.</li>

        </ul>
      </li>
      <li>
        5.8. The "Copyright holder" ensures the safe
        storage of personal data, including:
        <ul>
          <li>5.8.1. The storage, acquisition, accounting and
            use of documents containing personal data is
            organized in the form of a separate archive of
            the "Copyright holder".</li>

          <li>5.8.2. The storage of personal data must be
            carried out in a form that allows determining the
            subject of personal data, no longer than the
            purposes of personal data processing require,
            unless the period of personal data storage is
            established by federal law, an agreement to
            which the subject of personal data is a party,
            beneficiary or guarantor. The processed personal
            data is subject to destruction or
            depersonalization upon achievement of the
            processing goals or in case of loss of the need to
            achieve these goals, unless otherwise provided
            by federal law.</li>
        </ul>
      </li>
    </ul>
    <h5>6. TRANSFER OF PERSONAL DATA</h5>
    <ul>
      <li>6.1. Personal data is transferred by the
        "Copyright holder" in compliance with the
        following requirements:
        <ul>
          <li>6.1.1. it is prohibited to disclose personal data to
            a third party without the written consent of the
            client, except in cases where this is necessary in
            order to prevent threats to the life and health of
            the client, as well as in other cases provided for
            by law;</li>
          <li>6.1.2. not to disclose personal data for
            commercial purposes without the written
            consent of the subject of such data;</li>
          <li>6.1.3. to warn persons receiving personal data
            that this data can only be used for the purposes for which it is provided, and to require these
            persons to confirm that this rule is followed;</li>
          <li>6.1.4. to allow access to personal data only to
            specially authorized persons, while these
            persons should have the right to receive only
            those personal data that are necessary to perform
            specific functions;</li>
          <li>6.1.5. not to request information about the
            client's health status, except for those
            information that relates to the issue of the
            client's ability to fulfill obligations under the
            contract with the Operator;</li>
          <li>6.1.6. transfer the client's personal data to his
            representatives in accordance with the procedure
            established by Federal Law No. 152-FZ dated
            07/27/2006 "On Personal Data".</li>

        </ul>
      </li>
    </ul>
    <h5>7. ACCESS TO PERSONAL DATA</h5>
    <ul>
      <li>7.1. The right of access to personal data is granted to:
        <ul>
          <li>- the head of the "Copyright holder";</li>
          <li>- employees of the "Copyright holder" working
            with a certain client;</li>
          <li>- employees of the accounting department;</li>
          <li>- employees who provide technical support for
            the activities of the "Copyright holder".</li>
        </ul>
      </li>
      <li>7.2.  Users, in order to ensure the protection of
        personal data, have the following rights:
        <ul>
          <li>7.2.1. to complete information about their
            personal data and the processing of this data;</li>
          <li>7.2.2. free access to their personal data,
            including the right to receive copies of any
            record containing personal data, except in cases
            provided for by federal law;</li>
          <li>7.2.3. to identify their representatives to protect
            their personal data;</li>
          <li>7.2.4. to the requirement to exclude or correct
            incorrect or incomplete personal data, as well as
            data processed in violation of the requirements
            of Federal Law No. 152-FZ dated 07/27/2006
            "On Personal Data".</li>
        </ul>
      </li>
      <li>7.3. Copying and making extracts of personal
        data is allowed exclusively for official purposes
        with the permission of the head.</li>

    </ul>
    <h5>8. MODIFICATION OF THIS POLICY</h5>
    <ul>
      <li>8.1. The "Copyright Holder" has the right to
        make changes to the Policy at its discretion and
        without prior notice to the Website User.
        Therefore, the Website User is recommended to
        re-read the terms and conditions again the next
        time they visit the site and pay attention to
        possible changes or amendments. The new version of the Policy comes into force from the
        moment it is posted on the website, unless
        otherwise provided by the new version of the
        Policy</li>
    </ul>
    <h5>9. FINAL PROVISIONS</h5>
    <ul>
      <li>9.1. Under no circumstances shall the
        "Copyright Holder" be liable to the user or to
        any third parties for any indirect, accidental,
        unintentional damage, including lost profits or
        lost data, damage to honor, dignity or business
        reputation caused in connection with the use of
        the site, the content of the site or other materials
        to which the User The organizing user gained
        access through the website, even if the
        "Copyright Holder" warned or pointed out the
        possibility of such harm.</li>
      <li>9.2. The "Copyright Holder" is not responsible
        for data loss due to the actions of third parties,
        including the hosting provider of the "Copyright
        Holder", software errors, unreliability of
        communication channels, as well as illegal
        actions of hackers and other intruders. In case of
        detection of loss of user data, the "Copyright
        Holder" undertakes to notify users within 24
        hours from the moment of establishing the fact
        of loss, as well as to make every possible effort
        to reduce the negative consequences for Users
        and identify those responsible.</li>
      <li>9.3. The invalidity of certain provisions of this
        Privacy Policy, if recognized by a court decision
        or other authorized state body, does not entail its
        invalidity as a whole.</li>
    </ul>
    <h5>10. LIABILITY FOR VIOLATION OF THE
      RULES GOVERNING THE PROCESSING OF
      PERSONAL DATA</h5>
    <ul>
      <li>10.1. Persons guilty of violating the procedure
        for handling personal data are subject to
        disciplinary, administrative, civil or criminal
        liability in accordance with federal laws.</li>
    </ul>

  </div>
</div>
