import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from './components/button/button.directive';
import { ButtonGroupComponent } from './components/button-group/button-group.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ButtonDirective,
    ButtonGroupComponent
  ],
  exports: [
    ButtonDirective,
    ButtonGroupComponent
  ]
})
export class ButtonModule {}
