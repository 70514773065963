import { TeamUserRole } from '@core/models/team-user';
import { Locales } from '@shared/modules/localization/locales';

export const TeamUserRoleLocalization = {
  [Locales.ru]: {
    [TeamUserRole.moderator]: 'Игрок',
    [TeamUserRole.member]: 'Игрок',
    [TeamUserRole.admin]: 'Игрок',
    [TeamUserRole.coach]: 'Тренер',
    [TeamUserRole.head_coach]: 'Главный тренер',
    [TeamUserRole.playing_coach]: 'Играющий тренер',
    [TeamUserRole.manager]: 'Менеджер',
    [TeamUserRole.head_manager]: 'Генеральный менеджер',
    [TeamUserRole.captain]: 'Капитан',
    [TeamUserRole.assistant]: 'Ассистент',
    [TeamUserRole.president]: 'Президент',
    [TeamUserRole.vice_president]: 'Вице-президент',
    [TeamUserRole.press_attache]: 'Пресс-атташе',
    [TeamUserRole.executive]: 'Администратор',
    [TeamUserRole.senior_coach]: 'Старший тренер',
    [TeamUserRole.assistant_coach]: 'Помощник тренера',
    [TeamUserRole.serviceman]: 'Сервисмен',
    [TeamUserRole.game_application_manager]: 'Менеджер по заявкам на игры',
  },
  [Locales.en]: {
    [TeamUserRole.moderator]: 'Moderator',
    [TeamUserRole.member]: 'User',
    [TeamUserRole.admin]: 'Owner',
    [TeamUserRole.coach]: 'Coach',
    [TeamUserRole.head_coach]: 'Head coach',
    [TeamUserRole.playing_coach]: 'Playing coach',
    [TeamUserRole.manager]: 'Manager',
    [TeamUserRole.head_manager]: 'General Manager',
    [TeamUserRole.captain]: 'Captain',
    [TeamUserRole.assistant]: 'Assistant',
    [TeamUserRole.president]: 'President',
    [TeamUserRole.vice_president]: 'Vice President',
    [TeamUserRole.press_attache]: 'Press Attache',
    [TeamUserRole.executive]: 'Administrator',
    [TeamUserRole.senior_coach]: 'Senior Coach',
    [TeamUserRole.assistant_coach]: 'Assistant Coach',
    [TeamUserRole.serviceman]: 'Serviceman',
    [TeamUserRole.game_application_manager]: 'Game Application Manager',
  }
};
