import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LeagueNews } from '@core/models/league-news';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@core/services/paginated-response.interface';

@Injectable()
export class OrgLeagueNewsService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getNewsList(leagueId: number, page: number, size: number): Observable<PaginatedResponse<LeagueNews[]>> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpClient.get(`/org/api/v1/league/${leagueId}/news/`, {observe: 'response', params})
      .pipe(map(response => ({
        total: +response.headers.get('X-Page-Count'),
        data: LeagueNews.toFront(response.body)
      })));
  }

  getNewsById(newsId: number): Observable<LeagueNews> {
    return this.httpClient.get(`/org/api/v1/league_news/${newsId}/`)
      .pipe(map(result => LeagueNews.toFront(result)));
  }

  createNews(leagueId: number, data: any): Observable<LeagueNews> {
    return this.httpClient.post(`/org/api/v1/league/${leagueId}/news/`, LeagueNews.toBack(data))
      .pipe(map(result => LeagueNews.toFront(result)));
  }

  updateNews(newsId: number, data: any): Observable<LeagueNews> {
    return this.httpClient.patch(`/org/api/v1/league_news/${newsId}/`, LeagueNews.toBack(data))
      .pipe(map(result => LeagueNews.toFront(result)));
  }

  deleteNews(newsId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/league_news/${newsId}/`);
  }
}
