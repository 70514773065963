import { Injectable } from '@angular/core';
import { PollService } from '@core/services/poll.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Poll, PollAnswer } from '@core/models/poll';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { TeamProfileService } from '@core/services/team-profile.service';

@Injectable()
export class PollDetailService {
  private _pollSubject = new BehaviorSubject<Poll>(undefined);

  get poll$() {
    return this._pollSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    private pollService: PollService,
    private centrifugoService: CentrifugoService,
    private teamProfileService: TeamProfileService,
  ) {}

  initialize(pollId: number): Observable<Poll> {
    this.listenCentrifugoEvents(pollId);
    return this.getPoll(pollId);
  }

  dispose() {
    if (this._pollSubject.value) {
      this.centrifugoService.unsubscribe(`poll_${this._pollSubject.value.id}`);
      this._pollSubject.next(undefined);
    }
  }

  getPoll(pollId: number): Observable<Poll> {
    return this.pollService.getPoll(pollId).pipe(
      tap(result => this._pollSubject.next(result))
    );
  }

  deletePoll(poll: Poll): Observable<any> {
    return this.pollService.deletePoll(poll.id).pipe(
      tap(() => this._pollSubject.next(null)),
      tap(() => this.teamProfileService.deletePoll(poll)),
    );
  }

  archivePoll(poll: Poll): Observable<Poll> {
    return this.pollService.archivePoll(poll.id).pipe(
      tap(result => this._pollSubject.next(result)),
      tap(result => this.teamProfileService.updatePollValue(result)),
    );
  }

  closePoll(poll: Poll): Observable<Poll> {
    return this.pollService.closePoll(poll.id).pipe(
      tap(result => this._pollSubject.next(result)),
      tap(result => this.teamProfileService.updatePollValue(result)),
    );
  }

  savePollAnswer(pollId: number, answers: PollAnswer[]): Observable<PollAnswer[]> {
    return this.pollService.saveAnswer(pollId, answers).pipe(
      tap(result => {
        const poll = this._pollSubject.value;
        poll.answers = result;
        this._pollSubject.next(poll);
        this.teamProfileService.updatePollValue(poll);
      })
    );
  }

  resendNotification(pollId: number, userId: number): Observable<any> {
    return this.pollService.resendNotification(pollId, userId);
  }

  private listenCentrifugoEvents(pollId: number): void {
    this.centrifugoService.listen(`poll_${pollId}`).subscribe(message => {
      this._pollSubject.next(Poll.toFront(message['data']));
    });
  }
}
