import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, DateTimeField } from './util';
import { File } from './file';
import { VichanterJob } from '@core/models/vichanter-job';
import { Team } from '@core/models/team';
import { TournamentTeam } from '@core/models/team';
import { Tournament } from '@core/models/tournament';

@ModelInstance({
  mappingFields: {
    id: 'id',
    team: 'team',
    competitor_team: 'competitorTeam',
    tournament_team: 'tournamentTeam',
    competitor_tournament_team: 'competitorTournamentTeam',
    datetime: 'datetime',
  },
  relation: {
    team: Team,
    competitorTeam: Team,
    tournamentTeam: TournamentTeam,
    competitorTournamentTeam: TournamentTeam,
    datetime: DateTimeField,
  }
})
class GameShort extends BaseModel {
  id: number;
  team: Team;
  competitorTeam: Team;
  tournamentTeam: TournamentTeam;
  competitorTournamentTeam: Tournament;
  datetime: Date;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    original_photo: 'originalPhoto',
    list_photo: 'listPhoto',
    detail_photo: 'detailPhoto',
    game: 'game',
    tournament: 'tournament',
    game_id: 'gameId',
    tournament_id: 'tournament_id',
    video_url: 'videoUrl',
    video_preview: 'videoPreview',
    is_live: 'isLive',
    highlights_job: 'highlightsJob',
  },
  relation: {
    game: GameShort,
    originalPhoto: File,
    listPhoto: File,
    detailPhoto: File,
    videoPreview: File,
    highlightsJob: VichanterJob,
  }
})
export class MediaItem extends BaseModel {
  id: number;
  game: GameShort;
  originalPhoto: File;
  listPhoto: File;
  detailPhoto: File;
  gameId: number;
  tournamentId: number;
  videoUrl: string;
  videoPreview: File;
  isLive: boolean;
  highlightsJob: VichanterJob;

  get isVideo(): boolean {
    return Boolean(this.videoUrl);
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
