import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { IFacebookLogin } from '@shared/modules/authorization/models/social-auth';

export const FACEBOOK_APP_ID = '164936710911531';
export const FACEBOOK_APP_VERSION = 'v2.12';
const redirectUri = window.location.origin + '/assets/oauth/fb.html';
const authResultEvent = 'fb-auth-result';

@Injectable()
export class FacebookService {
  private interval: any;

  constructor() {}

  login(): Observable<IFacebookLogin> {
    return Observable.create(observer => {
      const url = `https://www.facebook.com/${FACEBOOK_APP_VERSION}/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile&display=popup&auth_type=rerequest`;
      const loginWindow = window.open(url, 'fb_auth', 'width=400,height=400');

      const handleLogin = (evt: CustomEvent) => {
        window.removeEventListener(authResultEvent, handleLogin);
        clearInterval(this.interval);

        if (!evt.detail) {
          observer.error();
          return;
        }

        observer.next({
          code: evt.detail,
          redirectUri: redirectUri
        });
        observer.complete();
      };

      this.interval = setInterval(() => {
        if (loginWindow && loginWindow.closed) {
          clearInterval(this.interval);
          window.removeEventListener(authResultEvent, handleLogin);
          observer.error();
        }
      }, 100);

      window.addEventListener(authResultEvent, handleLogin);
    });
  }
}
