import { BaseModel, enumField, ModelInstance, MomentDateTimeField, ToBackHook, ToFrontHook } from './util';
import * as moment from 'moment';
import { Team } from './team';
import { TeamEventTypes } from './team-event-types';
import { TeamEventTypeLocalization } from '@core/localization/team-event-type';

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    team: 'team',
    event_type: 'eventType',
    event_custom_name: 'eventCustomName',
    description: 'description',
    datetime: 'datetime',
    location: 'location',
  },
  relation: {
    eventType: enumField(TeamEventTypes),
    team: Team,
    datetime: MomentDateTimeField,
  }
})
export class TeamEvent extends BaseModel {
  id: number;
  teamId: number;
  team: Team;
  eventType: TeamEventTypes;
  eventCustomName: string;
  description: string;
  datetime: moment.Moment;
  location: string;

  get name(): string {
    if (this.eventType === TeamEventTypes.other) {
      return this.eventCustomName;
    }
    return TeamEventTypeLocalization[this.eventType];
  }

  get isClosed(): boolean {
    return this.datetime.isBefore(moment());
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
