import {
  ComponentFactoryResolver,
  Injectable,
  Injector
} from '@angular/core';
import { Router } from '@angular/router';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DialogRef } from './dialog-ref';
import { DialogComponent } from './dialog.component';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { DialogConfig, IDialogConfig } from './dialog-config';

@Injectable()
export class DialogService {
  private dialogs: DialogRef[] = [];

  constructor(
    private router: Router,
    private overlay: Overlay,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  open<C = any>(componentType: ComponentType<C>, config: IDialogConfig = {}, dialogRef?: DialogRef, injector?: Injector): DialogRef<C> {
    const dialogConfig = new DialogConfig(config);
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: dialogConfig.hasBackdrop,
      backdropClass: dialogConfig.backdropClass
    });
    if (!dialogRef) {
      dialogRef = new DialogRef(overlayRef);
      this.dialogs.push(dialogRef);
    } else {
      dialogRef.getOverlayRef().dispose();
      dialogRef.setOverlayRef(overlayRef);
      this.dialogs.push(dialogRef);
    }

    const portalInjector = this.getInjector(dialogConfig, dialogRef, injector);
    const dialogPortal = new ComponentPortal(DialogComponent, null, portalInjector);
    const dialogComponentRef = overlayRef.attach(dialogPortal);
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(componentType)
      .create(portalInjector);
    dialogComponentRef.instance.attachView(componentRef.hostView);
    dialogRef.setInstance(dialogComponentRef.instance);

    this.bodyBlock();
    overlayRef.detachments().subscribe(ref => {
      this.dialogs.splice(this.dialogs.indexOf(dialogRef), 1);
      if (this.dialogs.length === 0) {
        this.bodyUnBlock();
      }
    });

    return dialogRef;
  }

  bodyBlock() {
    document.body.classList.add('dialog-opened');
    document.body.addEventListener('ontouchmove', this.preventDefault);
  }

  bodyUnBlock() {
    document.body.classList.remove('dialog-opened');
    document.body.removeEventListener('ontouchmove', this.preventDefault);
  }

  preventDefault(event) {
    event.preventDefault();
    return false;
  }

  closeAll() {
    for (const dialog of this.dialogs) {
      dialog.close();
    }
  }

  private getInjector(config, dialogRef, injector?: Injector) {
    const tokens = new WeakMap();

    tokens.set(DialogConfig, config);
    tokens.set(DialogRef, dialogRef);

    return new PortalInjector(injector || this.injector, tokens);
  }
}
