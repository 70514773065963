import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, DateField } from './util';
import { TournamentTeamUser } from './tournament-team-user';
import { Team } from './team';
import { TeamUser } from './team-user';
import { User } from './user';
import { TournamentTeam } from './tournament-team';
import { LeaguePlayer } from './league-player';

@ModelInstance({
  mappingFields: {
    tournament_team_user: 'tournamentTeamUser',
    team: 'team',
    team_user: 'teamUser',
    user: 'user',
    tournament_team: 'tournamentTeam',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    lose_games_count: 'loseGamesCount',
    draw_games_count: 'drawGamesCount',
    newbie: 'newbie',
    // User statistic
    points: 'points',
    tries: 'tries',
    penalty_tries: 'penaltyTries',
    conversion_kicks: 'conversionKicks',
    conversion_goals: 'conversionGoals',
    conversion_misses: 'conversionMisses',
    conversion_goals_percent: 'conversionGoalsPercent',
    penalties: 'penalties',
    penalty_kicks_on_goal: 'penaltyKicksOnGoal',
    penalty_misses: 'penaltyMisses',
    penalty_goals: 'penaltyGoals',
    tap_penalties: 'tapPenalties',
    penalty_kicks_to_touch: 'penaltyKicksToTouch',
    penalty_goals_percent: 'penaltyGoalsPercent',
    drop_goal_kicks: 'dropGoalKicks',
    drop_goals: 'dropGoals',
    drop_goal_misses: 'dropGoalMisses',
    drop_goals_percent: 'dropGoalsPercent',
    free_kicks: 'freeKicks',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    offloads: 'offloads',
    tackles: 'tackles',
    handling_errors: 'handlingErrors',
    carries_over_gainline: 'carriesOverGainline',
    plus_minus: 'plusMinus',
    game_time: 'gameTime',

    // Team statistic
    scrums: 'scrums',
    scrums_won: 'scrumsWon',
    scrums_lost: 'scrumsLost',
    scrums_won_percent: 'scrumsWonPercent',
    scrums_won_free: 'scrumsWonFree',
    opponent_scrums_won: 'opponentScrumsWon',
    opponent_scrums_lost: 'opponentScrumsLost',
    lineouts: 'lineouts',
    lineouts_won: 'lineoutsWon',
    lineouts_lost: 'lineoutsLost',
    lineouts_steal: 'lineoutsSteal',
    opponent_lineouts_lost: 'opponentLineoutsLost',
    quick_throws: 'quickThrows',
    rucks: 'rucks',
    rucks_won: 'rucksWon',
    rucks_lost: 'rucksLost',
    rucks_won_percent: 'rucksWonPercent',
    opponent_rucks_won: 'opponentRucksWon',
    opponent_rucks_lost: 'opponentRucksLost',
    mauls: 'mauls',
    mauls_won: 'maulsWon',
    mauls_lost: 'maulsLost',
    mauls_won_percent: 'maulWonPercent',
    opponent_mauls_won: 'opponentMaulsWon',
    opponent_mauls_lost: 'opponentMaulsLost',
    fouls: 'fouls',
    team_fouls: 'teamFouls',
  },
  relation: {
    tournamentTeamUser: TournamentTeamUser,
    team: Team,
    teamUser: TeamUser,
    user: User,
    tournamentTeam: TournamentTeam,
    month: DateField
  }
})
export class RugbyStatistic extends BaseModel {
  leaguePlayer: LeaguePlayer;
  tournamentTeamUser: TournamentTeamUser;
  team: Team;
  teamUser: TeamUser;
  user: User;
  tournamentTeam: TournamentTeam;
  month: Date;
  winLose: string;
  gamesCount: number;
  wonGamesCount: number;
  loseGamesCount: number;
  drawGamesCount: number;
  tournamentTeamPoints: number;
  newbie: boolean;
  rank: number;
  points: number;
  tries: number;
  penaltyTries: number;
  conversionKicks: number;
  conversionGoals: number;
  conversionMisses: number;
  conversionGoalsPercent: number;
  penalties: number;
  penaltyKicksOnGoal: number;
  penaltyMisses: number;
  penaltyGoals: number;
  penaltyGoalsPercent: number;
  tapPenalties: number;
  penaltyKicksToTouch: number;
  dropGoalKicks: number;
  dropGoals: number;
  dropGoalMisses: number;
  dropGoalsPercent: number;
  freeKicks: number;
  yellowCards: number;
  redCards: number;
  offloads: number;
  tackles: number;
  handlingErrors: number;
  carriesOverGainline: number;
  plusMinus: number;
  gameTime: number;

  scrums: number;
  scrumsWon: number;
  scrumsLost: number;
  scrumsWonPercent: number;
  scrumsWonFree: number;
  opponentScrumsWon: number;
  opponentScrumsLost: number;
  lineouts: number;
  lineoutsWon: number;
  lineoutsLost: number;
  lineoutsSteal: number;
  opponentLineoutsLost: number;
  quickThrows: number;
  rucks: number;
  rucksWon: number;
  rucksLost: number;
  rucksWonPercent: number;
  opponentRucksWon: number;
  opponentRucksLost: number;
  mauls: number;
  maulsWon: number;
  maulsLost: number;
  maulWonPercent: number;
  opponentMaulsWon: number;
  opponentMaulsLost: number;
  fouls: number;
  teamFouls: number;

  get userMinutes(): string {
    if (!this.gameTime) {
      return '00:00';
    }
    const minutes = Math.floor(this.gameTime / 60);
    const seconds = Math.floor(this.gameTime - minutes * 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
