import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from './util';
import { HandballStatistic } from './handball-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: HandballStatistic,
    competitorTeam: HandballStatistic
  }
})
export class HandballGameTeamStatistic extends BaseModel {
  team: HandballStatistic;
  competitorTeam: HandballStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
