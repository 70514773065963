import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, DateField } from './util';
import { TournamentTeamUser } from './tournament-team-user';
import { Team } from './team';
import { TeamUser } from './team-user';
import { User } from './user';
import { TournamentTeam } from './tournament-team';

@ModelInstance({
  mappingFields: {
    tournament_team_user: 'tournamentTeamUser',
    team: 'team',
    team_user: 'teamUser',
    user: 'user',
    tournament_team: 'tournamentTeam',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    points: 'points',
    shot_misses: 'shotMisses',
    shots_on_goal: 'shotsOnGoal',
    shots_blocked: 'shotsBlocked',
    goals: 'goals',
    assists: 'assists',
    penalty_goals: 'penaltyGoals',
    penalty_misses: 'penaltyMisses',
    penalty_shots_on_goal: 'penaltyShotsOnGoal',
    saves: 'saves',
    penalty_saves: 'penaltySaves',
    goals_against: 'goalsAgainst',
    penalty_goals_against: 'penaltyGoalsAgainst',
    safety_rate: 'safetyRate',
    fouls: 'fouls',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    two_minute_fouls: 'twoMinuteFouls',
    turnovers: 'turnovers',
    steals: 'steals',
    block_shots: 'blockShots',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    shots: 'shots',
    goals_percent: 'goalsPercent',
    penalty_shots: 'penaltyShots',
    penalty_goals_percent: 'penaltyGoalsPercent',
    shots_against: 'shotsAgainst',
    saves_percent: 'savesPercent',
    penalty_shots_against: 'penaltyShotsAgainst',
    penalty_saves_percent: 'penaltySavesPercent',
    total_goals: 'totalGoals',
    total_saves: 'totalSaves',
    total_goals_against: 'totalGoalsAgainst',
    newbie: 'newbie'
  },
  relation: {
    tournamentTeamUser: TournamentTeamUser,
    team: Team,
    teamUser: TeamUser,
    user: User,
    tournamentTeam: TournamentTeam,
    month: DateField
  }
})
export class HandballStatistic extends BaseModel {
  tournamentTeamUser: TournamentTeamUser;
  team: Team;
  teamUser: TeamUser;
  user: User;
  tournamentTeam: TournamentTeam;
  month: Date;
  winLose: boolean;
  gamesCount: number;
  wonGamesCount: number;
  points: number;
  shotMisses: number;
  shotsOnGoal: number;
  shotsBlocked: number;
  goals: number;
  assists: number;
  penaltyGoals: number;
  penaltyMisses: number;
  penaltyShotsOnGoal: number;
  saves: number;
  penaltySaves: number;
  goalsAgainst: number;
  penaltyGoalsAgainst: number;
  safetyRate: number;
  fouls: number;
  yellowCards: number;
  redCards: number;
  twoMinuteFouls: number;
  turnovers: number;
  steals: number;
  blockShots: number;
  gameTime: number;
  plusMinus: number;
  shots: number;
  goalsPercent: number;
  penaltyShots: number;
  penaltyGoalsPercent: number;
  shotsAgainst: number;
  savesPercent: number;
  penaltyShotsAgainst: number;
  penaltySavesPercent: number;
  totalGoals: number;
  totalSaves: number;
  totalGoalsAgainst: number;
  newbie: boolean;

  get userMinutes(): string {
    if (!this.gameTime) {
      return '00:00';
    }
    const minutes = Math.floor(this.gameTime / 60);
    const seconds = Math.floor(this.gameTime - minutes * 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
