import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonDirective } from '../button/button.directive';

const noop = () => {};

type colors = 'primary' | 'default' | 'secondary';

@Component({
  selector: 'mtg-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonGroupComponent),
      multi: true
    }
  ]
})
export class ButtonGroupComponent implements OnInit, ControlValueAccessor {
  private _onTouchFunc: any = noop;
  private _onChangeFunc: any = noop;

  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';
  @Input()
  color: colors = 'default';

  buttons: ButtonDirective[] = [];

  @Input()
  value: any;
  @Input()
  disabled: boolean;
  @Output()
  change = new EventEmitter<any>();

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this._onChangeFunc = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchFunc = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.markButtons();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addButton(button: ButtonDirective): void {
    button.color = button.value === this.value ? this.getActiveColor() : this.getDefaultColor();
    button.size = this.size;
    this.buttons.push(button);
  }

  onChange(value: any): void {
    this.value = value;
    this.markButtons();
    this._onChangeFunc(this.value);
    this._onTouchFunc();
    this.change.emit(this.value);
  }

  markButtons() {
    this.buttons.forEach(button => {
      button.color = this.value === button.value ? this.getActiveColor() : this.getDefaultColor();
    });
  }

  getActiveColor(): colors {
    if (this.color === 'primary') {
      return 'default';
    }
    return 'primary';
  }

  getDefaultColor(): colors {
    return this.color;
  }
}
