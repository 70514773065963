import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';

export enum GameFootballPosition {
  defensemen = 1,
  midfielder,
  forward,
  goalkeeper
}

export enum FootballWorkFoot {
  left = 1,
  right,
  both
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    position: 'position',
    work_foot: 'workFoot'
  },
  relation: {
    position: enumField(GameFootballPosition),
    workFoot: enumField(FootballWorkFoot)
  }
})
export class FootballProfile extends BaseModel {
  id: number;
  userId: number;
  position: GameFootballPosition;
  workFoot: FootballWorkFoot;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
