import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, DateTimeField } from './util';

@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    updated_at: 'updatedAt',
    points: 'points',
    tries: 'tries',
    penalty_tries: 'penaltyTries',
    conversion_goals: 'conversionGoals',
    conversion_misses: 'conversionMisses',
    conversion_kicks: 'conversionKicks',
    conversion_goals_percent: 'conversionGoalsPercent',
    penalty_misses: 'penaltyMisses',
    penalty_goals: 'penaltyGoals',
    penalty_kicks_on_goal: 'penaltyKicksOnGoal',
    penalty_goals_percent: 'penaltyGoalsPercent',
    drop_goals: 'dropGoals',
    drop_goal_misses: 'dropGoalMisses',
    drop_goal_kicks: 'dropGoalKicks',
    drop_goals_percent: 'dropGoalsPercent',
    free_kicks: 'freeKicks',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    offloads: 'offloads',
    tackles: 'tackles',
    handling_errors: 'handlingErrors',
    carries_over_gainline: 'carriesOverGainline',
    game_time: 'gameTime',
    plus_minus: 'plusMinus'
  },
  relation: {
    updatedAt: DateTimeField,
  }
})
export class RugbyGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  tries: number;
  penaltyTries: number;
  conversionKicks: number;
  conversionGoals: number;
  conversionMisses: number;
  conversionGoalsPercent: number;
  penaltyMisses: number;
  penaltyGoals: number;
  penaltyKicksOnGoal: number;
  penaltyGoalsPercent: number;
  dropGoals: number;
  dropGoalMisses: number;
  dropGoalKicks: number;
  dropGoalsPercent: number;
  freeKicks: number;
  yellowCards: number;
  redCards: number;
  offloads: number;
  tackles: number;
  handlingErrors: number;
  carriesOverGainline: number;
  gameTime: number;
  plusMinus: number;
  updatedAt: Date;

  get id(): number {
    return this.gameUserId;
  }

  get gameMinutes(): number {
    return Math.floor(this.gameTime / 60);
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
