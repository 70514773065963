<label
  #tooltip
  [ngClass]="{'with-icon': type === 'password' || icon, 'search': search}"
>
  <span class="prefix" *ngIf="prefix">{{ prefix }}</span>
  <input
    #input
    [type]="type === 'password' && showPassword ? 'text' : type"
    (input)="changeValue($event.target.value)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    [disabled]="disabled"
    [readOnly]="readOnly"
    [pattern]="pattern"
    [placeholder]="placeholder || ''"
  />
  <span class="suffix" *ngIf="suffix">{{ suffix }}</span>
  <div class="error-text" *ngIf="getErrors().length && (!isFocused || hasErrorsOnFocus)">
    <div *ngFor="let error of getErrors()">{{ error }}</div>
  </div>
  <span class="placeholder" *ngIf="label">{{ label }} {{ required && '*' || ''}}</span>
  <!--<div class="error-text"></div>-->
  <ng-template #errorText>
    <div class="error-text">

    </div>
  </ng-template>
  <div class="number-controls" *ngIf="type === 'number'">
    <button tabIndex="-1" type="button" (click)="changeValue(+value + 1, true)"><span class="icon-chevron-up"></span></button>
    <button tabIndex="-1" type="button" (click)="changeValue(+value - 1, true)"><span class="icon-chevron-down"></span></button>
  </div>
  <div class="icon" *ngIf="icon"><span class="{{ icon }}"></span></div>
  <div class="icon"
       *ngIf="type === 'password'"
       (click)="showPassword = !showPassword"
  >
    <span class="icon-eye-slash" *ngIf="!showPassword"></span>
    <span class="icon-eye" *ngIf="showPassword"></span>
  </div>
</label>

