import { ModelInstance, ToFrontHook, ToBackHook, enumField, MomentDateField, MomentDateTimeField } from './util';
import * as moment from 'moment';

export enum FileEngine {
  django = 1,
  s3
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    path: 'path',
    mime_type: 'mimeType',
    size: 'size',
    engine: 'engine',
    created_at: 'createdAt',
  },
  relation: {
    engine: enumField(FileEngine),
    createdAt: MomentDateTimeField,
  }
})
export class File {
  id: number;
  name: string;
  path: string;
  mimeType: string;
  size: number;
  engine: FileEngine;
  createdAt: moment.Moment;

  @ToFrontHook
  static toFront(data: any): File {
    return null;
  }

  @ToBackHook
  static toBack(file: File): any {
    return null;
  }
}
