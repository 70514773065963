import { BaseModel, enumField, listField, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { File } from '@core/models/file';
import { UserGender } from '@core/models/user';
import * as moment from 'moment';
import { VolleyballProfile } from '@core/models/volleyball-profile';
import { BasketballProfile } from '@core/models/basketball-profile';
import { HandballProfile } from '@core/models/handball-profile';
import { FootballProfile } from '@core/models/football-profile';
import { HockeyProfile } from '@core/models/hockey-profile';
import { MomentDateField } from '@core/models/util';
import { UserProfile } from '@core/models/user-profile';
import { RugbyProfile } from '@core/models/rugby-profile';
import { Qualification } from '@core/models/league-player';
import { WaterpoloProfile } from '@core/models/waterpolo-profile';
import { LeaguePlayerFieldValue } from '@core/models/league-player-field-value';
import { WrestballProfile } from '@core/models/wrestball-profile';


@ModelInstance({
  mappingFields: {
    id: 'id',
    first_name: 'firstName',
    last_name: 'lastName',
    middle_name: 'middleName',
    email: 'email',
    birth_date: 'birthDate',
    gender: 'gender',
    photo: 'photo',
    rating: 'rating',
    basketball_profile: 'basketballProfile',
    volleyball_profile: 'volleyballProfile',
    hockey_profile: 'hockeyProfile',
    football_profile: 'footballProfile',
    handball_profile: 'handballProfile',
    rugby_profile: 'rugbyProfile',
    waterpolo_profile: 'waterpoloProfile',
    wrestball_profile: 'wrestballProfile',
    profile: 'profile',
    license_number: 'licenseNumber',
    qualification: 'qualification',
    field_values: 'fieldValues'
  },
  relation: {
    photo: File,
    birthDate: MomentDateField,
    gender: enumField(UserGender),
    basketballProfile: BasketballProfile,
    volleyballProfile: VolleyballProfile,
    hockeyProfile: HockeyProfile,
    footballProfile: FootballProfile,
    handballProfile: HandballProfile,
    rugbyProfile: RugbyProfile,
    waterpoloProfile: WaterpoloProfile,
    wrestballProfile: WrestballProfile,
    profile: UserProfile,
    qualification: enumField(Qualification),
    fieldValues: listField(LeaguePlayerFieldValue)
  }
})
export class LeaguePlayerForm extends BaseModel {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  photo: File;
  rating: number;
  email: string;
  birthDate: moment.Moment;
  gender: UserGender;
  basketballProfile: BasketballProfile;
  volleyballProfile: VolleyballProfile;
  hockeyProfile: HockeyProfile;
  footballProfile: FootballProfile;
  handballProfile: HandballProfile;
  rugbyProfile: RugbyProfile;
  waterpoloProfile: WaterpoloProfile;
  wrestballProfile: WrestballProfile;
  profile: UserProfile;
  licenseNumber: string;
  qualification: Qualification;
  fieldValues: LeaguePlayerFieldValue[];

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
