import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { AppLayoutComponent } from '@app/layout/app-layout.component';
import { HeaderComponent } from '@app/layout/components/header/header.component';
import { PageNotFoundComponent } from '@app/layout/components/page-not-found/page-not-found.component';
import { LayoutComponent } from '@app/layout/components/layout/layout.component';
import { LayoutService } from '@app/layout/services/layout.service';
import { FeedbackComponent } from '@app/layout/components/feedback/feedback.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from '@shared/modules/recaptcha/recaptcha.module';
import { NotificationModule } from '@shared/modules/notification/notification.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    RecaptchaModule,
    NotificationModule,
  ],
  declarations: [
    AppLayoutComponent,
    LayoutComponent,
    HeaderComponent,
    PageNotFoundComponent,
    FeedbackComponent,
  ],
  exports: [
    AppLayoutComponent,
    PageNotFoundComponent,
  ],
  entryComponents: [
    FeedbackComponent
  ],
  providers: [
    LayoutService,
  ]
})
export class LayoutModule {}
