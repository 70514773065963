import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    serve_hits: 'serveHits',
    serve_faults: 'serveFaults',
    serve_aces: 'serveAces',
    stuff_blocks: 'stuffBlocks',
    block_faults: 'blockFaults',
    block_rebounds: 'blockRebounds',
    attack_spikes: 'attackSpikes',
    attack_faults: 'attackFaults',
    attack_shots: 'attackShots',
    receives: 'receives',
    receive_faults: 'receiveFaults',
    excellent_receives: 'excellentReceives',
    serve_receives: 'serveReceives',
    serve_receive_faults: 'serveReceiveFaults',
    excellent_serve_receives: 'excellentServeReceives',
    points: 'points',
    faults: 'faults',
    yellow_cards: 'yellowCards',
  }
})
export class VolleyballGameStatistic extends BaseModel {
  gameUserId: number;
  serveHits: number;
  serveFaults: number;
  serveAces: number;
  stuffBlocks: number;
  blockFaults: number;
  blockRebounds: number;
  attackSpikes: number;
  attackFaults: number;
  attackShots: number;
  receives: number;
  receiveFaults: number;
  excellentReceives: number;
  serveReceives: number;
  serveReceiveFaults: number;
  excellentServeReceives: number;
  points: number;
  faults: number;
  yellowCards: number;

  get id(): number {
    return this.gameUserId;
  }

  get attacks(): number {
    return this.attackSpikes + this.attackShots;
  }

  get blocks(): number {
    return this.stuffBlocks + this.blockRebounds;
  }

  get totalReceives(): number {
    return this.receives + this.excellentReceives;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
