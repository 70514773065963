import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from '@core/models/util';
import { HockeyStatistic } from '@core/models/hockey-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: HockeyStatistic,
    competitorTeam: HockeyStatistic
  }
})
export class HockeyGameTeamStatistic extends BaseModel {
  team: HockeyStatistic;
  competitorTeam: HockeyStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
