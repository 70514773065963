import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, enumField, DateField } from './util';
import { File } from './file';

export enum TournamentSeasonStatuses {
  open,
  in_progress,
  closed,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    alias: 'alias',
    logo: 'logo',
    cover: 'cover',
    preview_image: 'previewImage',
    status: 'status',
    start_date: 'startDate',
    closed_date: 'closedDate',
  },
  relation: {
    logo: File,
    cover: File,
    previewImage: File,
    status: enumField(TournamentSeasonStatuses),
    startDate: DateField,
    closedDate: DateField,
  }
})
export class TournamentSeason extends BaseModel {
  id: number;
  name: string;
  alias: string;
  logo: File;
  cover: File;
  previewImage: File;
  status: TournamentSeasonStatuses;
  startDate: Date;
  closedDate: Date;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
