import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { TournamentDivision } from '@core/models/tournament-division';

@Injectable()
export class OrgTournamentDivisionApi {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getListByTournament(tournamentId: number): Promise<TournamentDivision[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/divisions/`).pipe(
      map(response => TournamentDivision.toFront(response)),
    ).toPromise();
  }

  getListByRound(roundId: number): Promise<TournamentDivision[]> {
    return this.httpClient.get(`/org/api/v1/tournament_round/${roundId}/divisions/`).pipe(
      map(response => TournamentDivision.toFront(response)),
    ).toPromise();
  }

  getById(divisionId: number): Promise<TournamentDivision> {
    return this.httpClient.get(`/org/api/v1/tournament_division/${divisionId}/`).pipe(
      map(response => TournamentDivision.toFront(response)),
    ).toPromise();
  }

  create(tournamentId: number, division: TournamentDivision): Promise<TournamentDivision> {
    return this.httpClient.post(`/org/api/v1/tournament/${tournamentId}/divisions/`, TournamentDivision.toBack(division)).pipe(
      map(response => TournamentDivision.toFront(response)),
    ).toPromise();
  }

  update(divisionId: number, data: any): Promise<TournamentDivision> {
    return this.httpClient.patch(`/org/api/v1/tournament_division/${divisionId}/`, TournamentDivision.toBack(data)).pipe(
      map(response => TournamentDivision.toFront(response)),
    ).toPromise();
  }

  delete(divisionId: number): Promise<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_division/${divisionId}/`).toPromise();
  }
}
