import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isMobileDevice } from '@shared/util/device';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PwaPromptComponent } from '@shared/modules/pwa-prompt/pwa-prompt.component';
import { EXCLUDED_PAGES, PWA_SETTINGS } from '@shared/modules/pwa-prompt/settings';
import { isPlatformBrowser } from '@angular/common';

export interface IPwaPromptSettings {
  appLogoPath: string;
  appName: string;
}

@Injectable({
  providedIn: 'root'
})
export class PwaPromptService {
  private deferredPrompt;
  private overlayRef: OverlayRef;
  private promptSettings: IPwaPromptSettings;

  constructor(
    private overlay: Overlay,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.initialize();
  }

  hidePrompt() {
    this.overlayRef.dispose();
  }

  async installPwa(): Promise<void> {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();

      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        this.deferredPrompt = null;
        this.hidePrompt();
      }
    }
  }

  private initialize(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js', {
        scope: '/'
      }).then(() => {
        console.log('sw registered');
      }).catch(error => {
        console.log('sw registration error', error);
      });
    }
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showPrompt();
    });
  }

  setPromptSettings(settings: IPwaPromptSettings): void {
    this.promptSettings = settings;
    this.showPrompt();
  }

  showPrompt(): void {
    const currentPageUrl = window.location.href;

    if (
      !this.deferredPrompt ||
      !this.promptSettings ||
      !isMobileDevice() ||
      EXCLUDED_PAGES.some(routePart => currentPageUrl.indexOf(routePart) > -1)
    ) {
      return;
    }
    if (this.overlayRef) {
      return;
    }
    this.overlayRef = this.overlay.create();
    const injector = Injector.create({
      providers: [{provide: PWA_SETTINGS, useValue: this.promptSettings}]
    });
    const portal = new ComponentPortal(PwaPromptComponent, null, injector);
    this.overlayRef.attach(portal);
  }
}
