import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Tournament } from '@core/models';
import { map } from 'rxjs/internal/operators';
import { TournamentSeason } from '@core/models/tournament-season';

@Injectable()
export class OrgTournamentSeasonService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getById(seasonId: number): Observable<TournamentSeason> {
    return this.httpClient.get(`/org/api/v1/tournament_season/${seasonId}/`).pipe(
      map(response => TournamentSeason.toFront(response)),
    );
  }

  update(seasonId: number, data: any): Observable<TournamentSeason> {
    return this.httpClient.patch(`/org/api/v1/tournament_season/${seasonId}/`, TournamentSeason.toBack(data)).pipe(
      map(response => TournamentSeason.toFront(response)),
    );
  }

  delete(seasonId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_season/${seasonId}/`);
  }

  getTournaments(seasonId: number): Observable<Tournament[]> {
    return this.httpClient.get(`/org/api/v1/tournament_season/${seasonId}/tournaments/`).pipe(
      map(response => Tournament.toFront(response))
    );
  }
}
