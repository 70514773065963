import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField } from './util';
import { TeamUser } from './team-user';
import * as moment from 'moment';
import { TournamentTeamUser } from '@core/models/tournament-team-user';

@ModelInstance({
  mappingFields: {
    id: 'id',
    game_id: 'gameId',
    team_user: 'teamUser',
    tournament_team_user: 'tournamentTeamUser',
    is_mvp: 'isMvp',
    number: 'number',
    updated_at: 'updatedAt'
  },
  relation: {
    teamUser: TeamUser,
    tournamentTeamUser: TournamentTeamUser,
    updatedAt: MomentDateTimeField,
  }
})
export class GameUser extends BaseModel {
  id: number;
  gameId: number;
  teamUser: TeamUser;
  tournamentTeamUser: TournamentTeamUser;
  isMvp: boolean;
  number: number;
  updatedAt: moment.Moment;

  get gameUserNumber(): number {
    return this.number ? this.number : this.teamUser.number;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
