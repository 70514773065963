import { Component, HostBinding, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { ToastData } from './toast-config';
import { ToastRef } from './toast-ref';

@Component({
  selector: 'mtg-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.3s ease-in', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.3s ease-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class ToastComponent implements OnInit {
  @HostBinding('@openClose') true;

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.close();
    }, this.data.duration || 5000);
  }

  close() {
    setTimeout(() => {
      this.ref.close();
    }, 300);
  }
}
