import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    created: 'Создан',
    optionsCreated: 'Создано вариантов',
    noAnswers: 'Еще никто не ответил.',
    noConsensus: 'Нет единого мнения...',
  },
  [Locales.en]: {
    created: 'Created',
    optionsCreated: 'Options created',
    noAnswers: 'No one has answered yet.',
    noConsensus: 'There is no consensus...',
  }
};
