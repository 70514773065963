import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SelectComponent } from '@ui-kit/form/components/select/select.component';
import { OptionComponent } from '@ui-kit/form/components/select/option.component';

@Component({
  selector: 'mtg-option-group',
  templateUrl: './option-group.component.html',
  styleUrls: ['./option-group.component.scss']
})
export class OptionGroupComponent implements OnInit {
  options: OptionComponent[] = [];

  @Input()
  title: string;

  constructor(
    private selectComponent: SelectComponent
  ) {}

  ngOnInit(): void {
    this.selectComponent.addOptionGroup(this);
  }

  addOption(component: OptionComponent): void {
    this.options.push(component);
  }
}
