import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab.component';
import { TabContentDirective } from './tab-content.directive';
import { TabLabelDirective } from './tab-label.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TabsComponent,
    TabComponent,
    TabContentDirective,
    TabLabelDirective,
  ],
  exports: [
    TabsComponent,
    TabComponent,
    TabContentDirective,
    TabLabelDirective,
  ]
})
export class TabsModule {}
