import { Inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FORM_ERROR_LIST,
  FORM_ERROR_LOCALIZATION,
  LOCALIZATION_DATA,
  LOCALIZATION_DATA_LIST,
} from './token';
import { LocalizationPipe } from './localization.pipe';
import { DatePipe } from './date.pipe';
import { TranslatePipe } from './translate.pipe';
import { FormErrorLocalizationPipe } from './form-error-localization.pipe';
import { LocalizationService } from './localization.service';
import { Locales } from './locales';
import { FORMS_LOCALIZATION } from './forms.localization';
import { CityNamePipe } from '@shared/modules/localization/city-name.pipe';
import { SportNamePipe } from '@shared/modules/localization/sport-name.pipe';

interface LocalizationData { [key: string]: string; }
interface RootLocalization { [key: string]: LocalizationData; }

function mergeLocalization(
  newData: RootLocalization[],
  current: RootLocalization
) {
  return Object.values(Locales).reduce(
    (acc, localeId) => ({
      ...acc,
      [localeId]: {
        ...(acc[localeId] || {}),
        ...newData.reduce(
          (data, value) => ({
            ...data,
            ...value[localeId],
          }),
          {} as LocalizationData
        ),
      },
    }),
    current
  );
}

const ErrorsProvider = {
  provide: FORM_ERROR_LIST,
  useFactory: (value: RootLocalization[], parent: RootLocalization) =>
    mergeLocalization(value, parent || FORMS_LOCALIZATION),
  deps: [
    FORM_ERROR_LOCALIZATION,
    [new SkipSelf(), new Optional(), new Inject(FORM_ERROR_LIST)],
  ],
};

const LocalizationDataProvider = {
  provide: LOCALIZATION_DATA_LIST,
  useFactory: (value: RootLocalization[], parent: RootLocalization) =>
    mergeLocalization(value, parent || {}),
  deps: [
    LOCALIZATION_DATA,
    [new SkipSelf(), new Optional(), new Inject(LOCALIZATION_DATA_LIST)],
  ],
};

@NgModule({
  imports: [CommonModule],
  declarations: [
    TranslatePipe,
    LocalizationPipe,
    FormErrorLocalizationPipe,
    DatePipe,
    CityNamePipe,
    SportNamePipe,
  ],
  exports: [
    TranslatePipe,
    LocalizationPipe,
    FormErrorLocalizationPipe,
    DatePipe,
    CityNamePipe,
    SportNamePipe,
  ],
  providers: [
    LocalizationService,
    LocalizationDataProvider,
    ErrorsProvider,
    {
      provide: FORM_ERROR_LOCALIZATION,
      useValue: {},
      multi: true,
    },
    {
      provide: LOCALIZATION_DATA,
      useValue: {},
      multi: true,
    },
  ],
})
export class LocalizationModule {}
