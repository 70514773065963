import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from '@core/models/util';
import { Team } from '@core/models/team';

export enum GameChallengeStatuses {
  sent = 1,
  accepted,
  declined,
  moderation,
  closed,
  rejected,
}

@ModelInstance({
  mappingFields: {
    team_score: 'teamScore',
    competitor_team_score: 'competitorTeamScore',
    youtube_video: 'youtubeVideo',
  }
})
export class GameChallengeResults extends BaseModel {
  teamScore: number;
  competitorTeamScore: number;
  youtubeVideo: string;

  get youtubeEmbedVideo(): string {
    const url = new URL(this.youtubeVideo);
    let videoId = '';
    if (url.hostname === 'youtu.be' || url.hostname === 'www.youtu.be') {
      videoId = url.pathname;
    } else if (url.hostname === 'youtube.com' || url.hostname === 'www.youtube.com') {
      videoId = url.searchParams.get('v');
    }
    return `https://youtube.com/embed/${videoId}`;
  }

  isSame(results: GameChallengeResults): boolean {
    return results.teamScore === this.teamScore &&
      results.competitorTeamScore === this.competitorTeamScore &&
      results.youtubeVideo === this.youtubeVideo;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    initiator_team: 'initiatorTeam',
    competitor_team: 'competitorTeam',
    tournament_id: 'tournamentId',
    status: 'status',
    initiator_team_results: 'initiatorTeamResults',
    competitor_team_results: 'competitorTeamResults',
  },
  relation: {
    initiatorTeam: Team,
    competitorTeam: Team,
    status: enumField(GameChallengeStatuses),
    initiatorTeamResults: GameChallengeResults,
    competitorTeamResults: GameChallengeResults,
  }
})
export class GameChallenge extends BaseModel {
  id: number;
  initiatorTeam: Team;
  competitorTeam: Team;
  tournamentId: number;
  status: GameChallengeStatuses;
  initiatorTeamResults: GameChallengeResults;
  competitorTeamResults: GameChallengeResults;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
