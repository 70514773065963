import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { MainPageResolve } from './services/main-page.resolve';
import { UserService } from './services/user.service';
import { TeamService } from './services/team.service';
import { FileService } from './services/file.service';
import { GameService } from './services/game.service';
import { PollService } from './services/poll.service';
import { DadataService } from './services/dadata.service';
import { NotificationService } from './services/notification.service';
import { ProfileService } from './services/profile.service';
import { TeamProfileService } from './services/team-profile.service';
import { GameDetailService } from './services/game-detail.service';
import { LoaderService } from './services/loader.service';
import { CentrifugoService } from './services/centrifugo.service';
import { PollDetailService } from './services/poll-detail.service';
import { FeedbackService } from './services/feedback.service';
import { TeamEventService } from './services/team-event.service';
import { TeamEventDetailService } from './services/team-event-detail.service';
import { ReferenceService } from './services/reference.service';
import { TournamentService } from './services/tournament.service';
import { FacebookService } from './services/facebook.service';
import { VKService } from './services/vk.service';
import { OrgGameService } from './services/org-game.service';
import { LeagueService } from './services/league.service';
import { OrgLeagueService } from './services/org-league.service';
import { TournamentAdminService } from './services/tournament-admin.service';
import { TournamentGroupService } from './services/tournament-group.service';
import { TournamentNewsService } from './services/tournament-news.service';
import { VolleyballGameService } from './services/volleyball-game.service';
import { OrgVolleyballGameService } from './services/org-volleyball-game.service';
import { BasketballGameDetailService } from '@core/services/basketball-game-detail.service';
import { VolleyballGameDetailService } from '@core/services/volleyball-game-detail.service';
import { TournamentGameService } from '@core/services/tournament-game.service';
import { TournamentVolleyballGameService } from '@core/services/tournament-volleyball-game.service';
import { TournamentStageService } from '@core/services/tournament-stage.service';
import { OrgMediaService } from '@core/services/org-media.service';
import { MediaService } from '@core/services/media.service';
import { OrgNotificationService } from '@core/services/org-notification.service';
import { BasketballGameService } from '@core/services/basketball-game.service';
import { OrgBasketballGameService } from '@core/services/org-basketball-game.service';
import { TournamentBasketballGameService } from '@core/services/tournament-basketball-game.service';
import { SocialMetaService } from '@core/services/social-meta.service';
import { OrgLeagueNewsService } from '@core/services/org-league-news.service';
import { OrgLeaguePartnerService } from '@core/services/org-league-partner.service';
import { OrgLeagueBannerService } from '@core/services/org-league-banner.service';
import { HockeyGameService } from '@core/services/hockey-game.service';
import { FootballGameService } from '@core/services/football-game.service';
import { FootballGameDetailService } from '@core/services/football-game-detail.service';
import { HockeyGameDetailService } from '@core/services/hockey-game-detail.service';
import { OrgHockeyGameService } from '@core/services/org-hockey-game.service';
import { HandballGameService } from '@core/services/handball-game.service';
import { HandballGameDetailService } from '@core/services/handball-game-detail.service';
import { TournamentFootballGameService } from '@core/services/tournament-football-game.service';
import { OrgLeaguePlayerService } from '@core/services/org-league-player.service';
import { OrgLeagueDocumentService } from '@core/services/org-league-document.service';
import { OrgLeagueTeamService } from '@core/services/org-league-team.service';
import { LoyaltyProgramsService } from '@core/services/loyalty-programs.service';
import { RugbyGameDetailService } from '@core/services/rugby-game-detail.service';
import { RugbyGameService } from '@core/services/rugby-game.service';
import { UrlService } from '@core/services/url.service';
import { OrgTournamentSeasonService } from '@core/services/org-tournament-season.service';
import { OrgAuthService } from '@core/services/org-auth.service';
import { OrgGameChallengeService } from '@core/services/org-game-challenge.service';
import { TournamentHockeyGameService } from '@core/services/tournament-hockey-game.service';
import { WaterpoloGameDetailService } from '@core/services/waterpolo-game-detail.service';
import { TournamentWaterpoloGameService } from '@core/services/tournament-waterpolo-game.service';
import { WaterpoloGameService } from '@core/services/waterpolo-game.service';
import { OrgWaterpoloGameService } from '@core/services/org-waterpolo-game.service';
import { TournamentHandballGameService } from '@core/services/tournament-handball-game.service';
import { OrgTournamentDivisionApi } from '@core/services/org-tournament-division-api';
import { OrgTournamentRoundApi } from '@core/services/org-tournament-round-api';
import { OrgSeasonTournamentService } from '@core/services/org-season-tournament.service';
import { OrgFootballGameService } from '@core/services/org-football-game.service';
import { OrgHandballGameService } from '@core/services/org-handball-game.service';
import { OrgRugbyGameService } from '@core/services/org-rugby-game.service';
import { OrgLeaguePlayerFieldService } from '@core/services/org-league-player-field.service';
import { OrgLeagueTeamFieldService } from '@core/services/org-league-team-field.service';
import { TournamentWrestballGameService } from '@core/services/tournament-wrestball-game.service';
import { RsvAuthService } from '@core/services/rsv-auth.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
  ],
  providers: [
    AuthService,
    OrgAuthService,
    AuthGuard,
    GuestGuard,
    MainPageResolve,
    FacebookService,
    VKService,
    RsvAuthService,
    FileService,
    UserService,
    TeamService,
    GameService,
    PollService,
    DadataService,
    NotificationService,
    OrgNotificationService,
    ProfileService,
    TeamProfileService,
    GameDetailService,
    BasketballGameDetailService,
    VolleyballGameDetailService,
    FootballGameDetailService,
    HockeyGameDetailService,
    WaterpoloGameDetailService,
    LoaderService,
    CentrifugoService,
    PollDetailService,
    FeedbackService,
    TeamEventService,
    TeamEventDetailService,
    TournamentAdminService,
    TournamentGroupService,
    TournamentNewsService,
    TournamentGameService,
    TournamentVolleyballGameService,
    TournamentBasketballGameService,
    TournamentFootballGameService,
    TournamentHockeyGameService,
    TournamentWaterpoloGameService,
    TournamentWrestballGameService,
    TournamentStageService,
    ReferenceService,
    TournamentService,
    OrgGameService,
    OrgLeagueService,
    LeagueService,
    VolleyballGameService,
    OrgVolleyballGameService,
    BasketballGameService,
    OrgBasketballGameService,
    WaterpoloGameService,
    OrgWaterpoloGameService,
    OrgMediaService,
    MediaService,
    SocialMetaService,
    OrgLeagueNewsService,
    OrgLeaguePartnerService,
    OrgLeaguePlayerService,
    OrgLeagueTeamService,
    OrgLeagueBannerService,
    OrgLeagueDocumentService,
    HockeyGameService,
    OrgHockeyGameService,
    FootballGameService,
    OrgFootballGameService,
    HandballGameService,
    OrgHandballGameService,
    HandballGameDetailService,
    RugbyGameService,
    OrgRugbyGameService,
    RugbyGameDetailService,
    LoyaltyProgramsService,
    UrlService,
    OrgTournamentSeasonService,
    OrgGameChallengeService,
    TournamentHandballGameService,
    OrgTournamentDivisionApi,
    OrgTournamentRoundApi,
    OrgSeasonTournamentService,
    OrgLeaguePlayerFieldService,
    OrgLeagueTeamFieldService
  ]
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor (
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
