import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    dateDefault: 'Дата не назначена',
    addGame: 'Добавить игру',
    loser: 'Проигравший',
    winner: 'Победитель',
    final: 'Финал',
    place: 'место',
    quarterfinals: 'Четвертьфинал',
    semiFinals: 'Полуфинал',
    finalEndText: 'финала',
    third: '3',
    loserBracket: 'Проигравшие',
    round: 'Раунд',
  },
  [Locales.en]: {
    dateDefault: 'Empty date',
    addGame: 'Add game',
    loser: 'Loser',
    winner: 'Winner',
    final: 'Final',
    place: 'place',
    quarterfinals: 'Quarterfinals',
    semiFinals: 'Semi-finals',
    finalEndText: 'finals',
    third: '3rd',
    loserBracket: 'Loser bracket',
    round: 'Round',
  }
};
