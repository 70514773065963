import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input, OnInit, Output
} from '@angular/core';
import { Game, GameStatuses } from '@core/models/game';
import { Playoff } from '@core/models/playoff';
import tournamentPlayoffLocalization from '@shared/modules/shared/components/tournament-playoff/tournament-playoff.localization';
import { LocalizationService } from '@shared/modules/localization/localization.service';

@Component({
  selector: 'mtg-tournament-playoff-loser-bracket',
  templateUrl: './tournament-playoff-loser-bracket.component.html',
  styleUrls: [
    './tournament-playoff-loser-bracket.component.scss',
    '../shared/playoff-bracket.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPlayoffLoserBracketComponent{
  @Input()
  games: Game[];
  @Input()
  playoff: Playoff;
  @Input()
  gameRoute = '';
  @Input()
  canManage: boolean;
  @Input()
  openGameInNewWindow: boolean;
  @Input()
  currentRound: number;
  @Output()
  gameForm = new EventEmitter<{playoffNumber: string, gameId: number}>();
  localization = tournamentPlayoffLocalization;
  currentLocale = this.localizationService.getLocale();


  get toursCount(): number {
    const singleEliminationSemifinalRound = this.semiFinalRoundNumber;
    return (singleEliminationSemifinalRound * 2) - 2;
  }

  get semiFinalRoundNumber(): number {
    const toursCount = (Math.log(this.playoff.settings.teamsCount) / Math.log(2)) + 1;
    return toursCount - 2;
  }

  getMatchesCount(i: number): number {
    let toursCount = this.toursCount;
    let power = this.semiFinalRoundNumber;
    const tour = i + 1;
    while (toursCount > tour) {
      if (toursCount % 2 !== 0) {
        power -= 1;
      }
      toursCount -= 1;
    }
    return this.playoff.settings.teamsCount / Math.pow(2, power);
  }

  getRoundNumber(i: number, g: number = 0): any {
    const tour = i + 1;
    return this.localization[this.currentLocale].loserBracket + '. ' + this.localization[this.currentLocale].round + ' ' + tour;
  }

  getNextGameTeam(tour: number, game: number, team: number = 0): any {
    let number;
    if (tour === 0) {
      if (team % 2 === 0) {
        number = ((game + 1) * 2) - 1;
      } else {
        number = ((game + 1) * 2);
      }
      return this.localization[this.currentLocale].loser + ' ' + number;
    }
    const matchesPrevRound = this.getMatchesCount(tour - 1);
    const gameNumber = this.getGameNumber(tour, game);
    if (tour % 2 === 0) {
      number = (gameNumber - matchesPrevRound) + game + team;
      return this.localization[this.currentLocale].winner + ' L' + number;
    } else {
      if (team === 1) {
        const winnerTour = (tour + 1) / 2;
        const matchesInRound = this.getMatchesCount(tour);
        let winnerGameNumber;
        if (matchesInRound >= 8) {
          winnerGameNumber = matchesInRound - game - 1;
        } else {
          if (game % 2 === 0) {
            winnerGameNumber = game + 1;
          } else {
            winnerGameNumber = game - 1;
          }
        }
        number = this.getWinnerGameNumber(winnerTour, winnerGameNumber);
        return this.localization[this.currentLocale].loser + ' ' + number;
      } else {
        number = gameNumber - matchesPrevRound;
        return this.localization[this.currentLocale].winner + ' L' + number;
      }
    }
  }

  generateArray(length: number): any[] {
    return new Array(length);
  }

  getGames(tourNumber: number, gameNumber: number): Game[] {
    let newGameNumber;
    if (tourNumber % 2 === 0) {
      newGameNumber = (gameNumber * 2) + 1;
    } else {
      newGameNumber = gameNumber + 1;
    }
    return this.games.filter(game => game.playoffNumber === `l_${tourNumber + 1}_${newGameNumber}`).sort((a, b) => a.id - b.id);
  }

  getGameNumber(tour: number, game: number): number {
    let gamesCount = 0;
    while (tour) {
      gamesCount += this.getMatchesCount(tour - 1);
      tour--;
    }
    return 1 + game + gamesCount;
  }

  getWinnerGameNumber(tour: number, game: number): number {
    return 1 + game + this.playoff.settings.teamsCount - this.playoff.settings.teamsCount / Math.pow(2, tour);
  }

  getTitle(tour: number): string {
    const matchesCount = this.getMatchesCount(tour);
    if (matchesCount === 4) {
      return this.localization[this.currentLocale].quarterfinals;
    } else if (matchesCount === 2) {
      return this.localization[this.currentLocale].semiFinals;
    } else if (matchesCount === 1) {
      return this.localization[this.currentLocale].final;
    } else {
      return `1/${matchesCount} ${this.localization[this.currentLocale].finalEndText}`;
    }
  }

  constructor(
    private localizationService: LocalizationService,
  ) {}
}
