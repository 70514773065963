import { BaseModel, enumField, listField, ModelInstance, MomentDateField, ToBackHook, ToFrontHook } from './util';
import { UserProfile } from './user-profile';
import { File } from './file';
import * as moment from 'moment';
import { City } from './city';
import { BasketballProfile } from '@core/models/basketball-profile';
import { VolleyballProfile } from '@core/models/volleyball-profile';
import { HockeyProfile } from '@core/models/hockey-profile';
import { FootballProfile } from '@core/models/football-profile';
import { HandballProfile } from '@core/models/handball-profile';
import { RugbyProfile } from '@core/models/rugby-profile';
import { WaterpoloProfile } from '@core/models/waterpolo-profile';
import { Sport } from '@core/models/sport';
import { WrestballProfile } from '@core/models/wrestball-profile';

export enum UserGender {
  male = 1,
  female
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    sport: 'sport',
    logo: 'logo',
    created_by_league_id: 'createdByLeagueId',
    unique_code: 'uniqueCode',
    timezone: 'timezone',
  },
  relation: {
    sport: Sport,
    logo: File,
    city: City,
  }
})
export class TeamShort extends BaseModel {
  id: number;
  name: string;
  sport: Sport;
  logo: File;
  createdByLeagueId: number;
  uniqueCode: string;
  timezone: string;

  @ToFrontHook
  static toFront(data: any): any {
    return null;
  }

  @ToBackHook
  static toBack(team: TeamShort): any {
    return null;
  }
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    last_name: 'lastName',
    first_name: 'firstName',
    middle_name: 'middleName',
    email: 'email',
    phone: 'phone',
    birth_date: 'birthDate',
    photo: 'photo',
    profile: 'profile',
    basketball_profile: 'basketballProfile',
    volleyball_profile: 'volleyballProfile',
    hockey_profile: 'hockeyProfile',
    football_profile: 'footballProfile',
    handball_profile: 'handballProfile',
    rugby_profile: 'rugbyProfile',
    waterpolo_profile: 'waterpoloProfile',
    wrestball_profile: 'wrestballProfile',
    wizards: 'wizards',
    city: 'city',
    gender: 'gender',
    is_active: 'isActive',
    created_by_league_id: 'createdByLeagueId',
    unique_code: 'uniqueCode',
    rsv_account_id: 'rsvAccountId',
    teams: 'teams'
  },
  relation: {
    birthDate: MomentDateField,
    photo: File,
    profile: UserProfile,
    basketballProfile: BasketballProfile,
    volleyballProfile: VolleyballProfile,
    hockeyProfile: HockeyProfile,
    footballProfile: FootballProfile,
    handballProfile: HandballProfile,
    rugbyProfile: RugbyProfile,
    waterpoloProfile: WaterpoloProfile,
    wrestballProfile: WrestballProfile,
    city: City,
    gender: enumField(UserGender),
    teams: listField(TeamShort)
  }
})
export class User extends BaseModel {
  id: number;
  lastName: string;
  firstName: string;
  middleName: string;
  email: string;
  phone: string;
  birthDate: moment.Moment;
  profile: UserProfile;
  basketballProfile: BasketballProfile;
  volleyballProfile: VolleyballProfile;
  hockeyProfile: HockeyProfile;
  footballProfile: FootballProfile;
  handballProfile: HandballProfile;
  rugbyProfile: RugbyProfile;
  waterpoloProfile: WaterpoloProfile;
  wrestballProfile: WrestballProfile;
  photo: File;
  wizards: string[];
  city: City;
  gender: UserGender;
  isActive: boolean;
  createdByLeagueId: number;
  uniqueCode: string;
  rsvAccountId: number;
  teams: TeamShort[];

  @ToFrontHook
  static toFront(data: any): any {
    return null;
  }

  @ToBackHook
  static toBack(user: User): any {
    return null;
  }

  get fullName(): string {
    return [this.lastName, this.firstName].filter(s => s).join(' ');
  }

  get shortName(): string {
    return [this.lastName, this.firstName].filter(s => s).join(' ');
  }

  get initials(): string {
    return [this.lastName, this.firstName].filter(s => s).map(s => s.substr(0, 1)).join('');
  }

  get age(): number {
    if (!this.birthDate) {
      return null;
    }
    return moment().diff(this.birthDate, 'years');
  }
}
