import { BaseModel, enumField, listField, MomentDateField, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { User } from './user';
import { File } from '@core/models/file';
import { UserGender } from '@core/models/user';
import * as moment from 'moment';
import { LeaguePlayerFieldValue } from '@core/models/league-player-field-value';


export enum Qualification {
  practician = 1,
  amateur,
  practician2,
  practician3,
  amateur2,
  theorist,
  theorist2,
  theorist3,
  sportsman,
  lider,
  master,
  profi,
  goalkeeper,
  goalkeeper2,
  goalkeeper_sh,
  goalkeeper_ul,
  goalkeeper_mhl,
  goalkeeper_vhl,
  goalkeeper_khl,
  beginner,
  junior,
  debutant,
  schoolchild,
  preschool_child
}

export const QualificationLocalization = {
  [Qualification.practician]: 'Практик',
  [Qualification.practician2]: 'Практик 2',
  [Qualification.practician3]: 'Практик 3',
  [Qualification.amateur]: 'Любитель',
  [Qualification.amateur2]: 'Любитель 2',
  [Qualification.theorist]: 'Теоретик',
  [Qualification.theorist2]: 'Теоретик 2',
  [Qualification.theorist3]: 'Теоретик 3',
  [Qualification.sportsman]: 'Спортсмен',
  [Qualification.lider]: 'Лидер',
  [Qualification.master]: 'Мастер',
  [Qualification.profi]: 'Профи',
  [Qualification.goalkeeper]: 'Вратарь',
  [Qualification.goalkeeper2]: 'Вратарь 2',
  [Qualification.goalkeeper_sh]: 'Вратарь СШ',
  [Qualification.goalkeeper_ul]: 'Вратарь ЮЛ',
  [Qualification.goalkeeper_mhl]: 'Вратарь МХЛ',
  [Qualification.goalkeeper_vhl]: 'Вратарь ВХЛ',
  [Qualification.goalkeeper_khl]: 'Вратарь КХЛ',
  [Qualification.beginner]: 'Новичок',
  [Qualification.junior]: 'Юниор',
  [Qualification.debutant]: 'Дебютант',
  [Qualification.schoolchild]: 'Школьник',
  [Qualification.preschool_child]: 'Дошкольник',
};

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    league_id: 'leagueId',
    first_name: 'firstName',
    last_name: 'lastName',
    middle_name: 'middleName',
    birth_date: 'birthDate',
    gender: 'gender',
    photo: 'photo',
    has_changes: 'hasChanges',
    rating: 'rating',
    user: 'user',
    license_number: 'licenseNumber',
    additional_data: 'additionalData',
    qualification: 'qualification',
    field_values: 'fieldValues'
  },
  relation: {
    photo: File,
    user: User,
    birthDate: MomentDateField,
    gender: enumField(UserGender),
    qualification: enumField(Qualification),
    fieldValues: listField(LeaguePlayerFieldValue)
  }
})
export class LeaguePlayer extends BaseModel {
  id: number;
  userId: number;
  leagueId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: moment.Moment;
  gender: UserGender;
  photo: File;
  hasChanges: boolean;
  rating: string;
  user: User;
  licenseNumber: string;
  additionalData: any;
  qualification: Qualification;
  fieldValues: LeaguePlayerFieldValue[];

  get fullName(): string {
    return [this.lastName, this.firstName, this.middleName].filter(s => s).join(' ');
  }

  get shortName(): string {
    return [this.lastName, this.firstName].filter(s => s).join(' ');
  }

  get initials(): string {
    return [this.lastName, this.firstName].filter(s => s).map(s => s.substr(0, 1)).join('');
  }

  get age(): number {
    if (!this.birthDate) {
      return null;
    }
    return moment().diff(this.birthDate, 'years');
  }

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
