import { ModelInstance, BaseModel, ToBackHook, ToFrontHook, enumField } from './util';

export enum TeamPermissionTypes {
  for_all = 1,
  for_users,
  for_team
}

@ModelInstance({
  mappingFields: {
    players: 'players',
    games: 'games',
    statistic: 'statistic',
    polls: 'polls',
    events: 'events'
  },
  relation: {
    players: enumField(TeamPermissionTypes),
    games: enumField(TeamPermissionTypes),
    statistic: enumField(TeamPermissionTypes),
    polls: enumField(TeamPermissionTypes),
    events: enumField(TeamPermissionTypes)
  }
})
export class TeamPermission extends BaseModel {
  players: TeamPermissionTypes;
  games: TeamPermissionTypes;
  statistic: TeamPermissionTypes;
  polls: TeamPermissionTypes;
  events: TeamPermissionTypes;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
