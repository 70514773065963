import { BaseModel, enumField, ModelInstance, ToBackHook, ToFrontHook } from './util';

export enum GameVolleyballPosition {
  setter = 1,
  libero,
  middle_player,
  receiver_attacker,
  dioganal
}

export enum VolleyballWorkHand {
  left = 1,
  right
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user_id: 'userId',
    position: 'position',
    work_hand: 'workHand'
  },
  relation: {
    position: enumField(GameVolleyballPosition),
    workHand: enumField(VolleyballWorkHand)
  }
})
export class VolleyballProfile extends BaseModel {
  id: number;
  userId: number;
  position: GameVolleyballPosition;
  workHand: VolleyballWorkHand;

  @ToFrontHook
  static toFront(value: any): any {
  }

  @ToBackHook
  static toBack(value: any): any {
  }
}
