import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { TeamEvent } from '@core/models/team-event';
import { TeamEventInvite } from '@core/models/team-event-invite';

@Injectable()
export class TeamEventService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getTeamEvent(teamEventId: number): Observable<TeamEvent> {
    return this.httpClient
      .get(`/api/v1/team_event/${teamEventId}/`)
      .pipe(
        map(data => TeamEvent.toFront(data))
      );
  }

  updateTeamEvent(teamEvent: TeamEvent): Observable<TeamEvent> {
    return this.httpClient
      .patch(`/api/v1/team_event/${teamEvent.id}/`, TeamEvent.toBack(teamEvent))
      .pipe(
        map(data => TeamEvent.toFront(data))
      );
  }

  deleteTeamEvent(teamEventId: number): Observable<any> {
    return this.httpClient
      .delete(`/api/v1/team_event/${teamEventId}/`);
  }

  getTeamEventInvites(teamEventId: number): Observable<TeamEventInvite[]> {
    return this.httpClient
      .get(`/api/v1/team_event/${teamEventId}/invites/`)
      .pipe(
        map(data => TeamEventInvite.toFront(data))
      );
  }

  createTeamEventInvite(teamEventId: number, teamUserId: number): Observable<TeamEventInvite> {
    return this.httpClient
      .post(`/api/v1/team_event/${teamEventId}/invites/`, {team_user_id: teamUserId})
      .pipe(
        map(data => TeamEventInvite.toFront(data))
      );
  }

  acceptTeamEventInvite(teamEventInvite: TeamEventInvite): Observable<TeamEventInvite> {
    return this.httpClient
      .post(`/api/v1/team_event_invite/${teamEventInvite.id}/accept/`, {})
      .pipe(
        map(data => TeamEventInvite.toFront(data))
      );
  }

  declineTeamEventInvite(teamEventInvite: TeamEventInvite): Observable<TeamEventInvite> {
    return this.httpClient
      .post(`/api/v1/team_event_invite/${teamEventInvite.id}/decline/`, {})
      .pipe(
        map(data => TeamEventInvite.toFront(data))
      );
  }
}
