import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, MomentDateField, enumField } from './util';
import { TournamentTeamUser } from '@core/models/tournament-team-user';
import { Team } from '@core/models/team';
import { TeamUser } from '@core/models/team-user';
import * as moment from 'moment';
import { User } from '@core/models/user';
import { TournamentTeam } from '@core/models/tournament-team';

@ModelInstance({
  mappingFields: {
    tournament_team_user: 'tournamentTeamUser',
    team: 'team',
    team_user: 'teamUser',
    user: 'user',
    tournament_team: 'tournamentTeam',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    points: 'points',
    two_points_made: 'twoPointsMade',
    three_points_made: 'threePointsMade',
    free_throws_made: 'freeThrowsMade',
    one_points_made: 'onePointsMade',
    two_point_attempts: 'twoPointAttempts',
    three_point_attempts: 'threePointAttempts',
    free_throw_attempts: 'freeThrowAttempts',
    one_point_attempts: 'onePointAttempts',
    two_point_percent: 'twoPointPercent',
    three_point_percent: 'threePointPercent',
    free_throw_percent: 'freeThrowPercent',
    one_point_percent: 'onePointPercent',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    offensive_rebounds: 'offensiveRebounds',
    defensive_rebounds: 'defensiveRebounds',
    steals: 'steals',
    turnovers: 'turnovers',
    personal_fouls: 'personalFouls',
    technical_fouls: 'technicalFouls',
    unsportsmanlike_fouls: 'unsportsmanlikeFouls',
    disqualification_fouls: 'disqualificationFouls',
    drawn_fouls: 'drawnFouls',
    player_efficiency: 'playerEfficiency',
    shots_blocked: 'shotsBlocked',
    four_point_attempts: 'fourPointAttempts',
    four_points_made: 'fourPointsMade',
    four_point_percent: 'fourPointPercent',
    dunk_attempts: 'dunkAttempts',
    dunks_made: 'dunksMade',
    shootouts_won: 'shootoutsWon',
    shootouts_lost: 'shootoutsLost',
    moneyball_free_throw_attempts: 'moneyballFreeThrowAttempts',
    moneyball_free_throws_made: 'moneyballFreeThrowsMade',
    moneyball_free_throw_percent: 'moneyballFreeThrowPercent',
    newbie: 'newbie',
  },
  relation: {
    tournamentTeamUser: TournamentTeamUser,
    team: Team,
    teamUser: TeamUser,
    user: User,
    tournamentTeam: TournamentTeam,
    month: MomentDateField
  }
})
export class BasketballStatistic extends BaseModel {
  tournamentTeamUser: TournamentTeamUser;
  team: Team;
  teamUser: TeamUser;
  user: User;
  tournamentTeam: TournamentTeam;
  month: moment.Moment;
  winLose: boolean;
  gamesCount: number;
  wonGamesCount: number;
  points: number;
  twoPointsMade: number;
  threePointsMade: number;
  freeThrowsMade: number;
  onePointsMade: number;
  twoPointAttempts: number;
  threePointAttempts: number;
  freeThrowAttempts: number;
  onePointAttempts: number;
  twoPointPercent: number;
  threePointPercent: number;
  freeThrowPercent: number;
  onePointPercent: number;
  assists: number;
  blocks: number;
  rebounds: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  steals: number;
  turnovers: number;
  personalFouls: number;
  technicalFouls: number;
  unsportsmanlikeFouls: number;
  disqualificationFouls: number;
  drawnFouls: number;
  playerEfficiency: number;
  shotsBlocked: number;
  fourPointAttempts: number;
  fourPointsMade: number;
  fourPointPercent: number;
  dunkAttempts: number;
  dunksMade: number;
  shootoutsWon: number;
  shootoutsLost: number;
  moneyballFreeThrowAttempts: number;
  moneyballFreeThrowsMade: number;
  moneyballFreeThrowPercent: number;
  newbie: boolean;

  get totalFouls() {
    return this.personalFouls + this.technicalFouls + this.unsportsmanlikeFouls;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
