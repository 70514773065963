<mtg-notification-popup
  [notifications]="popupNotifications"
  [visible]="(notificationDataService.popupNotificationsVisible$ | async) && settings.popup"
  [notificationSettingsRoute]="notificationSettingsRoute"
  (hide)="hidePopupNotifications()"
></mtg-notification-popup>
<mtg-notifications
  *ngIf="notificationDataService.notificationsVisible$ | async"
  [notifications]="notificationDataService.notifications$ | async"
  [loading]="notificationDataService.loading$ | async"
  [notificationSettingsRoute]="notificationSettingsRoute"
  [filterType]="notificationDataService.notificationsFilter$ | async"
  (close)="hideNotifications()"
  (nextPage)="nextPage()"
  (viewed)="markViewed($event)"
  (filterChanged)="changeFilter($event)"
  (markAllViewed)="markAllViewed()"
></mtg-notifications>
<div class="overlay" *ngIf="(notificationDataService.notificationsVisible$ | async) || (notificationDataService.popupNotificationsVisible$ | async)"></div>
