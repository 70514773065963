import { BaseModel, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { File } from '@core/models/file';

@ModelInstance({
  mappingFields: {
    id: 'id',
    league_player_field_id: 'leaguePlayerFieldId',
    value: 'value',
    file: 'file'
  },
  relation: {
    file: File,
  }
})
export class LeaguePlayerFieldValue extends BaseModel {
  id: number;
  leaguePlayerFieldId: number;
  value: string;
  file: File;

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
