import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Team } from '@core/models/team';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PermissionRole, TeamUser, TeamUserRole } from '@core/models/team-user';
import { TeamInvite } from '@core/models/team-invite';
import { User } from '@core/models/user';
import { Poll } from '@core/models/poll';
import { TeamUserStatistic } from '@core/models/team-user-statistic';
import { Game } from '@core/models/game';
import { TeamInviteExternal } from '@core/models/team-invite-external';
import { TeamEvent } from '@core/models/team-event';
import { Tournament } from '@core/models/tournament';
import { TournamentInvite } from '@core/models/tournament-invite';
import { TeamAccess } from '@core/models/team-access';
import { TeamPermission } from '@core/models/team-permission';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';
import { PaginatedResponse } from '@core/services/paginated-response.interface';
import { TournamentTeam } from '@core/models/tournament-team';
import { FootballStatistic } from '@core/models/football-statistic';
import { HandballStatistic } from '@core/models/handball-statistic';
import { BasketballStatistic } from '@core/models/basketball-statistic';
import { RugbyStatistic } from '@core/models/rugby-statistic';
import { HockeyStatistic } from '@core/models/hockey-statistic';
import { WaterpoloStatistic } from '@core/models/waterpolo-statistic';

@Injectable()
export class TeamService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  search(query: string): Observable<Team[]> {
    const params = (new HttpParams()).set('search', query);
    return this.httpClient
      .get('/api/v1/team/', {params})
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  searchBySport(query: string, sportId: number, leagueId?: number, playedInLeague?: false): Observable<Team[]> {
    let params = (new HttpParams()).set('search', query).set('sport', sportId.toString());

    if (leagueId) {
      params = params.set('for_league', leagueId.toString());
    }

    if (playedInLeague && leagueId) {
      params = params.set('league_id', leagueId.toString());
    }

    return this.httpClient
      .get('/api/v1/team/', {params})
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  create(team: Team): Observable<Team> {
    return this.httpClient
      .post('/api/v1/team/', Team.toBack(team))
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  getById(teamId: number): Observable<Team> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/`)
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  getTeamAccess(teamId: number): Observable<TeamAccess> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/access/`)
      .pipe(
        map(data => TeamAccess.toFront(data))
      );
  }

  update(teamId: number, values: any): Observable<Team> {
    return this.httpClient
      .patch(`/api/v1/team/${teamId}/`, Team.toBack(values))
      .pipe(
        map(data => Team.toFront(data))
      );
  }

  delete(team: Team): Observable<any> {
    return this.httpClient
      .delete(`/api/v1/team/${team.id}/`);
  }

  getPermission(teamId: number): Observable<TeamPermission> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/permission/`)
      .pipe(
        map(data => TeamPermission.toFront(data))
      );
  }

  updatePermission(teamId: number, permission: TeamPermission): Observable<TeamPermission> {
    return this.httpClient
      .patch(`/api/v1/team/${teamId}/permission/`, TeamPermission.toBack(permission))
      .pipe(
        map(data => TeamPermission.toFront(data))
      );
  }

  getUsers(teamId: number, tournamentId?: number): Observable<TeamUser[]> {
    let params = new HttpParams();
    if (tournamentId) {
      params = params.set('tournament_id', tournamentId.toString());
    }
    return this.httpClient
      .get(`/api/v1/team/${teamId}/users/`, {params})
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  updateUserRole(teamId: number, teamUserId: number, role: TeamUserRole): Observable<TeamUser> {
    return this.httpClient
      .patch(`/api/v1/team/${teamId}/users/${teamUserId}/role/`, {role: TeamUserRole[role]})
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  updateUserPermissionRole(teamId: number, teamUserId: number, role: PermissionRole): Observable<TeamUser> {
    return this.httpClient
      .patch(`/api/v1/team/${teamId}/users/${teamUserId}/permission_role/`, {permission_role: PermissionRole[role]})
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  updateUserNumber(teamId: number, teamUserId: number, number: number): Observable<TeamUser> {
    return this.httpClient
      .patch(`/api/v1/team/${teamId}/users/${teamUserId}/number/`, {number: number.toString()})
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  deleteUserNumber(teamId: number, teamUserId: number): Observable<any> {
    return this.httpClient
      .patch(`/api/v1/team/${teamId}/users/${teamUserId}/number/`, {number: null})
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  deleteUser(teamUser: TeamUser): Observable<any> {
    return this.httpClient
      .delete(`/api/v1/team/${teamUser.teamId}/users/${teamUser.id}/`);
  }

  changeOwner(teamId: number, user: User): Observable<Team> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/owner/`, {id: user.id})
      .pipe(
        map(data => Team.toFront(data)),
      );
  }

  getInvites(teamId: number): Observable<TeamInvite[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/invites/`)
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  sendInvite(teamId: number, user: User): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/invites/`, {user})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  acceptInvite(invite: TeamInvite): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/team_invite/${invite.id}/accept/`, {})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  declineInvite(invite: TeamInvite): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/team_invite/${invite.id}/decline/`, {})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  resendInvite(invite: TeamInvite): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/team_invite/${invite.id}/resend/`, {})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  getInvitesExternal(teamId: number): Observable<TeamInviteExternal[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/invites_external/`)
      .pipe(
        map(data => TeamInviteExternal.toFront(data))
      );
  }

  sendInviteExternal(teamId: number, invite: TeamInviteExternal): Observable<TeamInviteExternal> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/invites_external/`, TeamInviteExternal.toBack(invite))
      .pipe(
        map(data => TeamInviteExternal.toFront(data))
      );
  }

  deleteInviteExternal(invite: TeamInviteExternal): Observable<any> {
    return this.httpClient
      .delete(`/api/v1/invites_external/${invite.id}/`);
  }

  resendInviteExternal(invite: TeamInviteExternal): Observable<TeamInviteExternal> {
    return this.httpClient
      .post(`/api/v1/invites_external/${invite.id}/resend/`, {})
      .pipe(
        map(data => TeamInviteExternal.toFront(data))
      );
  }

  getPolls(teamId: number, archive: boolean = false): Observable<Poll[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/polls/${archive ? '?archive=1' : ''}`)
      .pipe(
        map(data => Poll.toFront(data))
      );
  }

  createPoll(teamId: number, poll: Poll): Observable<Poll> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/polls/`, Poll.toBack(poll))
      .pipe(
        map(data => Poll.toFront(data))
      );
  }

  getUsersStatistic(teamId: number): Observable<BasketballStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/basketball_statistic/`, {params})
      .pipe(
        map(data => TeamUserStatistic.toFront(data))
      );
  }

  getVolleyballStatistic(teamId: number): Observable<VolleyballStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/volleyball_statistic/`, {params})
      .pipe(
        map(data => VolleyballStatistic.toFront(data))
      );
  }

  getFootballStatistic(teamId: number): Observable<FootballStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/football_statistic/`, {params})
      .pipe(
        map(data => FootballStatistic.toFront(data))
      );
  }

  getHandballStatistic(teamId: number): Observable<HandballStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/handball_statistic/`, {params})
      .pipe(
        map((data: any[]) => HandballStatistic.toFront(data))
      );
  }

  getRugbyStatistic(teamId: number): Observable<RugbyStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/rugby_statistic/`, {params})
      .pipe(
        map((data: any[]) => RugbyStatistic.toFront(data))
      );
  }

  getHockeyStatistic(teamId: number): Observable<HockeyStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/hockey_statistic/`, {params})
      .pipe(
        map((data: any[]) => HockeyStatistic.toFront(data))
      );
  }

  getWaterpoloStatistic(teamId: number): Observable<WaterpoloStatistic[]> {
    const params = new HttpParams()
      .set('team_id', teamId.toString())
      .set('group_by', 'team_user')
      .set('per_game', '0');
    return this.httpClient
      .get(`/api/v1/waterpolo_statistic/`, {params})
      .pipe(
        map((data: any[]) => WaterpoloStatistic.toFront(data))
      );
  }

  joinTeam(teamId: number): Observable<TeamInvite> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/join/`, {})
      .pipe(
        map(data => TeamInvite.toFront(data))
      );
  }

  leaveTeam(teamId: number): Observable<any> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/leave/`, {});
  }

  getGames(teamId: number, archive = false): Observable<Game[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/games/${archive ? '?archive=1' : ''}`)
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  createGame(teamId: number, game: Game): Observable<Game> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/games/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  getTeamEvents(teamId: number): Observable<TeamEvent[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/events/`)
      .pipe(
        map(data => TeamEvent.toFront(data))
      );
  }

  createTeamEvent(teamId: number, teamEvent: TeamEvent): Observable<TeamEvent> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/events/`, TeamEvent.toBack(teamEvent))
      .pipe(
        map(data => TeamEvent.toFront(data))
      );
  }

  getTournaments(teamId: number): Observable<Tournament[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/tournaments/`)
      .pipe(map(data => Tournament.toFront(data)));
  }

  getTournamentTeams(teamId: number): Observable<TournamentTeam[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/tournament_teams/`)
      .pipe(map(data => TournamentTeam.toFront(data)));
  }

  getTournamentInvites(teamId: number): Observable<TournamentInvite[]> {
    return this.httpClient
      .get(`/api/v1/team/${teamId}/tournament_invites/`)
      .pipe(map(data => TournamentInvite.toFront(data)));
  }

  getTournamentGames(teamId: number, tournamentId: number, page: number, size?: number): Observable<PaginatedResponse<Game[]>> {
    let params = new HttpParams().set('page', page.toString());
    if (size) {
      params = params.set('size', size.toString());
    }
    return this.httpClient
      .get(`/api/v1/team/${teamId}/tournaments/${tournamentId}/games/`, {params, observe: 'response'})
      .pipe(map((response: HttpResponse<any>) => {
        return {
          total: +response.headers.get('X-Page-Count'),
          data: Game.toFront(response.body)
        };
      }));
  }

  acceptTournamentInvite(inviteId: number): Observable<TournamentInvite> {
    return this.httpClient
      .post(`/api/v1/tournament_invite/${inviteId}/accept/`, {})
      .pipe(map(data => TournamentInvite.toFront(data)));
  }

  declineTournamentInvite(inviteId: number): Observable<TournamentInvite> {
    return this.httpClient
      .post(`/api/v1/tournament_invite/${inviteId}/decline/`, {})
      .pipe(map(data => TournamentInvite.toFront(data)));
  }

  registerLazyUser(teamId: number, userData: User): Observable<TeamUser> {
    return this.httpClient
      .post(`/api/v1/team/${teamId}/lazy_user/`,  {user: User.toBack(userData)})
      .pipe(map(data => TeamUser.toFront(data)));
  }

  getLazyUser(teamUserId: number): Observable<TeamUser> {
    return this.httpClient
      .get(`/api/v1/lazy_user/${teamUserId}/`)
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  updateLazyUser(teamUserId: number, userData: User): Observable<TeamUser> {
    return this.httpClient
      .patch(`/api/v1/lazy_user/${teamUserId}/`, {user: User.toBack(userData)})
      .pipe(
        map(data => TeamUser.toFront(data))
      );
  }

  deleteLazyUser(teamUserId: number): Observable<any> {
    return this.httpClient
      .delete(`/api/v1/lazy_user/${teamUserId}/`);
  }

  resendLazyUserEmail(teamUserId: number): Observable<any> {
    return this.httpClient
      .post(`/api/v1/lazy_user/${teamUserId}/resend_email/`, {});
  }
}
