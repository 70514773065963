import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, MomentDateTimeField } from './util';
import { File } from './file';
import { User } from './user';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    id: 'id',
    title: 'title',
    picture: 'picture',
    text: 'text',
    author: 'author',
    created_at: 'createdAt',
  },
  relation: {
    picture: File,
    author: User,
    createdAt: MomentDateTimeField,
  }
})
export class TournamentNews extends BaseModel {
  id: number;
  title: string;
  picture: File;
  text: string;
  author: User;
  createdAt: moment.Moment;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
