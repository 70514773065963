import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FootballGameLog } from '@core/models/football-game-log';
import { Game } from '@core/models/game';
import { GameService } from '@core/services/game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { FootballGameConfig } from '@core/models/football-game-config';
import { FootballGameStatistic } from '@core/models/football-game-statistic';
import { FootballGameTeamStatistic } from '@core/models/football-game-team-statistic';

@Injectable()
export class FootballGameService  extends GameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(gameId: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/football_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient.patch(`${this.apiUrl}/football_game/${gameId}/`, data).pipe(
      map(result => Game.toFront(result))
    );
  }

  closeGame(game: Game): Observable<Game> {
    return this.httpClient.post(`${this.apiUrl}/football_game/${game.id}/close/`, {}).pipe(
      map(data => Game.toFront(data))
    );
  }

  getGameLogs(gameId: number): Observable<FootballGameLog[]> {
    return this.httpClient.get(`${this.apiUrl}/football_game/${gameId}/logs/`).pipe(
      map(result => FootballGameLog.toFront(result))
    );
  }

  saveGameLog(gameId: number, gameLog: FootballGameLog): Observable<FootballGameLog> {
    return this.httpClient.post(`${this.apiUrl}/football_game/${gameId}/logs/`, FootballGameLog.toBack(gameLog)).pipe(
      map(result => FootballGameLog.toFront(result))
    );
  }

  updateGameLog(gameLogId: number, data: any): Observable<FootballGameLog> {
    return this.httpClient.patch(`${this.apiUrl}/football_game_log/${gameLogId}/`, FootballGameLog.toBack(data)).pipe(
      map(result => FootballGameLog.toFront(result))
    );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/football_game_log/${gameLogId}/`);
  }

  getGameUserStatistic(gameId: number): Observable<FootballGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/football_game/${gameId}/user_statistic/`).pipe(
      map(result => FootballGameStatistic.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<FootballGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/football_game/${gameId}/team_statistic/`).pipe(
      map(result => FootballGameTeamStatistic.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<any> {
    return of(null);
  }

  updateGameConfig(gameId: number, data: any): Observable<FootballGameConfig> {
    return this.httpClient.patch(`${this.apiUrl}/football_game/${gameId}/`, {
      football_game_config: FootballGameConfig.toBack(data)
    }).pipe(
      map(result => Game.toFront(data).gameConfig)
    );
  }

  getGameProtocolFile(gameId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient.get(`${this.apiUrl}/football_game/${gameId}/game_protocol/`, {params, responseType: 'blob'});
  }
}
