import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    title: 'Страница не найдена',
  },
  [Locales.en]: {
    title: 'Page not found',
  }
};
