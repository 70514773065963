export const b64toBlob = (b64Data: any, contentType: string = '', sliceSize: number = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
};

export const countFileSize = (fileSize: number) => {
  if (fileSize > 1000000) {
    return `${(fileSize / 1000000).toFixed(2)}\u00A0mb`;
  } else if (fileSize < 1000000 && fileSize > 1000) {
    return `${(fileSize / 1000).toFixed(0)}\u00A0kB`;
  } else {
    return `${fileSize}\u00A0b`;
  }
};
