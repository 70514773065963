import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import { City } from '@core/models/city';
import { Locales } from '@shared/modules/localization/locales';

@Pipe({
  name: 'mtgCityName',
})
export class CityNamePipe implements PipeTransform {
  constructor(
    private localizationService: LocalizationService,
  ) {
  }

  transform(value: City): any {
    if (this.localizationService.getLocale() === Locales.en) {
      return value.nameEn;
    }
    return value.name;
  }
}
