import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaComponent } from './recaptcha.component';
import { RecaptchaService } from './recaptcha.service';
import { FormModule } from '@shared/ui-kit/form/form.module';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    RecaptchaComponent,
  ],
  providers: [
    RecaptchaService,
  ],
  exports: [
    RecaptchaComponent,
  ]
})
export class RecaptchaModule {}
