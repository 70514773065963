import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField, DateTimeField } from '@core/models/util';

export enum VichanterJobStatuses {
  in_queue = 1,
  in_progress,
  done,
  error
}

@ModelInstance({
  mappingFields: {
    archive_url: 'archiveUrl',
    available_till: 'availableTill',
  },
  relation: {
    availableTill: DateTimeField,
  }
})
export class VichanterJobResult extends BaseModel {
  archiveUrl: string;
  availableTill: Date;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    request_uid: 'requestUid',
    status: 'status',
    payload: 'payload',
    result: 'result',
    error: 'error',
  },
  relation: {
    status: enumField(VichanterJobStatuses),
    result: VichanterJobResult,
  }
})
export class VichanterJob extends BaseModel {
  requestUid: string;
  status: VichanterJobStatuses;
  payload: any;
  result: VichanterJobResult;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
