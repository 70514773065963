import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    attach: 'Прикрепить файл',
  },
  [Locales.en]: {
    attach: 'Attach a file',
  }
};
