import { ActivatedRouteSnapshot } from '@angular/router';

export function getRouteParams(route: ActivatedRouteSnapshot): {[key: string]: any} {
  if (!route.parent) {
    return route.params || {};
  }
  return {
    ...route.params,
    ...getRouteParams(route.parent),
  };
}
