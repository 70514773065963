import {
  ModelInstance,
  BaseModel,
  ToFrontHook,
  ToBackHook,
  MomentDateTimeField,
  enumField,
  DateField,
  MomentDateField
} from '@core/models/util';
import { File } from './file';
import * as moment from 'moment';

export enum TournamentStageStatuses {
  open,
  in_progress,
  closed
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    date: 'date',
    tournament_id: 'tournamentId',
    status: 'status',
    location: 'location',
    picture: 'picture',
  },
  relation: {
    date: MomentDateField,
    status: enumField(TournamentStageStatuses),
    picture: File,
  }
})
export class TournamentStage extends BaseModel {
  id: number;
  name: string;
  date: moment.Moment;
  tournamentId: number;
  status: TournamentStageStatuses;
  location: string;
  picture: File;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
