export const ROUTE_AUTHENTICATION_ROOT = 'auth';
export const ROUTE_AUTHENTICATION_LOGIN = 'login';
export const ROUTE_AUTHENTICATION_LOGIN_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_LOGIN}`;
export const ROUTE_AUTHENTICATION_REGISTRATION = 'registration';
export const ROUTE_AUTHENTICATION_REGISTRATION_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_REGISTRATION}`;
export const ROUTE_AUTNETICATION_CONFIRMATION = `confirmation`;
export const ROUTE_AUTHENTICATION_CONFIRMATION_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTNETICATION_CONFIRMATION}`;
export const ROUTE_AUTHENTICATION_INVITE_CONFIRM = `invite`;
export const ROUTE_AUTHENTICATION_REG_CONFIRM = 'confirm';
export const ROUTE_AUTHENTICATION_INVITE_CONFIRM_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_INVITE_CONFIRM}`;
export const ROUTE_AUTHENTICATION_RESET_PASSWORD = `reset_password`;
export const ROUTE_AUTHENTICATION_RESET_PASSWORD_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_RESET_PASSWORD}`;
export const ROUTE_AUTHENTICATION_CHANGE_PASSWORD = `change_password`;
export const ROUTE_AUTHENTICATION_CHANGE_PASSWORD_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_CHANGE_PASSWORD}`;

export const ROUTE_POLICY = 'privacy_policy';
export const ROUTE_POLICY_ABSOLUTE = `/${ROUTE_POLICY}`;

export const ROUTE_PROFILE = 'profile';
export const ROUTE_PROFILE_ABSOLUTE = `/${ROUTE_PROFILE}`;
export const ROUTE_PROFILE_FORM = `${ROUTE_PROFILE}/edit`;
export const ROUTE_PROFILE_FORM_ABSOLUTE = `/${ROUTE_PROFILE_FORM}`;
export const ROUTE_PROFILE_FORM_PERSONAL = 'personal';
export const ROUTE_PROFILE_FORM_SPORT = 'sport';
export const ROUTE_PROFILE_FORM_PASSWORD = 'password';
export const ROUTE_PROFILE_FORM_SETTINGS = 'settings';
export const ROUTE_PROFILE_FORM_SETTINGS_ABSOLUTE = `${ROUTE_PROFILE_FORM_ABSOLUTE}/settings`;

export const ROUTE_CREATE_TEAM = 'create-team';
export const ROUTE_PROFILE_CREATE_TEAM_ABSOLUTE = `${ROUTE_PROFILE_ABSOLUTE}/${ROUTE_CREATE_TEAM}`;

export const ROUTE_DASHBOARD_ROOT = 'dashboard';
export const ROUTE_DASHBOARD_ROOT_ABSOLUTE = `/${ROUTE_DASHBOARD_ROOT}`;

export const ROUTE_TEAM_ROOT = 'team';
export const ROUTE_TEAM_ROOT_ABSOLUTE = `/${ROUTE_TEAM_ROOT}`;
export const ROUTE_TEAM_DETAIL = `:id`;
export const ROUTE_TEAM_PRIVACY_SETTINGS = `privacy_settings`;
export const ROUTE_TEAM_DETAIL_ABSOLUTE = `/${ROUTE_TEAM_ROOT}/${ROUTE_TEAM_DETAIL}`;
export const ROUTE_TEAM_NEW = `${ROUTE_TEAM_ROOT}/new`;
export const ROUTE_TEAM_NEW_ABSOLUTE = `/${ROUTE_TEAM_NEW}`;
export const ROUTE_TEAM_EDIT = `edit`;
export const ROUTE_TEAM_EDIT_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_TEAM_EDIT}`;

export const ROUTE_TEAM_INVITE_USER = 'invite-user';
export const ROUTE_TEAM_INVITE_USER_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_TEAM_INVITE_USER}`;
export const ROUTE_TEAM_CREATE_GAME = 'create-game';
export const ROUTE_TEAM_CREATE_GAME_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_TEAM_CREATE_GAME}`;
export const ROUTE_TEAM_CREATE_POLL = 'create-poll';
export const ROUTE_TEAM_CREATE_POLL_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_TEAM_CREATE_POLL}`;

export const ROUTE_TEAM_POLL_ROOT = 'poll';
export const ROUTE_TEAM_POLL = `${ROUTE_TEAM_POLL_ROOT}/:pollId`;
export const ROUTE_TEAM_POLL_EDIT = 'edit';
export const ROUTE_TEAM_ARCHIVED_POLLS = 'archived-polls';

export const ROUTE_GAME_ROOT = 'game';
export const ROUTE_GAME_ROOT_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_GAME_ROOT}`;
export const ROUTE_GAME = `${ROUTE_GAME_ROOT}/:gameId`;
export const ROUTE_GAME_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_GAME}`;
export const ROUTE_GAME_EDIT = `edit`;
export const ROUTE_GAME_EDIT_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_GAME}/${ROUTE_GAME_EDIT}`;

export const ROUTE_ARCHIVED_GAMES = 'archived-games';
export const ROUTE_ARCHIVED_GAMES_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_ARCHIVED_GAMES}`;

export const ROUTE_TEAM_EVENT_ROOT = 'event';
export const ROUTE_TEAM_EVENT = `${ROUTE_TEAM_EVENT_ROOT}/:eventId`;
export const ROUTE_TEAM_EVENT_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_TEAM_EVENT}`;
export const ROUTE_TEAM_EVENT_EDIT = 'edit';
export const ROUTE_TEAM_EVENT_EDIT_ABSOLUTE = `${ROUTE_TEAM_DETAIL_ABSOLUTE}/${ROUTE_TEAM_EVENT}/${ROUTE_TEAM_EVENT_EDIT}`;

export const ROUTE_TEAM_TOURNAMENT_APPLICATION = 'tournament-team';
export const ROUTE_TEAM_TOURNAMENT_INVITE = 'tournament-invite';


export const ROUTE_TOURNAMENT_ROOT = 'tournament';
export const ROUTE_TOURNAMENT_ROOT_ABSOLUTE = `/${ROUTE_TOURNAMENT_ROOT}`;
export const ROUTE_TOURNAMENT_NEWS_ROOT = 'news';
export const ROUTE_TOURNAMENT_NEWS_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_NEWS_ROOT}`;
export const ROUTE_TOURNAMENT_JOIN = 'join';
export const ROUTE_TOURNAMENT_TABLE = 'table';
export const ROUTE_TOURNAMENT_GRID = 'grid';
export const ROUTE_TOURNAMENT_GRID_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_GRID}`;
export const ROUTE_TOURNAMENT_GAMES_ROOT = 'games';
export const ROUTE_GAME_STATISTIC_ROOT = 'statistic';
export const ROUTE_GAME_PROTOCOL_ROOT = 'protocol';
export const ROUTE_GAME_TIMELINE_ROOT = 'timeline';
export const ROUTE_GAME_USERS = 'users';
export const ROUTE_TOURNAMENT_GAMES_LIST = 'list';
export const ROUTE_TOURNAMENT_GAMES_PLAYOFF = 'playoff';
export const ROUTE_TOURNAMENT_GAMES_PLAYOFF_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_GAMES_ROOT}/${ROUTE_TOURNAMENT_GAMES_PLAYOFF}`;
export const ROUTE_TOURNAMENT_STATISTIC = 'statistic';
export const ROUTE_TOURNAMENT_LEADERS = 'leaders';
export const ROUTE_TOURNAMENT_STATISTIC_PLAYERS = 'players';
export const ROUTE_TOURNAMENT_STATISTIC_TEAMS = 'teams';
export const ROUTE_TOURNAMENT_CONTACTS = 'contacts';
export const ROUTE_TOURNAMENT_MEDIA = 'media';
export const ROUTE_TOURNAMENT_MEDIA_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_MEDIA}`;
export const ROUTE_TOURNAMENT_GAME_MEDIA_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_GAMES_ROOT}/:id/${ROUTE_TOURNAMENT_MEDIA}`;
export const ROUTE_TOURNAMENT_TEAM_ROOT = 'team';
export const ROUTE_TOURNAMENT_TEAM_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_TEAM_ROOT}/:tournamentTeamId`;
export const ROUTE_TOURNAMENT_USER_ROOT = 'player';
export const ROUTE_TOURNAMENT_USER_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_USER_ROOT}/:tournamentUserId`;

export const ROUTE_GAME_MANAGEMENT_ROOT = 'game_management';
export const ROUTE_GAME_MANAGEMENT_ROOT_ABSOLUTE = `/${ROUTE_GAME_MANAGEMENT_ROOT}`;
export const ROUTE_GAME_MANAGEMENT_ORG = 'org';
export const ROUTE_GAME_MANAGEMENT_TEAM = 'team';

export const ROUTE_LEAGUE_ROOT = 'league';
export const ROUTE_LEAGUE_ROOT_ABSOLUTE = `/${ROUTE_LEAGUE_ROOT}`;

export const ROUTE_TOURNAMENT_STAGES_ROOT = 'stages';
export const ROUTE_TOURNAMENT_STAGE_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_STAGES_ROOT}/:stageId`;
export const ROUTE_TOURNAMENT_STAGE_STANDINGS = 'standings';
export const ROUTE_TOURNAMENT_STAGE_RESULTS = 'results';
export const ROUTE_TOURNAMENT_STAGE_GROUPS = 'groups';
export const ROUTE_TOURNAMENT_STAGE_PLAYOFF = 'playoff';
export const ROUTE_TOURNAMENT_STAGE_PLAYOFF_ABSOLUTE = `${ROUTE_TOURNAMENT_STAGE_ABSOLUTE}/${ROUTE_TOURNAMENT_STAGE_PLAYOFF}`;
export const ROUTE_TOURNAMENT_STAGE_GAMES = 'stages-games';
export const ROUTE_TOURNAMENT_STAGE_GAMES_LIST = 'list';
