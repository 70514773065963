import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, listField, MomentDateTimeField, enumField } from '@core/models/util';
import { File } from './file';

export enum BannerLocation {
  slider = 'slider',
  central = 'central',
  media = 'media',
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    title: 'title',
    picture: 'picture',
    link: 'link',
    sort: 'sort',
    location: 'location',
  },
  relation: {
    picture: File,
    location: enumField(BannerLocation),
  }
})
export class LeagueBanner extends BaseModel {
  id: number;
  title: string;
  picture: File;
  link: string;
  sort: number;
  location: BannerLocation;

  @ToFrontHook
  static toFront(data: any): any {
    return undefined;
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
