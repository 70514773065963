import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { GameChallenge, GameChallengeResults } from '@core/models/game-challenge';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class OrgGameChallengeService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getGameChallenges(tournamentId: number): Observable<GameChallenge[]> {
    return this.httpClient.get(`/org/api/v1/tournament/${tournamentId}/game_challenges/`).pipe(
      map(data => GameChallenge.toFront(data))
    );
  }

  approveGameChallenge(gameChallengeId: number, results: GameChallengeResults): Observable<GameChallenge> {
    return this.httpClient.post(
      `/org/api/v1/game_challenge/${gameChallengeId}/approve/`,
      GameChallengeResults.toBack(results)
    ).pipe(
      map(data => GameChallenge.toFront(data))
    );
  }

  rejectGameChallenge(gameChallengeId: number, comment: string): Observable<GameChallenge> {
    return this.httpClient.post(
      `/org/api/v1/game_challenge/${gameChallengeId}/reject/`,
      {reject_comment: comment}
    ).pipe(
      map(data => GameChallenge.toFront(data))
    );
  }
}
