import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Game } from '@core/models/game';
import { VolleyballGameLog } from '@core/models/volleyball-game-log';
import { VolleyballGameConfig } from '@core/models/volleyball-game-config';
import { VolleyballGameTeamStatistic } from '@core/models/volleyball-game-team-statistic';
import { VolleyballGameStatistic } from '@core/models/volleyball-game-statistic';
import { GameService } from '@core/services/game.service';
import { OrgGameService } from '@core/services/org-game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';

@Injectable()
export class VolleyballGameService extends GameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(id: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${id}`).pipe(
      map(data => Game.toFront(data))
    );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/volleyball_game/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/volleyball_game/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(data))
      );
  }

  getGameLogs(gameId: number): Observable<VolleyballGameLog[]> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/logs/`).pipe(
      map(data => VolleyballGameLog.toFront(data))
    );
  }

  saveGameLog(gameId: number, gameLog: VolleyballGameLog): Observable<VolleyballGameLog> {
    return this.httpClient
      .post(`${this.apiUrl}/volleyball_game/${gameId}/logs/`, VolleyballGameLog.toBack(gameLog))
      .pipe(
        map(data => VolleyballGameLog.toFront(data))
      );
  }

  updateGameLog(gameLogId: number, data: any): Observable<VolleyballGameLog> {
    return this.httpClient
      .patch(`${this.apiUrl}/volleyball_game_log/${gameLogId}/`, VolleyballGameLog.toBack(data))
      .pipe(
        map(result => VolleyballGameLog.toFront(data))
      );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/volleyball_game_log/${gameLogId}/`);
  }

  closeGame(game: Game): Observable<Game> {
    return this.httpClient.post(`${this.apiUrl}/volleyball_game/${game.id}/close/`, {}).pipe(
      map(data => Game.toFront(data))
    );
  }

  getGameConfig(gameId: number): Observable<VolleyballGameConfig> {
    return of(null);
  }

  updateGameConfig(gameId: number, data: any): Observable<VolleyballGameConfig> {
    return this.httpClient.patch(`${this.apiUrl}/volleyball_game/${gameId}/`, {
      volleyball_game_config: VolleyballGameConfig.toBack(data)
    }).pipe(
      map(result => Game.toFront(result).gameConfig)
    );
  }

  getGameUserStatistic(gameId: number): Observable<VolleyballGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/user_statistic/`).pipe(
      map(data => VolleyballGameStatistic.toFront(data))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<VolleyballGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/team_statistic/`).pipe(
      map(data => VolleyballGameTeamStatistic.toFront(data))
    );
  }

  getGameProtocolFile(gameId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/game_protocol/`, {params, responseType: 'blob'});
  }
}
