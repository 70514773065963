import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from '@core/models/util';
import { FootballStatistic } from '@core/models/football-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: FootballStatistic,
    competitorTeam: FootballStatistic
  }
})
export class FootballGameTeamStatistic extends BaseModel {
  team: FootballStatistic;
  competitorTeam: FootballStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
