import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentNews } from '@core/models/tournament-news';

@Injectable()
export class TournamentNewsService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  getById(newsId: number): Observable<TournamentNews> {
    return this.httpClient.get(`/org/api/v1/tournament_news/${newsId}/`).pipe(
      map(data => TournamentNews.toFront(data))
    );
  }

  create(news: TournamentNews, tournamentId: number): Observable<TournamentNews> {
    return this.httpClient.post(`/org/api/v1/tournament/${tournamentId}/news/`, TournamentNews.toBack(news)).pipe(
      map(data => TournamentNews.toFront(data))
    );
  }

  update(news: TournamentNews): Observable<TournamentNews> {
    return this.httpClient
      .patch(`/org/api/v1/tournament_news/${news.id}/`, TournamentNews.toBack(news))
      .pipe(
        map(data => TournamentNews.toFront(data))
      );
  }

  delete(newsId: number): Observable<any> {
    return this.httpClient.delete(`/org/api/v1/tournament_news/${newsId}/`);
  }

}
