import { NgModule } from '@angular/core';
import { ButtonModule } from './button/button.module';
import { DialogModule } from './dialog/dialog.module';
import { ExpansionPanelModule } from './expansion-panel/expansion-panel.module';
import { FormModule } from './form/form.module';
import { MenuModule } from './menu/menu.module';
import { SpinnerModule } from './spinner/spinner.module';
import { StepperModule } from './stepper/stepper.module';
import { TabsModule } from './tabs/tabs.module';
import { ToastModule } from './toast/toast.module';
import { TooltipModule } from './tooltip/tooltip.module';
import { PaginationModule } from './pagination/pagination.module';
import { AccordionModule } from '@ui-kit/accordion/accordion.module';

@NgModule({
  imports: [
    ButtonModule,
    DialogModule,
    ExpansionPanelModule,
    FormModule,
    MenuModule,
    SpinnerModule,
    StepperModule,
    TabsModule,
    ToastModule,
    TooltipModule,
    PaginationModule,
    AccordionModule,
  ],
  exports: [
    ButtonModule,
    DialogModule,
    ExpansionPanelModule,
    FormModule,
    MenuModule,
    SpinnerModule,
    StepperModule,
    TabsModule,
    ToastModule,
    TooltipModule,
    PaginationModule,
    AccordionModule,
  ]
})
export class UiKitModule {}
