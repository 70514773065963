import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from './util';
import { User } from './user';
import { TeamUser } from './team-user';
import { Game } from './game';

export enum GameInviteStatus {
  accepted = 1,
  declined,
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_user: 'teamUser',
    game_id: 'gameId',
    game: 'game',
    status: 'status',
  },
  relation: {
    game: Game,
    teamUser: TeamUser,
    status: enumField(GameInviteStatus),
  }
})
export class GameInvite extends BaseModel {
  id: number;
  teamUser: TeamUser;
  gameId: number;
  game: Game;
  status: GameInviteStatus;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
