<label>
    <div class="file_input-label">
        <div class="icon">
            <i class="icon-attachment"></i>
        </div>
        <div class="label-text">{{ localization | mtgTranslate: 'attach' }}</div>
    </div>
    <input
        #input
        class="invisible_file_input"
        type="file"
        (input)="changeValue($event.target.files)"
    />
</label>

<div class="file-list">
    <div class="file-item" *ngFor="let file of value; let i = index">
        <div class="file-name">{{ file.name }}</div>
        <div class="remove-file" (click)="removeFileFromInput(i)">
            <i class="icon-close"></i>
        </div>
        <div class="file-size"> ({{ getFileSize(file.size) }}) </div>
    </div>
</div>
