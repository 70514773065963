import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from './util';
import { WrestballStatistic } from './wrestball-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: WrestballStatistic,
    competitorTeam: WrestballStatistic
  }
})
export class WrestballGameTeamStatistic extends BaseModel {
  team: WrestballStatistic;
  competitorTeam: WrestballStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
