import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Game } from '@core/models/game';
import { GameInvite } from '@core/models/game-invite';
import { TeamUser } from '@core/models/team-user';
import { GameBaseService } from '@core/services/game-base.service';

@Injectable()
export class GameService extends GameBaseService {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  updateGameCompetitorTeamScore(gameId: number, competitorTeamScore: number): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/${this.resource}/${gameId}/`, {competitor_team_score: competitorTeamScore})
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  archiveGame(game: Game): Observable<Game> {
    return this.httpClient
      .post(`${this.apiUrl}/${this.resource}/${game.id}/archive/`, {})
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  getGameInvites(gameId: number): Observable<GameInvite[]> {
    return this.httpClient
      .get(`${this.apiUrl}/${this.resource}/${gameId}/invites/`)
      .pipe(
        map(data => GameInvite.toFront(data)),
      );
  }

  sendInvite(gameId: number, teamUser: TeamUser): Observable<GameInvite> {
    return this.httpClient
      .post(`${this.apiUrl}/${this.resource}/${gameId}/invites/`, {team_user: teamUser})
      .pipe(
        map(data => GameInvite.toFront(data))
      );
  }
}
