import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Game, GameResultTypes } from '@core/models/game';
import { map } from 'rxjs/operators';
import { OrgGameService } from '@core/services/org-game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { HockeyGameStatistic } from '@core/models/hockey-game-statistic';
import { HockeyGameTeamStatistic } from '@core/models/hockey-game-team.statistic';
import { HockeyGameLog } from '@core/models/hockey-game-log';
import { HockeyGameConfig } from '@core/models/hockey-game-config';

@Injectable()
export class OrgHockeyGameService extends OrgGameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(id: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${id}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/hockey_game/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/hockey_game/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(result))
      );
  }

  getGameUserStatistic(gameId: number): Observable<HockeyGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/user_statistic/`).pipe(
      map(result => HockeyGameStatistic.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<HockeyGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/team_statistic/`).pipe(
      map(result => HockeyGameTeamStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<HockeyGameLog[]> {
    return this.httpClient
      .get(`${this.apiUrl}/hockey_game/${gameId}/logs/`)
      .pipe(
        map(data => HockeyGameLog.toFront(data))
      );
  }

  saveGameLog(gameId: number, gameLog: HockeyGameLog): Observable<HockeyGameLog> {
    return this.httpClient
      .post(`${this.apiUrl}/hockey_game/${gameId}/logs/`, HockeyGameLog.toBack(gameLog))
      .pipe(
        map(data => HockeyGameLog.toFront(data))
      );
  }

  updateGameLog(gameLogId: number, data: any): Observable<HockeyGameLog> {
    return this.httpClient
      .patch(`${this.apiUrl}/hockey_game_log/${gameLogId}/`, HockeyGameLog.toBack(data))
      .pipe(
        map(result => HockeyGameLog.toFront(result))
      );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient
      .delete(`${this.apiUrl}/hockey_game_log/${gameLogId}/`);
  }

  getGameConfig(gameId: number): Observable<HockeyGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/config/`).pipe(
      map(result => HockeyGameConfig.toFront(result))
    );
  }

  updateGameConfig(gameId: number, data: any): Observable<HockeyGameConfig> {
    return this.httpClient.patch(`${this.apiUrl}/hockey_game/${gameId}/config/`, HockeyGameConfig.toBack(data)).pipe(
      map(result => HockeyGameConfig.toFront(result))
    );
  }

  closeGame(game: Game, resultType?: GameResultTypes): Observable<Game> {
    const payload = {};
    if (resultType) {
      payload['result_type'] = GameResultTypes[resultType];
    }
    return this.httpClient.post(`${this.apiUrl}/hockey_game/${game.id}/close/`, payload).pipe(
      map(result => Game.toFront(result))
    );
  }
}
