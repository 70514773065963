<a href="javascript:;"
   class="btn left"
   (click)="slideLeft()"
   [ngClass]="{disabled: !canScrollLeft}"
   *ngIf="showArrows && arrowsVisible"
><span class="icon-chevron-left"></span></a>
<div class="wrapper" #slider>
  <div class="content" #content cdk-scrollable>
    <ng-content></ng-content>
  </div>
</div>
<a href="javascript:;"
   class="btn right"
   (click)="slideRight()"
   [ngClass]="{disabled: !canScrollRight}"
   *ngIf="showArrows && arrowsVisible"
><span class="icon-chevron-right"></span></a>
