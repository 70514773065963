import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

@ModelInstance({
  mappingFields: {
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    points: 'points',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    steals: 'steals',
    personal_fouls: 'personalFouls',
  }
})
export class UserStatistic extends BaseModel {
  gamesCount: number;
  wonGamesCount: number;
  points: number;
  assists: number;
  blocks: number;
  rebounds: number;
  steals: number;
  personalFouls: number;

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
