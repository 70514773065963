import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Game } from '@core/models/game';
import { map } from 'rxjs/operators';
import { GameUser } from '@core/models/game-user';
import { TeamUser } from '@core/models/team-user';
import { HandballGameConfig } from '@core/models/handball-game-config';
import { HandballGameLog } from '@core/models/handball-game-log';
import { OrgGameService } from '@core/services/org-game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { HandballGameTeamStatistic } from '@core/models/handball-game-team-statistic';
import { HandballGameStatistic } from '@core/models/handball-game-statistic';
import { HandballStatistic } from '@core/models/handball-statistic';

@Injectable()
export class OrgHandballGameService extends OrgGameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(id: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/handball_game/${id}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/handball_game/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/handball_game/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(data))
      );
  }

  getGameLogs(gameId: number): Observable<HandballGameLog[]> {
    return this.httpClient
      .get(`${this.apiUrl}/handball_game/${gameId}/logs/`)
      .pipe(
        map(data => HandballGameLog.toFront(data))
      );
  }

  saveGameLog(gameId: number, gameLog: HandballGameLog): Observable<HandballGameLog> {
    return this.httpClient
      .post(`${this.apiUrl}/handball_game/${gameId}/logs/`, HandballGameLog.toBack(gameLog))
      .pipe(
        map(data => HandballGameLog.toFront(data))
      );
  }

  updateGameLog(gameLogId: number, data: any): Observable<HandballGameLog> {
    return this.httpClient
      .patch(`${this.apiUrl}/handball_game_log/${gameLogId}/`, HandballGameLog.toBack(data))
      .pipe(
        map(result => HandballGameLog.toFront(result))
      );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient
      .delete(`${this.apiUrl}/handball_game_log/${gameLogId}/`);
  }

  getGameConfig(gameId: number): Observable<HandballGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/handball_game/${gameId}/config/`).pipe(
      map(result => HandballGameConfig.toFront(result))
    );
  }

  updateGameConfig(gameId: number, data: any): Observable<HandballGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/handball_game/${gameId}/config/`, HandballGameConfig.toBack(data)).pipe(
      map(result => HandballGameConfig.toFront(result))
    );
  }

  closeGame(game: Game, result_type?: any): Observable<Game> {
    return this.httpClient.post(`${this.apiUrl}/handball_game/${game.id}/close/`, {result_type: result_type}).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<HandballGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/handball_game/${gameId}/team_statistic/`).pipe(
      map(result => HandballGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<HandballGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/handball_game/${gameId}/user_statistic/`).pipe(
      map(result => HandballGameStatistic.toFront(result))
    );
  }
}
