import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';
import { Team } from './team';
import { Tournament } from './tournament';
import { TournamentTeamUserInvite } from '@core/models/tournament-team-user-invite';

@ModelInstance({
  mappingFields: {
    id: 'id',
    team: 'team',
    tournament: 'tournament',
    team_accept: 'teamAccept',
    organization_accept: 'organizationAccept',
    user_invites_count: 'userInvitesCount',
    user_invites: 'userInvites',
    notifications_count: 'notificationsCount',
  },
  relation: {
    team: Team,
    tournament: Tournament,
    userInvites: TournamentTeamUserInvite,
  }
})
export class TournamentInvite extends BaseModel {
  id: number;
  team: Team;
  tournament: Tournament;
  teamAccept: boolean;
  organizationAccept: boolean;
  userInvitesCount: number;
  userInvites: TournamentTeamUserInvite[];
  notificationsCount: number;

  get isOpen(): boolean {
    return (this.teamAccept === null || this.organizationAccept === null) && this.teamAccept !== false && this.organizationAccept !== false;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
