import { GameDetailService } from '@core/services/game-detail.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasketballGameLog, GameLogTypes } from '@core/models/basketball-game-log';
import { filter, tap } from 'rxjs/operators';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameService } from '@core/services/game.service';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { UserService } from '@core/services/user.service';
import { MediaService } from '@core/services/media.service';
import { BasketballGameService } from '@core/services/basketball-game.service';
import { BasketballGameStatistic } from '@core/models/basketball-game-statistic';
import { updateItemInArray } from '@shared/util/data';
import { BasketballGameTeamStatistic } from '@core/models/basketball-game-team-statistic';
import { Game } from '@core/models/game';
import { TournamentService } from '@core/services/tournament.service';

@Injectable()
export class BasketballGameDetailService extends GameDetailService {
  protected _gameStatisticSubject = new BehaviorSubject<BasketballGameStatistic[]>(undefined);

  get gameLogs$(): Observable<BasketballGameLog[]> {
    return this._gameLogsSubject.pipe(filter(item => item !== undefined));
  }

  get gameStatistic$(): Observable<BasketballGameStatistic[]> {
    return this._gameStatisticSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    protected gameService: GameService,
    protected centrifugoService: CentrifugoService,
    protected userService: UserService,
    protected mediaService: MediaService,
    protected basketballGameService: BasketballGameService,
    protected tournamentService: TournamentService,
  ) {
    super(gameService, centrifugoService, userService, mediaService, tournamentService);
  }

  dispose() {
    super.dispose();
    this._gameStatisticSubject.next(undefined);
  }

  getGame(gameId): Observable<Game> {
    return this.basketballGameService.getById(gameId).pipe(
      tap(game => this._gameSubject.next(game))
    );
  }

  getGameLogs(gameId: number): Observable<BasketballGameLog[]> {
    return this.basketballGameService.getGameLogs(gameId).pipe(
      tap(logs => this._gameLogsSubject.next(logs))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<BasketballGameTeamStatistic> {
    return this.basketballGameService.getGameTeamStatistic(gameId);
  }

  getUsersStatistic(gameId: number): Observable<BasketballGameStatistic[]> {
    return this.basketballGameService.getGameUserStatistic(gameId).pipe(
      tap(statistic => this._gameStatisticSubject.next(statistic))
    );
  }

  getGameProtocolFile(type: string): Observable<any> {
    if (!this._gameSubject.value) {
      return;
    }
    return this.basketballGameService.getGameProtocolFile(this._gameSubject.value.id, type);
  }

  protected updateGameScore() {
    if (!this._gameSubject.value) {
      return;
    }
    const game = this._gameSubject.value;
    const teamGameUserIds = (this._gameUsersSubject.value || [])
      .filter(item => item.teamUser.teamId === game.team.id)
      .map(item => item.id);
    game.teamScore = (this._gameStatisticSubject.value || [])
      .filter(item => teamGameUserIds.indexOf(item.gameUserId) > -1)
      .reduce((acc, item) => (acc + (+item.points || 0)), 0);
    game.teamScore += (this._gameLogsSubject.value || [])
      .filter(item => item.teamId === game.team.id)
      .filter(item => !item.gameUserId)
      .reduce((acc, item) => {
        if (item.logType === GameLogTypes.free_throw_made || item.logType === GameLogTypes.one_point_made) {
          return acc + 1;
        } else if (item.logType === GameLogTypes.two_point_made) {
          return acc + 2;
        } else if (item.logType === GameLogTypes.three_point_made) {
          return acc + 3;
        }
        return acc;
      }, 0);

    if (game.competitorTeam.id) {
      const competitorTeamUserIds = (this._gameUsersSubject.value || [])
        .filter(item => item.teamUser.teamId === game.competitorTeam.id)
        .map(item => item.id);
      game.competitorTeamScore = (this._gameStatisticSubject.value || [])
        .filter(item => competitorTeamUserIds.indexOf(item.gameUserId) > -1)
        .reduce((acc, item) => (acc + (+item.points || 0)), 0);
      game.competitorTeamScore += (this._gameLogsSubject.value || [])
        .filter(item => item.teamId === game.competitorTeam.id)
        .filter(item => !item.gameUserId)
        .reduce((acc, item) => {
          if (item.logType === GameLogTypes.free_throw_made || item.logType === GameLogTypes.one_point_made) {
            return acc + 1;
          } else if (item.logType === GameLogTypes.two_point_made) {
            return acc + 2;
          } else if (item.logType === GameLogTypes.three_point_made) {
            return acc + 3;
          }
          return acc;
        }, 0);
    }
    this._gameSubject.next(game);
  }

  protected listenCentrifugoEvents(gameId) {
    super.listenCentrifugoEvents(gameId);
    this.centrifugoService.listen(`game_${gameId}`).subscribe(message => {
      switch (message['action']) {
        case 'GAME_STATISTIC_UPDATED': {
          const userStatistic = BasketballGameStatistic.toFront(message['data']);
          return this._gameStatisticSubject.next(updateItemInArray(
            this._gameStatisticSubject.value,
            userStatistic,
            true,
            item => item.gameUserId === userStatistic.gameUserId
          ));
        }
      }
    });
  }

  protected getGameService(): GameManagementHttpInterface {
    return this.basketballGameService;
  }

  protected gameLogToFront(data: any) {
    return BasketballGameLog.toFront(data);
  }
}
