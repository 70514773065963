import * as Sentry from '@sentry/angular';
import { environment } from '@env/environment';

export function handleError(error, force= false) {
  if (!(error && (error.status === 0 || error.status === 404)) || (force && error.status !== 0)) {
    Sentry.captureException(error);
  }

  console.error(error);
}
