import { Component, Input, OnInit } from '@angular/core';
import { Game, GameStatuses } from '@core/models/game';
import { TournamentDetailService } from '@app/tournament/services/tournament-detail.service';
import { take } from 'rxjs/operators';
import * as RoutingMap from '@app/routing-map';
import { Tournament } from '@core/models/tournament';

@Component({
  selector: 'mtg-tournament-game-card',
  templateUrl: './tournament-game-card.component.html',
  styleUrls: ['./tournament-game-card.component.scss']
})
export class TournamentGameCardComponent {
  @Input() game: Game;
  @Input() tournament: Tournament;
  gameStatuses = GameStatuses;

  shareVk() {
    const route = [
      RoutingMap.ROUTE_TOURNAMENT_ROOT_ABSOLUTE,
      this.tournament.alias,
      RoutingMap.ROUTE_TOURNAMENT_GAMES_ROOT,
      this.game.id.toString()
    ];
    const link = `${window.location.origin}${route.join('/')}`;
    const title = `${this.game.team.name} vs ${this.game.competitorTeam.name}`;
    window.open(`https://vk.com/share.php?url=${link}&title=${title}`, 'social_share_vk', 'left=50,top=50,width=400,height=500');
  }
}
