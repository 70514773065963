import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from '@core/models/util';
import { Team } from '@core/models/team';

@ModelInstance({
  mappingFields: {
    valid_users_count: 'validUsersCount',
    invalid_users_count: 'invalidUsersCount',
    has_access: 'hasAccess',
    invite_sent: 'inviteSent',
    already_in_tournament: 'alreadyInTournament'
  }
})
export class TournamentJoinData extends BaseModel {
  validUsersCount: number;
  invalidUsersCount: number;
  hasAccess: boolean;
  inviteSent: boolean;
  alreadyInTournament: boolean;

  get usersCount(): number {
    return this.validUsersCount + this.invalidUsersCount;
  }

  get valid() {
    return this.hasAccess && !this.inviteSent && !this.alreadyInTournament;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    team: 'team',
    join_data: 'joinData'
  },
  relation: {
    team: Team,
    joinData: TournamentJoinData
  }
})
export class TournamentJoinTeam extends BaseModel {
  team: Team;
  joinData: TournamentJoinData;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
