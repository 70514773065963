import { InjectionToken } from '@angular/core';

export const LOCALIZATION_DATA = new InjectionToken('localizationData');
export const FORM_ERROR_LOCALIZATION = new InjectionToken(
  'formErrorLocalization'
);
export const FORM_ERROR_LIST = new InjectionToken('ErrorList');
export const LOCALIZATION_DATA_LIST = new InjectionToken(
  'LocalizationDataList'
);
