import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { Team } from '@core/models/team';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import * as RoutingMap from '@app/routing-map';
import { TeamProfileService } from '@core/services/team-profile.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { TeamAccess } from '@core/models/team-access';
import { LayoutService } from '@app/layout/services/layout.service';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import myTeamsLocalization from '@app/shared/components/my-teams/my-teams.localization';

@UntilDestroy
@Component({
  selector: 'mtg-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss']
})
export class MyTeamsComponent implements OnInit, OnDestroy {
  teams: Team[];
  access: TeamAccess;
  localization = myTeamsLocalization[this.localizationService.getLocale()];

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private dialogRef: DialogRef,
    private teamProfileService: TeamProfileService,
    private layoutService: LayoutService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    this.layoutService.teams$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(myTeams => {
        this.teams = myTeams;
      });
    this.teamProfileService.access$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(access => this.access = access);
  }

  ngOnDestroy(): void {
  }

  goToTeamPage(teamId: number): void {
    this.router.navigate([
      RoutingMap.ROUTE_TEAM_ROOT_ABSOLUTE,
      teamId.toString()
    ]);
    this.dialogRef.close();
  }

  get createTeamRoute(): string {
    return RoutingMap.ROUTE_PROFILE_CREATE_TEAM_ABSOLUTE;
  }
}
