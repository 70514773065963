import { enumField, ModelInstance, ToFrontHook, ToBackHook, BaseModel, DateTimeField } from './util';
import { GameUser } from './game-user';
import { GameLogBase } from './game-log-base';

export enum WaterpoloGameLogTypes {
  enter_game = 1,
  exit_game,
  shot_miss,
  shot_on_goal,
  shot_blocked,
  goal,
  shootout_attempt,
  shootout_goal,
  assist,
  block_shot ,
  free_kick_on_goal,
  minor_penalty ,
  major_penalty ,
  match_penalty ,
  foul,
  yellow_card,
  red_card,
  penalty_shot ,
  face_off_lose ,
  face_off_win ,
  save ,
  timeout,
  penalty_start,
  penalty_end,
  after_game_shootout_attempt,
  after_game_shootout_goal,
  after_game_shootout_save,
  shootout_save,
  loss,
  steal,
  free_kick_miss,
  free_kick_goal,
  free_kick_pass,
  free_kick_self,
  corner_on_goal,
  corner_miss,
  corner_goal,
  corner_pass,
  corner_self,
  personal_foul,
  counterattack_goal,
  counterattack_on_goal,
  counterattack_miss,
  counterattack_save ,
  maneuver_goal,
  maneuver_on_goal,
  maneuver_miss,
  maneuver_save,
  center_forward_goal,
  center_forward_on_goal,
  center_forward_miss,
  center_forward_save,
  free_kick_save,
  corner_save,
  tackle,
  penalty_foul,
  attack_foul,
  turnover,
  counter_attack_shot_blocked,
  maneuver_shot_blocked,
  center_forward_shot_blocked,
  free_kick_shot_blocked,
  corner_shot_blocked,
  complete_penalty_time
}

export enum WaterpoloAdvantageTypes {
  power_play  = 1,
  shorthanded,
  even_strength
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    advantage: 'advantage',
    advantage_num: 'advantageNum',
    is_goalie: 'isGoalie',
    penalized_game_user_id: 'penalizedGameUserId',
    foul_on_game_user_id: 'foulOnGameUserId',
    mutual_penalty: 'mutualPenalty',
  },
  relation: {
    datetime: DateTimeField,
    logType: enumField(WaterpoloGameLogTypes),
    advantage: enumField(WaterpoloAdvantageTypes)
  }
})
export class WaterpoloGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: WaterpoloGameLogTypes;
  datetime: Date;
  time: number;
  period: number;
  active = true;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  advantage: WaterpoloAdvantageTypes;
  advantageNum: number;
  isGoalie: boolean;
  _penalizedGameUserId: number;
  foulOnGameUserId: number;
  foulOnGameUser: GameUser;
  mutualPenalty: boolean;

  get penalizedGameUserId(): number {
    if (this._penalizedGameUserId) {
      return this._penalizedGameUserId;
    }
    return this.gameUserId;
  }

  set penalizedGameUserId(value: number) {
    this._penalizedGameUserId = value;
  }

  compare(model: WaterpoloGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      return this.datetime.getTime() < model.datetime.getTime() ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  isScoreType(): boolean {
    return [WaterpoloGameLogTypes.goal, WaterpoloGameLogTypes.shootout_goal, WaterpoloGameLogTypes.after_game_shootout_goal,
      WaterpoloGameLogTypes.free_kick_goal, WaterpoloGameLogTypes.corner_goal,
      WaterpoloGameLogTypes.counterattack_goal, WaterpoloGameLogTypes.maneuver_goal, WaterpoloGameLogTypes.center_forward_goal].indexOf(this.logType) > -1;
  }

  isPenaltyType(): boolean {
    return [
      WaterpoloGameLogTypes.minor_penalty, WaterpoloGameLogTypes.major_penalty, WaterpoloGameLogTypes.match_penalty, WaterpoloGameLogTypes.personal_foul
    ].indexOf(this.logType) > -1;
  }

  isFoulType(): boolean {
    return [
      WaterpoloGameLogTypes.minor_penalty, WaterpoloGameLogTypes.major_penalty, WaterpoloGameLogTypes.match_penalty,
      WaterpoloGameLogTypes.personal_foul, WaterpoloGameLogTypes.foul, WaterpoloGameLogTypes.attack_foul, WaterpoloGameLogTypes.penalty_foul
    ].indexOf(this.logType) > -1;
  }

  isAfter(log: WaterpoloGameLog): boolean {
    if (this.time === log.time && this.period === log.period) {
      return this.id > log.id;
    }
    if (this.period === log.period) {
      return this.time > log.time;
    }
    return this.period > log.period;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
