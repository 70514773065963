import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface TextConfig {
  positionX?: 'left' | 'center' | 'right' | number;
  positionY?: 'top' | 'center' | 'bottom' | number;
  width?: number;
  height?: number;
  font?: string;
  color?: string;
  strokeColor?: string;
}

const defaultTextConfig: TextConfig = {
  positionX: 'center',
  positionY: 'center',
};


@Directive({
  selector: 'canvas[mtgMaskText]'
})
export class MaskTextDirective implements OnChanges {
  @Input()
  text: string;
  @Input()
  textConfig: TextConfig = defaultTextConfig;

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && this.text) {
      this.drawText();
    }
  }

  private drawText() {
    const f = new FontFace('StemRegular', 'url(/assets/fonts/stem/StemRegular.otf)');
    const canvas = this.elementRef.nativeElement;

    const fontSetting = this.textConfig.font ? this.textConfig.font : '80px/80px StemRegular';
    const strokeStyle = this.textConfig.strokeColor ? this.textConfig.strokeColor : '#d57944';
    const text = this.text.toUpperCase();
    const color = this.textConfig.color ? this.textConfig.color : 'transparent';
    f.load().then(function(font) {
      document.fonts.add(font);
      const ctx = canvas.getContext('2d');
      const maskSizeRatio = canvas.width / canvas.height;
      const maskHeight = canvas.height;
      const maskWidth = canvas.width;
      let maskTop = 0;
      let maskLeft = 0;
      maskTop = maskHeight / 2;
      maskLeft = maskWidth / 2;
      ctx.clearRect(maskLeft, maskTop, maskWidth, maskHeight);
      ctx.save();

      ctx.font = fontSetting;
      ctx.strokeStyle = strokeStyle;
      ctx.strokeText(text, 0, maskTop);
      ctx.fillStyle = color;
      ctx.fillText(text, 0, maskTop);
      ctx.restore();
    });
  }
}
