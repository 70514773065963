import { ModelInstance, BaseModel, ToBackHook, ToFrontHook } from './util';
import { RugbyStatistic } from '@core/models/rugby-statistic';

@ModelInstance({
  mappingFields: {
    team: 'team',
    competitor_team: 'competitorTeam'
  },
  relation: {
    team: RugbyStatistic,
    competitorTeam: RugbyStatistic
  }
})
export class RugbyGameTeamStatistic extends BaseModel {
  team: RugbyStatistic;
  competitorTeam: RugbyStatistic;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
