import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { GameService } from './game.service';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { UserService } from '@core/services/user.service';
import { VolleyballGameLog } from '@core/models/volleyball-game-log';
import { VolleyballGameService } from '@core/services/volleyball-game.service';
import { VolleyballGameStatistic } from '@core/models/volleyball-game-statistic';
import { GameDetailService } from '@core/services/game-detail.service';
import { VolleyballGameTeamStatistic } from '@core/models/volleyball-game-team-statistic';
import { updateItemInArray } from '@shared/util/data';
import { MediaService } from '@core/services/media.service';
import { Game } from '@core/models/game';
import { TournamentService } from '@core/services/tournament.service';

@Injectable()
export class VolleyballGameDetailService extends GameDetailService {
  protected _gameStatisticSubject = new BehaviorSubject<VolleyballGameStatistic[]>(undefined);

  get gameLogs$(): Observable<VolleyballGameLog[]> {
    return this._gameLogsSubject.pipe(filter(item => item !== undefined));
  }

  get gameStatistic$(): Observable<VolleyballGameStatistic[]> {
    return this._gameStatisticSubject.pipe(filter(item => item !== undefined));
  }

  constructor(
    protected gameService: GameService,
    protected volleyballGameService: VolleyballGameService,
    protected centrifugoService: CentrifugoService,
    protected userService: UserService,
    protected mediaService: MediaService,
    protected tournamentService: TournamentService,
  ) {
    super(gameService, centrifugoService, userService, mediaService, tournamentService);
  }

  dispose() {
    super.dispose();
    this._gameStatisticSubject.next(undefined);
  }

  getGame(gameId): Observable<Game> {
    return this.volleyballGameService.getById(gameId).pipe(
      tap(game => this._gameSubject.next(game))
    );
  }

  getVolleyballGameTeamStatistic(gameId: number): Observable<VolleyballGameTeamStatistic> {
    return this.volleyballGameService.getGameTeamStatistic(gameId);
  }

  getUsersStatistic(gameId: number): Observable<VolleyballGameStatistic[]> {
    return this.volleyballGameService.getGameUserStatistic(gameId).pipe(
      tap(statistic => this._gameStatisticSubject.next(statistic))
    );
  }

  getGameLogs(gameId: number): Observable<VolleyballGameLog[]> {
    return this.volleyballGameService.getGameLogs(gameId).pipe(
      tap(logs => this._gameLogsSubject.next(logs))
    );
  }

  getGameProtocolFile(type: string): Observable<any> {
    if (!this._gameSubject.value) {
      return;
    }
    return this.volleyballGameService.getGameProtocolFile(this._gameSubject.value.id, type);
  }

  protected listenCentrifugoEvents(gameId) {
    super.listenCentrifugoEvents(gameId);
    this.centrifugoService.listen(`game_${gameId}`).subscribe(message => {
      switch (message['action']) {
        case 'VOLLEYBALL_GAME_STATISTIC_UPDATED': {
          const userStatistic = VolleyballGameStatistic.toFront(message['data']);
          this._gameStatisticSubject.next(updateItemInArray(
            this._gameStatisticSubject.value,
            VolleyballGameStatistic.toFront(message['data']),
            true,
            (item) => item.gameUserId === userStatistic.gameUserId
          ));
          break;
        }
        case 'VOLLEYBALL_GAME_SCORE_UPDATED': {
          const game = this._gameSubject.value;
          game.teamScore = message['data']['team_score'];
          game.competitorTeamScore = message['data']['competitor_team_score'];
          game.scoreByPeriod = message['data']['score_by_period'];
          this._gameSubject.next(game);
          break;
        }
      }
    });
  }

  protected updateGameScore() {
    return;
  }

  protected getGameService() {
    return this.volleyballGameService;
  }

  protected gameLogToFront(data: any) {
    return VolleyballGameLog.toFront(data);
  }
}
