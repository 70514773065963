<div class="header">
  <div class="left" *ngIf="filterValue">
    <a href="javascript:;" class="filter-btn" [mtgMenuTriggerFor]="filterMenu" mtgMenuActiveClass="active">
      {{ filterValue.title }} <span class="icon-chevron-down"></span>
    </a>
    <mtg-menu #filterMenu="mtgMenu">
      <div
        *ngFor="let item of filterItems"
        mtgMenuItem
        class="filter-menu-item"
        (click)="changeFilter(item)"
      >
        {{ item.title }} <span class="icon-ok" *ngIf="item.value === filterValue.value"></span>
      </div>
    </mtg-menu>
  </div>
  <div class="right">
    <button class="settings-btn" [mtgMenuTriggerFor]="settingsMenu" mtgMenuActiveClass="active">
      <span class="icon-more"></span>
    </button>
    <button class="close-btn" (click)="close.emit()"><span class="icon-close"></span></button>

    <mtg-menu #settingsMenu="mtgMenu">
      <div class="setting-menu-item" mtgMenuItem (click)="markAllViewed.emit()">
        <div class="icon"><span class="icon-ok"></span></div>
        {{ localization.allMarkRead }}
      </div>
      <div class="setting-menu-item" mtgMenuItem (click)="openSettings()">
        <div class="icon"><span class="icon-settings"></span></div>
        {{ localization.settings }}
      </div>
    </mtg-menu>
  </div>
</div>
<div class="notifications" #notificationsContainer>
  <ng-container *ngIf="notifications.length > 0">
    <a [href]="item.externalLink || item.route && item.route.join('/')"
       target="_blank"
       *ngFor="let item of notifications; trackBy: trackById"
       (click)="onClick($event, item)"
    >
      <mtg-notification-item
        [notification]="item"
        (viewed)="viewed.emit(item.model)"
      ></mtg-notification-item>
    </a>
  </ng-container>
  <div class="empty" *ngIf="notifications.length === 0 && !loading">
    <div class="icon"><span class="icon-bell-new"></span></div>
    <div class="text">{{ localization.defaultText }}</div>
  </div>
  <div class="loading" *ngIf="loading">
    <mtg-spinner></mtg-spinner>
  </div>
</div>
