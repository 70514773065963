import { OverlayRef, PositionStrategy } from '@angular/cdk/overlay';

export class ToastRef {
  constructor(private readonly overlay: OverlayRef) { }

  close() {
    this.overlay.detach();
    setTimeout(() => {
      this.overlay.dispose();
    }, 300);
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }

  getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }

  updatePosition(strategy: PositionStrategy) {
    this.overlay.updatePositionStrategy(strategy);
  }
}
