import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[mtgPaginationScroll]'
})
export class PaginationScrollDirective implements OnInit, OnDestroy {
  private _subscriptions: Function[] = [];

  @Output('mtgPaginationScroll')
  change = new EventEmitter();

  @Input('mtgPaginationScrollDirection')
  scrollDirection: 'vertical' | 'horizontal' = 'vertical';

  @HostListener('scroll')
  onScroll() {
    if (this.scrollDirection === 'horizontal') {
      this.checkHorizontalScroll();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this.renderer.listen('window', 'scroll', $event => {
        if (this.scrollDirection === 'vertical') {
          this.checkVerticalScroll();
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const unsubscribe of this._subscriptions) {
      unsubscribe();
    }
  }

  checkVerticalScroll() {
    if (this.elementRef.nativeElement.offsetParent === null) {
      return;
    }
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    if (window.innerHeight >= position.bottom) {
      this.change.emit();
    }
  }

  checkHorizontalScroll() {
    if (this.elementRef.nativeElement.offsetParent === null) {
      return;
    }
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    if (this.elementRef.nativeElement.scrollWidth <= (this.elementRef.nativeElement.scrollLeft + position.width)) {
      this.change.emit();
    }
  }
}
