import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HockeyGameLog } from '@core/models/hockey-game-log';
import { Game } from '@core/models/game';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameService } from '@core/services/game.service';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { HockeyGameStatistic } from '@core/models/hockey-game-statistic';
import { HockeyGameTeamStatistic } from '@core/models/hockey-game-team.statistic';
import { HockeyGameConfig } from '@core/models/hockey-game-config';

@Injectable()
export class HockeyGameService extends GameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(gameId: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient.patch(`${this.apiUrl}/hockey_game/${gameId}/`, data).pipe(
      map(result => Game.toFront(result))
    );
  }

  closeGame(game: Game): Observable<Game> {
    return this.httpClient.post(`${this.apiUrl}/hockey_game/${game.id}/close/`, {}).pipe(
      map(data => Game.toFront(data))
    );
  }

  getGameLogs(gameId: number): Observable<HockeyGameLog[]> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/logs/`).pipe(
      map(result => HockeyGameLog.toFront(result))
    );
  }

  saveGameLog(gameId: number, gameLog: HockeyGameLog): Observable<HockeyGameLog> {
    return this.httpClient.post(`${this.apiUrl}/hockey_game/${gameId}/logs/`, HockeyGameLog.toBack(gameLog)).pipe(
      map(result => HockeyGameLog.toFront(result))
    );
  }

  updateGameLog(gameLogId: number, data: any): Observable<HockeyGameLog> {
    return this.httpClient.patch(`${this.apiUrl}/hockey_game_log/${gameLogId}/`, HockeyGameLog.toBack(data)).pipe(
      map(result => HockeyGameLog.toFront(result))
    );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/hockey_game_log/${gameLogId}/`);
  }

  getGameUserStatistic(gameId: number): Observable<HockeyGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/user_statistic/`).pipe(
      map(result => HockeyGameStatistic.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<HockeyGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/team_statistic/`).pipe(
      map(result => HockeyGameTeamStatistic.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<any> {
    return of(null);
  }

  updateGameConfig(gameId: number, data: any): Observable<HockeyGameConfig> {
    return this.httpClient.patch(`${this.apiUrl}/hockey_game/${gameId}/`, {
      hockey_game_config: HockeyGameConfig.toBack(data)
    }).pipe(
      map(result => Game.toFront(data).gameConfig)
    );
  }

  getGameProtocolFile(gameId: number, type: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('file_type', type);
    return this.httpClient.get(`${this.apiUrl}/hockey_game/${gameId}/game_protocol/`, {params, responseType: 'blob'});
  }
}
