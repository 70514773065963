import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField } from '@core/models/util';
import * as moment from 'moment';


@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    updated_at: 'updatedAt',
    points: 'points',
    pp_shot_misses: 'ppShotMisses',
    pp_shots_on_goal: 'ppShotsOnGoal',
    pp_shots_blocked: 'ppShotsBlocked',
    ev_shot_misses: 'evShotMisses',
    ev_shots_on_goal: 'evShotsOnGoal',
    ev_shots_blocked: 'evShotsBlocked',
    sh_shot_misses: 'shShotMisses',
    sh_shots_on_goal: 'shShotsOnGoal',
    sh_shots_blocked: 'shShotsBlocked',
    pp_total_shots_on_goal: 'ppTotalShotsOnGoal',
    ev_total_shots_on_goal: 'evTotalShotsOnGoal',
    sh_total_shots_on_goal: 'shTotalShotsOnGoal',
    pp_goals: 'ppGoals',
    ev_goals: 'evGoals',
    sh_goals: 'shGoals',
    pp_counterattack_goals: 'ppCounterAttackGoals',
    pp_counterattack_on_goals: 'ppCounterAttackOnGoals',
    pp_counterattack_misses: 'ppCounterAttackMisses',
    pp_counterattack_shots_blocked: 'ppCounterAttackShotsBlocked',
    ev_counterattack_goals: 'evCounterAttackGoals',
    ev_counterattack_on_goals: 'evCounterAttackOnGoals',
    ev_counterattack_misses: 'evCounterAttackMisses',
    ev_counterattack_shots_blocked: 'evCounterAttackShotsBlocked',
    sh_counterattack_goals: 'shCounterAttackGoals',
    sh_counterattack_on_goals: 'shCounterAttackOnGoals',
    sh_counterattack_misses: 'shCounterAttackMisses',
    sh_counterattack_shots_blocked: 'shCounterAttackShotsBlocked',
    pp_maneuver_goals: 'ppManeuverGoals',
    pp_maneuver_on_goals: 'ppManeuverOnGoals',
    pp_maneuver_misses: 'ppManeuverMisses',
    pp_maneuver_shots_blocked: 'ppManeuverShotsBlocked',
    ev_maneuver_goals: 'evManeuverGoals',
    ev_maneuver_on_goals: 'evManeuverOnGoals',
    ev_maneuver_misses: 'evManeuverMisses',
    ev_maneuver_shots_blocked: 'evManeuverShotsBlocked',
    sh_maneuver_goals: 'shManeuverGoals',
    sh_maneuver_on_goals: 'shManeuverOnGoals',
    sh_maneuver_misses: 'shManeuverMisses',
    sh_maneuver_shots_blocked: 'shManeuverShotsBlocked',
    pp_center_forward_goals: 'ppCenterForwardGoals',
    pp_center_forward_on_goals: 'ppCenterForwardOnGoals',
    pp_center_forward_misses: 'ppCenterForwardMisses',
    pp_center_forward_shots_blocked: 'ppCenterForwardShotsBlocked',
    ev_center_forward_goals: 'evCenterForwardGoals',
    ev_center_forward_on_goals: 'evCenterForwardOnGoals',
    ev_center_forward_misses: 'evCenterForwardMisses',
    ev_center_forward_shots_blocked: 'evCenterForwardShotsBlocked',
    sh_center_forward_goals: 'shCenterForwardGoals',
    sh_center_forward_on_goals: 'shCenterForwardOnGoals',
    sh_center_forward_misses: 'shCenterForwardMisses',
    sh_center_forward_shots_blocked: 'shCenterForwardShotsBlocked',
    pp_assists: 'ppAssists',
    ev_assists: 'evAssists',
    sh_assists: 'shAssists',
    shootout_attempts: 'shootoutAttempts',
    shootout_goals: 'shootoutGoals',
    after_game_shootout_attempts: 'afterGameShootoutAttempts',
    after_game_shootout_goals: 'afterGameShootoutGoals',
    block_shots: 'blockShots',
    face_off_losses: 'faceOffLosses',
    face_off_wins: 'faceOffWins',
    pp_saves: 'ppSaves',
    sh_saves: 'shSaves',
    ev_saves: 'evSaves',
    pp_counterattack_saves: 'ppCounterAttackSaves',
    sh_counterattack_saves: 'shCounterAttackSaves',
    ev_counterattack_saves: 'evCounterAttackSaves',
    pp_maneuver_saves: 'ppManeuverSaves',
    sh_maneuver_saves: 'shManeuverSaves',
    ev_maneuver_saves: 'evManeuverSaves',
    pp_center_forward_saves: 'ppCenterForwardSaves',
    sh_center_forward_saves: 'shCenterForwardSaves',
    ev_center_forward_saves: 'evCenterForwardSaves',
    pp_free_kick_saves: 'ppFreeKickSaves',
    sh_free_kick_saves: 'shFreeKickSaves',
    ev_free_kick_saves: 'evFreeKickSaves',
    pp_corner_saves: 'ppCornerSaves',
    sh_corner_saves: 'shCornerSaves',
    ev_corner_saves: 'evCornerSaves',
    pp_total_game_saves: 'ppTotalGameSaves',
    sh_total_game_saves: 'shTotalGameSaves',
    ev_total_game_saves: 'evTotalGameSaves',
    shootout_saves: 'shootoutSaves',
    after_game_shootout_saves: 'afterGameShootoutSaves',
    pp_goals_against: 'ppGoalsAgainst',
    sh_goals_against: 'shGoalsAgainst',
    ev_goals_against: 'evGoalsAgainst',
    goals_against: 'goalsAgainst',
    pp_counterattack_goals_against: 'ppCounterAttackGoalsAgainst',
    sh_counterattack_goals_against: 'shCounterAttackGoalsAgainst',
    ev_counterattack_goals_against: 'evCounterAttackGoalsAgainst',
    counterattack_goals_against: 'counterAttackGoalsAgainst',
    pp_maneuver_goals_against: 'ppManeuverGoalsAgainst',
    sh_maneuver_goals_against: 'shManeuverGoalsAgainst',
    ev_maneuver_goals_against: 'evManeuverGoalsAgainst',
    maneuver_goals_against: 'maneuverGoalsAgainst',
    pp_center_forward_goals_against: 'ppCenterForwardGoalsAgainst',
    sh_center_forward_goals_against: 'shCenterForwardGoalsAgainst',
    ev_center_forward_goals_against: 'evCenterForwardGoalsAgainst',
    center_forward_goals_against: 'centerForwardGoalsAgainst',
    pp_free_kick_goals_against: 'ppFreeKickGoalsAgainst',
    sh_free_kick_goals_against: 'shFreeKickGoalsAgainst',
    ev_free_kick_goals_against: 'evFreeKickGoalsAgainst',
    free_kick_goals_against: 'freeKickGoalsAgainst',
    pp_corner_goals_against: 'ppCornerGoalsAgainst',
    sh_corner_goals_against: 'shCornerGoalsAgainst',
    ev_corner_goals_against: 'evCornerGoalsAgainst',
    corner_goals_against: 'cornerGoalsAgainst',
    shootout_goals_against: 'shootoutGoalsAgainst',
    after_game_shootout_goals_against: 'afterGameShootoutGoalsAgainst',
    safety_rate: 'safetyRate',
    penalty_minutes: 'penaltyMinutes',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    fouls: 'fouls',
    minor_penalties: 'minorPenalties',
    major_penalties: 'majorPenalties',
    match_penalties: 'matchPenalties',
    penalty_fouls: 'penaltyFouls',
    turnovers: 'turnovers',
    attack_fouls: 'attackFouls',
    personal_fouls: 'personalFouls',
    drawn_fouls: 'drawnFouls',
    drawn_turnovers: 'drawnTurnovers',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    losses: 'losses',
    steals: 'steals',
    pp_free_kick_goals: 'ppFreeKickGoals',
    pp_free_kick_on_goals: 'ppFreeKickOnGoals',
    pp_free_kick_misses: 'ppFreeKickMisses',
    pp_free_kick_shots_blocked: 'ppFreeKickShotsBlocked',
    sh_free_kick_goals: 'shFreeKickGoals',
    sh_free_kick_on_goals: 'shFreeKickOnGoals',
    sh_free_kick_misses: 'shFreeKickMisses',
    sh_free_kick_shots_blocked: 'shFreeKickShotsBlocked',
    ev_free_kick_goals: 'evFreeKickGoals',
    ev_free_kick_on_goals: 'evFreeKickOnGoals',
    ev_free_kick_misses: 'evFreeKickMisses',
    ev_free_kick_shots_blocked: 'evFreeKickShotsBlocked',
    free_kick_passes: 'freeKickPasses',
    free_kick_selfs: 'freeKickSelfs',
    pp_corner_goals: 'ppCornerGoals',
    pp_corner_on_goals: 'ppCornerOnGoals',
    pp_corner_misses: 'ppCornerMisses',
    pp_corner_shots_blocked: 'ppCornerShotsBlocked',
    sh_corner_goals: 'shCornerGoals',
    sh_corner_on_goals: 'shCornerOnGoals',
    sh_corner_misses: 'shCornerMisses',
    sh_corner_shots_blocked: 'shCornerShotsBlocked',
    ev_corner_goals: 'evCornerGoals',
    ev_corner_on_goals: 'evCornerOnGoals',
    ev_corner_misses: 'evCornerMisses',
    ev_corner_shots_blocked: 'evCornerShotsBlocked',
    corner_passes: 'cornerPasses',
    corner_selfs: 'cornerSelfs',
    tackles: 'tackles',
    player_efficiency: 'playerEfficiency'
  },
  relation: {
    updatedAt: MomentDateTimeField,
  }
})
export class WaterpoloGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  ppShotMisses: number;
  ppShotsOnGoal: number;
  ppShotsBlocked: number;
  evShotMisses: number;
  evShotsOnGoal: number;
  evShotsBlocked: number;
  shShotMisses: number;
  shShotsOnGoal: number;
  shShotsBlocked: number;
  ppTotalShotsOnGoal: number;
  evTotalShotsOnGoal: number;
  shTotalShotsOnGoal: number;
  ppGoals: number;
  evGoals: number;
  shGoals: number;
  ppCounterAttackGoals: number;
  ppCounterAttackOnGoals: number;
  ppCounterAttackMisses: number;
  ppCounterAttackShotsBlocked: number;
  evCounterAttackGoals: number;
  evCounterAttackOnGoals: number;
  evCounterAttackMisses: number;
  evCounterAttackShotsBlocked: number;
  shCounterAttackGoals: number;
  shCounterAttackOnGoals: number;
  shCounterAttackMisses: number;
  shCounterAttackShotsBlocked: number;
  ppManeuverGoals: number;
  ppManeuverOnGoals: number;
  ppManeuverMisses: number;
  ppManeuverShotsBlocked: number;
  evManeuverGoals: number;
  evManeuverOnGoals: number;
  evManeuverMisses: number;
  evManeuverShotsBlocked: number;
  shManeuverGoals: number;
  shManeuverOnGoals: number;
  shManeuverMisses: number;
  shManeuverShotsBlocked: number;
  ppCenterForwardGoals: number;
  ppCenterForwardOnGoals: number;
  ppCenterForwardMisses: number;
  ppCenterForwardShotsBlocked: number;
  evCenterForwardGoals: number;
  evCenterForwardOnGoals: number;
  evCenterForwardMisses: number;
  evCenterForwardShotsBlocked: number;
  shCenterForwardGoals: number;
  shCenterForwardOnGoals: number;
  shCenterForwardMisses: number;
  shCenterForwardShotsBlocked: number;
  ppAssists: number;
  evAssists: number;
  shAssists: number;
  shootoutAttempts: number;
  shootoutGoals: number;
  afterGameShootoutAttempts: number;
  afterGameShootoutGoals: number;
  blockShots: number;
  faceOffLosses: number;
  faceOffWins: number;
  ppSaves: number;
  shSaves: number;
  evSaves: number;
  ppCounterAttackSaves: number;
  shCounterAttackSaves: number;
  evCounterAttackSaves: number;
  ppManeuverSaves: number;
  shManeuverSaves: number;
  evManeuverSaves: number;
  ppCenterForwardSaves: number;
  shCenterForwardSaves: number;
  evCenterForwardSaves: number;
  ppFreeKickSaves: number;
  shFreeKickSaves: number;
  evFreeKickSaves: number;
  ppCornerSaves: number;
  shCornerSaves: number;
  evCornerSaves: number;
  ppTotalGameSaves: number;
  shTotalGameSaves: number;
  evTotalGameSaves: number;
  shootoutSaves: number;
  afterGameShootoutSaves: number;
  ppGoalsAgainst: number;
  shGoalsAgainst: number;
  evGoalsAgainst: number;
  goalsAgainst: number;
  ppCounterAttackGoalsAgainst: number;
  shCounterAttackGoalsAgainst: number;
  evCounterAttackGoalsAgainst: number;
  counterAttackGoalsAgainst: number;
  ppManeuverGoalsAgainst: number;
  shManeuverGoalsAgainst: number;
  evManeuverGoalsAgainst: number;
  maneuverGoalsAgainst: number;
  ppCenterForwardGoalsAgainst: number;
  shCenterForwardGoalsAgainst: number;
  evCenterForwardGoalsAgainst: number;
  centerForwardGoalsAgainst: number;
  ppFreeKickGoalsAgainst: number;
  shFreeKickGoalsAgainst: number;
  evFreeKickGoalsAgainst: number;
  freeKickGoalsAgainst: number;
  ppCornerGoalsAgainst: number;
  shCornerGoalsAgainst: number;
  evCornerGoalsAgainst: number;
  cornerGoalsAgainst: number;
  shootoutGoalsAgainst: number;
  afterGameShootoutGoalsAgainst: number;
  safetyRate: number;
  penaltyMinutes: number;
  gameTime: number;
  plusMinus: number;
  fouls: number;
  minorPenalties: number;
  majorPenalties: number;
  matchPenalties: number;
  penaltyFouls: number;
  turnovers: number;
  attackFouls: number;
  personalFouls: number;
  drawnFouls: number;
  drawnTurnovers: number;
  yellowCards: number;
  redCards: number;
  losses: number;
  steals: number;
  ppFreeKickGoals: number;
  ppFreeKickOnGoals: number;
  ppFreeKickMisses: number;
  ppFreeKickShotsBlocked: number;
  shFreeKickGoals: number;
  shFreeKickOnGoals: number;
  shFreeKickMisses: number;
  shFreeKickShotsBlocked: number;
  evFreeKickGoals: number;
  evFreeKickOnGoals: number;
  evFreeKickMisses: number;
  evFreeKickShotsBlocked: number;
  freeKickPasses: number;
  freeKickSelfs: number;
  ppCornerGoals: number;
  ppCornerOnGoals: number;
  ppCornerMisses: number;
  ppCornerShotsBlocked: number;
  shCornerGoals: number;
  shCornerOnGoals: number;
  shCornerMisses: number;
  shCornerShotsBlocked: number;
  evCornerGoals: number;
  evCornerOnGoals: number;
  evCornerMisses: number;
  evCornerShotsBlocked: number;
  cornerPasses: number;
  cornerSelfs: number;
  tackles: number;
  playerEfficiency: number;
  updatedAt: moment.Moment;

  get id(): number {
    return this.gameUserId;
  }

  get gameMinutes(): number {
    return Math.floor(this.gameTime / 60);
  }

  get penaltyTime(): string {
    if (!this.penaltyMinutes) {
      return '00:00';
    }
    const minutes = Math.floor(this.penaltyMinutes / 60);
    const seconds = Math.floor(this.penaltyMinutes - minutes * 60);
    return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  get assists(): number {
    return (this.ppAssists || 0) + (this.shAssists || 0)  + (this.evAssists || 0);
  }

  get totalCounterAttackGoals(): number {
    return (this.ppCounterAttackGoals || 0) + (this.evCounterAttackGoals || 0)  + (this.shCounterAttackGoals || 0);
  }

  get ppCounterAttackShots(): number {
    return (this.ppCounterAttackOnGoals || 0) + (this.ppCounterAttackMisses || 0) + (this.ppCounterAttackShotsBlocked || 0);
  }

  get shCounterAttackShots(): number {
    return (this.shCounterAttackOnGoals || 0) + (this.shCounterAttackMisses || 0) + (this.shCounterAttackShotsBlocked || 0);
  }

  get evCounterAttackShots(): number {
    return (this.evCounterAttackOnGoals || 0) + (this.evCounterAttackMisses || 0) + (this.evCounterAttackShotsBlocked || 0);
  }

  get counterAttackShotMisses(): number {
    return (this.ppCounterAttackMisses || 0) + (this.shCounterAttackMisses || 0) + (this.evCounterAttackMisses || 0);
  }

  get counterAttackShotOnGoals(): number {
    return (this.ppCounterAttackOnGoals || 0) + (this.shCounterAttackOnGoals || 0) + (this.evCounterAttackOnGoals || 0);
  }

  get counterAttackShotsBlocked(): number {
    return (this.ppCounterAttackShotsBlocked || 0) + (this.shCounterAttackShotsBlocked || 0) + (this.evCounterAttackShotsBlocked || 0);
  }

  get counterAttackShots(): number {
    return this.counterAttackShotMisses + this.counterAttackShotOnGoals + this.counterAttackShotsBlocked;
  }

  get counterAttackGoalsPercent(): number {
    if (!this.totalCounterAttackGoals || !this.counterAttackShots) {
      return 0;
    }
    return Math.round(1000 * this.totalCounterAttackGoals / this.counterAttackShots) / 10;
  }

  get totalManeuverGoals(): number {
    return (this.ppManeuverGoals || 0) + (this.shManeuverGoals || 0) + (this.evManeuverGoals || 0);
  }

  get ppManeuverShots(): number {
    return (this.ppManeuverOnGoals || 0) + (this.ppManeuverMisses || 0) + (this.ppManeuverShotsBlocked || 0);
  }

  get shManeuverShots(): number {
    return (this.shManeuverOnGoals || 0) + (this.shManeuverMisses || 0) + (this.shManeuverShotsBlocked || 0);
  }

  get evManeuverShots(): number {
    return (this.evManeuverOnGoals || 0) + (this.evManeuverMisses || 0) + (this.evManeuverShotsBlocked || 0);
  }

  get maneuverShotMisses(): number {
    return (this.ppManeuverMisses || 0) + (this.shManeuverMisses || 0) +  (this.evManeuverMisses || 0);
  }

  get maneuverShotOnGoals(): number {
    return (this.ppManeuverOnGoals || 0) + (this.shManeuverOnGoals || 0) +  (this.evManeuverOnGoals || 0);
  }

  get maneuverShotsBlocked(): number {
    return (this.ppManeuverShotsBlocked || 0) + (this.shManeuverShotsBlocked || 0) +  (this.evManeuverShotsBlocked || 0);
  }

  get maneuverShots(): number {
    return this.maneuverShotMisses + this.maneuverShotOnGoals + this.maneuverShotsBlocked;
  }

  get maneuverGoalsPercent(): number {
    if (!this.totalManeuverGoals || !this.maneuverShots) {
      return 0;
    }
    return Math.round(1000 * this.totalManeuverGoals / this.maneuverShots) / 10;
  }

  get totalCenterForwardGoals(): number {
    return (this.ppCenterForwardGoals || 0) + (this.shCenterForwardGoals || 0) +  (this.evCenterForwardGoals || 0);
  }

  get ppCenterForwardShots(): number {
    return (this.ppCenterForwardOnGoals || 0) + (this.ppCenterForwardMisses || 0) + (this.ppCenterForwardShotsBlocked || 0);
  }

  get shCenterForwardShots(): number {
    return (this.shCenterForwardOnGoals || 0) + (this.shCenterForwardMisses || 0) + (this.shCenterForwardShotsBlocked || 0);
  }

  get evCenterForwardShots(): number {
    return (this.evCenterForwardOnGoals || 0) + (this.evCenterForwardMisses || 0) + (this.evCenterForwardShotsBlocked || 0);
  }

  get centerForwardShotMisses(): number {
    return (this.ppCenterForwardMisses || 0) + (this.shCenterForwardMisses || 0) + (this.evCenterForwardMisses || 0);
  }

  get centerForwardShotOnGoals(): number {
    return (this.ppCenterForwardOnGoals || 0) + (this.shCenterForwardOnGoals || 0) + (this.evCenterForwardOnGoals || 0);
  }

  get centerForwardShotsBlocked(): number {
    return (this.ppCenterForwardShotsBlocked || 0) + (this.shCenterForwardShotsBlocked || 0) + (this.evCenterForwardShotsBlocked || 0);
  }

  get centerForwardShots(): number {
    return this.centerForwardShotMisses + this.centerForwardShotOnGoals + this.centerForwardShotsBlocked;
  }

  get centerForwardGoalsPercent(): number {
    if (!this.totalCenterForwardGoals || !this.centerForwardShots) {
      return 0;
    }
    return Math.round(1000 * this.totalCenterForwardGoals / this.centerForwardShots) / 10;
  }

  get ppShots(): number {
    return (this.ppShotMisses || 0) + (this.ppShotsOnGoal || 0) + (this.ppShotsBlocked || 0) +
      (this.ppFreeKickMisses || 0) + (this.ppFreeKickOnGoals || 0) + (this.ppFreeKickShotsBlocked || 0) +
      (this.ppCornerMisses || 0) + (this.ppCornerOnGoals || 0) + (this.ppCornerShotsBlocked || 0) +
      this.ppCounterAttackShots + this.ppManeuverShots + this.ppCenterForwardShots;
  }

  get evShots(): number {
    return (this.evShotMisses || 0) + (this.evShotsOnGoal || 0) + (this.evShotsBlocked || 0) +
      (this.evFreeKickMisses || 0) + (this.evFreeKickOnGoals || 0) + (this.evFreeKickShotsBlocked || 0) +
      (this.evCornerMisses || 0) + (this.evCornerOnGoals || 0) + (this.evCornerShotsBlocked || 0)  +
      this.evCounterAttackShots + this.evManeuverShots + this.evCenterForwardShots;
  }

  get shShots(): number {
    return (this.shShotMisses || 0) + (this.shShotsOnGoal || 0) + (this.shShotsBlocked || 0) +
      (this.shFreeKickMisses || 0) + (this.shFreeKickOnGoals || 0) + (this.shFreeKickShotsBlocked || 0) +
      (this.shCornerMisses || 0) + (this.shCornerOnGoals || 0) + (this.shCornerShotsBlocked || 0) +
      this.shCounterAttackShots + this.shManeuverShots + this.shCenterForwardShots;
  }

  get totalShGoals(): number {
    return (this.shGoals || 0) + (this.shFreeKickGoals || 0) + (this.shCornerGoals || 0) +
      (this.shCounterAttackGoals || 0) + (this.shManeuverGoals || 0) + (this.shCenterForwardGoals || 0);
  }

  get totalEvGoals(): number {
    return (this.evGoals || 0) + (this.evFreeKickGoals || 0) + (this.evCornerGoals || 0) +
      (this.evCounterAttackGoals || 0) + (this.evManeuverGoals || 0) + (this.evCenterForwardGoals || 0);
  }

  get totalPpGoals(): number {
    return (this.ppGoals || 0) + (this.ppFreeKickGoals || 0) + (this.ppCornerGoals || 0) +
      (this.ppCounterAttackGoals || 0) + (this.ppManeuverGoals || 0) + (this.ppCenterForwardGoals || 0);
  }

  get goals(): number {
    return this.totalShGoals + this.totalEvGoals + this.totalPpGoals;
  }

  get totalGoals(): number {
    return this.goals + (this.shootoutGoals || 0);
  }

  get shootoutShots(): number {
    return (this.shootoutGoals || 0) + (this.shootoutAttempts || 0);
  }

  get inGameShotMisses(): number {
    return (this.ppShotMisses || 0) + (this.evShotMisses || 0) + (this.shShotMisses || 0) +
      this.counterAttackShotMisses + this.maneuverShotMisses + this.centerForwardShotMisses;
  }

  get shotMisses(): number {
    return (this.ppFreeKickMisses || 0) + (this.evFreeKickMisses || 0) + (this.shFreeKickMisses || 0) +
      (this.ppCornerMisses || 0) + (this.evCornerMisses || 0) + (this.shCornerMisses || 0) +
      this.inGameShotMisses;
  }

  get totalPpShotMisses(): number {
    return (this.ppShotMisses || 0) + (this.ppFreeKickMisses || 0) + (this.ppCornerMisses || 0) +
      (this.ppCounterAttackMisses || 0) + (this.ppManeuverMisses || 0) + (this.ppCenterForwardMisses || 0);
  }

  get shotsOnGoal(): number {
    return (this.ppShotsOnGoal || 0) + (this.evShotsOnGoal || 0) + (this.shShotsOnGoal || 0) +
      (this.ppFreeKickOnGoals || 0) + (this.evFreeKickOnGoals || 0) + (this.shFreeKickOnGoals || 0) +
      (this.ppCornerOnGoals || 0) + (this.evCornerOnGoals || 0) + (this.shCornerOnGoals || 0) +
      this.counterAttackShotOnGoals + this.maneuverShotOnGoals + this.centerForwardShotOnGoals;
  }

  get totalPpShotsOnGoal(): number {
    return (this.ppShotsOnGoal || 0) + (this.ppFreeKickOnGoals || 0) + (this.ppCornerOnGoals || 0) +
      (this.ppCounterAttackOnGoals || 0) + (this.ppManeuverOnGoals || 0) + (this.ppCenterForwardOnGoals || 0);
  }

  get inGameShotsOnGoals(): number {
    return (this.ppShotsOnGoal || 0) + (this.evShotsOnGoal || 0) + (this.shShotsOnGoal || 0) +
      this.counterAttackShotOnGoals + this.maneuverShotOnGoals + this.centerForwardShotOnGoals;
  }

  get shots(): number {
    return this.ppShots + this.evShots + this.shShots;
  }

  get totalShots(): number {
    return this.shots + this.shootoutShots;
  }

  get faceOffs(): number {
    return (this.faceOffWins || 0) + (this.faceOffLosses || 0);
  }

  get freeKickShotMisses(): number {
    return (this.ppFreeKickMisses || 0) + (this.evFreeKickMisses || 0) + (this.shFreeKickMisses || 0);
  }

  get freeKickShotsBlocked(): number {
    return (this.ppFreeKickShotsBlocked || 0) + (this.evFreeKickShotsBlocked || 0) + (this.shFreeKickShotsBlocked || 0);
  }

  get freeKickOnGoals(): number {
    return (this.ppFreeKickOnGoals || 0) + (this.evFreeKickOnGoals || 0) + (this.shFreeKickOnGoals || 0);
  }

  get totalFreeKickGoals(): number {
    return (this.ppFreeKickGoals || 0) + (this.shFreeKickGoals || 0) + (this.evFreeKickGoals || 0);
  }

  get totalFreeKicks(): number {
    return (this.freeKickSelfs || 0) + (this.freeKickPasses || 0) +
      this.freeKickShotMisses + this.freeKickOnGoals + this.totalFreeKickGoals + this.freeKickShotsBlocked;
  }

  get freeKickGoalsPercent(): number {
    if (!this.totalFreeKickGoals || !this.totalFreeKicks) {
      return 0;
    }
    return Math.round(1000 * this.totalFreeKickGoals / this.totalFreeKicks) / 10;
  }

  get totalCornerGoals(): number {
    return (this.ppCornerGoals || 0) + (this.shCornerGoals || 0) + (this.evCornerGoals || 0);
  }

  get cornerShotMisses(): number {
    return (this.ppCornerMisses || 0) + (this.shCornerMisses || 0) + (this.evCornerMisses || 0);
  }

  get cornerOnGoals(): number {
    return (this.ppCornerOnGoals || 0) + (this.shCornerOnGoals || 0) + (this.evCornerOnGoals || 0);
  }

  get cornerShotsBlocked(): number {
    return (this.ppCornerShotsBlocked || 0) + (this.shCornerShotsBlocked || 0) + (this.evCornerShotsBlocked || 0);
  }

  get totalCorners(): number {
    return (this.cornerSelfs || 0) + (this.cornerPasses || 0) + this.cornerShotsBlocked +
      this.cornerOnGoals + this.cornerShotMisses + this.totalCornerGoals;
  }

  get shotsBlocked(): number {
    return (this.ppShotsBlocked || 0) + (this.evShotsBlocked || 0) + (this.shShotsBlocked || 0) +
      this.counterAttackShotsBlocked + this.maneuverShotsBlocked + this.centerForwardShotsBlocked +
      this.freeKickShotsBlocked + this.cornerShotsBlocked;
  }

  get totalFouls(): number {
    return (this.fouls || 0) + (this.personalFouls || 0);
  }

  get totalPenalties(): number {
    return (this.minorPenalties || 0) + (this.majorPenalties || 0) + (this.matchPenalties || 0);
  }

  get positionAttackGoals(): number {
    return (this.shGoals || 0) + (this.evGoals || 0) + (this.ppGoals || 0);
  }

  get positionAttackShotsOnGoal(): number {
    return (this.ppShotsOnGoal || 0) + (this.evShotsOnGoal || 0) + (this.shShotsOnGoal || 0);
  }

  get positionAttackShotsMiss(): number {
    return (this.ppShotMisses || 0) + (this.evShotMisses || 0) + (this.shShotMisses || 0);
  }

  get positionAttackShots(): number {
    return this.positionAttackShotsOnGoal + this.positionAttackShotsMiss;
  }

  get positionAttackGoalsPercent(): number {
    if (!this.positionAttackGoals || !this.positionAttackShots) {
      return 0;
    }
    return Math.round(1000 * this.positionAttackGoals / this.positionAttackShots) / 10;
  }

  get totalInGameGoals(): number {
    return this.totalCenterForwardGoals + this.totalManeuverGoals + this.totalCounterAttackGoals + this.positionAttackGoals;
  }

  get ppGoalsPercent(): number {
    if (!this.totalPpGoals || !this.ppShots) {
      return 0;
    }
    return Math.round(1000 * this.totalPpGoals / this.ppShots) / 10;
  }

  get evGoalsPercent(): number {
    if (!this.totalEvGoals || !this.evShots) {
      return 0;
    }
    return Math.round(1000 * this.totalEvGoals / this.evShots) / 10;
  }

  get shGoalsPercent(): number {
    if (!this.totalShGoals || !this.shShots) {
      return 0;
    }
    return Math.round(1000 * this.totalShGoals / this.shShots) / 10;
  }

  get shotsOnGoalPercent(): number {
    if (!this.shotsOnGoal || !this.shots) {
      return 0;
    }
    return Math.round(1000 * this.shotsOnGoal / this.shots) / 10;
  }

  get goalsPercent(): number {
    if (!this.goals || !this.shots) {
      return 0;
    }
    return Math.round(1000 * this.goals / this.shots) / 10;
  }

  get totalGoalsPercent(): number {
    if (!this.totalGoals || !this.totalShots) {
      return 0;
    }
    return Math.round(1000 * this.totalGoals / this.totalShots) / 10;
  }

  get faceOffPercent(): number {
    if (!this.faceOffWins || !this.faceOffs) {
      return 0;
    }
    return Math.round(1000 * this.faceOffWins / this.faceOffs) / 10;
  }

  get shootoutGoalsPercent(): number {
    if (!this.shootoutGoals || !this.shootoutShots) {
      return 0;
    }
    return Math.round(1000 * this.shootoutGoals / this.shootoutShots) / 10;
  }

  get goalpass(): number {
    return (this.totalGoals || 0) + (this.assists || 0);
  }

  /*goalie stat */
  get positionAttackSaves(): number {
    return (this.ppSaves || 0) + (this.shSaves || 0) + (this.evSaves || 0);
  }

  get positionAttackShotsAgainst(): number {
    return this.positionAttackSaves + (this.goalsAgainst || 0);
  }

  get positionAttackSavesPercent(): number {
    if (!this.positionAttackSaves || !this.positionAttackShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.positionAttackSaves / this.positionAttackShotsAgainst) / 10;
  }

  get counterAttackSaves(): number {
    return (this.ppCounterAttackSaves || 0) + (this.shCounterAttackSaves || 0) + (this.evCounterAttackSaves || 0);
  }

  get counterAttackShotsAgainst(): number {
    return this.counterAttackSaves + (this.counterAttackGoalsAgainst || 0);
  }

  get counterAttackSavesPercent(): number {
    if (!this.counterAttackSaves || !this.counterAttackShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.counterAttackSaves / this.counterAttackShotsAgainst) / 10;
  }


  get centerForwardSaves(): number {
    return (this.ppCenterForwardSaves || 0) + (this.shCenterForwardSaves || 0) + (this.evCenterForwardSaves || 0);
  }

  get centerForwardShotsAgainst(): number {
    return this.centerForwardSaves + (this.centerForwardGoalsAgainst || 0);
  }

  get centerForwardSavesPercent(): number {
    if (!this.centerForwardSaves || !this.centerForwardShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.centerForwardSaves / this.centerForwardShotsAgainst) / 10;
  }

  get maneuverSaves(): number {
    return (this.ppManeuverSaves || 0) + (this.shManeuverSaves || 0) + (this.evManeuverSaves || 0);
  }

  get maneuverShotsAgainst(): number {
    return this.maneuverSaves + (this.maneuverGoalsAgainst || 0);
  }

  get maneuverSavesPercent(): number {
    if (!this.maneuverSaves || !this.maneuverShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.maneuverSaves / this.maneuverShotsAgainst) / 10;
  }

  get freeKickSaves(): number {
    return (this.ppFreeKickSaves || 0) + (this.shFreeKickSaves || 0) + (this.evFreeKickSaves || 0);
  }

  get freeKickShotsAgainst(): number {
    return this.freeKickSaves + (this.freeKickGoalsAgainst || 0);
  }

  get freeKickSavesPercent(): number {
    if (!this.freeKickSaves || !this.freeKickShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.freeKickSaves / this.freeKickShotsAgainst) / 10;
  }

  get cornerSaves(): number {
    return (this.ppCornerSaves || 0) + (this.shCornerSaves || 0) + (this.evCornerSaves || 0);
  }

  get cornerShotsAgainst(): number {
    return this.cornerSaves + (this.cornerGoalsAgainst || 0);
  }

  get cornerSavesPercent(): number {
    if (!this.cornerSaves || !this.cornerShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.cornerSaves / this.cornerShotsAgainst) / 10;
  }

  get inGameSaves(): number {
    return (this.shSaves || 0) + (this.evSaves || 0) + (this.ppSaves || 0) +
      this.counterAttackSaves + this.maneuverSaves + this.centerForwardSaves;
  }

  get saves(): number {
    return this.inGameSaves + this.freeKickSaves + this.cornerSaves;
  }

  get totalSaves(): number {
    return this.saves + (this.shootoutSaves || 0);
  }

  get inGameGoalsAgainst(): number {
    return (this.goalsAgainst || 0) + (this.counterAttackGoalsAgainst || 0) + (this.maneuverGoalsAgainst || 0) +
      (this.centerForwardGoalsAgainst || 0);
  }

  get shootoutShotsAgainst(): number {
    return (this.shootoutSaves || 0) + (this.shootoutGoalsAgainst || 0);
  }

  get gameGoalsAgainst(): number {
    return this.inGameGoalsAgainst + (this.freeKickGoalsAgainst || 0) + (this.cornerGoalsAgainst || 0);
  }

  get totalGoalsAgainst(): number {
    return this.gameGoalsAgainst + (this.shootoutGoalsAgainst || 0);
  }

  get gameShotsAgainst(): number {
    return this.saves + this.gameGoalsAgainst;
  }

  get totalShotsAgainst(): number {
    return this.gameShotsAgainst + this.shootoutShotsAgainst;
  }

  get shootoutSavesPercent(): number {
    if (!this.shootoutSaves || !this.shootoutShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.shootoutSaves / this.shootoutShotsAgainst) / 10;
  }

  get savesPercent(): number {
    if (!this.saves || !this.gameShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.saves / this.gameShotsAgainst) / 10;
  }

  get totalSavesPercent(): number {
    if (!this.totalSaves || !this.totalShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.totalSaves / this.totalShotsAgainst) / 10;
  }

  get totalShSaves(): number {
    return (this.shCounterAttackSaves || 0) + (this.shManeuverSaves || 0) + (this.shCenterForwardSaves || 0) +
      (this.shSaves || 0) + (this.shFreeKickSaves || 0) + (this.shCornerSaves || 0);
  }

  get totalShGoalsAgainst(): number {
    return (this.shGoalsAgainst || 0) + (this.shFreeKickGoalsAgainst || 0) + (this.shCornerGoalsAgainst || 0) +
      (this.shCounterAttackGoalsAgainst || 0) + (this.shManeuverGoalsAgainst || 0) + (this.shCenterForwardGoalsAgainst || 0);
  }

  get totalShShotsAgainst(): number {
    return this.totalShSaves + this.totalShGoalsAgainst;
  }

  get totalShSavesPercent(): number {
    if (!this.totalShSaves || !this.totalShShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.totalShSaves / this.totalShShotsAgainst) / 10;
  }

  get totalEvSaves(): number {
    return (this.evCounterAttackSaves || 0) + (this.evManeuverSaves || 0) + (this.evCenterForwardSaves || 0) +
      (this.evSaves || 0) + (this.evFreeKickSaves || 0) + (this.evCornerSaves || 0);
  }

  get totalEvGoalsAgainst(): number {
    return (this.evGoalsAgainst || 0) + (this.evFreeKickGoalsAgainst || 0) + (this.evCornerGoalsAgainst || 0) +
      (this.evCounterAttackGoalsAgainst || 0) + (this.evManeuverGoalsAgainst || 0) + (this.evCenterForwardGoalsAgainst || 0);
  }

  get totalEvShotsAgainst(): number {
    return this.totalEvSaves + this.totalEvGoalsAgainst;
  }

  get totalEvSavesPercent(): number {
    if (!this.totalEvSaves || !this.totalEvShotsAgainst) {
      return 0;
    }
    return Math.round(1000 * this.totalEvSaves / this.totalEvShotsAgainst) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
