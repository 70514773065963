import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GameBaseService } from '@core/services/game-base.service';
import { Observable } from 'rxjs/internal/Observable';
import { TeamUser } from '@core/models/team-user';
import { map } from 'rxjs/operators';
import { TournamentTeamUser } from '@core/models/tournament-team-user';

@Injectable()
export class OrgGameService extends GameBaseService {
  protected apiUrl = '/org/api/v1';
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  updateTeamUserNumber(teamUserId: number, number: number): Observable<TeamUser> {
    return this.httpClient.patch(`${this.apiUrl}/tournament_team_user/${teamUserId}/number/`, {number: number.toString()}).pipe(
      map(data => TournamentTeamUser.toFront(data))
    );
  }
}
