import { Injectable, ErrorHandler } from '@angular/core';
import { handleError } from '@shared/util/errors';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    handleError(error);
  }
}
