import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { DialogRef } from '@ui-kit/dialog/dialog-ref';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';
import { getRouteParams } from '@shared/util/route';

@Component({
  selector: 'mtg-dialog-page',
  template: ''
})
export class DialogPageComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private dialogRef: DialogRef;

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    const routeParams = getRouteParams(this.route.snapshot);
    const data = this.route.snapshot.data;
    const config = {...data['config']};
    delete data['config'];
    config['data'] = {...data, ...routeParams, ...(config['data'] || {})};
    for (const key of Object.keys(routeParams)) {
      if (config['closeLink']) {
        config['closeLink'] = config['closeLink'].replace(`:${key}`, routeParams[key]);
      }
    }
    if (data['component']) {
      setTimeout(() => {
        this.dialogRef = this.dialogService.open(data['component'], config, null, this.injector);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.dialogRef.close();
  }
}
