<div class="panel team-card">
  <div class="team-info">
    <div class="logo">
      <img [src]="team.logo.path" *ngIf="team.logo.path" />
      <div [class]="team.getEmptyLogoClass()" *ngIf="!team.logo.path"></div>
      <ng-container *ngIf="detail">
        <a [routerLink]="editTeamRoute"
           class="btn btn-circle"
           *ngIf="access.canEdit"
        >
          <span class="icon-edit"></span>
        </a>
        <a href="javascript:;"
           class="btn btn-circle"
           (click)="leave.emit()"
           *ngIf="access.role && access.role !== teamRoles.admin"
        >
          <span class="icon-exit"></span>
        </a>
      </ng-container>
      <span class="icon-abs">
          <span class="icon icon-volleyball" *ngIf="team.sport.isVolleyball()"></span>
          <span class="icon icon-basketball" *ngIf="team.sport.isClassicBasketball()"></span>
          <span class="icon icon-3x3" *ngIf="team.sport.isStreetball()"></span>
          <span class="icon icon-hockey-puck" *ngIf="team.sport.isHockey()"></span>
          <span class="icon icon-football" *ngIf="team.sport.isClassicFootball()"></span>
          <span class="icon icon-football-mini" *ngIf="team.sport.isMiniFootball()"></span>
          <span class="icon icon-handball" *ngIf="team.sport.isHandball()"></span>
          <span class="icon icon-rugby" *ngIf="team.sport.isRugby()"></span>
          <span class="icon icon-waterpolo" *ngIf="team.sport.isWaterpolo()"></span>
      </span>
    </div>
    <div class="title">
      <a href="javascript:;" class="team-name" (click)="openMyTeams()" *ngIf="detail && isMyTeamsLinkVisible">
        {{ team.name }}
        <span class="unique_code" *ngIf="team.uniqueCode">#{{ team.uniqueCode }}</span>
        <i class="icon-select"></i>
      </a>
      <div class="team-name" *ngIf="!detail || !isMyTeamsLinkVisible">
        {{ team.name }}
        <span class="unique_code" *ngIf="team.uniqueCode">#{{ team.uniqueCode }}</span>
      </div>
      <div class="text-muted players-count">
        <span *ngIf="team.city">{{ team.city | mtgCityName }}</span>
        <span>{{ localization.players }}: {{ team.usersCount }}</span>
      </div>
      <!--
      <div class="privacy-settings" [routerLink]="teamPrivacySettingsRoute" *ngIf="detail && access.canEdit">
        Настройки приватности
      </div>
      -->
    </div>
  </div>
  <div class="statistic">
    <div class="item award">
      <div class="count">{{ +team.wonGamesCount }}</div>
      <div class="name">{{ localization.wins }}</div>
    </div>
    <div class="item">
      <div class="count">{{ +team.gamesCount }}</div>
      <div class="name">{{ localization.matchesPlayed1 }} <br/>{{ localization.matchesPlayed2 }}</div>
    </div>
    <div class="item">
      <div class="count">{{ +team.scoreSum }}</div>
      <div class="name">
        <ng-container *ngIf="team.sport.isFootball()">{{ localization.goals }} </ng-container>
        <ng-container *ngIf="team.sport.isHockey()">{{ localization.pucks }} </ng-container>
        <ng-container *ngIf="team.sport.isBasketball()">{{ localization.points }} </ng-container>
        <ng-container *ngIf="team.sport.isVolleyball()">{{ localization.points }} </ng-container>
        <ng-container *ngIf="team.sport.isRugby()">{{ localization.points }} </ng-container>
        <ng-container *ngIf="team.sport.isHandball()">{{ localization.goals }} </ng-container>
        <ng-container *ngIf="team.sport.isWaterpolo()">{{ localization.goals }} </ng-container>
        <br/>{{ localization.perSeason }}</div>
    </div>
  </div>

  <div class="join-block" *ngIf="detail && !access.role">
    <a href="javascript:;"
       class="btn join-link btn-link"
       (click)="join.emit()"
       *ngIf="!invite"
    >{{ localization.joinRequest }}</a>
    <span class="btn join-link btn-link" *ngIf="invite && invite.userAccept">
      {{ localization.requestSent }}
    </span>
    <span class="btn join-link btn-link" *ngIf="invite && invite.teamAccept">
      {{ localization.invited }}
    </span>
  </div>
</div>
