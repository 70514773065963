import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IVKLogin } from '@shared/modules/authorization/models/social-auth';

const redirectUri = window.location.origin + '/assets/oauth/vk.html';
const apiId = 6256084;
const eventAuthResult = 'vk-auth-result';

@Injectable()
export class VKService {
  private interval: any;

  constructor() {}

  login(scope: string = 'email'): Observable<IVKLogin> {
    return Observable.create(observer => {
      const url = `https://oauth.vk.com/authorize?client_id=${apiId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&display=popup`;
      const loginWindow = window.open(url, 'vk_auth', 'width=400,height=400');

      const handleLogin = (evt: CustomEvent) => {
        window.removeEventListener(eventAuthResult, handleLogin);
        clearInterval(this.interval);

        if (!evt.detail) {
          observer.error();
          return;
        }

        observer.next({
          code: evt.detail,
          redirectUri: redirectUri
        });
        observer.complete();
      };

      this.interval = setInterval(() => {
        if (loginWindow && loginWindow.closed) {
          window.removeEventListener(eventAuthResult, handleLogin);
          observer.error();
          clearInterval(this.interval);
        }
      }, 100);

      window.addEventListener(eventAuthResult, handleLogin);
    });
  }
}
