import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'mtg-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input()
  progress: number;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  setProgress(progress: number): void {
    this.progress = progress;
    this.cdr.detectChanges();
  }
}
