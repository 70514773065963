import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import * as RoutingMap from '@app/routing-map';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService
      .user$
      .pipe(
        take(1),
        map(user => !!!user),
        tap(canActivate => {
          if (!canActivate) {
            this.router.navigateByUrl(RoutingMap.ROUTE_PROFILE_ABSOLUTE);
          }
        })
      );
  }
}
