import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from '@core/models/util';

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
  },
})
export class SeasonTournament extends BaseModel {
  id: number;
  name: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
