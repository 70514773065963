import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

@ModelInstance({
  mappingFields: {
    edit: 'edit',
    personal_data: 'personalData',
    teams: 'teams',
    statistic: 'statistic',
    games: 'games'
  }
})
export class UserAccess extends BaseModel {
  edit: boolean;
  personalData: boolean;
  teams: boolean;
  statistic: boolean;
  games: boolean;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
