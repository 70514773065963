import { BaseModel, ToFrontHook, ToBackHook, ModelInstance, enumField, listField } from '@core/models/util';
import { TournamentDivision } from '@core/models/tournament-division';

export enum TournamentRoundTypes {
  group = 1,
  playoff,
}

export class TournamentRoundSettings extends BaseModel {
  toursCount: number;
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    type: 'type',
    divisions: 'divisions',
    sort: 'sort',
  },
  relation: {
    type: enumField(TournamentRoundTypes),
    divisions: listField(TournamentDivision),
  }
})
export class TournamentRound extends BaseModel {
  id: number;
  name: string;
  type: TournamentRoundTypes;
  divisions: TournamentDivision[];
  sort: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
