import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { League } from '@core/models/league';
import { map } from 'rxjs/operators';
import { Tournament, TournamentStatuses } from '@core/models/tournament';
import { Game } from '@core/models/game';
import { TournamentTeam } from '@core/models/tournament-team';

@Injectable()
export class LeagueService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getLeague(alias: string): Observable<League> {
    return this.httpClient.get(`/api/v1/league/${alias}/`).pipe(
      map(data => League.toFront(data))
    );
  }

  getTournaments(leagueId: number, statuses: TournamentStatuses[] = []): Observable<Tournament[]> {
    let params = new HttpParams();
    if (statuses) {
      params = params.set('statuses', statuses.map(item => TournamentStatuses[item]).join(','));
    }
    return this.httpClient.get(`/api/v1/league/${leagueId}/tournaments/`, {params}).pipe(
      map(data => Tournament.toFront(data))
    );
  }

  getClosestGame(leagueId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/league/${leagueId}/closest_game/`).pipe(
      map(data => Game.toFront(data))
    );
  }

  getTournamentWinner(tournamentId: number): Observable<TournamentTeam> {
    return this.httpClient.get(`/api/v1/tournament/${tournamentId}/winner/`).pipe(
      map(result => TournamentTeam.toFront(result))
    );
  }

  getGraphicSettings(leagueId: number): Observable<any> {
    return this.httpClient.get(`/api/v1/league/${leagueId}/graphic_settings/`);
  }
}
