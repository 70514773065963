import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, listField, MomentDateTimeField, enumField } from '@core/models/util';
import { File } from './file';
import { Tournament } from '@core/models/tournament';
import * as moment from 'moment';
import { TournamentStage } from '@core/models/tournament-stage';


export enum LeagueNewsTypes {
  text = 1,
  video
}


@ModelInstance({
  mappingFields: {
    id: 'id',
    title: 'title',
    datetime: 'datetime',
    preview_text: 'previewText',
    detail_text: 'detailText',
    picture: 'picture',
    cover: 'cover',
    mobile_picture: 'mobilePicture',
    is_main: 'isMain',
    tournaments: 'tournaments',
    tournament_stages: 'tournamentStages',
    type: 'type',
    video_link: 'videoLink',
    tag: 'tag',
    send_notification: 'sendNotification',
    notifications_count: 'notificationsCount',
    notifications_viewed_count: 'notificationsViewedCount',
  },
  relation: {
    datetime: MomentDateTimeField,
    picture: File,
    cover: File,
    mobilePicture: File,
    tournaments: listField(Tournament),
    tournamentStages: listField(TournamentStage),
    type: enumField(LeagueNewsTypes),
  }
})
export class LeagueNews extends BaseModel {
  id: number;
  title: string;
  datetime: moment.Moment;
  previewText: string;
  detailText: string;
  picture: File;
  cover: File;
  mobilePicture: File;
  isMain: boolean;
  tournaments: Tournament[];
  tournamentStages: TournamentStage[];
  type: LeagueNewsTypes;
  videoLink: string;
  tag: string;
  sendNotification: boolean;
  notificationsCount: number;
  notificationsViewedCount: number;

  @ToFrontHook
  static toFront(data: any): any {
    return undefined;
  }

  @ToBackHook
  static toBack(data: any): any {}
}
