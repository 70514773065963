import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGameService } from '@core/services/tournament-game.service';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { Game } from '@core/models/game';
import { WrestballGameTeamStatistic } from '@core/models/wrestball-game-team-statistic';
import { WrestballGameStatistic } from '@core/models/wrestball-game-statistic';
import { WrestballGameLog } from '@core/models/wrestball-game-log';
import { WrestballGameConfig } from '@core/models/wrestball-game-config';

@Injectable()
export class TournamentWrestballGameService extends TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getGame(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/tournament_wrestball_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<WrestballGameTeamStatistic> {
    return this.httpClient.get(`/api/v1/tournament_wrestball_game/${gameId}/team_statistic/`).pipe(
      map(result => WrestballGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<WrestballGameStatistic[]> {
    return this.httpClient.get(`/api/v1/tournament_wrestball_game/${gameId}/user_statistic/`).pipe(
      map(result => WrestballGameStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<WrestballGameLog[]> {
    return this.httpClient.get(`/api/v1/tournament_wrestball_game/${gameId}/logs/`).pipe(
      map(result => WrestballGameLog.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<WrestballGameConfig> {
    return this.httpClient.get(`/api/v1/tournament_wrestball_game/${gameId}/config/`).pipe(
      map(result => WrestballGameConfig.toFront(result))
    );
  }
}
