import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@app/layout/services/layout.service';
import { CentrifugoService } from '@core/services/centrifugo.service';
import { DialogService } from '@ui-kit/dialog/dialog.service';
import { FeedbackComponent } from '@app/layout/components/feedback/feedback.component';
import * as RoutingMap from '@app/routing-map';
import { LocalizationService } from '@shared/modules/localization/localization.service';
import layoutLocalization from '@app/layout/components/layout/layout.localization';

@Component({
  selector: 'mtg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  year: number;
  agreementRoute = RoutingMap.ROUTE_POLICY_ABSOLUTE;
  localization = layoutLocalization[this.localizationService.getLocale()];

  constructor(
    private layoutService: LayoutService,
    private centrifugoService: CentrifugoService,
    private dialogService: DialogService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    this.layoutService.initialize().subscribe();
    this.centrifugoService.listen('autoreload').subscribe(() => {
      window.location.reload();
    });
    this.year = (new Date()).getFullYear();
  }

  ngOnDestroy(): void {
    this.layoutService.dispose();
    this.centrifugoService.unsubscribe('autoreload');
  }

  openFeedbackForm() {
    this.dialogService.open(FeedbackComponent, {
      isCloseButtonVisible: true
    });
  }
}
