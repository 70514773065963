import { Locales } from '@shared/modules/localization/locales';

export default {
  [Locales.ru]: {
    createTeam: 'Создать команду',
  },
  [Locales.en]: {
    createTeam: 'Create a team',
  }
};
