import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { LocalizationService } from './localization.service';

@Pipe({
  name: 'mtgDateFormat',
  pure: false,
})
export class DatePipe implements PipeTransform, OnDestroy {
  private langSubscription?: Subscription;
  private value: any;
  private lastKey: any;

  constructor(
    private localizationService: LocalizationService,
    private cdr: ChangeDetectorRef
  ) {}

  transform(value: any, format: string): any {
    const cacheKey = `${value}_${format}`;
    if (this.lastKey === cacheKey) {
      return this.value;
    }
    if (!this.langSubscription) {
      this.langSubscription = this.localizationService.locale$.subscribe(() => {
        if (this.lastKey) {
          this.value = formatDate(
            value,
            format,
            this.localizationService.getLocale() as string
          );
          this.cdr.markForCheck();
        }
      });
    }
    this.lastKey = cacheKey;
    this.value = formatDate(
      value,
      format,
      this.localizationService.getLocale() as string
    );
    return this.value;
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
