export function isTouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function(q) {
    return window.matchMedia(q).matches;
  };

  if (('ontouchstart' in window) || (window as any).DocumentTouch && document instanceof (window as any).DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export function isMobileDevice(): boolean {
  let deviceGotTouchEvent = false;

  try {
    document.createEvent('TouchEvent');
    deviceGotTouchEvent = true;
  } catch (e) {
    deviceGotTouchEvent = false;
  }

  return window.matchMedia('only screen and (max-width: 1050px)').matches && deviceGotTouchEvent;
}
