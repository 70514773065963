import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField } from '@core/models/util';
import { VolleyballStatisticTypes } from '@core/models/tournament';

export enum VolleyballGameTypes {
  best_of_five = 1,
  best_of_three
}

@ModelInstance({
  mappingFields: {
    game_type: 'gameType',
    timeout_count: 'timeoutCount',
    timeout_time: 'timeoutTime',
    substitute_count: 'substituteCount',
    score_in_set: 'scoreInSet',
    score_in_last_set: 'scoreInLastSet',
    statistic_type: 'statisticType',
    libero_user_ids: 'liberoUserIds',
  },
  relation: {
    gameType: enumField(VolleyballGameTypes),
    statisticType: enumField(VolleyballStatisticTypes),
  }
})
export class VolleyballGameConfig extends BaseModel {
  gameType: VolleyballGameTypes;
  timeoutCount: number;
  timeoutTime: number;
  substituteCount: number;
  scoreInSet: number;
  scoreInLastSet: number;
  statisticType: VolleyballStatisticTypes;
  liberoUserIds: number[];

  get setsCount(): number {
    return this.gameType === VolleyballGameTypes.best_of_three ? 3 : 5;
  }

  getSetMaxScore(set: number) {
    return set === this.setsCount ? this.scoreInLastSet : this.scoreInSet;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
