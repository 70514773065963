import { BaseModel, enumField, listField, ModelInstance, ToBackHook, ToFrontHook } from './util';
import { User } from './user';
import { File } from '@core/models/file';

export enum LeagueUserRoles {
  admin = 1,
  moderator,
  game_manager
}

export enum LeagueUserPermissions {
  settings,
  users,
  teams,
  media,
  news,
  game_export,
  schedule,
  game_management,
  user_rating,
  documents
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    user: 'user',
    role: 'role',
    permissions: 'permissions'
  },
  relation: {
    user: User,
    permissions: listField(enumField(LeagueUserPermissions))
  }
})
export class LeagueUser extends BaseModel {
  id: number;
  user: User;
  _role: LeagueUserRoles;
  permissions: LeagueUserPermissions[];

  set role(value: LeagueUserRoles | string) {
    this._role = LeagueUserRoles[value];
  }

  get role(): LeagueUserRoles | string {
    return this._role;
  }

  @ToFrontHook
  static toFront(data: any): any {
  }

  @ToBackHook
  static toBack(data: any): any {
  }
}
