import { enumField, ModelInstance, ToFrontHook, ToBackHook, BaseModel, DateTimeField } from './util';
import { GameUser } from './game-user';
import { GameLogBase } from './game-log-base';

export enum WrestballGameLogTypes {
  enter_game = 1,
  exit_game,
  remove_game,
  one_point_made,
  one_point_attempt,
  two_point_made,
  two_point_attempt,
  three_point_made,
  three_point_attempt,
  free_throw_made,
  free_throw_attempt,
  assist,
  block,
  rebound,
  offensive_rebound,
  defensive_rebound,
  team_rebound,
  steal,
  turnover,
  personal_foul,
  timeout,
  wrestler_scrum,
  wrestler_scrum_won,
  wrestler_scrum_lost,
  wrestler_scrum_tie,
  scrum,
  scrum_won,
  scrum_lost,
  scrum_tie,
  yellow_card,
  red_card,
  wrestler_scrum_counterattack,
  scrum_ball_advantage_won,
  scrum_ball_advantage_lost,
  scrum_ball_advantage_tie,
  dunk_made,
  dunk_attempt,
  two_point_free_throw_made,
  two_point_free_throw_attempt,
  three_point_free_throw_made,
  three_point_free_throw_attempt
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    team_id: 'teamId',
    log_type: 'logType',
    datetime: 'datetime',
    time: 'time',
    period: 'period',
    active: 'active',
    is_highlight: 'isHighlight',
    foul_on_game_user_id: 'foulOnGameUserId',
    is_coach: 'isCoach',
    is_bench: 'isBench',
    marked: 'marked',
  },
  relation: {
    datetime: DateTimeField,
    logType: enumField(WrestballGameLogTypes),
  }
})
export class WrestballGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  gameId: number;
  gameUserId: number;
  gameUser: GameUser;
  teamId: number;
  logType: WrestballGameLogTypes;
  datetime: Date;
  time: number;
  period: number;
  active = true;
  teamScore: number;
  competitorTeamScore: number;
  isHighlight: boolean;
  foulOnGameUserId: number;
  foulOnGameUser: GameUser;
  isCoach: boolean;
  isBench: boolean;

  isPointsType() {
    return [
      WrestballGameLogTypes.two_point_made,
      WrestballGameLogTypes.three_point_made,
      WrestballGameLogTypes.free_throw_made,
      WrestballGameLogTypes.two_point_free_throw_made,
      WrestballGameLogTypes.three_point_free_throw_made,
      WrestballGameLogTypes.one_point_made,
      WrestballGameLogTypes.scrum_won,
      WrestballGameLogTypes.wrestler_scrum_won,
      WrestballGameLogTypes.wrestler_scrum_counterattack,
      WrestballGameLogTypes.dunk_made,
    ].indexOf(this.logType) > -1;
  }

  isReboundType() {
    return [
      WrestballGameLogTypes.rebound,
      WrestballGameLogTypes.offensive_rebound,
      WrestballGameLogTypes.defensive_rebound,
      WrestballGameLogTypes.team_rebound,
    ].indexOf(this.logType) > -1;
  }

  isFoulType() {
    return [
      WrestballGameLogTypes.personal_foul,
      WrestballGameLogTypes.yellow_card,
      WrestballGameLogTypes.red_card,
    ].indexOf(this.logType) > -1;
  }

  getScore() {
    const scoreMapping = {
      [WrestballGameLogTypes.one_point_made]: 1,
      [WrestballGameLogTypes.free_throw_made]: 1,
      [WrestballGameLogTypes.two_point_free_throw_made]: 2,
      [WrestballGameLogTypes.three_point_free_throw_made]: 3,
      [WrestballGameLogTypes.two_point_made]: 2,
      [WrestballGameLogTypes.three_point_made]: 3,
      [WrestballGameLogTypes.scrum_won]: 2,
      [WrestballGameLogTypes.wrestler_scrum_won]: 2,
      [WrestballGameLogTypes.wrestler_scrum_counterattack]: 2,
      [WrestballGameLogTypes.dunk_made]: 2,
    };
    return scoreMapping[this.logType] || 0;
  }

  compare(model: WrestballGameLog): number {
    if (this.time === model.time && this.period === model.period) {
      if (this.datetime.getTime() === model.datetime.getTime()) {
        return this.id > model.id ? 1 : -1;
      }
      return this.datetime.getTime() < model.datetime.getTime() ? 1 : -1;
    }
    if (this.period === model.period) {
      return this.time < model.time ? 1 : -1;
    }
    return this.period < model.period ? 1 : -1;
  }

  get timeFormatted(): string {
    const minutes = Math.floor(this.time / 60);
    const seconds = this.time - minutes * 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  @ToFrontHook
  static toFront(value: any): any {}

  @ToBackHook
  static toBack(value: any): any {}
}
