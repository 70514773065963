import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Game } from '@core/models/game';
import { map } from 'rxjs/operators';
import { GameUser } from '@core/models/game-user';
import { TeamUser } from '@core/models/team-user';
import { BasketballGameConfig } from '@core/models/basketball-game-config';
import { VolleyballGameConfig } from '@core/models/volleyball-game-config';
import { VolleyballGameLog } from '@core/models/volleyball-game-log';
import { OrgGameService } from '@core/services/org-game.service';
import { GameManagementHttpInterface } from '@core/services/game-management-http.interface';
import { GameServiceInterface } from '@core/services/game-service.interface';
import { VolleyballGameTeamStatistic } from '@core/models/volleyball-game-team-statistic';
import { VolleyballGameStatistic } from '@core/models/volleyball-game-statistic';
import { VolleyballStatistic } from '@core/models/volleyball-statistic';

@Injectable()
export class OrgVolleyballGameService extends OrgGameService implements GameManagementHttpInterface, GameServiceInterface {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getById(id: number): Observable<Game> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${id}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  updateGame(game: Game): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/volleyball_game/${game.id}/`, Game.toBack(game))
      .pipe(
        map(data => Game.toFront(data))
      );
  }

  updateGameById(gameId: number, data: any): Observable<Game> {
    return this.httpClient
      .patch(`${this.apiUrl}/volleyball_game/${gameId}/`, data)
      .pipe(
        map(result => Game.toFront(data))
      );
  }

  getGameLogs(gameId: number): Observable<VolleyballGameLog[]> {
    return this.httpClient
      .get(`${this.apiUrl}/volleyball_game/${gameId}/logs/`)
      .pipe(
        map(data => VolleyballGameLog.toFront(data))
      );
  }

  saveGameLog(gameId: number, gameLog: VolleyballGameLog): Observable<VolleyballGameLog> {
    return this.httpClient
      .post(`${this.apiUrl}/volleyball_game/${gameId}/logs/`, VolleyballGameLog.toBack(gameLog))
      .pipe(
        map(data => VolleyballGameLog.toFront(data))
      );
  }

  updateGameLog(gameLogId: number, data: any): Observable<VolleyballGameLog> {
    return this.httpClient
      .patch(`${this.apiUrl}/volleyball_game_log/${gameLogId}/`, VolleyballGameLog.toBack(data))
      .pipe(
        map(result => VolleyballGameLog.toFront(result))
      );
  }

  deleteGameLog(gameLogId: number): Observable<any> {
    return this.httpClient
      .delete(`${this.apiUrl}/volleyball_game_log/${gameLogId}/`);
  }

  getGameConfig(gameId: number): Observable<VolleyballGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/config/`).pipe(
      map(result => VolleyballGameConfig.toFront(result))
    );
  }

  updateGameConfig(gameId: number, data: any): Observable<VolleyballGameConfig> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/config/`, VolleyballGameConfig.toBack(data)).pipe(
      map(result => VolleyballGameConfig.toFront(result))
    );
  }

  closeGame(game: Game, result_type?: any): Observable<Game> {
    return this.httpClient.post(`${this.apiUrl}/volleyball_game/${game.id}/close/`, {result_type: result_type}).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<VolleyballGameTeamStatistic> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/team_statistic/`).pipe(
      map(result => VolleyballGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<VolleyballGameStatistic[]> {
    return this.httpClient.get(`${this.apiUrl}/volleyball_game/${gameId}/user_statistic/`).pipe(
      map(result => VolleyballGameStatistic.toFront(result))
    );
  }
}
