import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@shared/modules/localization/localization.service';

@Pipe({
  name: 'localization',
})
export class LocalizationPipe implements PipeTransform {
  constructor(
    private localizationService: LocalizationService,
  ) {
  }
  transform(value: any, dictionary: any): any {
    const locale = this.localizationService.getLocale();
    return dictionary && dictionary[locale] && dictionary[locale][value] || value;
  }
}
