import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, listField } from './util';
import { TournamentGroup } from './tournament-group';
import { TournamentDivision } from './tournament-division';
import { TournamentTeam } from './tournament-team';

@ModelInstance({
  mappingFields: {
    id: 'id',
    team_id: 'teamId',
    competitor_team_id: 'competitorTeamId',
    team_score: 'teamScore',
    competitor_team_score: 'competitorTeamScore',
  }
})
export class RoundTeamGame extends BaseModel {
  id: number;
  teamId: number;
  competitorTeamId: number;
  teamScore: number;
  competitorTeamScore: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    tournament_team: 'tournamentTeam',
    group: 'group',
    division: 'division',
    points: 'points',
    handicap: 'handicap',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    lose_games_count: 'loseGamesCount',
    draw_games_count: 'drawGamesCount',
    score_sum: 'scoreSum',
    missed_sum: 'missedSum',
    games_won_percent: 'gamesWonPercent',
    score_points_sum: 'scorePointsSum',
    missed_points_sum: 'missedPointsSum',
    win_normal_time_games_count: 'winNormalTimeGamesCount',
    lose_normal_time_games_count: 'loseNormalTimeGamesCount',
    win_overtime_games_count: 'winOvertimeGamesCount',
    lose_overtime_games_count: 'loseOvertimeGamesCount',
    last_games_won: 'lastGamesWon',
    last_games_count: 'lastGamesCount',
    tries_scored: 'triesScored',
    tries_missed: 'triesMissed',
    attack_bonuses: 'attackBonuses',
    defense_bonuses: 'defenseBonuses',
    games_by_score_count: 'gamesByScoreCount',
    games: 'games',
  },
  relation: {
    group: TournamentGroup,
    division: TournamentDivision,
    tournamentTeam: TournamentTeam,
    games: listField(RoundTeamGame),
  }
})
export class TournamentRoundTeam extends BaseModel {
  private _games: RoundTeamGame[];

  id: number;
  tournamentTeam: TournamentTeam;
  group: TournamentGroup;
  division: TournamentDivision;
  points: number;
  handicap: number;
  gamesCount: number;
  wonGamesCount: number;
  loseGamesCount: number;
  drawGamesCount: number;
  scoreSum: number;
  missedSum: number;
  scorePointsSum: number;
  missedPointsSum: number;
  winNormalTimeGamesCount: number;
  loseNormalTimeGamesCount: number;
  winOvertimeGamesCount: number;
  loseOvertimeGamesCount: number;
  gamesWonPercent: number;
  lastGamesWon: number;
  lastGamesCount: number;
  triesScored: number;
  triesMissed: number;
  attackBonuses: number;
  defenseBonuses: number;
  gamesByScoreCount: {[key: string]: number};
  gamesMapping: {[key: number]: RoundTeamGame[]} = {};

  set games(value: RoundTeamGame[]) {
    this._games = value;
    this.gamesMapping = value.reduce((acc, game) => {
      const competitorTeamId = game.teamId === this.tournamentTeam.team.id ? game.competitorTeamId : game.teamId;
      return {
        ...acc,
        [competitorTeamId]: [
          ...(acc[competitorTeamId] || []),
          game,
        ]
      };
    }, {});
  }

  get games(): RoundTeamGame[] {
    return this._games;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
