<mtg-basketball-statistic-table
  *ngIf="sport.isBasketball()"
  [sport]="sport"
  [filters]="filters"
  [identity]="identity"
  [subTitle]="subTitle"
  [route]="route"
  [sortable]="sortable"
  [tournament]="tournament"
></mtg-basketball-statistic-table>
<mtg-volleyball-statistic-table
  *ngIf="sport.isVolleyball()"
  [sport]="sport"
  [filters]="filters"
  [identity]="identity"
  [subTitle]="subTitle"
  [route]="route"
  [sortable]="sortable"
></mtg-volleyball-statistic-table>
