import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalizationService } from './localization.service';

@Pipe({ name: 'mtgFormErrorLocalization', pure: false })
export class FormErrorLocalizationPipe implements PipeTransform, OnDestroy {
  private langChangeSubscription?: Subscription;
  private lastKey: string;
  private lastParams: any[];
  private value: string;

  constructor(
    private localizationService: LocalizationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  transform(value: any, ...args: any[]): string {
    if (this.lastKey === value) {
      return this.value;
    }
    if (!this.langChangeSubscription) {
      this.langChangeSubscription = this.localizationService.locale$.subscribe(
        () => {
          if (this.lastKey) {
            this.value = this.localizationService.formErrorLocalize(value);
            this.cdr.markForCheck();
          }
        }
      );
    }
    this.value = this.localizationService.formErrorLocalize(value);
    this.lastKey = value;
    return this.value;
  }
}
