import { Component } from '@angular/core';
import { StepComponent } from './step.component';

@Component({
  selector: 'mtg-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  steps: StepComponent[] = [];

  addStep(step: StepComponent) {
    this.steps.push(step);
  }

  getStepNumber(step: StepComponent): number {
    return this.steps.indexOf(step) + 1;
  }
}
