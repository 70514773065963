<div class="pagination" [ngClass]="size" *ngIf="pages.length > 1">
  <a class="page-circle arr prev-page icon icon-chevron-left"
     *ngIf="current > 1"
     (click)="changePage(current - 1)"
  ></a>
  <a *ngFor="let page of pages"
     class="page-circle"
     [ngClass]="{current: current == page}"
     (click)="changePage(page)"
  >{{ page }}</a>
  <a class="page-circle arr next-page icon icon-chevron-right"
     *ngIf="current < count"
     (click)="changePage(current + 1)"
  ></a>
</div>
