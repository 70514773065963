import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, enumField, MomentDateTimeField } from '@core/models/util';
import { GameLogBase } from '@core/models/game-log-base';
import * as moment from 'moment';
import { GameUser } from '@core/models/game-user';

export enum VolleyballGameLogType {
  enter_game = 1,
  exit_game,
  remove_game,
  serve_hit,
  serve_fault,
  serve_ace,
  stuff_block,
  block_fault,
  block_rebound,
  attack_spike,
  attack_fault,
  attack_shot,
  receive,
  receive_fault,
  excellent_receive,
  point,
  fault,
  serve_receive,
  serve_receive_fault,
  excellent_serve_receive,
  timeout,
  yellow_card,
}

export const SCORE_LOG_TYPES = [
  VolleyballGameLogType.serve_ace, VolleyballGameLogType.stuff_block,
  VolleyballGameLogType.attack_spike, VolleyballGameLogType.point
];
export const FAULT_LOG_TYPES = [
  VolleyballGameLogType.serve_fault, VolleyballGameLogType.attack_fault,
  VolleyballGameLogType.block_fault, VolleyballGameLogType.receive_fault,
  VolleyballGameLogType.fault, VolleyballGameLogType.serve_receive_fault
];

@ModelInstance({
  mappingFields: {
    id: 'id',
    unique_id: 'uniqueId',
    game_id: 'gameId',
    game_user_id: 'gameUserId',
    log_type: 'logType',
    set: 'set',
    rally: 'rally',
    position: 'position',
    team_id: 'teamId',
    datetime: 'datetime',
    group: 'group',
  },
  relation: {
    logType: enumField(VolleyballGameLogType),
    datetime: MomentDateTimeField
  }
})
export class VolleyballGameLog extends BaseModel implements GameLogBase {
  id: number;
  uniqueId: string;
  active = true;
  gameId: number;
  gameUserId: number;
  logType: VolleyballGameLogType;
  set: number;
  rally: number;
  position: number;
  teamId: number;
  group: string;
  datetime: moment.Moment;
  gameUser: GameUser;

  compare(model: VolleyballGameLog): number {
    if (this.rally === model.rally && this.set === model.set) {
      if (this.datetime && model.datetime) {
        return this.datetime.isBefore(model.datetime) ? 1 : -1;
      } else {
        return this.id < model.id ? 1 : -1;
      }
    }
    if (this.set === model.set) {
      return this.rally < model.rally ? 1 : -1;
    }
    return this.set < model.set ? 1 : -1;
  }

  isScoreType() {
    return SCORE_LOG_TYPES.includes(this.logType);
  }

  isFaultType() {
    return FAULT_LOG_TYPES.includes(this.logType);
  }

  isServe() {
    return [
      VolleyballGameLogType.serve_hit,
      VolleyballGameLogType.serve_ace,
      VolleyballGameLogType.serve_fault
    ].includes(this.logType);
  }

  isAttack() {
    return [
      VolleyballGameLogType.attack_fault,
      VolleyballGameLogType.attack_spike,
      VolleyballGameLogType.attack_shot
    ].includes(this.logType);
  }

  isReceive() {
    return [
      VolleyballGameLogType.receive_fault,
      VolleyballGameLogType.receive,
      VolleyballGameLogType.excellent_receive
    ].includes(this.logType);
  }

  isServeReceive() {
    return [
      VolleyballGameLogType.serve_receive_fault,
      VolleyballGameLogType.serve_receive,
      VolleyballGameLogType.excellent_serve_receive
    ].includes(this.logType);
  }

  isBlock() {
    return [
      VolleyballGameLogType.block_fault,
      VolleyballGameLogType.block_rebound,
      VolleyballGameLogType.stuff_block
    ].includes(this.logType);
  }

  isEnterExit() {
    return [
      VolleyballGameLogType.enter_game,
      VolleyballGameLogType.exit_game,
      VolleyballGameLogType.timeout
    ].includes(this.logType);
  }

  isComboType() {
    return ![
      VolleyballGameLogType.receive,
      VolleyballGameLogType.serve_receive
    ].includes(this.logType);
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
