import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Metrika } from 'ng-yandex-metrika';
import * as Sentry from '@sentry/angular';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { componentDestroyed, UntilDestroy } from '@shared/util/component-destroyed';
import { User } from '@core/models/user';
import * as RoutingMap from '@app/routing-map';
import { PwaPromptService } from '@shared/modules/pwa-prompt/pwa-prompt.service';

@UntilDestroy
@Component({
  selector: 'mtg-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  notificationExcludeRoutes = [
    '/stream-widgets',
    `/${RoutingMap.ROUTE_GAME_MANAGEMENT_ROOT}`
  ];
  notificationsEnabled: boolean;
  resizeTimeout;

  constructor(
    private metrika: Metrika,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private pwaPromptService: PwaPromptService,
  ) {
  }

  ngOnInit(): void {
    let prevPath = this.location.path();
    this.router
      .events
      .pipe(
        filter(event => (event instanceof NavigationEnd))
      )
      .subscribe(() => {
        const newPath = this.location.path();
        this.metrika.hit(newPath, {
          referer: prevPath,
        });
        prevPath = newPath;
        this.checkNotificationsEnabled();
      });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 500);
    });
    this.authService.user$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        this.user = user;
        if (user) {
          Sentry.setUser({id: user.id.toString()});
        } else {
          Sentry.setUser(null);
        }
      });
    this.checkNotificationsEnabled();
    this.pwaPromptService.setPromptSettings({
      appName: 'MTGame',
      appLogoPath: '/assets/favicon/pwa-icon-dark-android-chrome-192x192.png'
    });
  }

  ngOnDestroy(): void {
  }

  checkNotificationsEnabled() {
    this.notificationsEnabled = this.notificationExcludeRoutes.filter(
      item => window.location.pathname.substr(0, item.length) === item
    ).length === 0;
  }

  get notificationSettingsRoute(): string[] {
    return [RoutingMap.ROUTE_PROFILE_FORM_ABSOLUTE, RoutingMap.ROUTE_PROFILE_FORM_SETTINGS];
  }
}
