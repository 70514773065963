import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class MainPageResolve implements Resolve<any> {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.authService
      .user$
      .pipe(
        tap(user => {
          if (!user) {
            this.router.navigate(['auth', 'login']);
          } else {
            this.router.navigate(['profile']);
          }
        })
      );
  }
}
