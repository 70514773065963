import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';

@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    points: 'points',
    shot_misses: 'shotMisses',
    shots_on_goal: 'shotsOnGoal',
    shots_blocked: 'shotsBlocked',
    goals: 'goals',
    assists: 'assists',
    penalty_goals: 'penaltyGoals',
    penalty_misses: 'penaltyMisses',
    penalty_shots_on_goal: 'penaltyShotsOnGoal',
    saves: 'saves',
    penalty_saves: 'penaltySaves',
    goals_against: 'goalsAgainst',
    penalty_goals_against: 'penaltyGoalsAgainst',
    safety_rate: 'safetyRate',
    fouls: 'fouls',
    yellowCards: 'yellowCards',
    red_cards: 'redCards',
    two_minute_fouls: 'twoMinuteFouls',
    turnovers: 'turnovers',
    steals: 'steals',
    block_shots: 'blockShots',
    game_time: 'gameTime',
    plus_minus: 'plusMinus'
  }
})
export class HandballGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  shotMisses: number;
  shotsOnGoal: number;
  shotsBlocked: number;
  goals: number;
  assists: number;
  penaltyGoals: number;
  penaltyMisses: number;
  penaltyShotsOnGoal: number;
  saves: number;
  penaltySaves: number;
  goalsAgainst: number;
  penaltyGoalsAgainst: number;
  safetyRate: number;
  fouls: number;
  yellowCards: number;
  redCards: number;
  twoMinuteFouls: number;
  turnovers: number;
  steals: number;
  blockShots: number;
  gameTime: number;
  plusMinus: number;

  get id(): number {
    return this.gameUserId;
  }

  get shots(): number {
    return (this.shotMisses || 0) + (this.shotsOnGoal || 0) + (this.shotsBlocked || 0);
  }


  get totalGoals(): number {
    return (this.goals || 0) + (this.penaltyGoals || 0);
  }

  get goalsPercent(): number {
    if (!this.shots || !this.totalGoals) {
      return 0;
    }
    return Math.round(1000 * this.totalGoals / this.shots) / 10;
  }

  get shotsOnGoalPercent(): number {
    if (!this.shotsOnGoal || !this.shots) {
      return 0;
    }
    return Math.round(1000 * this.shotsOnGoal / this.shots) / 10;
  }

  get penaltyShots(): number {
    return (this.penaltyShotsOnGoal || 0) + (this.penaltyMisses || 0);
  }

  get penaltyGoalsPercent(): number {
    if (!this.penaltyShots || !this.penaltyGoals) {
      return 0;
    }
    return Math.round(1000 * this.penaltyGoals / this.penaltyShots) / 10;
  }

  get shotsAgainst(): number {
    return (this.saves || 0) + (this.goalsAgainst || 0);
  }

  get penaltyShotsAgainst(): number {
    return (this.penaltySaves || 0) + (this.penaltyGoalsAgainst || 0);
  }

  get totalGoalsAgainst(): number {
    return (this.goalsAgainst || 0) + (this.penaltyGoalsAgainst || 0);
  }

  get totalSaves(): number {
    return (this.saves || 0) + (this.penaltySaves || 0);
  }

  get savesPercent(): number {
    if (!this.shotsAgainst || !this.saves) {
      return 0;
    }
    return Math.round(1000 * this.saves / this.shotsAgainst) / 10;
  }

  get penaltySavesPercent(): number {
    if (!this.penaltyShotsAgainst || !this.penaltySaves) {
      return 0;
    }
    return Math.round(1000 * this.penaltySaves / this.penaltyShotsAgainst) / 10;
  }

  get gameMinutes(): number {
    return Math.floor(this.gameTime / 60);
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
