import { AfterViewChecked, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mtgFitToParent]'
})
export class FitToParentDirective implements AfterViewChecked {
  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('window:resize')
  onWindowResize() {
    this.transform();
  }

  ngAfterViewChecked(): void {
    this.transform();
  }

  transform() {
    this.elementRef.nativeElement.style.transform = null;
    const parentPosition = this.elementRef.nativeElement.parentNode.getBoundingClientRect();
    const parentStyles = window.getComputedStyle(this.elementRef.nativeElement.parentNode);
    parentPosition.width -= parseFloat(parentStyles.paddingLeft) + parseFloat(parentStyles.paddingRight);
    parentPosition.height -= parseFloat(parentStyles.paddingTop) + parseFloat(parentStyles.paddingBottom);
    const elementPosition = this.elementRef.nativeElement.getBoundingClientRect();
    if (elementPosition.width > parentPosition.width || elementPosition.height > parentPosition.height) {
      const scale = Math.min(parentPosition.width / elementPosition.width, parentPosition.height / elementPosition.height);
      this.elementRef.nativeElement.style.transform = `scale(${scale})`;
      if (parentPosition.width < elementPosition.width && parentPosition.height < elementPosition.height) {
        this.elementRef.nativeElement.style.transformOrigin = 'center center';
      } else if (parentPosition.height < elementPosition.height) {
        this.elementRef.nativeElement.style.transformOrigin = 'center 0';
      } else {
        this.elementRef.nativeElement.style.transformOrigin = '0';
      }
    }
  }
}
