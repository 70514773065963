import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField } from '@core/models/util';
import * as moment from 'moment';

@ModelInstance({
  mappingFields: {
    game_user_id: 'gameUserId',
    points: 'points',
    free_throws_made: 'freeThrowsMade',
    two_points_made: 'twoPointsMade',
    three_points_made: 'threePointsMade',
    one_points_made: 'onePointsMade',
    two_point_attempts: 'twoPointAttempts',
    three_point_attempts: 'threePointAttempts',
    free_throw_attempts: 'freeThrowAttempts',
    one_point_attempts: 'onePointAttempts',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    offensive_rebounds: 'offensiveRebounds',
    defensive_rebounds: 'defensiveRebounds',
    steals: 'steals',
    turnovers: 'turnovers',
    personal_fouls: 'personalFouls',
    technical_fouls: 'technicalFouls',
    unsportsmanlike_fouls: 'unsportsmanlikeFouls',
    disqualification_fouls: 'disqualificationFouls',
    drawn_fouls: 'drawnFouls',
    player_efficiency: 'playerEfficiency',
    shots_blocked: 'shotsBlocked',
    four_point_attempts: 'fourPointAttempts',
    four_points_made: 'fourPointsMade',
    dunk_attempts: 'dunkAttempts',
    dunks_made: 'dunksMade',
    shootouts_won: 'shootoutsWon',
    shootouts_lost: 'shootoutsLost',
    moneyball_free_throw_attepts: 'moneyballFreeThrowAttempts',
    moneyball_free_throws_made: 'moneyballFreeThrowsMade',
    updated_at: 'updatedAt',
  },
  relation: {
    updatedAt: MomentDateTimeField,
  }
})
export class BasketballGameStatistic extends BaseModel {
  gameUserId: number;
  points: number;
  freeThrowsMade: number;
  twoPointsMade: number;
  threePointsMade: number;
  onePointsMade: number;
  twoPointAttempts: number;
  threePointAttempts: number;
  freeThrowAttempts: number;
  onePointAttempts: number;
  assists: number;
  blocks: number;
  rebounds: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  steals: number;
  turnovers: number;
  personalFouls: number;
  technicalFouls: number;
  unsportsmanlikeFouls: number;
  disqualificationFouls: number;
  drawnFouls: number;
  playerEfficiency: number;
  shotsBlocked: number;
  fourPointAttempts: number;
  fourPointsMade: number;
  dunkAttempts: number;
  dunksMade: number;
  shootoutsWon: number;
  shootoutsLost: number;
  moneyballFreeThrowAttempts: number;
  moneyballFreeThrowsMade: number;
  updatedAt: moment.Moment;

  get id(): number {
    return this.gameUserId;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}

  get onePointsPercent(): number {
    if (!this.onePointsMade || !this.onePointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.onePointsMade / this.onePointAttempts) / 10;
  }

  get twoPointsPercent(): number {
    if (!this.twoPointsMade || !this.twoPointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.twoPointsMade / this.twoPointAttempts) / 10;
  }

  get threePointsPercent(): number {
    if (!this.threePointsMade || !this.threePointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.threePointsMade / this.threePointAttempts) / 10;
  }

  get freeThrowsPercent(): number {
    if (!this.freeThrowsMade || !this.freeThrowAttempts) {
      return 0;
    }
    return Math.round(1000 * this.freeThrowsMade / this.freeThrowAttempts) / 10;
  }

  get fourPointPercent(): number {
    if (!this.fourPointAttempts || !this.fourPointsMade) {
      return 0;
    }
    return Math.round(1000 * this.fourPointsMade / this.fourPointAttempts) / 10;
  }

  get moneyballFreeThrowPercent(): number {
    if (!this.moneyballFreeThrowsMade || !this.moneyballFreeThrowAttempts) {
      return 0;
    }
    return Math.round(1000 * this.moneyballFreeThrowsMade / this.moneyballFreeThrowAttempts) / 10;
  }

  get totalFouls(): number {
    return (this.personalFouls || 0) + (this.technicalFouls || 0) + (this.unsportsmanlikeFouls || 0);
  }
}
