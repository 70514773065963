import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoyaltyProgramsStatistics } from '@core/models/loyalty-programs-statistics';
import { LoyaltyProgram } from '@core/models/loyalty-program';
import { LoyaltyProgramLeagueStatistic } from '@core/models/loyalty-program-league-statistic';

@Injectable()
export class LoyaltyProgramsService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getLoyaltyPrograms(): Observable<LoyaltyProgram[]> {
    return this.httpClient
      .get(`/org/api/v1/loyalty_program/`)
      .pipe(map(response => LoyaltyProgram.toFront(response)));
  }

  getTotalStatistic(): Observable<LoyaltyProgramsStatistics> {
    return this.httpClient
      .get(`/org/api/v1/loyalty_program/statistic/`)
      .pipe(map(response => LoyaltyProgramsStatistics.toFront(response)));
  }

  getLeagueStatistic(loyaltyProgramId: number): Observable<LoyaltyProgramLeagueStatistic[]> {
    return this.httpClient
      .get(`/org/api/v1/loyalty_program/${loyaltyProgramId}/statistic/`)
      .pipe(map(response => LoyaltyProgramLeagueStatistic.toFront(response)));
  }
}
