import { BaseModel, ModelInstance, ToFrontHook, ToBackHook, DateField } from './util';
import { TournamentTeamUser } from './tournament-team-user';
import { Team } from './team';
import { TeamUser } from './team-user';
import { User } from './user';
import { TournamentTeam } from './tournament-team';
import { LeaguePlayer } from './league-player';

@ModelInstance({
  mappingFields: {
    league_player: 'leaguePlayer',
    tournament_team_user: 'tournamentTeamUser',
    team: 'team',
    team_user: 'teamUser',
    user: 'user',
    tournament_team: 'tournamentTeam',
    month: 'month',
    win_lose: 'winLose',
    games_count: 'gamesCount',
    won_games_count: 'wonGamesCount',
    team_score_sum: 'teamScoreSum',
    team_missed_sum: 'teamMissedSum',
    tournament_points: 'tournamentPoints',
    points: 'points',
    two_points_made: 'twoPointsMade',
    three_points_made: 'threePointsMade',
    free_throws_made: 'freeThrowsMade',
    two_points_free_throw_made: 'twoPointsFreeThrowMade',
    three_points_free_throw_made: 'threePointsFreeThrowMade',
    total_free_throw_made: 'totalFreeThrowMade',
    one_points_made: 'onePointsMade',
    dunks_made: 'dunksMade',
    two_point_attempts: 'twoPointAttempts',
    three_point_attempts: 'threePointAttempts',
    free_throw_attempts: 'freeThrowAttempts',
    two_point_free_throw_attempts: 'twoPointFreeThrowAttempts',
    three_point_free_throw_attempts: 'threePointFreeThrowAttempts',
    total_free_throw_attempts: 'totalFreeThrowAttempts',
    one_point_attempts: 'onePointAttempts',
    dunk_attempts: 'dunkAttempts',
    two_point_percent: 'twoPointPercent',
    three_point_percent: 'threePointPercent',
    free_throw_percent: 'freeThrowPercent',
    total_free_throw_percent: 'totalFreeThrowPercent',
    one_point_percent: 'onePointPercent',
    dunks_made_percent: 'dunksMadePercent',
    assists: 'assists',
    blocks: 'blocks',
    rebounds: 'rebounds',
    offensive_rebounds: 'offensiveRebounds',
    defensive_rebounds: 'defensiveRebounds',
    steals: 'steals',
    turnovers: 'turnovers',
    personal_fouls: 'personalFouls',
    drawn_fouls: 'drawnFouls',
    drawn_wrestler_attempts: 'drawnWrestlerAttempts',
    drawn_wrestler_won_attempts: 'drawnWrestlerWonAttempts',
    drawn_wrestler_lost_attempts: 'drawnWrestlerLostAttempts',
    wrestler_scrums_won: 'wrestlerScrumsWon',
    wrestler_scrums_lost: 'wrestlerScrumsLost',
    wrestler_scrums_tie: 'wrestlerScrumsTie',
    wrestler_scrum_points: 'wrestlerScrumPoints',
    wrestler_scrums_counterattack: 'wrestlerScrumsCounterattack',
    wrestler_scrums_counterattack_points: 'wrestlerScrumsCounterattackPoints',
    scrums_won: 'scrumsWon',
    scrums_lost: 'scrumsLost',
    scrums_tie: 'scrumsTie',
    scrum_points: 'scrumPoints',
    scrums_ball_advantage_won: 'scrumsBallAdvantageWon',
    scrums_ball_advantage_lost: 'scrumsBallAdvantageLost',
    scrums_ball_advantage_tie: 'scrumsBallAdvantageTie',
    scrums_ball_advantage_total: 'scrumsBallAdvantageTotal',
    scrums_ball_advantage_won_percent: 'scrumsBallAdvantageWonPercent',
    yellow_cards: 'yellowCards',
    red_cards: 'redCards',
    wrestler_scrums: 'wrestlerScrums',
    wrestler_scrums_won_percent: 'wrestlerScrumsWonPercent',
    scrums: 'scrums',
    scrums_won_percent: 'scrumsWonPercent',
    total_scrums: 'totalScrums',
    game_time: 'gameTime',
    plus_minus: 'plusMinus',
    newbie: 'newbie',
    rank: 'rank',
  },
  relation: {
    leaguePlayer: LeaguePlayer,
    tournamentTeamUser: TournamentTeamUser,
    team: Team,
    teamUser: TeamUser,
    user: User,
    tournamentTeam: TournamentTeam,
    month: DateField
  }
})
export class WrestballStatistic extends BaseModel {
  leaguePlayer: LeaguePlayer;
  tournamentTeamUser: TournamentTeamUser;
  team: Team;
  teamUser: TeamUser;
  user: User;
  tournamentTeam: TournamentTeam;
  month: Date;
  gamesCount: number;
  wonGamesCount: number;
  teamScoreSum: number;
  teamMissedSum: number;
  tournamentPoints: number;
  points: number;
  twoPointsMade: number;
  threePointsMade: number;
  freeThrowsMade: number;
  twoPointsFreeThrowMade: number;
  threePointsFreeThrowMade: number;
  totalFreeThrowMade: number;
  onePointsMade: number;
  dunksMade: number;
  twoPointAttempts: number;
  threePointAttempts: number;
  freeThrowAttempts: number;
  twoPointFreeThrowAttempts: number;
  threePointFreeThrowAttempts: number;
  totalFreeThrowAttempts: number;
  onePointAttempts: number;
  dunkAttempts: number;
  twoPointPercent: number;
  threePointPercent: number;
  freeThrowPercent: number;
  totalFreeThrowPercent: number;
  onePointPercent: number;
  dunksMadePercent: number;
  assists: number;
  blocks: number;
  rebounds: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  steals: number;
  turnovers: number;
  personalFouls: number;
  drawnFouls: number;
  drawnWrestlerAttempts: number;
  drawnWrestlerWonAttempts: number;
  drawnWrestlerLostAttempts: number;
  wrestlerScrums: number;
  wrestlerScrumsWon: number;
  wrestlerScrumsLost: number;
  wrestlerScrumsTie: number;
  wrestlerScrumPoints: number;
  wrestlerScrumsWonPercent: number;
  wrestlerScrumsCounterattack: number;
  wrestlerScrumsCounterattackPoints: number;
  scrums: number;
  scrumsWon: number;
  scrumsLost: number;
  scrumsTie: number;
  scrumPoints: number;
  scrumsWonPercent: number;
  scrumsBallAdvantageWon: number;
  scrumsBallAdvantageLost: number;
  scrumsBallAdvantageTie: number;
  scrumsBallAdvantageTotal: number;
  scrumsBallAdvantageWonPercent: number;
  totalScrums: number;
  yellowCards: number;
  redCards: number;
  gameTime: number;
  plusMinus: number;
  newbie: boolean;
  rank: number;

  get totalFouls() {
    return Math.floor(10 * (
      (this.personalFouls || 0)
    )) / 10;
  }

  get gameMinutes(): number {
    return Math.floor(this.gameTime / 60);
  }

  get tournamentTeamPoints(): number {
    return this.tournamentTeam && this.tournamentTeam.points;
  }

  get onePointsPercent(): number {
    if (!this.onePointsMade || !this.onePointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.onePointsMade / this.onePointAttempts) / 10;
  }

  get twoPointsPercent(): number {
    if (!this.twoPointsMade || !this.twoPointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.twoPointsMade / this.twoPointAttempts) / 10;
  }

  get threePointsPercent(): number {
    if (!this.threePointsMade || !this.threePointAttempts) {
      return 0;
    }
    return Math.round(1000 * this.threePointsMade / this.threePointAttempts) / 10;
  }

  get dunkMadePercent(): number {
    return !this.dunksMade || !this.dunkAttempts ? 0 : Math.round(1000 * this.dunksMade / this.dunkAttempts) / 10;
  }

  get freeThrowsPercent(): number {
    if (!this.freeThrowsMade || !this.freeThrowAttempts) {
      return 0;
    }
    return Math.round(1000 * this.freeThrowsMade / this.freeThrowAttempts) / 10;
  }

  get totalRebounds(): number {
    return Math.floor(10 * ((this.offensiveRebounds || 0) + (this.defensiveRebounds || 0))) / 10;
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
