import { Locales } from '@shared/modules/localization/locales';


export default {
  [Locales.ru]: {
    search: 'Поиск',
    select: 'Выбрано',
  },
  [Locales.en]: {
    search: 'Search',
    select: 'Select',
  }
};
