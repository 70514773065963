import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TournamentGameService } from '@core/services/tournament-game.service';
import { TournamentGameHttpInterface } from '@core/services/tournament-game-http.interface';
import { Game } from '@core/models/game';
import { HockeyGameTeamStatistic } from '@core/models/hockey-game-team.statistic';
import { HockeyGameStatistic } from '@core/models/hockey-game-statistic';
import { HockeyGameLog } from '@core/models/hockey-game-log';
import { HockeyGameConfig } from '@core/models/hockey-game-config';

@Injectable()
export class TournamentHockeyGameService extends TournamentGameService implements TournamentGameHttpInterface {
  protected resource = 'tournament_game';

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getGame(gameId: number): Observable<Game> {
    return this.httpClient.get(`/api/v1/tournament_hockey_game/${gameId}/`).pipe(
      map(result => Game.toFront(result))
    );
  }

  getGameTeamStatistic(gameId: number): Observable<HockeyGameTeamStatistic> {
    return this.httpClient.get(`/api/v1/tournament_hockey_game/${gameId}/team_statistic/`).pipe(
      map(result => HockeyGameTeamStatistic.toFront(result))
    );
  }

  getGameUserStatistic(gameId: number): Observable<HockeyGameStatistic[]> {
    return this.httpClient.get(`/api/v1/tournament_hockey_game/${gameId}/user_statistic/`).pipe(
      map(result => HockeyGameStatistic.toFront(result))
    );
  }

  getGameLogs(gameId: number): Observable<HockeyGameLog[]> {
    return this.httpClient.get(`/api/v1/tournament_hockey_game/${gameId}/logs/`).pipe(
      map(result => HockeyGameLog.toFront(result))
    );
  }

  getGameConfig(gameId: number): Observable<HockeyGameConfig> {
    return this.httpClient.get(`/api/v1/tournament_hockey_game/${gameId}/config/`).pipe(
      map(result => HockeyGameConfig.toFront(result))
    );
  }
}
