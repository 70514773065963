import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from '@ui-kit/accordion/accordion.component';

@Component({
  selector: 'mtg-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionHeaderComponent implements OnInit {
  contentShown = false;

  constructor(private accordionComponent: AccordionComponent) {}

  ngOnInit() {
    this.accordionComponent
      .showContent$
      .subscribe(value => this.contentShown = value);
  }

  toggleContent(): void {
    this.accordionComponent.toggleContent();
  }

}
