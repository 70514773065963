import { ModelInstance, BaseModel, ToFrontHook, ToBackHook } from './util';
import { TournamentTeam } from '@core/models/tournament-team';

@ModelInstance({
  mappingFields: {
    id: 'id',
    name: 'name',
    sort: 'sort',
    tournament_stage_id: 'tournamentStageId',
    tournament_round_id: 'tournamentRoundId',
    division_id: 'divisionId',
  }
})
export class TournamentGroup extends BaseModel {
  id: number;
  name: string;
  sort: number;
  tournamentStageId: number;
  teams: TournamentTeam[];
  tournamentRoundId: number;
  divisionId: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
