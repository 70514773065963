import { ModelInstance, BaseModel, ToFrontHook, ToBackHook, MomentDateTimeField, enumField } from './util';
import { User } from './user';
import * as moment from 'moment';

export enum PollStatuses {
  open = 1,
  closed,
  archived,
}

@ModelInstance({
  mappingFields: {
    uuid: 'uuid',
    name: 'name'
  }
})
export class PollVariant extends BaseModel {
  uuid: string;
  name: string;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    answer_uuid: 'answerUuid',
    user: 'user',
    poll_id: 'pollId',
  },
  relation: {
    user: User
  }
})
export class PollAnswer extends BaseModel {
  id: number;
  answerUuid: string;
  user: User;
  pollId: number;

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}

@ModelInstance({
  mappingFields: {
    id: 'id',
    active: 'active',
    status: 'status',
    team_id: 'teamId',
    name: 'name',
    description: 'description',
    variants: 'variants',
    author: 'author',
    answers: 'answers',
    multiple_answer: 'multipleAnswer',
    anonymous: 'anonymous',
    created_at: 'createdAt'
  },
  relation: {
    status: enumField(PollStatuses),
    variants: PollVariant,
    author: User,
    answers: PollAnswer,
    createdAt: MomentDateTimeField,
  }
})
export class Poll extends BaseModel {
  id: number;
  active: boolean;
  teamId: number;
  status: PollStatuses;
  name: string;
  description: string;
  _variants: PollVariant[];
  author: User;
  _answers: PollAnswer[];
  multipleAnswer: boolean;
  anonymous: boolean;
  createdAt: moment.Moment;
  variantsWithAnswers: {variant: PollVariant, percent: number}[] = [];

  set variants(value: PollVariant[]) {
    this._variants = value || [];
    if (this._answers) {
      this.calculateAnswersPercentage();
    }
  }
  get variants(): PollVariant[] {
    return this._variants;
  }

  set answers(value: PollAnswer[]) {
    this._answers = value || [];
    if (this._variants) {
      this.calculateAnswersPercentage();
    }
  }
  get answers(): PollAnswer[] {
    return this._answers;
  }

  calculateAnswersPercentage() {
    if (!this._answers.length) {
      return;
    }

    const variants = this._variants.map(v => v['uuid']);
    const answersByVariant = this._answers.reduce((acc, item) => {
      if (variants.indexOf(item.answerUuid) < 0) {
        return acc;
      }
      if (!acc[item.answerUuid]) {
        acc[item.answerUuid] = 0;
      }
      acc[item.answerUuid]++;
      return acc;
    }, {});
    this.variantsWithAnswers = this._variants.map(item => ({
      variant: item,
      percent: answersByVariant[item.uuid] ? Math.round(100 * answersByVariant[item.uuid] / this._answers.length) : 0
    }))
      .sort((a, b) => a.percent > b.percent ? -1 : 1);
  }

  @ToFrontHook
  static toFront(data: any): any {}

  @ToBackHook
  static toBack(data: any): any {}
}
